import { FunctionComponent, h } from 'preact';
import { CarouselSlideProps } from '../types';
import './carousel.slide.scss';

export const CarouselSlide: FunctionComponent<CarouselSlideProps> = ({ slide }) => {
  return (
    <div className={'carousel-slides'}>
      <div
        style={{
          backgroundImage: `url(${slide.thumbnailUrl})`
        }}
        data-testid={`slide-${slide.sortOrder}`}
        className={`str-slide-image`}
      ></div>
    </div>
  );
};
