import { CSSProperties } from 'preact/compat';
import { ILayoutStyleResponse } from '.';

export class LayoutStyle implements ILayoutStyleResponse {
  margin: number | undefined;
  padding: number | undefined;
  marginTop: number | undefined;
  marginBottom: number | undefined;
  marginLeft: number | undefined;
  marginRight: number | undefined;
  paddingTop: number | undefined;
  paddingBottom: number | undefined;
  paddingLeft: number | undefined;
  paddingRight: number | undefined;

  constructor(response?: ILayoutStyleResponse) {
    const defaultMargin = response?.margin ? response.margin : 0;
    const defaultPadding = response?.padding ? response.padding : 0;
    this.marginTop = response?.marginTop ? response.marginTop : defaultMargin;
    this.marginBottom = response?.marginBottom ? response.marginBottom : defaultMargin;
    this.marginLeft = response?.marginLeft ? response.marginLeft : defaultMargin;
    this.marginRight = response?.marginRight ? response.marginRight : defaultMargin;
    this.paddingTop = response?.paddingTop ? response.paddingTop : defaultPadding;
    this.paddingBottom = response?.paddingBottom ? response.paddingBottom : defaultPadding;
    this.paddingLeft = response?.paddingLeft ? response.paddingLeft : defaultPadding;
    this.paddingRight = response?.paddingRight ? response.paddingRight : defaultPadding;
  }

  toStyle(): CSSProperties {
    return {
      margin: `${this.marginTop}px ${this.marginRight}px ${this.marginBottom}px ${this.marginLeft}px`,
      padding: `${this.paddingTop}px ${this.paddingRight}px ${this.paddingBottom}px ${this.paddingLeft}px`
    };
  }
}
