import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchVpaidEnabled = (arid: AdserverRequestId, vpaidEnabled: boolean) => {
  return new Beacon({
    fireOnce: true,
    cacheKey: `vPaidEnabled-${arid}`,

    data: {
      type: vpaidEnabled ? BeaconType.vastVpaidEnabled : BeaconType.vastVpaidNotEnabled,
      arid,
      ...getBaseParams(arid)
    }
  });
};
