import Beacons from 'modules/beacons/utils';
import { Signal } from '@preact/signals';
import { ImaSDK2 } from 'modules/imaSDK';

export const startVideoAd = (
  adserverRequestId: string,
  imaSDK: ImaSDK2 | null,
  videoStarted: Signal<boolean>,
  videoReplayed: Signal<boolean>
) => {
  videoStarted.value = true;
  imaSDK?.sdkEvents.playAds();
  window.setTimeout(() => {
    // this sends out the beacons when once the video is loaded, inView and autoplayed needed a ms push
    if (!videoReplayed.value) {
      Beacons.fire.vPaidEnabled(adserverRequestId, imaSDK?.ad?.h?.vpaid != undefined, true);
      Beacons.fire.clickThroughFound(adserverRequestId, imaSDK?.ad?.h?.clickThroughUrl != undefined, true);
    }
    return;
  }, 400); //TODO check if timeout is needed
};
