import { getBrowserName } from 'modules/environment-info/getBrowserName';
import { getBrowserUrl } from 'modules/environment-info/getBrowserUrl';
import { getPlatformType } from 'modules/environment-info/getPlatformType';
import { sample } from 'modules/monitoring';
import { elementDimensions, elementPosition, thumbnailDimensions } from 'modules/page_geometry';
import ResponseStore from 'modules/response/response_store';
import { buildViewTrackedUnit } from 'tag/helpers/in-view-tracker';
import { insertVisibilityTags } from 'tag/helpers/visibility.helper';
import { Beacon } from '../Beacon';
import { dispatchImpressionNotifications, dispatchWinNotifications } from '../third-party';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';
import { getRenderLatency, setRenderLatencyEndTime } from '../utils/performanceTimeStore';

export const dispatchImpression = (arid: AdserverRequestId, placeholderEl: HTMLElement, evid?: string) => {
  const butlerResponse = ResponseStore.getResponse(arid);
  setRenderLatencyEndTime();

  try {
    buildViewTrackedUnit(placeholderEl, arid);
  } catch (e) {
    sample(new Error('Failed to build view tracked unit'));
  }

  try {
    insertVisibilityTags(butlerResponse, placeholderEl);
  } catch (e) {
    sample(new Error('Failed to insert visibility tags'));
  }

  dispatchImpressionNotifications(arid);
  dispatchWinNotifications(arid);

  const eDimensions = elementDimensions(placeholderEl);
  const ePosition = elementPosition(placeholderEl);
  const thumbDimensions = thumbnailDimensions(placeholderEl);

  return new Beacon({
    data: {
      type: BeaconType.impression,
      arid,
      evid: evid || '',
      browser: getBrowserName(),
      platform: getPlatformType(),
      url: getBrowserUrl(),
      referrer: document.referrer.split('?')[0],
      pwidth: eDimensions.width.toString(),
      pxoff: ePosition.x?.toString(),
      pyoff: ePosition.y?.toString(),
      twidth: thumbDimensions.width.toString(),
      renderLatency: getRenderLatency(),
      ...getBaseParams(arid)
    }
  });
};
