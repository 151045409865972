import PlayIcon from 'assets/icons/play-button.svg';
import { FunctionComponent, h } from 'preact';
import './auto-play.scss';

interface AutoPlayProps {
  autoPlay: boolean;
  onClick: (event: MouseEvent) => void;
  videoStarted: boolean;
}

export const AutoPlay: FunctionComponent<AutoPlayProps> = ({ autoPlay, onClick, videoStarted }) => {
  if (videoStarted) {
    return null;
  }

  return (
    <div className="str-autoplay" hidden={autoPlay} onClick={(e) => onClick(e)} data-testid="autoplay-div">
      <img className="str-icon-play" src={PlayIcon} data-testid="play-button" />
    </div>
  );
};
