export type SupportedLanguage = 'en' | 'fr' | 'nl' | 'fe' | 'is' | 'no' | 'dk' | 'se' | 'de' | 'es' | 'br' | 'it';

export const COUNTRY_LANGUAGE_MAPPING: { [key: string]: SupportedLanguage } = {
  US: 'en',
  CA: 'en',
  GB: 'en',
  FR: 'fr',
  NL: 'nl',
  FE: 'fe',
  IS: 'is',
  NO: 'no',
  DK: 'dk',
  SE: 'se',
  DE: 'de',
  ES: 'es',
  MX: 'es',
  BR: 'br',
  IT: 'it'
};

export const ASSETS_BY_LANGUAGE: {
  [key in SupportedLanguage]: {
    title: string;
    description: string;
    promotedByText: string;
    advertiser: string;
    ctaText: string;
    days: string;
    hours: string;
    minutesAbbreviation: string;
    secondsAbbreviation: string;
  };
} = {
  en: {
    title: 'Click Here for More Information',
    description: "Don't miss this content from our sponsor",
    promotedByText: 'Ad by',
    advertiser: 'Sponsor',
    ctaText: 'See More',
    days: 'Days',
    hours: 'Hours',
    minutesAbbreviation: 'Min',
    secondsAbbreviation: 'Sec'
  },
  fr: {
    title: "Cliquez ici pour plus d'information",
    description: 'Ne manquez pas ce contenu de notre annonceur',
    promotedByText: 'Publicité par',
    advertiser: 'Annonceur',
    ctaText: 'En savoir plus',
    days: 'Jours',
    hours: 'Heures',
    minutesAbbreviation: 'Min',
    secondsAbbreviation: 'Sec'
  },
  nl: {
    title: 'Klik hier voor meer informatie',
    description: 'Mis deze inhoud van onze sponsor niet',
    promotedByText: 'Advertentie door',
    advertiser: 'Adverteerder',
    ctaText: 'Bekijk meer',
    days: 'Days', //TBD
    hours: 'Hours', //TBD
    minutesAbbreviation: 'Min', //TBD
    secondsAbbreviation: 'Sec' //TBD
  },
  fe: {
    title: 'Napsauta tätä saadaksesi lisätietoja',
    description: 'Älä missaa tätä sisältöä sponsoriltamme',
    promotedByText: 'Mainoksen tekijä',
    advertiser: 'Sponsori',
    ctaText: 'Katso lisää',
    days: 'Days', //TBD
    hours: 'Hours', //TBD
    minutesAbbreviation: 'Min', //TBD
    secondsAbbreviation: 'Sec' //TBD
  },
  is: {
    title: 'Smelltu hér fyrir frekari upplýsingar',
    description: 'Ekki missa af þessu efni frá styrktaraðila okkar',
    promotedByText: 'Auglýsingu eftir',
    advertiser: 'Styrktaraðila',
    ctaText: 'Sjá meira',
    days: 'Days', //TBD
    hours: 'Hours', //TBD
    minutesAbbreviation: 'Min', //TBD
    secondsAbbreviation: 'Sec' //TBD
  },
  no: {
    title: 'Klikk her for mer informasjon',
    description: 'Ikke gå glipp av dette innholdet fra sponsoren vår',
    promotedByText: 'Annonse fra',
    advertiser: 'Sponsor',
    ctaText: 'Se mer',
    days: 'Days', //TBD
    hours: 'Hours', //TBD
    minutesAbbreviation: 'Min', //TBD
    secondsAbbreviation: 'Sec' //TBD
  },
  dk: {
    title: 'Klik her for mere information',
    description: 'Gå ikke glip af dette indhold fra vores sponsor',
    promotedByText: 'Annonce fra',
    advertiser: 'Sponsor',
    ctaText: 'Se mere',
    days: 'Days', //TBD
    hours: 'Hours', //TBD
    minutesAbbreviation: 'Min', //TBD
    secondsAbbreviation: 'Sec' //TBD
  },
  se: {
    title: 'Klicka här för mer information',
    description: 'Missa inte detta innehåll från vår sponsor',
    promotedByText: 'Annons av',
    advertiser: 'Sponsor',
    ctaText: 'Se mer',
    days: 'Days', //TBD
    hours: 'Hours', //TBD
    minutesAbbreviation: 'Min', //TBD
    secondsAbbreviation: 'Sec' //TBD
  },
  de: {
    title: 'Klicken Sie hier für weitere Informationen',
    description: 'Verpassen Sie nicht diesen Inhalt von unserem Sponsor',
    promotedByText: 'Anzeige von',
    advertiser: 'Sponsor',
    ctaText: 'Mehr sehen',
    days: 'Tages',
    hours: 'Std',
    minutesAbbreviation: 'Min',
    secondsAbbreviation: 'Sek'
  },
  es: {
    title: 'Haga clic aquí para más información',
    description: 'No te pierdas este contenido de nuestro patrocinador',
    promotedByText: 'Anuncio por',
    advertiser: 'Patrocinador',
    ctaText: 'Ver más',
    days: 'Dias',
    hours: 'Horas',
    minutesAbbreviation: 'Min',
    secondsAbbreviation: 'Seg'
  },
  br: {
    title: 'Clique aqui para obter mais informações',
    description: 'Não perca este conteúdo do nosso patrocinador',
    promotedByText: 'Anúncio do',
    advertiser: 'Patrocinador',
    ctaText: 'Ver mais',
    days: 'Days', //TBD
    hours: 'Hours', //TBD
    minutesAbbreviation: 'Min', //TBD
    secondsAbbreviation: 'Sec' //TBD
  },
  it: {
    title: 'Clicca qui per maggiori informazioni',
    description: 'Non perdere questo contenuto del nostro sponsor',
    promotedByText: 'Annuncio per',
    advertiser: 'Sponsor',
    ctaText: 'Vedi altro',
    days: 'Giorni',
    hours: 'Ore',
    minutesAbbreviation: 'Min',
    secondsAbbreviation: 'Sec'
  }
};

export const FRENCH_DOMAINS = [
  'lespac.com',
  'rds.ca',
  'noovomoi.ca',
  'sympatico.ca',
  'noovo.ca',
  'lookdujour.ca',
  'lapresse.ca',
  'meilleurstrucs.com',
  'toutacoup.ca',
  '985fm.ca',
  'ledevoir.com',
  'francoischarron.com',
  'lechodelaval.ca',
  'lesaffaires.com',
  'journalmetro.com',
  'zone911.com',
  'journalexpress.ca',
  'neomedia.com',
  'kadogagnant.ca',
  'dessinsdrummond.com',
  'cimtchau.ca',
  'sorel-tracyexpress.ca',
  'droit-inc.com',
  'fm93.com',
  'rythmefm.com',
  'recettedemaman.com',
  'ckoi.com',
  'lanouvelle.net',
  'chamblyexpress.ca',
  'watchmojo.com',
  'radiox.com',
  'fm1047.ca',
  'inmemoriam.ca',
  'lereflet.qc.ca',
  'tvagatineau.ca',
  'journalsaint-francois.ca',
  'stockchase.com',
  'monmatane.com',
  'domainebleu.ca',
  'laction.com',
  'macotenord.com',
  'monjoliette.com',
  'journallesoir.ca',
  'cybersoleil.com',
  'lecitoyenrouynlasarre.com',
  'lecitoyenvaldoramos.com',
  'occasionenor.com',
  'montrealracing.com',
  'cime.fm',
  'wknd.fm',
  'valleedurichelieuexpress.ca',
  'lecourrierdusud.ca',
  '919sports.ca',
  'leclaireurprogres.ca',
  'lechodelarivenord.ca',
  'lerefletdulac.com',
  'mabeauce.com',
  'cjso.ca',
  'monvicto.com',
  '957kyk.com',
  'fm1069.ca',
  'fm1033.ca',
  'fm1077.ca',
  'lavantage.qc.ca',
  'quebechebdo.com',
  'secretaire-inc.com',
  'distances.plus',
  'm1029.com',
  'monthetford.com',
  'journaldelevis.com',
  'canadafrancais.com',
  'montemiscouata.com',
  'thereview.ca',
  'lhebdodustmaurice.com',
  'lhebdojournal.com',
  'journalacces.ca',
  'portail-assurance.ca',
  'lavantagegaspesien.com',
  'larevue.qc.ca',
  'monlatuque.com',
  'courrierfrontenac.qc.ca',
  'lavoixdusud.com',
  'genie-inc.com',
  'lejournaldejoliette.ca',
  'lechodelatuque.com',
  'tvaabitibi.ca',
  'lecourriersud.com',
  'planeteradio.ca',
  'lavibe.fm',
  'hebdorivenord.com',
  'lavantposte.ca',
  'beaucemedia.ca',
  'espresso-jobs.com',
  'journallenord.com',
  'monlimoilou.com',
  'courrierdeportneuf.com',
  'lechodemaskinonge.com',
  'montreal.tv',
  'lelacstjean.com',
  'thesarniajournal.ca',
  'haliburtonecho.ca',
  'guidebateau.com',
  'blvd.fm',
  'granbyexpress.com',
  'lactiondautray.com',
  'lhebdomekinacdeschenaux.ca',
  'monquartier.quebec',
  'lexpressmontcalm.com',
  'lepeuplelotbiniere.ca',
  'nouvelleshebdo.com',
  'monsaintsauveur.com',
  'wowfm.com',
  'monsaintroch.com',
  'journaldechambly.com',
  'letoiledulac.ca',
  'guidevacances.ca',
  'gaspesienouvelles.com',
  'estrieplus.com',
  'o973.ca',
  'monmontcalm.com',
  'lasentinelle.ca',
  'leprogres.net',
  'chaleursnouvelles.com',
  'capitalerock.ca',
  'vosclassees.ca',
  'o1035.ca',
  'versants.com',
  'hitcountry.com',
  'o983.ca',
  'laveniretdesrivieres.com',
  'journalleguide.com',
  'o1015.ca',
  'noovoabitibi.ca',
  'plaisir1019.com',
  'thecosmos.ca',
  'plaisir1067.com',
  'coupdoeil.info',
  'o991.ca',
  'o953.ca',
  'o971.ca',
  'plaisir1055.com',
  'creationsdici.ca',
  'nouvelleliberte.ca',
  'noovogatineau.ca',
  'pop965.ca',
  'lerichelieu.ca',
  'pmedici.ca',
  'restosdici.ca',
  'wow971.ca',
  'bouffedici.ca',
  'montreal.wknd.fm',
  'forumquad.com',
  'plaisir941.com',
  'mamanpourlavie.com',
  'reseaucontact.com',
  'jobboom.com',
  'pagesjaunes.ca',
  'meteomedia.com',
  'journaldemontreal.com',
  'tvanouvelles.ca',
  'journaldequebec.com',
  'tabloid.co',
  'tvasports.ca',
  '7jours.ca',
  'lesacdechips.com',
  'guidetoautoweb.com',
  'salutbonjour.ca',
  'recettes.qc.ca',
  'coupdeponce.com',
  'billie.ca',
  'guideautoweb.com',
  'clindoeil.ca',
  'tvhebdo.ca',
  'pesesurstart.com',
  'coupdepouce.com',
  'espaces.ca',
  'viago.ca',
  'moietcie.ca',
  'addik.tv',
  'ellecanada.com',
  'ici.radio-canada.ca',
  'ici.tou.tv',
  'artv.ca',
  'exploratv.ca',
  'ricardocuisine.com',
  'telequebec.tv',
  'ayoye.com',
  'noovo.info',
  'rcinet.ca',
  'lenecrologue.com',
  'mjardiner.com',
  'rencontresportive.com',
  'autohebdo.net',
  'illicoweb.videotron.com',
  'qub.ca',
  'zeste.ca',
  '24heures.ca',
  'videotron.com',
  'matv.ca',
  'silo57.ca',
  'tvhebdo.com',
  'ecranpartage.ca',
  'oabitibi.ca',
  'monemploi.com',
  'ressourcessante.salutbonjour.ca',
  'letoiledulac.com',
  'lereveil.com',
  'cinoche.com',
  'showbizz.net',
  'jeuxvideo.com',
  'allocine.fr',
  'enfant.com',
  'notretemps.com',
  'familiscope.fr',
  'notrefamille.com',
  'magicmaman.com',
  'marieclaire.fr',
  'cosmopolitan.fr',
  'vanityfair.fr',
  'vogue.fr',
  'gqmagazine.fr',
  'admagazine.fr',
  'voici.fr',
  'gala.fr',
  'businessinsider.fr',
  'caminteresse.fr',
  'capital.fr',
  'cesoirtv.com',
  'cuisineactuelle.fr',
  'femmeactuelle.fr',
  'geo.fr',
  'hbrfrance.fr',
  'programme-tv.net',
  'lecanalauditif.ca',
  'sorstu.ca',
  'baronmag.com',
  'atuvu.ca',
  'labibleurbaine.com',
  'nouvelobs.com',
  'courrierinternational.com',
  'telerama.fr',
  'lemonde.fr',
  'huffingtonpost.fr',
  'suttonquebec.com',
  'royallepage.ca',
  'elle.fr',
  'routard.com',
  'bpmsports.ca',
  'educatout.com',
  'ilesdelamadeleine.com'
];

export const FRENCH_BUNDLES = [
  '715135996',
  'com.rds.multisports',
  '1476103084',
  'com.vmediagroup.noovo',
  '451829003',
  'ca.lapresse.android.lapressemobile',
  '603111926',
  'ca.lapresse.lapresseplus',
  'com.newspaperdirect.jdq.market',
  '512764567',
  '942494435',
  'com.netcosports.andjdm',
  'com.quebecormedia.qubradio',
  '1434066481',
  'com.nurun.lcn',
  '437251500',
  'com.nurun.tva_sports',
  '909307725',
  'tv.accedo.ott.flow.tva.quebecor',
  '1150619411',
  'com.numeriq.tvaplus',
  'com.newspaperdirect.jdm.market',
  '512759149',
  'B07FXQT63T',
  'tv.toutv.androidtv',
  'tv.tou.android',
  '411538137',
  'com.radiocanada.android',
  '364125647'
];
