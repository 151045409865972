import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getElementParams } from '../utils';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchGroundControlPresent = (arid: AdserverRequestId, element: HTMLElement) => {
  return new Beacon({
    data: {
      type: BeaconType.groundControlPresent,
      arid,
      ...getBaseParams(arid),
      ...getElementParams(element)
    }
  });
};
