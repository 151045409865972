import LocationUtils from 'modules/location_utils';
import { objToQueryString } from 'modules/utils';
import { BeaconBase } from './BeaconBase';
import { BeaconData, BeaconEvents } from './types';
import { firePixel, getGroundControlInstanceId } from './utils';

const beaconPrioritizedParams: { [index: string]: number } = {
  type: 1,
  arid: 2,
  pkey: 3,
  supplyId: 4,
  tkey: 5,
  ckey: 6,
  userEvent: 7,
  umtime: 8
};

interface BeaconProps extends BeaconEvents {
  data: BeaconData;
  fireOnce?: boolean;
  cacheKey?: string;
}

/**
 * @class Beacon
 * @extends BeaconBase
 * @description Instantiates a new beacon based on a data object to be added to the queue.
 */
export class Beacon extends BeaconBase {
  data: BeaconData;
  url: string;

  constructor(props: BeaconProps) {
    super();

    this.data = {
      renderEnv: LocationUtils.renderEnv(),
      umtime: window.Date.now().toString(),
      version: CONFIG.version,
      refactored: true,
      gcid: getGroundControlInstanceId().gcid,
      ...props.data
    };

    this.url = this.getBeaconUrl();

    this.onSuccess = props.onSuccess;
    this.onFailure = props.onFailure;

    this.fireOnce = this.data.fireOnce ? this.data.fireOnce === true : false;
    this.cacheKey = this.data.cacheKey ? this.data.cacheKey.toString() : undefined;

    this.enqueue();
  }

  getBeaconUrl(): string {
    const resource = 'butler';
    const queryString = objToQueryString(this.data, beaconPrioritizedParams);

    return `${CONFIG.trackingDomain}/${resource}?${queryString}`;
  }

  async fire(): Promise<void> {
    return firePixel(this.url);
  }
}
