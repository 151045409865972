import { OUT_OF_VIEW_PAUSE_THRESHOLD } from 'modules/asset_swap/asset_swap';
import { AdsManager } from 'modules/imaSDK';
import { registerObserver } from 'modules/observer';
import { VastVideo } from '../vast-video.component';
import { startVideoAd } from './startVideoAd';
import { addEventListeners } from './vastEventListeners';
import { VastVideoSignalState } from '../vast-video.component.state';

const EMBED_MINIMUM_WIDTH = 150;

const initializeAd = (component: VastVideo) => {
  const { videoReplayed, autoPlay, inView, videoStarted } = VastVideoSignalState;
  if (component.imaSDK) {
    if (
      (autoPlay.value || videoReplayed.value) &&
      component.imaSDK?.browserSupportsAutoPlay &&
      (component.refThumbnail.current!.offsetWidth >= EMBED_MINIMUM_WIDTH ||
        component.butlerResponse.supplyId === 'fUD7hqXV')
    ) {
      if (inView.value) {
        startVideoAd(component.props.adserverRequestId, component.imaSDK, videoStarted, videoReplayed);
      } else {
        component.imaSDK.sdkEvents.initializeAds();
      }
    }
  }
};

const inviewVideoStartHandler = (component: VastVideo) => {
  const { behaviors } = component.creative;
  const { autoPlay, videoStarted, inView, videoReplayed } = VastVideoSignalState;
  const outOfViewPercentage = behaviors.shouldPauseAtFiftyPercentOutOfView ? OUT_OF_VIEW_PAUSE_THRESHOLD : 0.01;
  const inViewPercentage = behaviors.shouldPauseAtFiftyPercentOutOfView ? OUT_OF_VIEW_PAUSE_THRESHOLD : 0.01;
  registerObserver({
    element: component.refThumbnailContainer.current!,
    inViewPercentage,
    outOfViewPercentage,
    inViewCallback: () => {
      inView.value = true;
      if (!videoStarted.value && autoPlay.value) {
        startVideoAd(component.props.adserverRequestId, component.imaSDK, videoStarted, videoReplayed);
      } else {
        if (!behaviors.shouldNotPauseOutOfView) {
          if (behaviors.shouldPauseAtFiftyPercentOutOfView || component.creative.action === 'hosted-video') {
            if (component.adsManager && autoPlay.value) {
              component.adsManager.resume();
            }
          }
        }
      }
    },
    outOfViewCallback: () => {
      inView.value = false;
      if (!behaviors.shouldNotPauseOutOfView) {
        if (behaviors.shouldPauseAtFiftyPercentOutOfView || component.creative.action === 'hosted-video') {
          if (component.adsManager) {
            component.adsManager.pause();
          }
        }
      }
    }
  });
};

export const adsManagerLoadedHandler = (component: VastVideo, mainContainer: HTMLElement) => {
  return (adsManager: AdsManager) => {
    component.adsManager = adsManager;
    addEventListeners(adsManager, component.butlerResponse, mainContainer);
    initializeAd(component);
    inviewVideoStartHandler(component);
  };
};
