import { FunctionComponent, h } from 'preact';
import './carousel.indicator.scss';
import { CarouselIndicatorProps } from '../types';

export const CarouselIndicator: FunctionComponent<CarouselIndicatorProps> = ({ active, onSlideChanged, slides }) => {
  return (
    <div className={'str-carousel-indicators'}>
      {slides.map((_slide, index) => {
        return (
          <label
            className={`str-carousel-indicator ${index === active ? 'active' : ''}`}
            onClick={(event: MouseEvent) => onSlideChanged(index, event)}
          />
        );
      })}
    </div>
  );
};
