import { useSignal } from '@preact/signals';
import { CountdownConfig } from '@sharethrough/groundcontrol-enhancement-flush';
import { ASSETS_BY_LANGUAGE, SupportedLanguage } from 'modules/asset_swap/assets_by_language';
import { Fragment, h } from 'preact';
import { useEffect } from 'preact/hooks';
import { CountdownState } from './types';

interface CountdownClockProps {
  language: SupportedLanguage;
  countdown: CountdownConfig;
  timestampColor: string;
}

const calculateCountdownTime = (endTimeString: string) => {
  const now = new Date();
  const endTime = new Date(endTimeString);

  let totalSeconds = Math.floor((endTime.getTime() - now.getTime()) / 1000);

  const days = Math.floor(totalSeconds / (3600 * 24));
  totalSeconds %= 3600 * 24;

  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;

  const minutes = Math.floor(totalSeconds / 60);
  totalSeconds %= 60;

  const seconds = totalSeconds;

  return {
    days: String(days).padStart(2, '0'),
    hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0')
  };
};

export const CountdownClock = ({ language, countdown, timestampColor }: CountdownClockProps) => {
  const timer = useSignal<CountdownState>(calculateCountdownTime(countdown.endTime));

  const updateCountdownTimer = () => {
    const updateTime = () => (timer.value = calculateCountdownTime(countdown.endTime));

    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);
  };
  useEffect(updateCountdownTimer, [countdown, timer, timer.value]);

  const { days, hours, minutes, seconds } = timer.value;

  const Colon = () => (
    <div>
      <span style={{ color: timestampColor }}>:</span>
      <label></label>
    </div>
  );

  return (
    <div className="str-countdown-time" data-testid="countdown-time">
      {days && (
        <Fragment>
          <div data-testid="days">
            <span style={{ color: timestampColor }}>{days}</span>
            <label>{ASSETS_BY_LANGUAGE[language].days}</label>
          </div>
          <Colon />
        </Fragment>
      )}
      {hours && (
        <Fragment>
          <div data-testid="hours">
            <span style={{ color: timestampColor }}>{hours}</span>
            <label>{ASSETS_BY_LANGUAGE[language].hours}</label>
          </div>
          <Colon />
        </Fragment>
      )}
      <div data-testid="minutes">
        <span style={{ color: timestampColor }}>{minutes}</span>
        <label>{ASSETS_BY_LANGUAGE[language].minutesAbbreviation}</label>
      </div>
      <Colon />
      <div data-testid="seconds">
        <span style={{ color: timestampColor }}>{seconds}</span>
        <label>{ASSETS_BY_LANGUAGE[language].secondsAbbreviation}</label>
      </div>
    </div>
  );
};
