import Launcher from 'modules/beacons/launcher';
import { ButlerProvider } from 'modules/butler/butlerProvider';
import { CaptionsProvider } from 'modules/enhancements/captions/captionsProvider';
import { EnhancementProvider } from 'modules/enhancements/enhancementProvider';
import { sample } from 'modules/monitoring';
import SafeFrameHandler from 'modules/safe_frame_handler';
import { Component, h } from 'preact';
import WindowHelper from 'tag/helpers/window.helper';
import { DoubleVerifyStatus, DoubleVerifyTag } from '../DoubleVerifyTag';
import { PublisherStylingProvider } from '../common/template/publisher-styling/publisherStylingProvider';
import './ad-unit.scss';
import { ReactAdUnitProps } from './types';
import { responsiveTemplate } from './utils/responsiveTemplate';
import { CSSProperties } from 'preact/compat';

abstract class ReactAdUnitBase extends Component<ReactAdUnitProps> {
  element: HTMLElement | null = null;
  singleClickUsed: boolean = false;
  dvTracker: string | null = this.props.butlerResponse.creative.doubleVerifyTracker;
  state: {
    doubleVerifyStatus: DoubleVerifyStatus;
  } = { doubleVerifyStatus: 'init' };
  abstract experienceToRender(): h.JSX.Element | null;

  publishClickEvent = () => {
    Launcher.trackClick(this.props.butlerResponse.adserverRequestId, this.element as HTMLElement);
  };

  setDoubleVerifyStatus = (status: DoubleVerifyStatus): void => {
    this.setState({ doubleVerifyStatus: status });
  };

  adUnitAttrs = () => {
    const pkey = this.props.butlerResponse.placement.key;
    const arid = this.props.butlerResponse.adserverRequestId;
    const adUnitAttrs: { [key: string]: any } = {
      'data-str-native-key': pkey,
      'data-str-visited-flag': 'true',
      'data-str-arid': arid,
      'data-str-rendered': Date.now().toString()
    };
    return adUnitAttrs;
  };

  get placementSizeStyles(): { maxHeight: number | string; maxWidth: number | string } {
    const { width, height } = this.props.butlerResponse.placement.size;

    return {
      maxHeight: height || 'auto',
      maxWidth: width || 'auto'
    };
  }

  publishClickAndRedirect = (_event: MouseEvent, targetOverride?: { url: string; token: string; nonce: string }) => {
    this.publishClickEvent();
    let url;
    if (targetOverride) {
      url = WindowHelper.redirectUrl(targetOverride.url, targetOverride.token, targetOverride.nonce);
    } else {
      const creative = this.props.butlerResponse.creative;
      url = WindowHelper.redirectUrl(creative.mediaUrl, creative.mediaUrlToken, creative.mediaUrlNonce);
    }
    if (this.props.butlerResponse.creative.clickoutSamePage) {
      WindowHelper.redirectSamePage(url);
    } else {
      window.open(url, '_blank');
    }
  };

  // life cycle method to catch any errors in rendering
  componentDidCatch(error: any): void {
    sample(error, {
      function: 'src/tag/models/ReactAdUnit:componentDidCatch',
      adserverRequestId: this.props.butlerResponse.adserverRequestId
    });
  }

  componentDidMount(): void {
    if (SafeFrameHandler.isSafeFrame()) {
      SafeFrameHandler.register();
    }
  }

  render() {
    const butlerResponse = this.props.butlerResponse;
    const { width, height } = this.props.butlerResponse.placement.size;
    const isSizeless = !width || !height || GENERATOR;

    const responsiveClass = responsiveTemplate(butlerResponse);

    const adUnitStyle: CSSProperties = {
      ...(height && { maxHeight: height }),
      ...(width && { maxWidth: width }),
      ...(height && { minHeight: height }),
      ...(width && { minWidth: width }),
      ...(height && { height: height }),
      ...(width && { width: width })
    };

    return (
      <ButlerProvider butlerResponse={butlerResponse}>
        <EnhancementProvider butlerResponse={butlerResponse}>
          <PublisherStylingProvider butlerResponse={butlerResponse}>
            <CaptionsProvider>
              <div
                className={`str-ad-unit ${isSizeless ? 'str-responsive-ad' : ''} ${responsiveClass}`}
                data-testid="str-react-ad-unit"
                ref={(element) => (this.element = element)}
                {...this.adUnitAttrs()}
                style={adUnitStyle}
              >
                {this.experienceToRender()}
                {butlerResponse.creative.behaviors.enableDoubleVerify && this.dvTracker && (
                  <DoubleVerifyTag
                    tracker={this.dvTracker}
                    setDoubleVerifyStatus={this.setDoubleVerifyStatus}
                    butlerResponse={butlerResponse}
                  />
                )}
              </div>
            </CaptionsProvider>
          </PublisherStylingProvider>
        </EnhancementProvider>
      </ButlerProvider>
    );
  }
}

export default ReactAdUnitBase;
