export const stringParamsToObject = (url: string): Record<string, string> | undefined => {
  try {
    const params = new URLSearchParams(url.split('?')[1]);
    const result: Record<string, string> = {};

    params.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  } catch (e) {
    console.log(e);
    return;
  }
};
