import { sample } from 'modules/monitoring';
import { PublisherStyles } from '../model';
import { PublisherStyleError, PublisherStyleErrorType } from '../model/publisher-style-error';
import { ButlerResponse } from 'modules/butler/response';
import { fetchPublisherStyles } from './fetchPublisherStyle';
import { publisherStyles } from './publisherStyles';

export const loadPublisherStyles = async (butlerResponse: ButlerResponse): Promise<PublisherStyles> => {
  try {
    const { placement } = butlerResponse;
    const publisherStyle = `${placement.publisherKey}_${placement.domain}`;
    if (!publisherStyles[publisherStyle]) return new PublisherStyles();

    return new PublisherStyles(await fetchPublisherStyles(publisherStyles[publisherStyle]));
  } catch (e) {
    if (e instanceof PublisherStyleError) throw e;
    const error = new PublisherStyleError(PublisherStyleErrorType.FETCH_DATA_FAILED, `Error fetching data: ${e}`); //TODO
    sample(error, {
      adserverRequestId: butlerResponse.adserverRequestId
    });
    throw error;
  }
};
