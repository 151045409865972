import { Store } from 'modules/store/Store';

interface PerformanceTimeStore {
  renderLatency: [number, number];
  enhancementRenderLatency: [number, number];
  enhancementFetchLatency: [number, number];
  enhancementFetchError?: string;
}

const store = new Store<PerformanceTimeStore>({
  renderLatency: [0, 0],
  enhancementRenderLatency: [0, 0],
  enhancementFetchLatency: [0, 0]
});

export const setRenderLatencyStartTime = () => {
  store.setState({
    renderLatency: [Date.now(), 0]
  });
};

export const setEnhancementRenderLatencyStartTime = () => {
  store.setState({
    enhancementRenderLatency: [Date.now(), 0]
  });
};

export const setEnhancementFetchLatencyStartTime = () => {
  store.setState({
    enhancementFetchLatency: [Date.now(), 0]
  });
};

export const setRenderLatencyEndTime = () => {
  store.setState({
    renderLatency: [store.getState().renderLatency[0], Date.now()]
  });
};

export const setEnhancementRenderLatencyEndTime = () => {
  store.setState({
    enhancementRenderLatency: [store.getState().enhancementRenderLatency[0], Date.now()]
  });
};

export const setEnhancementFetchLatencyEndTime = () => {
  store.setState({
    enhancementFetchLatency: [store.getState().enhancementFetchLatency[0], Date.now()]
  });
};

export const getRenderLatency = () => {
  const [startTime, endTime] = store.getState().renderLatency;
  const renderLatency = endTime - startTime;
  return renderLatency.toFixed(2);
};

export const getEnhancementRenderLatency = () => {
  const [startTime, endTime] = store.getState().enhancementRenderLatency;
  const renderLatency = endTime - startTime;
  return renderLatency.toFixed(2);
};

export const getEnhancementFetchLatency = () => {
  const [startTime, endTime] = store.getState().enhancementFetchLatency;
  const fetchLatency = endTime - startTime;
  return fetchLatency.toFixed(2);
};
