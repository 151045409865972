import { Ref, useState } from 'preact/hooks';
import { useInterval } from 'tag/models/ad-experiences/hooks';

export const useAutoScroll = (ref: Ref<HTMLDivElement | null>, autoscroll: boolean, speed: number = 150) => {
  const [scrollComplete, setScrollComplete] = useState(false);
  const shouldScroll = !scrollComplete && autoscroll;

  useInterval(
    () => {
      const element = ref.current;
      if (element === null || !element.scrollBy) {
        return;
      }
      const oldPosition = element.scrollTop;
      element.scrollBy(0, 1);
      const newPosition = element.scrollTop;

      if (oldPosition === newPosition) {
        setScrollComplete(true);
      }
    },
    shouldScroll ? speed : null
  );

  return scrollComplete;
};
