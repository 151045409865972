import Bowser from 'bowser';

export const getBrowserName = () => {
  try {
    const { name: browserName } = Bowser.parse(window.navigator.userAgent).browser;
    return browserName || 'unknown';
  } catch (e) {
    return 'parsing-error';
  }
};
