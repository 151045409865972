import 'intersection-observer';

export interface CreatePercentageObserverProps {
  element: HTMLElement;
  inViewPercentage: number;
  outOfViewPercentage: number;
  inViewCallback?: () => void;
  outOfViewCallback?: () => void;
}

export function createPercentageObserver({
  inViewPercentage,
  outOfViewPercentage,
  inViewCallback,
  outOfViewCallback
}: CreatePercentageObserverProps): IntersectionObserver {
  const observerCallback = (entries: IntersectionObserverEntry[], _observer: IntersectionObserver) => {
    const [entry] = entries;

    if (inViewCallback && entry.intersectionRatio >= inViewPercentage) {
      inViewCallback();
    }

    if (outOfViewCallback && entry.intersectionRatio <= outOfViewPercentage) {
      outOfViewCallback();
    }
  };

  return new IntersectionObserver(observerCallback, {
    threshold: [outOfViewPercentage, inViewPercentage]
  });
}

export interface CreatePixelObserverProps {
  element: HTMLElement;
  inViewPixels: number;
  outOfViewPixels: number;
  inViewCallback?: () => void;
  outOfViewCallback?: () => void;
}

export function createPixelObserver({
  element,
  inViewPixels,
  outOfViewPixels,
  inViewCallback,
  outOfViewCallback
}: CreatePixelObserverProps): IntersectionObserver {
  const observerCallback = (entries: IntersectionObserverEntry[], _observer: IntersectionObserver) => {
    const entry = entries[0];
    const { width, height } = entry.boundingClientRect;
    const { width: intersectionWidth, height: intersectionHeight } = entry.intersectionRect;
    const visibleWidth = Math.min(width, intersectionWidth);
    const visibleHeight = Math.min(height, intersectionHeight);
    const visiblePixels = visibleWidth * visibleHeight;

    if (inViewCallback && visiblePixels >= inViewPixels) {
      inViewCallback();
    }

    if (outOfViewCallback && visiblePixels <= outOfViewPixels) {
      outOfViewCallback();
    }
  };

  let thresholdValues: number[];
  if (element.clientHeight > 0) {
    thresholdValues = [outOfViewPixels / element.clientHeight, inViewPixels / element.clientHeight];
  } else {
    thresholdValues = [0, 1];
  }

  return new IntersectionObserver(observerCallback, {
    threshold: thresholdValues
  });
}

export function registerPercentageObserver(props: CreatePercentageObserverProps) {
  const observer = createPercentageObserver(props);
  observer.observe(props.element);
}

export function registerPixelObserver(props: CreatePixelObserverProps) {
  const observer = createPixelObserver(props);
  observer.observe(props.element);
}

export function registerObserver(props: CreatePercentageObserverProps | CreatePixelObserverProps) {
  if ('inViewPercentage' in props || 'outOfViewPercentage' in props) {
    registerPercentageObserver(props as CreatePercentageObserverProps);
  } else if ('inViewPixels' in props || 'outOfViewPixels' in props) {
    registerPixelObserver(props as CreatePixelObserverProps);
  } else {
    throw new Error('inViewPercentage/outOfViewPercentage or inViewPixels/outOfViewPixels must be provided');
  }
}
