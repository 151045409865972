import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { AmazonPrimeLogo } from '../prime-logo/prime-logo.component';
import './coupon.scss';

export const AmazonCoupon: FunctionComponent = () => {
  const couponElementRef = useRef<HTMLDivElement>(null);
  const assets = useNativeAssets();

  useEffect(() => {
    if (assets && assets.coupon && couponElementRef.current) {
      const couponElement = couponElementRef.current;

      if (couponElement) {
        const container = couponElement.closest('.str-amazon-native');
        if (container) {
          container.classList.add('str-amazon-native-coupon');
        }
      }
    }
  }, [assets]);

  if (!assets) return null;

  const { coupon } = assets;
  if (!coupon) return null;

  return (
    <div className="str-amazon-coupon-container">
      <div className="str-amazon-coupon" ref={couponElementRef}>
        {coupon.value}
      </div>
      <AmazonPrimeLogo />
    </div>
  );
};
