export type BeaconParam = string | number | boolean;
export type AdserverRequestId = string;

export enum BeaconType {
  bannerRendered = 'banner.rendered',
  bundleIdWinTest = 'bundle_id_win_test',
  chromeTopics = 'chromeTopics',
  completionPercent = 'completionPercent',
  doubleVerifyError = 'doubleVerifyError',
  doubleVerifyFallback = 'doubleVerifyFallback',
  doubleVerifyRender = 'doubleVerifyRender',
  doubleVerifySuccess = 'doubleVerifySuccess',
  experiment = 'is_experiment',
  groundControlPresent = 'groundControlPresent',
  iframeBusterFailed = 'iframeBusterFailed',
  imaClick = 'imaClick',
  imaNoCreativeIdFound = 'imaNoCreativeIdFound',
  impression = 'impression',
  impressionReceived = 'impressionReceived',
  isEnhanced = 'isEnhanced',
  jsTrackerFailedToLoad = 'jsTrackerFailedToLoad',
  pixelVisibleInApp = 'pixelVisibleInApp',
  silentAutoPlayDuration = 'silentAutoPlayDuration',
  subtitlesEnabled = 'subtitlesEnabled',
  userEvent = 'userEvent',
  vastClickthroughFound = 'vastClickthroughFound',
  vastClickthroughNotFound = 'vastClickthroughNotFound',
  vastErrorCode = 'vastErrorCode',
  vastVpaidEnabled = 'VastVpaidEnabled',
  vastVpaidNotEnabled = 'VastVpaidNotEnabled',
  videoPlay = 'videoPlay',
  videoStart = 'videoStart',
  videoViewDuration = 'videoViewDuration',
  videoVolumeOn = 'videoVolumeOn',
  viewableImpression = 'visible',
  base64WinUrl = 'base_64_win_url',
  nurlFail = 'winNotificationFail'
}

export enum UserEvent {
  AutoplayVideoEngagement = 'autoplayVideoEngagement',
  Clickout = 'clickout',
  Share = 'share'
}

export enum VideoTypes {
  clickToPlay = 'clickToPlay',
  instantPlay = 'instant'
}

export type BeaconData = {
  type: BeaconType;
  arid: AdserverRequestId;
  [key: string]: BeaconParam;
};

export interface BeaconEvents {
  onFailure?: () => void;
  onSuccess?: () => void;
}

export enum CreativeBeaconType {
  Click = 'click',
  Impression = 'impression',
  Play = 'play',
  Visible = 'visible',
  WinNotification = 'win-notification',
  VideoWinNotification = 'video-win-notification',
  VideoImpression = 'video-impression',
  FirstQuartile = 'first_quartile',
  Midpoint = 'midpoint',
  ThirdQuartile = 'third_quartile',
  CompletedSilentPlay = 'completed_silent_play',
  SilentPlay = 'silent_play',
  TenSecondSilentPlay = 'ten_second_silent_play',
  FifteenSecondSilentPlay = 'fifteen_second_silent_play',
  ThirtySecondSilentPlay = 'thirty_second_silent_play'
}

export type BeaconOptions = Omit<BeaconData, 'type' | 'arid'>;
