import { Signal, signal } from '@preact/signals';
import Launcher from 'modules/beacons/launcher';
import Beacons from 'modules/beacons/utils';
import { CarouselCreative } from 'modules/butler/creative';
import { shouldFireFoursquarePixel } from 'modules/policies';
import { h } from 'preact';
import WindowHelper from 'tag/helpers/window.helper';
import { Template } from 'tag/models/common/template';
import { AdExperienceBase, AdExperienceBaseProps } from '../ad-experience';
import { CarouselControl } from './carousel.control/carousel.control';
import { CarouselIndicator } from './carousel.indicator/carousel.indicator';
import './carousel.scss';
import { CarouselSlide } from './carousel.slide/carousel.slide';
import { CarouselState } from './types';
const AUTO_SCROLL_INTERVAL_MS = 2000;
export const activeSlideIndex: Signal<number> = signal(0);

export class Carousel2 extends AdExperienceBase<CarouselCreative, AdExperienceBaseProps, CarouselState> {
  slideshowWrapper: HTMLDivElement | null = null;
  enhancedClasses: string = '';
  intervalid: NodeJS.Timeout | string | number | undefined;
  templates = new Array<HTMLDivElement>();

  constructor(props: AdExperienceBaseProps) {
    super(props);
    this.state = { scroll: true };
  }

  restartInterval = () => {
    if (!this.butlerResponse.creative.slideshowAutoscroll) {
      return;
    }

    if (this.intervalid) clearInterval(this.intervalid);
    const that = this;
    this.intervalid = setInterval(() => {
      if (!that.state.scroll) {
        return;
      }
      that.changeActiveSlide(1);
    }, AUTO_SCROLL_INTERVAL_MS);
  };

  getChildSize = () => {
    const firstChildElement = (this.templates[0] as HTMLElement).firstElementChild;
    if (firstChildElement) this.slideshowWrapper!.style.height = `${firstChildElement.scrollHeight}px`;
  };

  componentDidMount(): void {
    Launcher.trackRenderedImpression(
      this.butlerResponse.adserverRequestId,
      this.slideshowWrapper!.parentElement as HTMLElement
    );

    const fireFoursquarePixel = shouldFireFoursquarePixel(this.butlerResponse.creative);

    if (fireFoursquarePixel) {
      Beacons.fire.foursquareImpression(this.butlerResponse);
    }

    this.getChildSize();
    this.restartInterval();
  }

  componentWillUnmount() {
    if (this.intervalid) clearInterval(this.intervalid);
  }

  handleClick = (event: MouseEvent) => {
    const slideCreative = this.butlerResponse.creative.slides[activeSlideIndex.value];
    const slideUrl = slideCreative.mediaUrl;
    const slideToken = slideCreative.mediaUrlToken;
    const slideNonce = slideCreative.mediaUrlNonce;
    if (slideUrl && slideToken && slideNonce) {
      const url = WindowHelper.redirectUrl(slideUrl, slideToken, slideNonce);
      this.props.onClick(event);
      window.open(url, '_blank');
    }
  };

  mouseEnterHandler = () => {
    if (this.butlerResponse.creative.slideshowAutoscroll) {
      this.setState({ scroll: false });
    }
  };

  mouseLeaveHandler = () => {
    if (this.butlerResponse.creative.slideshowAutoscroll) {
      this.setState({ scroll: true });
    }
    this.restartInterval();
  };

  setActiveSlide = (activeIndex: number, event: MouseEvent) => {
    event.stopPropagation();
    // this.setState({ activeIndex });
    activeSlideIndex.value = activeIndex;
  };

  changeActiveSlide = (index: number, event?: MouseEvent) => {
    if (event) event.stopPropagation();
    let activeIndex = activeSlideIndex.value + index;
    let length = this.creative.slides.length;
    // If out of bounds, set to first or last slide
    if (activeIndex < 0 || activeIndex >= length) {
      activeIndex = activeIndex < 0 ? length - 1 : 0;
    }
    activeSlideIndex.value = activeIndex;
  };

  render() {
    return (
      <div
        className={`str-react-template str-carousels`}
        data-testid="carousel-container"
        onClick={this.handleClick}
        ref={(slideshowWrapper) => (this.slideshowWrapper = slideshowWrapper)}
        onMouseEnter={this.mouseEnterHandler}
        onMouseLeave={this.mouseLeaveHandler}
      >
        <div
          className={`str-carousels-container`}
          style={{
            transform: `translateX(${activeSlideIndex.value * -100}%)`,
            height: `100%`,
            transition: 'all 0.5s ease-out'
          }}
        >
          {this.creative.slides.map((slide, index) => {
            const creative: CarouselCreative = {
              ...this.creative,
              title: slide.headline!,
              description: slide.description!
            };
            return (
              <div
                className={`str-carousel ${index === activeSlideIndex.value ? 'active' : ''}`}
                style={{ transform: `translateX(${index * 100}%)` }}
              >
                <Template
                  ref={(activator: HTMLDivElement) => (this.templates[index] = activator as HTMLDivElement)}
                  creative={creative}
                  placement={this.placement}
                  country={this.butlerResponse.country}
                  adserverRequestId={this.props.adserverRequestId}
                >
                  <div className="str-thumbnail-wrapper">
                    <div className="str-thumbnail">
                      <CarouselIndicator
                        onSlideChanged={this.setActiveSlide}
                        slides={this.creative.slides}
                        active={activeSlideIndex.value}
                      />
                      <CarouselSlide slide={slide} active={activeSlideIndex.value} />
                      <CarouselControl onClick={this.changeActiveSlide} />
                    </div>
                  </div>
                </Template>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
