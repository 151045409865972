import Launcher from 'modules/beacons/launcher';
import Beacons from 'modules/beacons/utils';
import { ThumbnailCarouselCreative } from 'modules/butler/creative';
import { shouldFireFoursquarePixel } from 'modules/policies';
import { h } from 'preact';
import WindowHelper from 'tag/helpers/window.helper';
import { AdExperienceBase, AdExperienceBaseProps } from '../ad-experience';
import { CarouselControl } from '../carousel 2/carousel.control/carousel.control';
import { CarouselIndicator } from '../carousel 2/carousel.indicator/carousel.indicator';
import './slideshow.scss';
import { SlideshowSlide } from './slideshow.slides/slideshow.slides';
import { SlideshowState } from './types';
import { Template } from 'tag/models/common/template';
const AUTO_SCROLL_INTERVAL_MS = 2000;

export class Slideshow extends AdExperienceBase<ThumbnailCarouselCreative, AdExperienceBaseProps, SlideshowState> {
  element: HTMLElement | null = null;
  intervalid: NodeJS.Timeout | string | number | undefined;

  constructor(props: AdExperienceBaseProps) {
    super(props);
    this.state = { scroll: true, activeIndex: 0 };
  }

  handleClick = (event: MouseEvent) => {
    const url = WindowHelper.redirectUrl(
      this.creative.mediaUrl,
      this.creative.mediaUrlToken,
      this.creative.mediaUrlNonce
    );
    this.props.onClick(event);
    window.open(url, '_blank');
  };

  setActiveSlide = (activeIndex: number, event: MouseEvent) => {
    event.stopPropagation();
    this.setState({ activeIndex });
  };

  changeActiveSlide = (index: number, event?: MouseEvent) => {
    if (event) event.stopPropagation();
    let activeIndex = this.state.activeIndex + index;
    let length = this.creative.slides.length;
    if (activeIndex < 0 || activeIndex >= length) {
      activeIndex = activeIndex < 0 ? length - 1 : 0;
      this.setState({ activeIndex });
    } else {
      this.setState({ activeIndex });
    }
  };

  restartInterval = () => {
    if (!this.butlerResponse.creative.slideshowAutoscroll) {
      return;
    }

    if (this.intervalid) clearInterval(this.intervalid);
    const that = this;
    this.intervalid = setInterval(() => {
      if (!that.state.scroll) {
        return;
      }
      that.changeActiveSlide(1);
    }, AUTO_SCROLL_INTERVAL_MS);
  };

  componentDidMount() {
    Launcher.trackRenderedImpression(this.props.adserverRequestId, this.element?.parentElement as HTMLElement);

    const fireFoursquarePixel = shouldFireFoursquarePixel(this.butlerResponse.creative);
    if (fireFoursquarePixel) {
      Beacons.fire.foursquareImpression(this.butlerResponse);
    }

    this.restartInterval();
  }

  componentWillUnmount() {
    if (this.intervalid) clearInterval(this.intervalid);
  }

  mouseEnterHandler = () => {
    if (this.creative.slideshowAutoscroll) {
      this.setState({ scroll: false });
    }
  };

  mouseLeaveHandler = () => {
    if (this.creative.slideshowAutoscroll) {
      this.setState({ scroll: true });
    }
  };

  render() {
    return (
      <div
        data-testid="slideshow-container"
        className="str-react-template"
        onClick={this.handleClick}
        onMouseEnter={this.mouseEnterHandler}
        onMouseLeave={this.mouseLeaveHandler}
        ref={(element) => (this.element = element)}
      >
        <Template
          creative={this.creative}
          placement={this.placement}
          country={this.butlerResponse.country}
          adserverRequestId={this.props.adserverRequestId}
        >
          <div className="str-thumbnail-wrapper">
            <div className="str-thumbnail">
              <CarouselIndicator
                onSlideChanged={this.setActiveSlide}
                slides={this.creative.slides}
                active={this.state.activeIndex}
              />
              <div
                style={{
                  transform: `translateX(${this.state.activeIndex * -100}%)`,
                  height: `100%`,
                  transition: 'all 0.5s ease-out'
                }}
              >
                <SlideshowSlide activeIndex={this.state.activeIndex} slides={this.creative.slides} />
              </div>
              <CarouselControl onClick={this.changeActiveSlide} />
            </div>
          </div>
        </Template>
      </div>
    );
  }
}
