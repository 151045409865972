import { isTestEnvironment } from 'modules/environment-info/isTestEnvironment';
import { debug } from 'modules/log';
import { stringParamsToObject } from './stringParamsToObject';

export const firePixel = async (url: string, timeout: number = 1500): Promise<void> => {
  if (isTestEnvironment()) {
    const params = stringParamsToObject(url);
    if (params) {
      debug(`Beacon - ${(params as any)['type']}`, params);
    }
    return Promise.resolve();
  }

  if (typeof url !== 'string' || url.length === 0) {
    throw new Error('Invalid URL');
  }

  return new Promise((resolve, reject) => {
    const img = new Image();

    const timeoutId = setTimeout(() => {
      img.onload = img.onerror = null;
      reject(new Error(`Pixel tracking timed out for URL: ${url}`));
    }, timeout);

    img.onload = () => {
      clearTimeout(timeoutId);
      resolve();
    };

    img.onerror = (errorEvent: Event) => {
      const error = new Error(`Pixel tracking failed: ${JSON.stringify(errorEvent)}`);

      // sample(error, {
      //   errorEvent,
      //   url: url
      // });

      clearTimeout(timeoutId);
      reject(error);
    };

    img.src = url;
  });
};
