// The Trade Desk banners sometimes build an iframe with a src attribute
// that contains a 'clickTag' param, which is the clickout url
export const extractClickTagParam = (adm: string) => {
  // banner will contain a line that looks like i.setAttribute("src", "https://html5.adsrvr.org/foo?clickTag=http%3A%2F%2Fwww.foo.bar&cb=123")
  // The end of the url will be the closing string quote or an unescape param that belongs to the src url
  const clickTagRegex = /clickTag=(.*?)["|'|&]/i;
  const match = adm.match(clickTagRegex);

  if (!match) {
    return null;
  }

  return decodeURIComponent(match[1]);
};
