import Beacons from 'modules/beacons/utils';
import { getJsonObject } from 'modules/encoding/base64';
import * as Log from 'modules/log';
import { sample } from 'modules/monitoring';
import bootAd from 'tag/ad';

export type PayloadOptions = {
  placementKey: string | undefined;
  clickTracker: string | undefined;
  jsTracker: string | undefined;
  replaceIframeContainer: string | undefined;
  bidResponse: { bidId: string } | undefined;
  strNativeKey: string;
  strClickTracker: string | undefined;
  strJsTracker: string | undefined;
  retainInvisibleIframe: string | undefined;
};

// Listens for a message emitted from within an iframe that contains ad server response with which
// to render ad unit directly to the page (outside of an iframe)
// see IframeBuster class for message details
class IframeMessageListener {
  static listen() {
    // In the case that gc.js gets on page multiple times,
    // we only want one message listener listening to the iframe
    // buster message, otherwise we'll double process the message
    if (window.STR.IframeMessageListenerRegistered) {
      return;
    }
    IframeMessageListener.registerListener();
    window.STR.IframeMessageListenerRegistered = true;
  }

  static registerListener() {
    window.addEventListener('message', IframeMessageListener.replaceIframeWithPlacementTag);
  }

  static getInsertionPointIframeByID(frameIdentifier: string): HTMLElement {
    let element =
      document.getElementById(frameIdentifier) ||
      // TODO: Would a DFP iframe ever use the name attribute? If not, can eliminate the call to getElementsByName.
      document.getElementsByName(frameIdentifier)[0];

    if (!!element && element.firstChild && element.firstChild.nodeName === 'IFRAME') {
      return element.firstChild as HTMLElement;
    }

    if (!!element) {
      return element;
    }

    throw new Error(`Unable to find insertion point iframe with id or name "${frameIdentifier}"`);
  }

  // sometimes the insertion point (of the ad unit) iframe is not the iframe where the iframe-buster script is loaded
  // iframe buster frame is generally a child of the insertionPointIframe (where the ad is rendered)
  static getOriginIFrameByID(
    frameIdentifier: string,
    insertionPointIframe: HTMLIFrameElement
  ): HTMLIFrameElement | null {
    if (!insertionPointIframe.contentWindow) return null;
    let originIFrame: HTMLIFrameElement | null = null;
    const currentIframe: Window = insertionPointIframe.contentWindow;

    for (const frame of Array.from(currentIframe.frames)) {
      if (frame.frameElement && frameIdentifier === frame.frameElement.id) {
        originIFrame = currentIframe.document.getElementById(frameIdentifier) as HTMLIFrameElement;
        break;
      }
    }

    return originIFrame;
  }

  static generateSTRDivTag(payload: PayloadOptions): HTMLElement {
    let tag = document.createElement('div');

    tag.setAttribute('id', 'str-native-key');
    tag.setAttribute('data-str-native-key', payload.strNativeKey);

    if (payload.strClickTracker !== undefined) {
      tag.setAttribute('data-str-click-tracker', payload.strClickTracker);
    }

    if (payload.strJsTracker !== undefined) {
      tag.setAttribute('data-str-js-tracker', payload.strJsTracker);
    }

    if (payload.retainInvisibleIframe !== undefined) {
      tag.setAttribute('data-str-retain-invisible-iframe', payload.retainInvisibleIframe);
    }

    if (payload.bidResponse !== undefined) {
      IframeMessageListener.appendSTXResponse(tag, payload.bidResponse);
    }

    return tag;
  }

  static appendSTXResponse(tag: HTMLElement, bidResponse: { bidId: string }) {
    let bidId;
    try {
      bidId = getJsonObject(bidResponse).bidId;
    } catch (error) {
      sample(error, {
        function: 'IframeMessageListener:appendSTXResponse',
        bidResponse: bidResponse
      });
    }

    const bidResponseName = `str_response_${bidId}`;
    (window as any)[bidResponseName] = bidResponse;
    tag.setAttribute('data-stx-response-name', bidResponseName);
  }

  static replaceIframeWithPlacementTag(event: MessageEvent) {
    let payload = null;
    let data = event.data;

    if (typeof data !== 'string') return;

    if (data.indexOf('strNativeKey') > -1 && data.indexOf('insertionPointIframeIdentifier') > -1) {
      try {
        payload = JSON.parse(data);
      } catch (error) {
        sample(error, {
          function: 'IframeMessageListener:replaceIframeWithPlacementTag',
          bidResponse: data
        });
        return;
      }
    } else {
      return;
    }

    if (payload === null || payload.strNativeKey === undefined) {
      return;
    }

    let insertionEl: HTMLElement;
    try {
      // getInsertionPointIframeById() will throw an error if it does not find an element
      insertionEl = IframeMessageListener.getInsertionPointIframeByID(payload.insertionPointIframeIdentifier);
      // Avoid double processing DFP elements
      if (insertionEl.getAttribute('data-str-iframe-visited') === 'true') {
        return;
      }
    } catch (error) {
      Beacons.fire.iframeBusterFailed(Beacons.iframeBusterFailureTypes.iframeNotFound, payload);
      Log.error(error.message);
      return;
    }

    let insertionIframe = insertionEl as HTMLIFrameElement;

    // Check that the ad slot insertion point element is a valid iframe
    if (!insertionIframe.contentWindow) return;

    // Mark an attribute that we've handled this iframe already
    insertionIframe.setAttribute('data-str-iframe-visited', 'true');

    // Send a message from the client sdk to the iframe buster script for the iframe buster to stop posting the butler
    try {
      if (payload.originIframeIdentifier && payload.insertionPointIframeIdentifier !== payload.originIframeIdentifier) {
        const originIframe =
          IframeMessageListener.getOriginIFrameByID(payload.originIframeIdentifier, insertionIframe) || insertionIframe;
        if (originIframe.contentWindow) {
          originIframe.contentWindow.postMessage('strStopPosting', '*');
        } else {
          throw Error(`Unable to send message "strStopPosting" to the iframe buster`);
        }
      }
    } catch (error) {
      // this does not stop ad rendering - it just means the iframe buster will spam the bust-out message
      Log.error(error.message);
    }

    try {
      if (insertionIframe.parentNode) {
        const strTag = IframeMessageListener.generateSTRDivTag(payload);
        insertionIframe.parentNode.insertBefore(strTag, insertionIframe.nextSibling);
        bootAd(strTag);
      } else {
        throw Error(`Unable to insert STR placement tag created with iframe buster payload`);
      }
    } catch (error) {
      Beacons.fire.iframeBusterFailed(Beacons.iframeBusterFailureTypes.placementTagInsertion, payload);
      Log.error(error.message);
    } finally {
      insertionIframe.style.display = 'none';
    }
  }
}

export default IframeMessageListener;
