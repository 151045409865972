import { Creative } from 'modules/butler/creative';
import { ButlerPlacement } from 'modules/butler/placement';

const horizontalLayouts: { [key: string]: string[] } = {
  '300x250': ['250x250', '300x250', '336x280'],

  '320x100': ['300x50', '320x50'],

  '728x90': ['120x100', '300x50', '320x100', '320x50'],

  '970x250': ['250x250', '300x250', '320x250', '336x228', '728x90']
};

const verticalLayouts: { [key: string]: string[] } = {
  '160x600': ['160x600', '120x600'],
  '300x250': ['300x50', '320x50'],
  '300x600': ['160x600', '300x250', '300x600']
};

export const getLayoutType = (creative: Creative, placement: ButlerPlacement): string | null => {
  const creativeSize = `${creative.size.width}x${creative.size.height}`;
  const placementSize = `${placement.size.width}x${placement.size.height}`;

  const isVerticalLayout = verticalLayouts[placementSize] && verticalLayouts[placementSize].includes(creativeSize);
  if (isVerticalLayout) return 'vertical';

  const isHorizontalLayout =
    horizontalLayouts[placementSize] && horizontalLayouts[placementSize].includes(creativeSize);
  if (isHorizontalLayout) return 'horizontal';

  return null;
};
