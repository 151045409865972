import { CountdownConfig } from '@sharethrough/groundcontrol-enhancement-flush';

export const getActiveCountdown = (configs: CountdownConfig[]) => {
  for (const config of configs) {
    const startTime = config.startTime ? new Date(config.startTime) : null;
    const endTime = new Date(config.endTime);

    if (isStarted(startTime) && !isExpired(endTime)) {
      return config;
    }
  }
  return;
};

const isExpired = (expiryTime: Date) => {
  const currentTime = new Date();
  return expiryTime < currentTime;
};

const isStarted = (startTime: Date | null) => {
  if (!startTime) return true;

  const currentTime = new Date();
  return startTime < currentTime;
};
