export enum QuartileKey {
  Q25 = '25',
  Q50 = '50',
  Q75 = '75',
  Q95 = '95',
  Q100 = '100'
}

export enum DurationKey {
  D3 = 3,
  D10 = 10,
  D15 = 15,
  D30 = 30
}

export enum CreativeType {
  Banner = 'banner',
  Carousel = 'carousel',
  Clickout = 'clickout',
  HostedVideo = 'hosted-video',
  NativeOutstream = 'native-outstream',
  Outstream = 'outstream',
  Scroller = 'scroller',
  Slideshow = 'slideshow',
  Video = 'video',
}

export const EnhanceableCreativeTypes = [
  CreativeType.Banner,
  CreativeType.NativeOutstream,
  CreativeType.Carousel,
  CreativeType.Scroller,
  CreativeType.Slideshow
];
