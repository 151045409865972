export const addIframeWindowClick = (iframe: HTMLIFrameElement, onClickEvent: () => void) => {
  if (!iframe || !iframe.contentDocument || !iframe.contentWindow || !iframe.contentWindow.document) {
    return;
  }

  if (!iframe.dataset.hasClickListener) {
    iframe.contentWindow.window.addEventListener('click', () => {
      onClickEvent();
    });
    iframe.dataset.hasClickListener = 'true';
  }

  const observer = new MutationObserver(() => {
    const innerIframes = iframe.contentDocument?.querySelectorAll('iframe');
    innerIframes?.forEach((innerIframe: HTMLIFrameElement) => {
      if (innerIframe.contentDocument) addIframeWindowClick(innerIframe, onClickEvent);
    });
  });

  observer.observe(iframe.contentDocument, { childList: true, subtree: true });
};
