import Ajax from 'modules/ajax';
import Butler from 'modules/butler/api';
import { sample } from 'modules/monitoring';
import { getParameterByName } from 'modules/utils';

const nagRequestURI = (creativeId: string, placementKey: string) => {
  return `https://generator.sharethrough.com/api/external/v1/paparazzi_creatives/${creativeId}?placement_key=${placementKey}`;
};

// This logic is used for visual QA on test pages.
const adFakerRequestURI = ({
  type,
  templateKey,
  isi,
  width = '',
  height = '',
  captions = '',
  placementKey = ''
}: {
  type: string;
  templateKey: string;
  isi: string;
  width: string;
  height: string;
  captions: string;
  placementKey: string;
}) => {
  let uri = `https://ad-faker.sharethrough.com/supply/v1/?placement_key=${placementKey}&type=${type}&template_key=${templateKey}`;
  if (isi === '1') {
    uri += `&isi=${isi}`;
  }
  if (width && height) {
    uri += `&width=${width}&height=${height}`;
  }
  if (captions === '1') {
    uri += `&captions=${captions}`;
  }
  return uri;
};

const b64DecodeUnicode = (encodedResponse: string) => {
  try {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(swapUrlSafeB64Symbols(encodedResponse))
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
  } catch (e) {
    // If str is not utf-8 encoded, we skip the uri encoding
    return atob(swapUrlSafeB64Symbols(encodedResponse));
  }
};

const swapUrlSafeB64Symbols = (encodedResponse: string) => {
  let mapped = (encodedResponse + '===').slice(0, encodedResponse.length + (encodedResponse.length % 4));
  return mapped.replace(/-/g, '+').replace(/_/g, '/');
};

const getCachedResponse = (cacheKey: string) => {
  const cachedResponse = (window as any)[cacheKey];

  let responseObject;
  if (typeof cachedResponse === 'string') {
    try {
      responseObject = JSON.parse(b64DecodeUnicode(cachedResponse));
    } catch (e) {
      sample('Unable to decode cached response: ' + e, {
        response: b64DecodeUnicode(cachedResponse)
      });
    }
  } else {
    responseObject = cachedResponse;
  }
  return responseObject;
};

const CreativeResponse = {
  availableSynchronously: (placeholder: Element): boolean => {
    return !!placeholder.getAttribute('data-stx-response-name') && !placeholder.getAttribute('data-str-ad-faker-type');
  },

  getResponseSync: (placeholder: Element): any => {
    if (!CreativeResponse.availableSynchronously(placeholder)) return null;
    const cacheKey = placeholder.getAttribute('data-stx-response-name');
    return getCachedResponse(cacheKey!);
  },

  // If cacheKey is present, adserver response is cached on the window.
  // In a header bidding flow we will have already made the adserver request
  // and cached the response on the window
  getResponse: async (placeholder: Element, placementKey: string): Promise<any> => {
    const strModify: boolean = getParameterByName('str_modify') === 'true';
    const adFakerType = placeholder.getAttribute('data-str-ad-faker-type');
    const adFakerIsi: string | null = placeholder.getAttribute('data-str-isi');
    const cacheKey = placeholder.getAttribute('data-stx-response-name');
    const adFakerKey = placeholder.getAttribute('data-str-native-key');

    const bannerWidth: string | null = placeholder.getAttribute('data-str-banner-width');
    const bannerHeight: string | null = placeholder.getAttribute('data-str-banner-height');
    const captions: string | null = placeholder.getAttribute('data-str-captions');
    const fakeTemplate: string | null = placeholder.getAttribute('data-str-fake-template');

    const butlerParams: { [key: string]: string } = {};

    if (!!adFakerType) {
      return Ajax.GetJson(
        adFakerRequestURI({
          type: adFakerType,
          templateKey: placeholder.getAttribute('data-str-or-template-key') || '',
          isi: adFakerIsi || '',
          width: bannerWidth || '',
          height: bannerHeight || '',
          captions: captions || '',
          placementKey: adFakerKey || ''
        })
      ).then((res) =>
        fakeTemplate
          ? {
              ...res,
              placement: {
                placementAttributes: {
                  template: fakeTemplate
                }
              }
            }
          : res
      );
    }

    // Forced Creative
    if (strModify) {
      const nagCreativeId = getParameterByName('nag');

      if (!!nagCreativeId) {
        // forces Native Ad Generator creative
        return Ajax.GetJson(nagRequestURI(nagCreativeId, placementKey));
      } else {
        // incorrect usage of str_modify parameter; default to btlr request
        return Butler.request(placementKey);
      }
    }

    // Header Bidding
    else if (cacheKey) {
      return Promise.resolve(getCachedResponse(cacheKey));
    }

    // Other (tag based)
    else {
      return Butler.request(placementKey, { ...butlerParams });
    }
  }
};

export default CreativeResponse;
