import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import './amazon-logo.scss';

export const AmazonAvailableLogo: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { availableLogo } = assets;
  if (!availableLogo) return null;

  return (
    <div
      className="str-amazon-available-logo"
      data-testid="amazon-available-logo"
      style={{ backgroundImage: `url(${availableLogo.url})` }}
    ></div>
  );
};
