import { experimentManager } from 'modules/experiments';
import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';

export const dispatchExperiment = (arid: AdserverRequestId, experimentId?: string) => {
  const experiment = experimentManager.getActiveExperiment();
  if (!experiment) return;
  if (experimentId && experiment.id !== experimentId) return;

  const isViteBuild = CONFIG.version.includes('-vite');
  if (isViteBuild) {
    return;
  }

  return new Beacon({
    data: {
      type: BeaconType.experiment,
      arid,
      experiment_id: experiment.id,
      experiment_variant_id: experiment.result
    },

    fireOnce: true,
    cacheKey: `${BeaconType.experiment}-${arid}`
  });
};
