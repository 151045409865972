import { ButlerContext } from 'modules/butler/butlerProvider';
import { FunctionComponent, h } from 'preact';
import { Ref, useContext, useEffect, useRef, useState } from 'preact/hooks';
import './isi.scss';
import { useAnchorClickHandler } from './useAnchorClickHandler';
import { useAutoScroll } from './useAutoScroll';

const DEFAULT_HEADLINE = 'Important Safety Information';

const ISI: FunctionComponent<{}> = () => {
  const butlerResponse = useContext(ButlerContext);
  const textRef: Ref<HTMLDivElement | null> = useRef(null);
  const [autoscroll, setAutoscroll] = useState(butlerResponse?.creative?.isi?.autoscroll || false);
  useAutoScroll(textRef, autoscroll);
  useAnchorClickHandler(textRef);

  useEffect(() => {
    if (butlerResponse?.creative?.isi?.autoscroll) {
      setAutoscroll(butlerResponse.creative.isi.autoscroll);
    }
  }, [butlerResponse]);

  if (!butlerResponse?.creative?.isi?.text) return null;

  const headline = butlerResponse.creative.behaviors.isiHeadlineOverride || DEFAULT_HEADLINE;
  const subheader = butlerResponse.creative.behaviors.isiCustomSub;
  const text = butlerResponse.creative.isi.text;

  return (
    <div className={`str-isi-bundle-2`}>
      <div data-testid="isi-headline" className={`str-isi-headline`}>
        {headline}
      </div>
      {subheader && <div dangerouslySetInnerHTML={{ __html: subheader }} />}
      <div ref={textRef} dangerouslySetInnerHTML={{ __html: text }} className={'str-isi-text'} />
    </div>
  );
};

export default ISI;
