import { FunctionComponent, h } from 'preact';
import './mute-button.scss';

interface MuteButtonProps {
  muted: boolean;
  onClick: (event: MouseEvent) => void;
  videoCompleted: boolean;
  videoStarted: boolean;
}

export const MuteButton: FunctionComponent<MuteButtonProps> = ({ muted, onClick, videoCompleted, videoStarted }) => {
  if (videoCompleted || !videoStarted) {
    return null;
  }

  return (
    <div
      className={`str-icon-wrapper ${muted ? 'str-icon-mute' : 'str-icon-sound'}`}
      onClick={onClick}
      data-testid="mute-button"
    ></div>
  );
};
