import { dispatchExperiment } from 'modules/beacons/beacons';
import { ButlerCreative } from 'modules/butler/creative';
import { ButlerPlacement } from 'modules/butler/placement';
import { canExperimentDisableEnhancements, experimentManager } from 'modules/experiments';
import { RuleEngine, StopEvaluationError } from 'modules/rule-engine';
import { CreativeType } from 'tag/models/common/common.model';
import {
  ENHANCEMENT_CREATIVE_IDS,
  ENHANCEMENT_DOMAINS,
  NOT_ENHANCED_VIDEO_DSPS,
  NO_BANNER_ENHANCEMENT_PUBS,
  NO_ENHANCEMENT_BY_SEAT_ID_SOURCE_ID,
  NO_ENHANCEMENT_BY_SIZES,
  NO_ENHANCEMENT_DOMAINS,
  NO_ENHANCEMENT_DSPS,
  NO_ENHANCEMENT_OPEN_DSPS
} from './data';

const disableEnhancementPolicy = new RuleEngine<[ButlerCreative, ButlerPlacement]>();

disableEnhancementPolicy.addRule({
  priority: 0,
  evaluate: (creative) => {
    if (creative.action !== 'banner' && creative.action !== 'native-outstream' && creative.action !== 'hosted-video') {
      throw StopEvaluationError;
    }

    return false;
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative, placement) => {
    if (!placement.size.height || !placement.size.width) return false;
    if (!creative.size.height || !creative.size.width) return false;
    if (placement.size.height !== creative.size.height) return false;
    if (placement.size.width !== creative.size.width) return false;

    const size = NO_ENHANCEMENT_BY_SIZES.find(({ height, width }) => {
      return height === placement.size.height && width === placement.size.width;
    });

    return !!size;
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative) => {
    return !!NO_ENHANCEMENT_BY_SEAT_ID_SOURCE_ID.find(({ sourceId, seatId }) => {
      return creative.sourceId === sourceId && creative.seatId === seatId;
    });
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative) => {
    if (ENHANCEMENT_CREATIVE_IDS.includes(creative.creativeKey)) return false;
    return NO_ENHANCEMENT_OPEN_DSPS.includes(creative.sourceId) && !creative.dealId;
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative) => {
    if (ENHANCEMENT_CREATIVE_IDS.includes(creative.creativeKey)) return false;
    return NO_ENHANCEMENT_DSPS.includes(creative.sourceId);
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative) => {
    if (creative.isClickable || creative.isClickable === null || creative.isClickable === undefined) return false;
    else return !creative.mediaUrl;
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative) => {
    return !!creative.instl;
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative, placement) => {
    return creative.action === CreativeType.Banner && placement.templateKey === 'null';
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative, placement) => {
    return creative.behaviors.shouldRenderBannerTemplate && !placement.hasCustomTemplate;
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative, placement) => {
    return creative.behaviors.shouldRenderVideoOnly && !placement.hasCustomTemplate;
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (_creative, placement) => {
    return NO_ENHANCEMENT_DOMAINS.includes(placement.domain);
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative, placement) => {
    if (creative.action !== CreativeType.Banner) return false;
    if (ENHANCEMENT_DOMAINS.includes(placement.domain)) return false;

    return NO_BANNER_ENHANCEMENT_PUBS.includes(placement.publisherKey);
  }
});

// Disable videos by DSP
disableEnhancementPolicy.addRule({
  evaluate: (creative) => {
    const videoCreativeTypes = [CreativeType.HostedVideo, CreativeType.NativeOutstream];

    if (!videoCreativeTypes.includes(creative.action)) return false;

    return NOT_ENHANCED_VIDEO_DSPS.includes(creative.sourceId);
  }
});

disableEnhancementPolicy.addRule({
  evaluate: (creative, placement) => {
    const TEMPLATE_KEYS = [
      '300x250-mpu',
      '728x90',
      '970x90',
      '970x250',
      '300x600',
      '320x100',
      '320x480',
      '300x340',
      '336x280',
      '160x600',
      '300x100',
      '640x430',
      'image-on-top',
      'image-on-bottom',
      'image-on-left',
      'image-on-right',
      'small-box',
      'wide-box',
      'fake-template-key'
    ];

    if (creative.action !== CreativeType.Banner) return false;
    return !TEMPLATE_KEYS.includes(placement.templateKey) && !placement.hasCustomTemplate;
  }
});

export let VERIFIED_ARID: { [key: string]: boolean } = {};

const isAridVerified = (arid: string | null): boolean | null => {
  if (!arid) return null;
  return VERIFIED_ARID[arid] ?? null;
};

export const resetVerifiedArid = (): void => {
  VERIFIED_ARID = {};
};

export const evaluate = (adserverRequestId: string, creative: ButlerCreative, placement: ButlerPlacement): boolean => {
  const verified = isAridVerified(adserverRequestId);

  if (verified !== null) return verified;

  VERIFIED_ARID[adserverRequestId] = disableEnhancementPolicy.evaluate(creative, placement);

  return VERIFIED_ARID[adserverRequestId];
};

export const shouldDisableEnhancement = (
  adserverRequestId: string,
  creative: ButlerCreative,
  placement: ButlerPlacement,
  ignoreExperiment = false
): boolean => {
  if (creative.behaviors.shouldForceEnhanced) {
    return false;
  }

  if (creative.behaviors.shouldForceUnenhanced) {
    return true;
  }

  // if test environment, we should not run the experiment
  if (ENV === 'test' || CONFIG.version === 'test') {
    ignoreExperiment = true;
  }

  const shouldDisable = evaluate(adserverRequestId, creative, placement);

  // if we should disable based on the policy, we should not run the experiment
  if (shouldDisable || ignoreExperiment) {
    return shouldDisable;
  }

  const experiment = experimentManager.getActiveExperiment();

  // if there is no experiment or the experiment is not an enhancement disabling experiment, we should not run the experiment
  if (!experiment || !canExperimentDisableEnhancements()) return shouldDisable;

  // if the experiment is an enhancement disabling experiment, we fire the beacon
  if (canExperimentDisableEnhancements()) {
    dispatchExperiment(adserverRequestId);
  }

  // if the experiment variant is unenhanced, we should disable the enhancement
  return experiment.result === 'unenhanced';
};
