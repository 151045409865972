import { ButlerCreative } from 'modules/butler/creative';
import { RuleEngine } from 'modules/rule-engine';
import { NO_DRAWER_BY_SEAT_ID_SOURCE_ID } from './data';

const disableAdChoicesPolicy = new RuleEngine<[ButlerCreative]>();

disableAdChoicesPolicy.addRule({
  evaluate: (creative) => {
    for (const { sourceId, seatId } of NO_DRAWER_BY_SEAT_ID_SOURCE_ID) {
      if (creative.sourceId === sourceId && creative.seatId === seatId) {
        return true;
      }
    }

    return false;
  }
});

export const shouldDisableAdChoices = (creative: ButlerCreative) => {
  return disableAdChoicesPolicy.evaluate(creative);
};
