import Cannon from 'modules/beacons/cannon';
import Beacons from 'modules/beacons/utils';
import { MutableButlerCreative, VastVideoCreative } from 'modules/butler/creative';
import { ButlerResponse } from 'modules/butler/response';
import { replaceCacheBusterParam } from 'modules/utils';
import { CreativeType } from 'tag/models/common/common';
import { sample } from '../monitoring';
import {
  DCO_REQUEST_TIMEOUT,
  DEFAULT_MAX_HEADLINE_LENGTH,
  DcoAsset,
  DcoAssetResponse,
  DcoDataAsset,
  DcoEventTracker,
  DcoImageAsset,
  DcoLinkAsset,
  DcoResponse,
  DcoStatus,
  DcoTitleAsset,
  DcoVideoAsset
} from './types';
import { fetchWithTimeout } from './utils/fetchWithTimeout';

const isResponseJsonp = (response: Response) => {
  return response.headers.get('content-type') === 'application/javascript';
};

// Helper to handle DCO providers that return JSONP responses
export const handleJsonp = async (response: Response) => {
  const responseText = await response.text();
  const jsonpMatch = responseText.match(/{.*}/);
  if (jsonpMatch) {
    return new Response(jsonpMatch[0], {
      status: 200,
      statusText: 'OK',
      headers: response.headers
    });
  }

  return response;
};

// sort thumbnails using their height and width attributes
// a thumbnail is considered 'bigger' than another if BOTH width and height are larger
// TODO: look at this logic again; its not very intuitive
const sortByThumbnailsize = (assetA: DcoImageAsset, assetB: DcoImageAsset) => {
  if (assetA.h <= assetB.h && assetA.w <= assetB.w) return -1;
  if (assetA.h >= assetB.h && assetA.w >= assetB.w) return 1;
  return 0;
};

export const DcoHandler = {
  chooseBestThumbnail: (alternateThumbnailAssets: DcoImageAsset[], width: number, height: number): string => {
    const validThumbnails = alternateThumbnailAssets.filter((asset) => {
      return asset.h >= height && asset.w >= width;
    });

    const chosenThumbnail =
      validThumbnails && validThumbnails.length > 0
        ? validThumbnails.sort(sortByThumbnailsize)[0]
        : alternateThumbnailAssets.sort(sortByThumbnailsize)[alternateThumbnailAssets.length - 1];

    return chosenThumbnail.url;
  },

  dynamicallyOptimize: async (butlerResponse: ButlerResponse): Promise<ButlerResponse> => {
    try {
      const dcoResponse = await DcoHandler.parseDcoResponse(butlerResponse);

      let swapSuccess = false;
      if (dcoResponse) {
        DcoHandler.updateCreative(butlerResponse, dcoResponse);
        swapSuccess = true;
        DcoHandler.fireBeacon({ result: DcoStatus.Success }, butlerResponse);
      }

      DcoHandler.handleFallbackMacro(swapSuccess, butlerResponse);
      return butlerResponse;
    } catch (error) {
      sample(error, { adserverRequestId: butlerResponse.adserverRequestId });
      DcoHandler.handleFallbackMacro(false, butlerResponse);
      DcoHandler.fireBeacon(
        {
          result: DcoStatus.RenderFail,
          name: error
        },
        butlerResponse
      );
      return butlerResponse;
    }
  },

  fireBeacon: (
    params: {
      result: DcoStatus;
      [index: string]: string | string[] | number | boolean;
    },
    butlerResponse: ButlerResponse
  ) => {
    let beaconParams = {
      type: Beacons.types.DcoRequest,
      url: DcoHandler.getAssetUrl(butlerResponse) || '',
      ...params
    };
    Cannon.fireBeacon(beaconParams, butlerResponse);
  },

  getAssetUrl: (butlerResponse: ButlerResponse): string | null => {
    let assetUrl =
      butlerResponse && butlerResponse.creative && butlerResponse.creative.dcoAssetUrl
        ? butlerResponse.creative.dcoAssetUrl
        : null;

    if (!assetUrl) {
      return null;
    }

    const isJivox = assetUrl.includes('jivox');
    const noHttpProtocol = assetUrl.startsWith('//');
    if (isJivox && noHttpProtocol) {
      assetUrl = 'https:' + assetUrl;
    }

    return replaceCacheBusterParam(assetUrl);
  },

  handleFallbackMacro(swapSuccess: boolean, butlerResponse: ButlerResponse) {
    butlerResponse.creative.beacons.impression.forEach((url: string, index: number, impressionBeacons: string[]) => {
      impressionBeacons[index] = url.replace('{isfallback}', swapSuccess ? '0' : '1');
    });
  },

  parseDcoResponse: async (butlerResponse: ButlerResponse): Promise<DcoResponse | null> => {
    const assetUrl = DcoHandler.getAssetUrl(butlerResponse);

    if (!assetUrl) {
      DcoHandler.fireBeacon(
        {
          result: DcoStatus.Failure,
          name: 'No asset URL'
        },
        butlerResponse
      );

      return null;
    }

    try {
      let response: Response;
      try {
        response = await fetchWithTimeout(assetUrl, {
          timeout: DCO_REQUEST_TIMEOUT,
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        const message = error.message || error;
        throw new Error(`DCO Request Failed : ${message}`);
      }

      if (isResponseJsonp(response)) {
        response = await handleJsonp(response);
      }

      let assetResponse: DcoAssetResponse;
      try {
        assetResponse = await response.json();
      } catch (error) {
        throw new Error('Invalid JSON response');
      }

      if (Object.keys(assetResponse).length === 0) {
        throw new Error('Empty response');
      }

      const assetParser = new DcoParser(assetResponse, butlerResponse);

      return assetParser.parseDcoResponse();
    } catch (error) {
      const message = error.message || error;
      console.log(message);
      const result = message.includes('timeout') ? DcoStatus.RequestTimeout : DcoStatus.Failure;

      DcoHandler.fireBeacon(
        {
          result,
          name: error instanceof Error ? error.message : error
        },
        butlerResponse
      );

      return null;
    }
  },

  required: (butlerResponse: ButlerResponse): boolean => {
    return !!DcoHandler.getAssetUrl(butlerResponse);
  },

  updateCreative: (butlerResponse: ButlerResponse, dcoResponse: DcoResponse) => {
    let creative: MutableButlerCreative = butlerResponse.creative;

    if (dcoResponse.title) {
      creative.title = dcoResponse.title;
    }

    if (dcoResponse.description) {
      creative.description = dcoResponse.description;
    }

    if (dcoResponse.brandLogoUrl) {
      creative.brandLogoUrl = dcoResponse.brandLogoUrl;
    }

    if (dcoResponse.advertiser) {
      creative.advertiser = dcoResponse.advertiser;
    }

    if (dcoResponse.mediaUrl) {
      creative.mediaUrl = dcoResponse.mediaUrl;
    }

    if (dcoResponse.vastUrl) {
      (creative as VastVideoCreative).vastUrl = dcoResponse.vastUrl;
    }

    if (dcoResponse.creativeType) {
      creative.action = dcoResponse.creativeType;
    }

    if (dcoResponse.visibleTrackers && dcoResponse.visibleTrackers.length > 0) {
      if (!creative.beacons.visible) {
        creative.beacons.visible = [];
      }
      creative.beacons.visible = creative.beacons.visible.concat(dcoResponse.visibleTrackers);
    }

    if (dcoResponse.clickTrackers && dcoResponse.clickTrackers.length > 0) {
      if (!creative.beacons.click) {
        creative.beacons.click = [];
      }
      creative.beacons.click = creative.beacons.click.concat(dcoResponse.clickTrackers);
    }

    if (dcoResponse.impressionTrackers && dcoResponse.impressionTrackers.length > 0) {
      if (!creative.beacons.impression) {
        creative.beacons.impression = [];
      }
      creative.beacons.impression = creative.beacons.impression.concat(dcoResponse.impressionTrackers);
    }

    creative.alternateThumbnailAssets = dcoResponse.alternateThumbnailAssets;
  }
};

// written as a class so that we can use the 'this' construct
// makes it much easier to mix & match the different asset types and have them all build the DcoResponse object
class DcoParser {
  alternateThumbnailAssets: DcoImageAsset[];
  assets: DcoAsset[];
  eventTrackers: DcoEventTracker[];
  butlerResponse: ButlerResponse;
  dcoResponse: DcoResponse;
  maxHeadlineLength: number;

  constructor(assetResponse: DcoAssetResponse, butlerResponse: ButlerResponse) {
    this.alternateThumbnailAssets = [];
    this.assets = assetResponse.assets;
    this.eventTrackers = assetResponse.eventtrackers || [];
    this.butlerResponse = butlerResponse;
    this.dcoResponse = {
      alternateThumbnailAssets: [],
      creativeType: CreativeType.Clickout,
      advertiser: null,
      brandLogoUrl: null,
      description: null,
      title: null,
      mediaUrl: null,
      thumbnailUrl: null,
      vastUrl: null,
      clickTrackers: [],
      impressionTrackers: [],
      visibleTrackers: []
    };

    if (butlerResponse && butlerResponse.placement && butlerResponse.placement.maxHeadlineLength) {
      this.maxHeadlineLength = butlerResponse.placement.maxHeadlineLength;
    } else {
      this.maxHeadlineLength = DEFAULT_MAX_HEADLINE_LENGTH;
    }
  }

  formatTitle() {
    if (!(this.dcoResponse && this.dcoResponse.title)) return;
    if (this.dcoResponse.title.length > this.maxHeadlineLength) {
      this.dcoResponse.title = this.dcoResponse.title.substring(0, this.maxHeadlineLength) + '...';
    }
  }

  handleTitleAsset(asset: DcoTitleAsset) {
    const candidateTitleAsset = asset.text || '';
    if (!this.dcoResponse.title) {
      this.dcoResponse.title = candidateTitleAsset;
    }

    const selectedLength = this.dcoResponse.title.length;
    const candidateLength = candidateTitleAsset.length;

    if (selectedLength <= this.maxHeadlineLength) {
      if (candidateLength > selectedLength && candidateLength <= this.maxHeadlineLength) {
        this.dcoResponse.title = candidateTitleAsset;
      }
    } else {
      if (candidateLength < selectedLength) {
        this.dcoResponse.title = candidateTitleAsset;
      }
    }
  }

  handleImageAsset(asset: DcoImageAsset) {
    const BRAND_LOGO = 1;
    const THUMBNAIL_ASSET = 3;
    switch (asset.type) {
      case BRAND_LOGO:
        this.dcoResponse.brandLogoUrl = asset.url || null;
        break;
      case THUMBNAIL_ASSET:
        // TODO: check if image assets always have h and w attributes, if so, simplify types
        this.handleThumbnailAsset(asset);
        break;
    }
  }

  handleThumbnailAsset(asset: DcoImageAsset) {
    if (!asset.url) return;
    this.dcoResponse.alternateThumbnailAssets.push(asset);
  }

  handleDataAsset(asset: DcoDataAsset) {
    const ADVERTISER = 1;
    const DESCRIPTION = 2;
    switch (asset.type) {
      case ADVERTISER:
        this.dcoResponse.advertiser = asset.value || null;
        break;
      case DESCRIPTION:
        this.dcoResponse.description = asset.value || null;
        break;
    }
  }

  handleVideoAsset(asset: DcoVideoAsset) {
    this.dcoResponse.creativeType = CreativeType.HostedVideo;
    this.dcoResponse.vastUrl = asset.vasttag || null;
  }

  handleLinkAsset(asset: DcoLinkAsset) {
    this.dcoResponse.mediaUrl = asset.url;
    if (asset.clicktrackers) {
      this.dcoResponse.clickTrackers = asset.clicktrackers;
    }
  }

  parseDcoResponse(): DcoResponse {
    this.parseAssets();
    this.parseEventTrackers();
    this.formatTitle();

    return this.dcoResponse;
  }

  parseAssets() {
    this.assets.forEach((asset: DcoAsset) => {
      switch (true) {
        case !!asset.title:
          this.handleTitleAsset(asset.title as DcoTitleAsset);
          break;
        case !!asset.img:
          this.handleImageAsset(asset.img as DcoImageAsset);
          break;
        case !!asset.data:
          this.handleDataAsset(asset.data as DcoDataAsset);
          break;
        case !!asset.video:
          this.handleVideoAsset(asset.video as DcoVideoAsset);
          break;
        case !!asset.link:
          this.handleLinkAsset(asset.link as DcoLinkAsset);
          break;
      }
    });
  }

  parseEventTrackers() {
    const IMPRESSION = 1;
    const VISIBLE = 2;
    this.eventTrackers.forEach((eventTracker: DcoEventTracker) => {
      if (typeof eventTracker.url === 'string') {
        if (eventTracker.event === IMPRESSION && eventTracker.method === 1) {
          this.dcoResponse.impressionTrackers.push(eventTracker.url);
        }
        if (eventTracker.event === VISIBLE && eventTracker.method === 1) {
          this.dcoResponse.visibleTrackers.push(eventTracker.url);
        }
      }
    });
  }
}
