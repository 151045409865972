type Criteria<Context> = ((context: Context) => boolean) | Partial<Context>;

export interface ExperimentProps<Context> {
  id: string;
  volume: number;
  variants: string[];
  criteria: Criteria<Context>;
}

export type ExperimentWithResult<Context> = Experiment<Context> & { result: string };

/**
 * Represents an experiment that can be evaluated based on certain criteria and volume.
 * @template Context - The type of the context object used for evaluation.
 */
export class Experiment<Context> {
  id: string;
  variants: string[];
  criteria: Criteria<Context>;
  volume: number;
  result: string | null;

  private _evaluated: boolean = false;
  private _criteriaCheckResult: boolean | null = null;
  private _volumeCheckResult: boolean | null = null;

  /**
   * Creates a new Experiment instance.
   * @param props - The properties of the experiment.
   */
  constructor(props: ExperimentProps<Context>) {
    this.id = props.id;
    this.variants = props.variants;
    this.criteria = props.criteria;
    this.volume = props.volume;
  }

  /**
   * Evaluates the experiment based on the given context.
   * @param context - The context object used for evaluation.
   * @returns The selected variant or null if the experiment does not meet the criteria or volume check.
   */
  evaluate(context: Context): string {
    if (this._evaluated) return this.result;

    this._evaluated = true;

    if (!this.criteriaCheck(context) || !this.volumeCheck()) {
      this.result = null;
      return this.result;
    }

    const randomIndex = Math.floor(Math.random() * this.variants.length);
    this.result = this.variants[randomIndex];
    this._evaluated = true;
    return this.result;
  }

  /**
   * Checks if the experiment meets the criteria based on the given context.
   * @param context - The context object used for evaluation.
   * @returns True if the experiment meets the criteria, false otherwise.
   */
  criteriaCheck(context: Context): boolean {
    if (this._criteriaCheckResult !== null) return this._criteriaCheckResult;

    if (typeof this.criteria === 'function') {
      this._criteriaCheckResult = this.criteria(context);
      return this._criteriaCheckResult;
    }

    const isCriteriaMet = (context: Context, criteria: Criteria<Context>) => {
      return Object.keys(criteria).every((key) => {
        if (typeof criteria[key] === 'object' && criteria[key] !== null) {
          return isCriteriaMet(context[key], criteria[key]);
        } else {
          return context[key] === criteria[key];
        }
      });
    };

    this._criteriaCheckResult = isCriteriaMet(context, this.criteria);
    return this._criteriaCheckResult;
  }

  /**
   * Checks if the experiment meets the volume check.
   * @returns True if the experiment meets the volume check, false otherwise.
   */
  volumeCheck(): boolean {
    if (this._volumeCheckResult !== null) return this._volumeCheckResult;

    const random = Math.random();
    this._volumeCheckResult = random <= this.volume;
    return this._volumeCheckResult;
  }
}
