import { recreateChildScriptTags } from 'modules/utils';
import { Fragment, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useEffect, useErrorBoundary, useRef } from 'preact/hooks';
import { CssOverride } from './css-override/css-override.component';
import { EnhancedTemplate } from './enhanced/enhanced.template';
import './template.scss';
import { TemplateProps, TemplateType } from './types';
import { UnenhancedTemplate } from './unenhanced/unenhanced.component';
import { getTemplateType } from './utils';
import { usePublisherStyling } from './publisher-styling/hook';
import { loadFontFamily } from './publisher-styling/helper/loadFontFamily';

export const Template = forwardRef<HTMLDivElement, TemplateProps>((props: TemplateProps, ref) => {
  const [error] = useErrorBoundary();
  const adWrapperRef = useRef<any>(null);
  const { styles } = usePublisherStyling();

  useEffect(() => {
    if (adWrapperRef.current === null) return;
    const baseElement = adWrapperRef.current.base as HTMLElement;
    if (baseElement) recreateChildScriptTags(baseElement.parentElement as HTMLElement);
  }, [adWrapperRef]);

  useEffect(() => {
    if (!styles || !styles.fontFamily) return;
    loadFontFamily(styles.fontFamily);
  }, [styles]);

  const { adserverRequestId, creative, placement, country, className, children } = props;
  const currentTemplate = error ? TemplateType.Unenhanced : getTemplateType(adserverRequestId, placement, creative);

  const commonProps = { creative, placement, className, country, adserverRequestId };

  return (
    <div
      data-testid="str-compiled-template"
      className={`str-compiled-template`}
      style={{ width: '100%', height: '100%' }}
      ref={ref}
    >
      {currentTemplate === TemplateType.Enhanced ? (
        <Fragment ref={adWrapperRef}>
          <EnhancedTemplate {...commonProps}>{children}</EnhancedTemplate>
          <CssOverride cssOverrides={placement.cssOverrides} />
        </Fragment>
      ) : (
        <UnenhancedTemplate {...commonProps}>{children}</UnenhancedTemplate>
      )}
    </div>
  );
});
