import { ButlerCreative } from 'modules/butler/creative';
import { BannerType } from '.';

export enum RenderMethod {
  Postscribe = 'postscribe',
  Iframe = 'iframe'
}

export const getRenderMethod = (bannerType: BannerType, creative: ButlerCreative): RenderMethod => {
  const { shouldRenderFixedSizeBanner, shouldRenderBannerTemplate, shouldRenderWithPostscribe } = creative.behaviors;

  if (shouldRenderFixedSizeBanner || shouldRenderBannerTemplate) {
    return RenderMethod.Iframe;
  }

  if (shouldRenderWithPostscribe) {
    return RenderMethod.Postscribe;
  }

  switch (bannerType) {
    case BannerType.CentroJs:
    case BannerType.DcmScript:
    case BannerType.Flashtalking:
    case BannerType.Quantcast:
    case BannerType.Rubicon:
    case BannerType.Pubmatic:
    case BannerType.Yahoo:
    case BannerType.Postscribe:
    case BannerType.Dv360:
    return RenderMethod.Postscribe;
    case BannerType.Dv360Iframe:
    case BannerType.Adform:
    case BannerType.Iframe:
    case BannerType.Basis:
    case BannerType.TradeDeskBanner:
    case BannerType.Openx:
    case BannerType.Xandr:
    default:
      return RenderMethod.Iframe;
  }
};
