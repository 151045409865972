import Beacons from 'modules/beacons/utils';
import { ClickoutCreative } from 'modules/butler/creative';
import { ButlerResponse, getDefaultBehaviors } from 'modules/butler/response';
import { CreativeType } from './common/common';

// Creative to render in the case that DV says don't render the normal creative
const fallbackCreative: ClickoutCreative = {
  action: CreativeType.Clickout,
  advertiser: 'Sharethrough',
  advertiserKey: 'fallback-advertiser-key',
  beacons: {
    click: [],
    impression: [],
    play: [],
    visible: [],
    'win-notification': [],
    'video-win-notification': [],
    'video-impression': [],
    first_quartile: [],
    midpoint: [],
    third_quartile: [],
    completed_silent_play: [],
    silent_play: [],
    ten_second_silent_play: [],
    fifteen_second_silent_play: [],
    thirty_second_silent_play: []
  },
  nativeAssets: [],
  behaviors: getDefaultBehaviors({}),
  brandLogoUrl: '',
  campaignKey: '',
  contentUrl: '',
  clickoutSamePage: false,
  experiments: undefined,
  customEngagementLabel: null,
  customEngagementUrl: undefined,
  customEngagementUrlToken: '',
  customEngagementUrlNonce: '',
  creativeKey: 'fallback-creative-key',
  dcoAssetUrl: undefined,
  dealId: '',
  description: 'Our native advertising software is helping the Internet evolve beyond interruptive ads.',
  doubleVerifyTracker: null, // we already did the double verify dance!
  forceEnhanced: false,
  isi: null,
  jsTracker: [],
  mediaUrl: 'https://www.sharethrough.com',
  mediaUrlToken: '37766b95c4b07db2e49090eeb70df9801d67e638',
  mediaUrlNonce: '43302a56d1a6ae5c8c70621e3b9288c249112de7',
  nudgeEnabled: false,
  promotedByText: '',
  size: { width: null, height: null },
  slides: [],
  slideshowAutoscroll: false,
  sourceId: '',
  thumbnailFillImages: false,
  thumbnailUrl:
    'https://static.sharethrough.com/creative_workflow/creative_thumbnails/78638/images/original/sharethrough-og-hero.jpg',
  title: 'Sharethrough - Native Advertising Software For Publishers',
  language: 'es',
  seatId: '3312',
  enhancementVersionId: 'fallback-enhancement-version-id',
  hasEnhancement: false
};

const fireSuccessBeacon = (response: ButlerResponse) => {
  Beacons.fire.doubleVerifySuccess(response.adserverRequestId);
};

const fireFallbackBeacon = (response: ButlerResponse) => {
  Beacons.fire.doubleVerifyFallback(response);
};

const handleCallbacks = (successCallback: Function, failureCallback: Function, response: ButlerResponse) => {
  const arid = response.adserverRequestId;
  const interval = setInterval(function checkPageForDvStatus() {
    if (document.querySelector(`.dv-passback-${arid}`)) {
      failureCallback();
      fireFallbackBeacon(response);
      clearInterval(interval);
    }
    if (document.querySelector(`.dv-success-${arid}`)) {
      successCallback();
      fireSuccessBeacon(response);
      clearInterval(interval);
    }
  }, 100);
};

const generateDoubleVerifyScript = (tracker: string, arid: string) => {
  const splitTrackers = tracker
    .split('/script>')
    .filter((s) => !!s)
    .map((s) => s + '/script>');

  const separateTracker = splitTrackers.find((splitTracker) => splitTracker.includes('crt=')) || '';

  const tempDoc = document.implementation.createHTMLDocument('');
  tempDoc.body.innerHTML = separateTracker.replace(/<\\\/script>/gm, '<script>');
  let trackerUrl = '';
  // jsTrackers can typically be a url or a <script> element as a string
  // if no children, then it is a plain url
  if (tempDoc.body.children.length === 0) {
    trackerUrl = tracker;
  } else {
    Array.from(tempDoc.body.children).forEach((child) => {
      if (child.tagName.toString().toLocaleLowerCase() === 'script') {
        trackerUrl = (child as HTMLScriptElement).src;
      }
    });
    if (!trackerUrl) {
      throw new Error('Error building DoubleVerify script');
    }
  }

  const paramsRE = /(\#|\&|\?)(?<param>[^=]+)\=(?<value>[^&]+)/g;
  // REQUIRED fields
  let ctx = '';
  let cmp = '';
  let plc = '';
  let sid = '';

  let matches: RegExpExecArray | null = null;

  while ((matches = paramsRE.exec(trackerUrl))) {
    if (matches?.groups?.param === 'ctx') {
      ctx = matches.groups.value;
    } else if (matches?.groups?.param === 'cmp') {
      cmp = matches.groups.value;
    } else if (matches?.groups?.param === 'plc') {
      plc = matches.groups.value;
    } else if (matches?.groups?.param === 'sid') {
      sid = matches.groups.value;
    }
  }

  trackerUrl = trackerUrl.replace('[ST_IMP_ID]', arid);

  // For the DV code to work they expect this tracker to have this weird closing script!
  const trackerTemplate = `<script type="text/javascript" src="${trackerUrl}"></scr+ipt>`;

  let blockerTemplate = `<script type="text/javascript" src="https://cdn.doubleverify.com/dvbs_src.js?ctx=${ctx}&cmp=${cmp}&plc=${plc}&sid=${sid}&dvregion=0&unit=1x1&dvp_stimp=${arid}"></script>`;
  // use comment out script to force a success
  // scriptTemplate = `<script language="javascript" type="text/javascript" src="https://cdn.doubleverify.com/dvtp_src.js?ctx=818052&cmp=20200317&plc=2020031701&sid=sharethrough&dvregion=0&unit=1x1"></script>`;

  // Requirements from DV specify that the markup should end up as:
  //  <script type="text/adtag">
  //    <tag from advertiser>
  //  </script>
  //  <script type="text/passback">
  //    <fallback tag>
  //  </script>
  //  <script language="javascript" type="text/javascript" src="https://cdn.doubleverify.com/dvbs_src.js?ctx=818052&cmp=20200317&plc=2020031701&sid=sharethrough&dvregion=0&unit=1x1">
  //  </script>
  // The script from DV will replace itself with a copy of whatever is in the success/fallback slot
  // depending on if it wants to block the ad from rendering or not. In our case, instead of
  // being a normal ad tag (like a banner or something), we render a blank img with a class we watch for
  // and use the existence of one or the other to trigger callbacks in react.

  let script = `
  <script data-testid="dv-script" type="text/adtag">
    <img class="dv-success-${arid}" />
    ${trackerTemplate}
  </script>
  <script type="text/passback">
    <img class="dv-passback-${arid}" />
  </script>
  ${blockerTemplate}
  `;

  return script;
};

const DoubleVerify = {
  fallbackCreative,
  generateDoubleVerifyScript,
  handleCallbacks
};

export default DoubleVerify;
