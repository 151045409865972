import { FunctionComponent, h } from 'preact';
import './carousel.control.scss';
import { CarouselControlProps } from '../types';

export const CarouselControl: FunctionComponent<CarouselControlProps> = ({ onClick }) => {
  return (
    <div className={'str-carousel-controls'}>
      <span className={'str-carousel-control left'} onClick={(event: MouseEvent) => onClick(-1, event)}></span>
      <span className={'str-carousel-control right'} onClick={(event: MouseEvent) => onClick(1, event)}></span>
    </div>
  );
};
