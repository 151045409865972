import { IFontFamilyResponse } from '.';

export class FontFamilyStyle extends Array<IFontFamilyResponse> {
  constructor(response?: Array<IFontFamilyResponse>) {
    super();
    if (response && response.length > 0) {
      response.map((fontFamily) => {
        this.push(fontFamily);
      });
    }
  }
}
