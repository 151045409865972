import { RawResponse } from 'modules/butler/response';
import { getTemplateKey } from './utils/getTemplateKey';

export type ButlerPlacement = {
  key: string;
  maxHeadlineLength?: number;
  templateKey: string;
  publisherKey: string;
  siteKey: string;
  size: { width: number | null; height: number | null };
  domain: string;
  bundleIdentifier?: string;
  cssOverrides?: string;
  hasCustomTemplate: boolean;
  promoted_by_text?: string;
};

export type T = ButlerPlacement;

export const build = (placementKey: string, response: RawResponse) => {
  const placement: ButlerPlacement = {
    key: placementKey,
    maxHeadlineLength: response.placement.placementAttributes.max_headline_length,
    templateKey: response.placement.placementAttributes.template_key ?? getTemplateKey(response.placement),
    publisherKey: response.placement.placementAttributes.publisher_key,
    siteKey: response.placement.placementAttributes.site_key,
    size: {
      width: response.placement.placementAttributes.size?.w
        ? parseInt(response.placement.placementAttributes.size.w.toString())
        : null,
      height: response.placement.placementAttributes.size?.h
        ? parseInt(response.placement.placementAttributes.size.h.toString())
        : null
    },
    domain: response.placement.placementAttributes.domain,
    hasCustomTemplate: response.placement.placementAttributes.hasCustomTemplate || false
  };

  if (response.placement.placementAttributes.bundleIdentifier) {
    placement['bundleIdentifier'] = response.placement.placementAttributes.bundleIdentifier;
  }

  if (response.placement.placementAttributes.cssOverrides) {
    placement['cssOverrides'] = response.placement.placementAttributes.cssOverrides;
  }

  return placement;
};
