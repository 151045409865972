import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchVideoSubtitlesEnabled = (arid: AdserverRequestId) => {
  return new Beacon({
    fireOnce: true,
    cacheKey: `videoSubtitlesEnabled-${arid}`,

    data: {
      type: BeaconType.subtitlesEnabled,
      arid,
      ...getBaseParams(arid)
    }
  });
};
