import { experimentManager } from 'modules/experiments';
import ResponseStore from 'modules/response/response_store';
import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';

export const dispatchNagCreativeType = (arid: AdserverRequestId) => {
  const butlerResponse = ResponseStore.getResponse(arid);
  if (!butlerResponse) return null;

  // these nag creatives are only applied on clickouts
  if (butlerResponse.creative.action !== 'clickout') return null;

  let creativeType;
  // if isi is present, we know it's an isi creative
  if (butlerResponse.creative.isi) {
    creativeType = 'isi';
  } else if (
    // if it's a gif and is hosted on nag's CDN, we know it's a cinemagraph
    butlerResponse.creative.thumbnailUrl.includes('.gif') &&
    (butlerResponse.creative.thumbnailUrl.includes('https://str-thumbnails-production.imgix.net/generator') ||
      butlerResponse.creative.thumbnailUrl.includes('https://static.sharethrough.com/generator'))
  ) {
    creativeType = 'cinemagraph';
  } else {
    return null;
  }

  const nagCreativeTypesExperimentId = '9e1fb270-1a8d-4e63-88c1-4132a37acaa7';

  const isViteBuild = CONFIG.version.includes('-vite');
  if (isViteBuild) {
    return;
  }

  if (experimentManager.getActiveExperiment()) {
    return;
  }

  // fire an experiment beacon to easily track in looker
  return new Beacon({
    data: {
      type: BeaconType.experiment,
      arid,
      experiment_id: nagCreativeTypesExperimentId,
      experiment_variant_id: creativeType
    }
  });
};
