import { QuartileKey } from 'tag/models/common/common';
import { Beacon } from '../Beacon';
import { dispatchVideoQuartileNotifications } from '../third-party/videoQuartileNotifications';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchVideoQuartile = (arid: AdserverRequestId, percentThreshold: QuartileKey) => {
  dispatchVideoQuartileNotifications(arid, percentThreshold);

  return new Beacon({
    fireOnce: true,
    cacheKey: `video-quartile-${percentThreshold}-${arid}`,
    data: {
      type: BeaconType.completionPercent,
      arid,
      value: percentThreshold,
      ...getBaseParams(arid)
    }
  });
};
