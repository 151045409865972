import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import { Drawer } from 'tag/models/common/template/enhanced/drawer/drawer.component';
import { AdExperienceBaseProps } from '../ad-experience';
import { useButler } from '../hooks';
import { AmazonAdvertiser } from './advertiser/advertiser.component';
import './amazon-native.scss';
import { AmazonBadge } from './badge/badge.component';
import { AmazonCoupon } from './coupon/coupon.component';
import { AmazonCta } from './cta/cta.component';
import { AmazonDescription } from './description/description.component';
import { AmazonPrice } from './price/price.component';
import { AmazonRating } from './rating/rating.component';
import { AmazonReview } from './review/review.component';
import { AmazonThumbnail } from './thumbnail/thumbnail.component';

export const AmazonNative: FunctionComponent<AdExperienceBaseProps> = ({ onClick }) => {
  const butlerResponse = useButler();
  const assets = useNativeAssets();

  if (!butlerResponse || !assets) return null;

  const { coupon, price, salePrice } = assets;

  const showPriceContainer = !coupon && (price || salePrice);

  return (
    <div className="str-amazon-native-container" data-testid="str-amazon-native" onClick={onClick}>
      <div className="str-amazon-native">
        <AmazonThumbnail />
        <div className="str-amazon-body">
          <AmazonCoupon />
          <AmazonDescription />
          <AmazonRating />
          <AmazonBadge />
          {showPriceContainer && (
            <div className="str-amazon-price-cta">
              <AmazonPrice />
              <AmazonCta />
            </div>
          )}
          {/* {coupon && <AmazonAvailableLogo />} */}
          <AmazonReview />
        </div>
        <AmazonAdvertiser />
      </div>

      <Drawer creative={butlerResponse.creative} placement={butlerResponse.placement} />
    </div>
  );
};
