export class Store<T extends Record<string, any>> {
  private state: T;

  constructor(initialState: T) {
    this.state = initialState;
  }

  getState(): T {
    return this.state;
  }

  setState(newState: Partial<T>): void {
    this.state = {
      ...this.state,
      ...newState
    };
  }
}
