import { FunctionComponent, createContext, h } from 'preact';
import { ButlerResponse } from './response';

export const ButlerContext = createContext<ButlerResponse | null>(null);

interface ButlerProviderProps {
  butlerResponse: ButlerResponse;
}

export const ButlerProvider: FunctionComponent<ButlerProviderProps> = ({ butlerResponse, children }) => {
  return <ButlerContext.Provider value={butlerResponse}>{children}</ButlerContext.Provider>;
};
