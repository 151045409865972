import { ButlerCreative } from 'modules/butler/creative';
import { ButlerPlacement } from 'modules/butler/placement';
import { shouldDisableAdChoices } from 'modules/policies/disable-ad-choices';
import { FunctionComponent, h } from 'preact';
import { GreenPmpIcon, OptOutIcon } from '../../../common';
import './drawer.scss';

interface DrawerProps {
  creative: ButlerCreative;
  placement: ButlerPlacement;
}

export const Drawer: FunctionComponent<DrawerProps> = (props: DrawerProps) => {
  const { creative, placement } = props;

  const disableAdChoices = shouldDisableAdChoices(creative);

  return (
    <div className={`str-buttons-container ${creative.behaviors.shouldRelocateAdchoicesLogo ? 'bottom' : ''}`}>
      {creative.behaviors.shouldGreenPMP && <GreenPmpIcon creative={creative} />}
      {!disableAdChoices && <OptOutIcon creative={creative} placement={placement} />}
    </div>
  );
};
