import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchPixelVisible = (arid: AdserverRequestId) => {
  return new Beacon({
    fireOnce: true,
    cacheKey: `pixelVisible-${arid}`,

    data: {
      type: BeaconType.pixelVisibleInApp,
      arid,
      ...getBaseParams(arid)
    }
  });
};
