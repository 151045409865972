import { QuartileKey } from 'tag/models/common/common';
import { AdserverRequestId, CreativeBeaconType } from '../types';
import { dispatchCreativeNotifications } from './creativeNotifications';

export const dispatchVideoQuartileNotifications = (arid: AdserverRequestId, percentThreshold: QuartileKey) => {
  const beaconType = getCreativeBeaconType(percentThreshold);
  if (!beaconType) {
    return;
  }

  return dispatchCreativeNotifications({
    arid,
    beaconType
  });
};

const getCreativeBeaconType = (percentThreshold: QuartileKey): CreativeBeaconType | null => {
  switch (percentThreshold) {
    case '25':
      return CreativeBeaconType.FirstQuartile;
    case '50':
      return CreativeBeaconType.Midpoint;
    case '75':
      return CreativeBeaconType.ThirdQuartile;
    case '95':
      return CreativeBeaconType.CompletedSilentPlay; //TODO NOT WORKING (CURRENT VERSION)
    default:
      return null;
  }
};
