import SafeFrameHandler from 'modules/safe_frame_handler';

export type Dimensions = {
  width: number;
  height: number;
};

export type Coordinates = {
  x: number;
  y: number;
};

export function elementDimensions(element: HTMLElement): Dimensions {
  const bounding = element.getBoundingClientRect();
  return {
    width: bounding.width || 0,
    height: bounding.height || 0
  };
}

export function elementPosition(element: HTMLElement): Coordinates {
  let offsetLeft: number = 0;
  let offsetTop: number = 0;

  try {
    const geometry = SafeFrameHandler.safeFrameWindow().$sf.ext.geom();
    offsetLeft = geometry.self.l;
    offsetTop = geometry.self.t;
  } catch (e) {
    // not in a safeframe
    offsetLeft = element.offsetLeft;
    offsetTop = element.offsetTop;

    while (element.offsetParent) {
      element = element.offsetParent as HTMLElement;
      offsetLeft += element.offsetLeft;
      offsetTop += element.offsetTop;
    }
  }
  return {
    x: offsetLeft,
    y: offsetTop
  };
}

export function thumbnailDimensions(element: HTMLElement): Dimensions {
  const thumbnail = element.querySelector('.str-thumbnail') as HTMLElement;
  return thumbnail ? elementDimensions(thumbnail) : { width: 0, height: 0 };
}

export function distanceFromBottomOfViewport(element: HTMLElement) {
  const elementTop = element.getBoundingClientRect().top;
  return elementTop - bottomOfViewport();
}

export function elementOnPage(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  return !(rect.top === 0 && rect.bottom === 0 && rect.left === 0 && rect.right === 0);
}

function bottomOfViewport() {
  return window.innerHeight;
}
