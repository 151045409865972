import { useErrorBoundary } from 'modules/monitoring';
import { FunctionComponent, h } from 'preact';
import { useButler, useEnhancement } from 'tag/models/ad-experiences/hooks';
import './advertiser.scss';
import { usePublisherStyling } from '../../publisher-styling/hook';

export const Advertiser: FunctionComponent = () => {
  const [error] = useErrorBoundary('Advertiser Component Error');

  const butlerResponse = useButler();
  const { styles } = usePublisherStyling();
  const { enhancement } = useEnhancement();
  if (!butlerResponse || error) {
    return null;
  }

  let advertiserPayload;
  if (enhancement?.payload && 'advertiser' in enhancement.payload) {
    advertiserPayload = enhancement.payload.advertiser;
  }

  const advertiser = advertiserPayload?.name ?? butlerResponse.creative.advertiser;
  const promotedByText = advertiserPayload?.promotedByText ?? butlerResponse.creative.promotedByText;
  const brandLogoUrl = advertiserPayload?.brandLogoUrl ?? butlerResponse.creative.brandLogoUrl;

  const fontStyles = styles?.promotedBy?.font?.toStyle();
  const backroundStyle = styles?.promotedBy?.background?.toStyle();

  return (
    <div className={`str-advertiser-container advertiser-container`} style={backroundStyle}>
      <span className={`str-promoted-by promoted-by`} style={fontStyles}>
        {promotedByText}
      </span>
      <span className={`str-advertiser advertiser`} style={fontStyles}>
        {advertiser}
      </span>
      {brandLogoUrl && <img alt="Brand Logo" className="brand-logo" src={brandLogoUrl} />}
    </div>
  );
};
