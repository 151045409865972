import { FunctionComponent, h } from 'preact';
import Drawer from '../enhanced/drawer';
import { getFixedSizeBannerClass } from '../utils';
import { TemplateProps } from '..';

export const UnenhancedTemplate: FunctionComponent<TemplateProps> = ({ creative, placement, children }) => {
  const fixedSizeBannerClass = getFixedSizeBannerClass(creative, placement);

  return (
    <div
      class={`str-adunit str-adunit-banner-only ${fixedSizeBannerClass} str-compiled-template-unenhanced`}
      data-testid="unenhanced-adunit"
    >
      {children}
      <Drawer creative={creative} placement={placement} />
    </div>
  );
};
