export type PerformanceTimestamp = number | null;

const redirectSamePage = (url: string) => {
  window.location.assign(url);
};

const redirectUrl = (mediaUrl: string, token: string | undefined, nonce: string | undefined) => {
  if (GENERATOR) {
    return mediaUrl;
  } else {
    return `${CONFIG.clickoutDomain}?clickout_url=${encodeURIComponent(mediaUrl)}&token=${token}&nonce=${nonce}`;
  }
};

const performanceTimestamp = (): PerformanceTimestamp => {
  if (!window.performance || !window.performance.now || !isNative(window.performance.now)) {
    return null;
  }
  return window.performance.now();
};

/**
 * get the most recent PerformanceResourceTiming object for a given URL request
 * @param url
 */
const getPRT = (url: string): PerformanceResourceTiming | undefined => {
  if (!window.performance || !window.performance.getEntriesByName || !isNative(window.performance.getEntriesByName)) {
    return undefined;
  }
  return window.performance.getEntriesByName(url, 'resource').pop() as PerformanceResourceTiming;
}

// Detect whether a JS built-in browser function has been
// overwritten
const isNative = (fn: Function): boolean => {
  return !!fn.toString().match(/\[native code\]/);
};

export const isIframe = () => window.self !== window.top;
export const isTopWindow = () => window.self === window.top;

export default {
  redirectSamePage,
  redirectUrl,
  getPRT,
  performanceTimestamp,
  isNative,
  isIframe,
  isTopWindow
};
