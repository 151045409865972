import { getLanguage } from 'modules/asset_swap/asset_swap';
import { ASSETS_BY_LANGUAGE } from 'modules/asset_swap/assets_by_language';

import { ButlerResponse } from 'modules/butler/response';

export const getCtaText = (butlerResponse: ButlerResponse): string => {
  const { creative } = butlerResponse;

  const language = getLanguage(butlerResponse);

  // Use behavior CTA text if available
  if (creative.behaviors?.ctaCustomText) {
    return creative.behaviors.ctaCustomText;
  }

  // Use BR's CTA text if available
  if (creative.ctaText) {
    return creative.ctaText;
  }

  return ASSETS_BY_LANGUAGE[language].ctaText; // Replace 'Default Text' with whatever you consider a universal fallback
};
