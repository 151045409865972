import { RefObject } from 'preact';
import { BannerType, extractClickTagParam, extractEClickUrl, getUrlFromElement } from '.';
import { GWDWindow } from '../types';

const INSIGHTS_BEACON_URL = '//insight.adsrvr.org/track/clk?';
const ADNXS_BEACON_URL = '//nym1-ib.adnxs.com/click?';
const BASIS_BEACON_URL = /https?:\/\/clickserv\.sitescout\.com\/clk.[^"|']*/;

export interface FindClickoutUrlProps {
  container: RefObject<HTMLDivElement>;
  bannerType: string;
  adm: string;
  element: HTMLElement | null;
}

export const findClickoutUrl = (props: FindClickoutUrlProps): string | undefined | null => {
  const { container, bannerType, element, adm } = props;

  if (!container || !container.current) return;

  const iframes = container && container.current!.querySelectorAll('iframe');

  if (iframes) {
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      let iframeData;

      // guard against non-json iframe name attributes
      try {
        iframeData = iframe && JSON.parse(iframe.getAttribute('name') || '{}');
        if (iframeData && iframeData.clicks && iframeData.clicks.clickTag) {
          return iframeData.clicks.clickTag;
        }
      } catch (e) {}
    }
  }

  // dcm ads frequently store the click tracker in a data attr
  const dcmAd = element?.querySelector('.dcmads');
  if (dcmAd && dcmAd.getAttribute('data-dcm-click-tracker')) {
    const clickTracker = dcmAd.getAttribute('data-dcm-click-tracker');

    // only return if it's an actual redirect, not just a click tracking beacon
    if (!clickTracker?.match(INSIGHTS_BEACON_URL) && !clickTracker?.match(ADNXS_BEACON_URL)) {
      return clickTracker;
    }
  }

  if (bannerType === BannerType.Adform) {
    const div = document.createElement('div');
    div.innerHTML = adm;
    return getUrlFromElement(div.querySelector('noscript'));
  }

  if (bannerType === BannerType.Basis) {
    const basisClickout = adm.match(BASIS_BEACON_URL);
    if (basisClickout) {
      return basisClickout[0];
    }
  }

  // AMO (Adobe Media Optimizer) banners
  const amoKey: any = Object.keys(window).filter((key) => key.match(/^amo_/))[0];
  if (amoKey) {
    const amo: any = window[amoKey];

    if (amo && amo.atsLoggerBasePath && amo.atsParams && amo.baseParams) {
      const atsParams = Object.keys(amo.atsParams).map((key) => {
        return `&${key}=${encodeURIComponent(amo.atsParams[key])}`;
      });
      const baseParams = Object.keys(amo.baseParams).map((key) => {
        return `&${key}=${encodeURIComponent(amo.baseParams[key])}`;
      });

      return `${amo.atsLoggerBasePath}?cmd=CLICK_THRU&ProductURL=${encodeURIComponent(
        amo.ad[0].product_url
      )}${atsParams.join('')}${baseParams.join('')}`;
    }
  }

  // Google Web Designer banners
  // TODO: This check happens before creative is fully loaded, even if creative is of type Google Web Designer banner
  if (
    (window as unknown as GWDWindow).studioV2 &&
    (window as unknown as GWDWindow).studioV2.api &&
    (window as unknown as GWDWindow).studioV2.api.creatives &&
    (window as unknown as GWDWindow).studioV2.api.creatives.length > 0 &&
    ((window as unknown as GWDWindow).studioV2.api.creatives[0].a ||
      (window as unknown as GWDWindow).studioV2.api.creatives[0].j)
  ) {
    let creative: any;

    // it appears this creative could be in a few different spots.
    // there's probably a more clever way to do this and search all children in the object
    if ((window as unknown as GWDWindow).studioV2.api.creatives[0].a) {
      creative = (window as unknown as GWDWindow).studioV2.api.creatives[0].a;
    } else {
      creative = (window as unknown as GWDWindow).studioV2.api.creatives[0].j;
    }

    const creativeKeys = Object.keys(creative);
    const customEventKey = creativeKeys.filter((key) => creative[key] && creative[key].Exit)[0];

    if (!customEventKey) {
      return;
    }

    const exitObj = creative[customEventKey].Exit;
    const exitKeys = Object.keys(exitObj);

    // to handle tags with multiple clickouts
    const preferredKeys = ['mediaClickTag', 'clickTag1'];
    const preferredKey = exitKeys.filter((key) => {
      return preferredKeys.indexOf(key) >= 0;
    })[0];
    const exitKey = preferredKey || exitKeys[0];

    return exitObj[exitKey].url;
  }

  if (bannerType === BannerType.TradeDeskBanner) {
    const ttd_url = extractClickTagParam(adm);
    if (ttd_url) {
      return ttd_url;
    }
  }

  /**
   * Use eClickURL if available for clicks (#575)  Some creatives include an 'eClickURL' variable,
   * in the form of:  <!-- eClickURL = https://example.com/ad -->  This variable will be used, when available,
   * for the banner headline.
   */
  const eClickURL = extractEClickUrl(adm);
  if (eClickURL) {
    return eClickURL;
  }

  return container && getUrlFromElement(container.current!.querySelector('noscript'));
};
