import { h, FunctionalComponent } from 'preact';
import Description from './description/description.component';
import Title from './title/title.component';
import './enhanced-text.scss';
import { useButler } from '@ad-rendering/groundcontrol-legacy/tag/models/ad-experiences/hooks';
import { publisherStyles } from '../../../publisher-styling/helper/publisherStyles';

interface TextProps {
  currentSubtitle?: string | undefined;
}

const EnhancedText: FunctionalComponent<TextProps> = ({ currentSubtitle }) => {
  const butlerResponse = useButler();
  if (!butlerResponse) return null;

  const { placement } = butlerResponse;
  const publisherStyle = `${placement.publisherKey}_${placement.domain}`;
  const arrayClassName = [
    'str-text',
    ...(currentSubtitle !== undefined ? ['str-captions'] : []),
    ...(!!publisherStyles[publisherStyle] ? ['str-enhanced-text'] : [])
  ];

  return (
    <div className={arrayClassName.join(' ')}>
      <Title />
      <Description />
    </div>
  );
};

export default EnhancedText;
