import { useEffect, useState } from 'preact/hooks';
import { ThreeHourForecast } from './DailyWeatherForecast';
import { WeatherData } from './types';

export const useOpenWeatherAPI = (city?: string) => {
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const [loading, setLoading] = useState(true);

  let url = 'https://cdn.rendering.sharethrough.com/weather';

  if (city) {
    url += `?city=${city}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url);
      const data: any = await response.json();
      const forecasts = data.list.map(
        (forecast: any) => new ThreeHourForecast(data.city.country, data.city.name, forecast)
      );
      setWeatherData({ forecasts });
      setLoading(false);
    };

    fetchData();
  }, [url]);

  return { weatherData, loading };
};
