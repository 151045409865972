import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import './prime-logo.scss';

export const AmazonPrimeLogo: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { primeLogo } = assets;
  if (!primeLogo) return null;

  return (
    <div
      className="str-amazon-prime-logo"
      data-testid="amazon-prime-logo"
      style={{ backgroundImage: `url(${primeLogo.url})` }}
    ></div>
  );
};
