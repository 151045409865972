import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import { AmazonPrimeLogo } from '../prime-logo/prime-logo.component';
import './price.scss';

export const AmazonPrice: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { price, salePrice } = assets;
  if (!price) return null;

  const match = price.value.match(/([$€£¥]?)(\d+)\.(\d+)/);
  if (!match) return null;

  const [, currency, whole, fraction] = match;
  if (!currency || !whole || !fraction) return null;

  return (
    <div className="str-amazon-price-container">
      <div className="str-amazon-price">
        <span className="str-amazon-price-currency" data-testid="amazon-currency">
          {currency}
        </span>
        <span className="str-amazon-price-whole" data-testid="amazon-whole">
          {whole}
        </span>
        <span className="str-amazon-price-fraction" data-testid="amazon-fraction">
          {fraction}
        </span>
      </div>
      {salePrice && <span className="str-amazon-price-sale">{salePrice.value}</span>}
      <AmazonPrimeLogo />
    </div>
  );
};
