import { elementDimensions, elementPosition, thumbnailDimensions } from 'modules/page_geometry';
import { BeaconOptions } from '../types';

export const getElementParams = (element: HTMLElement): BeaconOptions => {
  return {
    pwidth: elementDimensions(element).width,
    pxoff: elementPosition(element).x,
    pyoff: elementPosition(element).y,
    twidth: thumbnailDimensions(element).width
  };
};
