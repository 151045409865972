export const getIframeHtml2 = (adm: string, scale: number): string => {
  return `<html><head><style>
        .str-thumbnail-body {
          margin: 0 auto;
          overflow: -moz-scrollbars-none;
          width: 100%;
          height: 100%;
          transform: scale(${scale});
          transform-origin: left top;
        }
        .str-thumbnail-body::-webkit-scrollbar { width: 0 !important };
        .str-thumbnail-body::-webkit-scrollbar::-ms-overflow-style: none;
      </style></head>
    <body class='str-thumbnail-body'>
      ${adm}
    </body></html>`;
};
