import { BackgroundStyle, FontStyle, IBackgroundStyleResponse, IFontStyleResponse, ITextStyleResponse } from '.';

export class TextStyle implements ITextStyleResponse {
  hide: boolean | undefined;
  font: IFontStyleResponse | undefined;
  background: IBackgroundStyleResponse | undefined;

  constructor(response?: ITextStyleResponse) {
    this.hide = response?.hide;
    this.font = new FontStyle(response?.font);
    this.background = new BackgroundStyle(response?.background);
  }
}
