export const iframeEvent = (data: any, onClickEvent?: ((event: MouseEvent) => void) | undefined) => {
  if (isClickEvent(data) && onClickEvent) {
    onClickEvent(new MouseEvent('thumbnailclick'));
  }
};

export const isClickEvent = (data: any) => {
  let objectData: any = typeof data === 'string' ? stringToObject(data) : data;
  if (objectData.stringValue) objectData = tryGetDataRegex(objectData.stringValue);
  if (objectData.method) {
    return objectData.method === 'clickthrough' || objectData.method === 'iframeclick';
  } else if (objectData.clickTag) {
    window.open(objectData.clickTag, '_blank');
    return true;
  } else if (objectData.methodName) {
    return objectData.methodName === 'AD_CLICKED';
  }
  return false;
};

export const stringToObject = (stringData: string): any => {
  try {
    return JSON.parse(stringData);
  } catch (e) {
    return defaultStringValue(stringData);
  }
};

export const tryGetDataRegex = (stringData: string): any => {
  try {
    const regex = /\"data":{(.*?)\}/;
    const match = stringData.match(regex);
    if (match) {
      const jsonObject = JSON.parse('{' + match[1] + '}');
      return jsonObject;
    }
    return defaultStringValue(stringData);
  } catch (e) {
    return defaultStringValue(stringData);
  }
};

export const defaultStringValue = (stringData: string) => {
  return { stringValue: stringData };
};
