import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';

export const dispatchWinNotificationFail = (arid: AdserverRequestId, nurl: string) => {
  return new Beacon({
    fireOnce: true,
    cacheKey: `nurlFail-${arid}`,
    data: {
      type: BeaconType.nurlFail,
      arid,
      nurl
    }
  });
};
