import { experimentManager } from 'modules/experiments';
import { sample } from 'modules/monitoring';
import { shouldDisableEnhancement } from 'modules/policies';
import ResponseStore from 'modules/response/response_store';
import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';
import { getEnhancementFetchLatency, getEnhancementRenderLatency } from '../utils/performanceTimeStore';

export const dispatchIsEnhanced = (
  arid: AdserverRequestId,
  enhancementVersionId: string,
  inner_creative_id: string = ''
) => {
  const butlerResponse = ResponseStore.getResponse(arid);
  if (shouldDisableEnhancement(arid, butlerResponse.creative, butlerResponse.placement)) {
    const experiment = experimentManager.getActiveExperiment();
    if (experiment && experiment.result === 'unenhanced') {
      sample(new Error('ExperimentIsEnhancedError'), { butlerResponse });
      return;
    }
  }

  return new Beacon({
    fireOnce: true,
    cacheKey: `is-enhanced-${arid}`,
    data: {
      type: BeaconType.isEnhanced,
      arid,
      ...(inner_creative_id != '' && { inner_creative_id }),
      evid: enhancementVersionId,
      enhancementRenderLatency: getEnhancementRenderLatency(),
      enhancementFetchLatency: getEnhancementFetchLatency() || '',
      ...getBaseParams(arid)
    }
  });
};
