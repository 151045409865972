import { Ref, useEffect } from 'preact/hooks';

export const useAnchorClickHandler = (ref: Ref<HTMLDivElement | null>) => {
  useEffect(() => {
    const handleClick = (anchor: HTMLAnchorElement) => (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      window.open(anchor.href, '_blank');
    };

    if (ref.current) {
      const anchors = ref.current.querySelectorAll('a');
      const handlers = new Map();

      anchors.forEach((anchor) => {
        const handler = handleClick(anchor);
        anchor.addEventListener('click', handler);
        handlers.set(anchor, handler);
      });

      return () => {
        anchors.forEach((anchor) => {
          anchor.removeEventListener('click', handlers.get(anchor));
        });
      };
    }

    return () => {};
  }, [ref]);
};
