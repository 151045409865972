import { sample } from 'modules/monitoring';
import { useNativeAssets } from 'modules/ortb';
import { Fragment, FunctionComponent, h } from 'preact';
import './badge.scss';

export const AmazonBadge: FunctionComponent = () => {
  const assets = useNativeAssets();

  if (!assets) return null;

  let { badge } = assets;
  if (!badge) return null;

  const parsedBadge = (() => {
    if (badge) {
      try {
        return JSON.parse(badge.value);
      } catch (e) {
        e.message = `Error parsing badge JSON: ${e.message}`;
        sample(e, { amazonBadge: badge });
      }
    }

    return null;
  })();
  if (!parsedBadge) return null;

  return (
    <Fragment>
      <div className="str-amazon-badge-flex-break" />
      <div className="str-amazon-badge">
        <div
          className="str-amazon-badge-label"
          style={{ color: `#${parsedBadge.label.color}`, backgroundColor: `#${parsedBadge.label.backgroundColor}` }}
        >
          <span className="str-amazon-badge-label-text">{parsedBadge.label.text}</span>
        </div>
        <div
          className="str-amazon-badge-messaging"
          style={{
            color: `#${parsedBadge.messaging.color}`,
            backgroundColor: `#${parsedBadge.messaging.backgroundColor}`
          }}
        >
          <span className="str-amazon-badge-messaging-text">{parsedBadge.messaging.text}</span>
        </div>
      </div>
    </Fragment>
  );
};
