import { ButlerCreative } from 'modules/butler/creative';
import { RuleEngine } from 'modules/rule-engine';
import { FOURSQUARE_PIXELS } from './data';

const foursquarePixelPolicy = new RuleEngine<[ButlerCreative], boolean>();

foursquarePixelPolicy.addRule({
  evaluate: ({ creativeKey, sourceId }) => {
    for (const pixel of FOURSQUARE_PIXELS) {
      if (pixel.sourceId !== sourceId) continue;
      const fsPixel = pixel.creativeKeys.find((key) => creativeKey === key);
      if (fsPixel) return true;
    }

    return false;
  }
});

export const shouldFireFoursquarePixel = (creative: ButlerCreative): boolean => {
  return foursquarePixelPolicy.evaluate(creative);
};
