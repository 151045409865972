import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType, UserEvent } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchUserShare = (arid: AdserverRequestId) => {
  return new Beacon({
    data: {
      arid,
      share: 'custom',
      type: BeaconType.userEvent,
      userEvent: UserEvent.Share,
      ...getBaseParams(arid)
    }
  });
};
