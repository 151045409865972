import { GCEnhancementPayload } from '@sharethrough/groundcontrol-enhancement-flush';
import BlankVideo from 'assets/icons/blank.mp4';
import Cannon from 'modules/beacons/cannon';
import Launcher from 'modules/beacons/launcher';
import Beacons from 'modules/beacons/utils';
import Butler from 'modules/butler/api';
import { VastVideoCreative } from 'modules/butler/creative';
import { CaptionsContext } from 'modules/enhancements/captions/captionsProvider';
import { EnhancementError } from 'modules/enhancements/loadEnhancement.type';
import { getParameterByName } from 'modules/utils';
import { createRef, h } from 'preact';
import { useContext } from 'preact/hooks';
import { CreativeType, DurationKey, getAllEnumValues } from 'tag/models/common/common';
import { AdExperienceBase, AdExperienceBaseProps } from '../../ad-experience';
import { useEnhancement } from '../../hooks';
import { AutoPlay } from '../auto-play';
import { MuteButton } from '../mute-button';
import { Replay } from '../replay';
import { VastVideo2State } from './types';
import { loadIma, startVideoAd } from './utils';
import { removeEventListeners } from './utils/vastEventListeners';
import { AdsManager, ImaSDK2 } from 'modules/imaSDK';
import { VastVideoSignalState } from './vast-video.component.state';
import { Template } from 'tag/models/common/template';

const durationBeacons = getAllEnumValues<number>(DurationKey);
const VIDEO_ONLY_MAX_WIDTH = 225;

interface VastVideoProps extends AdExperienceBaseProps {
  captionsContext: {
    setCurrentTime?: (time: number) => void;
    setInnerCreativeId?: (creativeId: string) => void;
  } | null;
  enhancementContext: {
    enhancement: GCEnhancementPayload | null;
    error: EnhancementError | null;
  } | null;
}

export class VastVideo extends AdExperienceBase<VastVideoCreative, VastVideoProps, VastVideo2State> {
  impressionFired: boolean = false;
  isVast: boolean = false;
  currentTime: number = 0;
  adsManager: AdsManager | null = null;
  imaSDK: ImaSDK2 | null = null;

  refBlankVideo = createRef<HTMLVideoElement>();
  refMainContainer = createRef<HTMLDivElement>();
  refThumbnail = createRef<HTMLDivElement>();
  refThumbnailContainer = createRef<HTMLDivElement>();

  constructor(props: VastVideoProps) {
    super(props);
    VastVideoSignalState.resetValues();
    this.isVast = this.creative.action === CreativeType.NativeOutstream;
    VastVideoSignalState.autoPlay.value = Butler.shouldCreativeInstantPlay(this.butlerResponse);
  }

  handleClick = (event: MouseEvent) => {
    const { videoReplayed } = VastVideoSignalState;
    if (!videoReplayed.value) {
      Cannon.fireCustomEngagement(this.butlerResponse);
    }
    /**
     * 1. The IMA player handles firing the click beacons in a callback function.
     * The following beacons are fired: 3rd party click beacons, clickout, imaClick, and autoplayVideoEngagement.
     * See IMA click event listener: src/modules/ima.ts#L256.
     */
    const clickedOnThumbnail = (event.target as HTMLElement).tagName === 'IFRAME';
    if (clickedOnThumbnail) {
      return;
    }

    /**
     * 2. For click-to-play videos, no beacons are currently fired.
     */
    const clickedOnPlayButton = (event.target as HTMLElement).classList.contains('icon-play-button');
    if (clickedOnPlayButton) {
      return;
    }

    /**
     * 4. Extract the clickthrough URL.
     * Fire autoplayVideoEngagement.
     */
    const clickThroughUrl = this.imaSDK?.ad?.h.clickThroughUrl;
    const { mediaUrl, customEngagementUrl, customEngagementUrlToken, customEngagementUrlNonce } = this.creative;

    // If there's a mediaUrl redirect to it
    if (mediaUrl && mediaUrl !== clickThroughUrl) {
      window.open(mediaUrl, '_blank');
    }

    /**
     * Fire any beacons defined in the VAST tag, except clickthrough,
     * since that is used for redirecting.
     */
    if (this.imaSDK?.vastUrl && this.imaSDK?.vastUrl.match(/<vast/i)) {
      const videoClicksMatch = this.imaSDK?.vastUrl.match(/<VideoClicks>.*?<\/VideoClicks>/m);
      const parser = new DOMParser();
      if (videoClicksMatch) {
        const xmlDoc = parser.parseFromString(videoClicksMatch[0], 'text/xml');
        xmlDoc.firstChild?.childNodes.forEach((childNode) => {
          if (childNode.textContent) {
            // Do not fire a track pixel on click through because we redirect to it bellow already.
            if (childNode.nodeName !== 'ClickThrough') {
              Cannon.firePixel(childNode.textContent).catch(() => undefined);
            }
          }
        });
      }
    }

    /**
     * Custom engagement URL here will only really come into play
     * with NAG workaround creatives, but if it does we need to make
     * sure to use the click tracker provided by the user.
     */
    if (customEngagementUrl) {
      // calls publishClickAndRedirect method of ReactAdUnit
      this.props.onClick(event, {
        url: customEngagementUrl,
        token: customEngagementUrlToken,
        nonce: customEngagementUrlNonce
      });
      return;
    } else if (clickThroughUrl) {
      // this is the URL we pulled from the VAST tag via IMA
      // calls publishClickEvent method of ReactAdUnit
      if (this.props.publishClick) this.props.publishClick(event);
      window.open(clickThroughUrl, '_blank');
      return;
    } else {
      if (!clickThroughUrl && this.props.publishClick) this.props.publishClick(event);
      // it's possible that the user gets here, but hopefully they dont.
      // they could click the video after it's done playing, but we don't have
      // a clickout URL from the vast tag, so nothing would happen.
      return;
    }
  };

  private _videoOnlyClass: string | undefined = undefined;

  get videoOnlyClass(): string {
    if (this._videoOnlyClass === undefined) {
      if (this.creative.behaviors.shouldRenderVideoOnly) {
        this._videoOnlyClass = 'str-video-only';
      } else if (
        // TODO: once testing plan is figured out, remove this flag and write tests
        getParameterByName('videoOnly') == 'true' &&
        this.refMainContainer.current &&
        this.refMainContainer.current.getBoundingClientRect().width <= VIDEO_ONLY_MAX_WIDTH
      ) {
        this._videoOnlyClass = 'str-video-only';
      } else {
        this._videoOnlyClass = '';
      }
    }
    return this._videoOnlyClass;
  }

  trackRenderedImpression() {
    const enhancementContext = this.props.enhancementContext;

    if (this.impressionFired || VastVideoSignalState.videoReplayed.value) return;
    if (
      (this.butlerResponse.creative.hasEnhancement && (enhancementContext?.enhancement || enhancementContext?.error)) ||
      !this.butlerResponse.creative.hasEnhancement
    ) {
      this.impressionFired = true;
      const { adserverRequestId } = this.props;
      Launcher.trackRenderedImpression(
        adserverRequestId,
        this.refMainContainer.current!.parentElement as HTMLElement,
        this.context?.enhancement_version_id
      );
    }
  }

  fireDurationBeacon() {
    const rounded = Math.round(this.currentTime);
    const exist = durationBeacons.indexOf(rounded);
    if (exist >= 0) {
      const { adserverRequestId } = this.props;
      Beacons.fire.silentAutoPlayDuration(durationBeacons[exist], adserverRequestId);
      durationBeacons.splice(exist, 1);
    }
  }

  onVideoPlayingChange = (playing: boolean) => {
    if (playing) {
      Beacons.fire.videoPlay(this.props.adserverRequestId, true);
      this.trackRenderedImpression();
    }
  };

  onCurrentTimeChange = (currentTime: number) => {
    // sets the current time in the captions context
    const { captionsContext } = this.props;
    if (captionsContext?.setCurrentTime) captionsContext?.setCurrentTime(currentTime);
    this.fireDurationBeacon();
  };

  componentDidUpdate() {
    this.trackRenderedImpression(); //TODO this funcion is firing in every render, it should only fire once
  }

  componentWillMount() {
    VastVideoSignalState.videoPlaying.subscribe(this.onVideoPlayingChange);
    VastVideoSignalState.currentTime.subscribe(this.onCurrentTimeChange);
  }

  componentDidMount() {
    this.setIma();
    window.addEventListener('focus', () => this.windowFocusHandler());
  }

  componentWillUnmount() {
    if (this.adsManager)
      removeEventListeners(this.adsManager, this.butlerResponse, this.refMainContainer.current!.parentElement!);
    window.removeEventListener('focus', () => this.windowFocusHandler());
  }

  async setIma() {
    this.adsManager = null;
    VastVideoSignalState.resetVideoValues();
    const ima = await loadIma(
      this,
      this.butlerResponse,
      this.refMainContainer.current!.parentElement!,
      VastVideoSignalState.videoReplayed,
      VastVideoSignalState.videoLoaded,
      VastVideoSignalState.videoSrc
    );
    if (ima) {
      this.imaSDK = ima;
    }
  }

  handlePlay = (e: MouseEvent) => {
    e.stopPropagation();
    startVideoAd(
      this.props.adserverRequestId,
      this.imaSDK,
      VastVideoSignalState.videoStarted,
      VastVideoSignalState.videoReplayed
    );
  };

  handleMute = (e: MouseEvent) => {
    e.stopPropagation();
    const { adserverRequestId } = this.props;
    const { muted } = VastVideoSignalState;
    if (this.adsManager) {
      this.adsManager.setVolume(!muted.value ? 0 : 1);
      muted.value = !muted.value;
      if (!muted.value && this.currentTime > 0) {
        Beacons.fire.videoVolumeOn(adserverRequestId, this.currentTime, true);
      }
    }
  };

  handleReplay = async (event: MouseEvent) => {
    event.stopPropagation();
    if (this.adsManager)
      removeEventListeners(this.adsManager, this.butlerResponse, this.refMainContainer.current!.parentElement!);
    VastVideoSignalState.resetValues();
    const promises = Promise.all([this.setIma()]);
    await Promise.resolve(promises);
    VastVideoSignalState.videoReplayed.value = true;
  };

  windowFocusHandler = () => {
    const { videoStarted } = VastVideoSignalState;
    if (this.adsManager && videoStarted) {
      this.adsManager.resume();
    }
  };

  render() {
    const { videoCompleted, videoStarted, muted, videoSrc } = VastVideoSignalState;
    return (
      <div
        ref={this.refMainContainer}
        onClick={(event: MouseEvent) => this.handleClick(event)}
        className={`${this.isVast ? 'str-vast-container' : ''} ${this.videoOnlyClass} str-react-template`}
        data-testid="vast-container"
      >
        <Template
          creative={this.creative}
          placement={this.placement}
          country={this.butlerResponse.country}
          className={`${videoCompleted ? 'video-completed' : ''}`}
          adserverRequestId={this.props.adserverRequestId}
        >
          <div
            className="str-thumbnail-wrapper str-thumbnail-wrapper-outstream"
            style={{ maxHeight: this.placement.size.height, maxWidth: this.placement.size.width }}
          >
            <div className="str-thumbnail" ref={this.refThumbnailContainer}>
              <MuteButton
                muted={muted.value}
                videoCompleted={videoCompleted.value}
                videoStarted={videoStarted.value}
                onClick={this.handleMute}
              />
              <AutoPlay
                autoPlay={VastVideoSignalState.autoPlay.value}
                onClick={this.handlePlay}
                videoStarted={videoStarted.value}
              />
              <div
                className={`str-video-container ${videoCompleted ? 'video-completed' : ''}`}
                data-testid="thumbnail-container"
              >
                <div className="video-wrapper" ref={this.refThumbnail}></div>
                <video
                  playsInline
                  className="blank-video"
                  ref={this.refBlankVideo}
                  src={BlankVideo}
                  preload="metadata"
                />
                <Replay
                  videoSrc={videoSrc.value}
                  videoStarted={videoStarted.value}
                  videoCompleted={videoCompleted.value}
                  onReplay={this.handleReplay}
                />
              </div>
            </div>
          </div>
        </Template>
      </div>
    );
  }
}

export function VastVideo2(props: AdExperienceBaseProps) {
  const captionsContext = useContext(CaptionsContext);
  const enhancementContext = useEnhancement();

  return <VastVideo {...props} captionsContext={captionsContext} enhancementContext={enhancementContext} />;
}
