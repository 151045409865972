import { Signal, signal } from '@preact/signals';
import Beacons from 'modules/beacons/utils';
import { EnhancedDisplayCreative } from 'modules/butler/creative';
import { sample } from 'modules/monitoring';
import ResponseStore from 'modules/response/response_store';
import { generateClickEvent } from 'modules/utils';
import { ErrorInfo, Fragment, createRef, h } from 'preact';
import { unescapeString } from 'tag/helpers/unescape-string.helper';
import { Template } from 'tag/models/common/template';
import { v4 as uuidv4 } from 'uuid';
import { AdExperienceBase } from '../ad-experience';
import BannerConfig from './banner/banner.config';
import BannerIframe2 from './banner/banner_iframe.component.v2';
import { TransformOrigin } from './banner/types';
import { getSize } from './banner/utils/getSize';
import ClickBlocker from './clickblocker/clickblocker.component';
import './enhanced_display.scss';
import { EnhancedDisplayProps, EnhancedDisplayState } from './types';
import { RenderMethod, getRenderMethod } from './utils';
import { clearMediaUrl } from './utils/clearMediaUrls';
import { findClickoutUrl } from './utils/findClickoutUrl';
import { getAdm } from './utils/getAdm';
import { BannerType, getBannerType } from './utils/getBannerType';
import { getClickableElement } from './utils/getClickableElement';
import { postBannerMeta } from './utils/postBannerMeta';

export class EnhancedDisplay2 extends AdExperienceBase<
  EnhancedDisplayCreative,
  EnhancedDisplayProps,
  EnhancedDisplayState
> {
  container = createRef<HTMLDivElement>();
  element: HTMLElement | null = null;
  height: number;
  isEnhanced: boolean = !this.creative.behaviors.shouldRenderBannerTemplate;
  thumbnailId = uuidv4();
  transformOrigin: TransformOrigin;
  width: number;
  mediaUrl: string;
  showClickBlocker: Signal<boolean> = signal(false);
  isBannerClickable: Signal<boolean> = signal(false);

  constructor(props: EnhancedDisplayProps) {
    super(props);

    this.state = {
      creative: {
        ...this.creative,
        adm: getAdm(this.creative),
        promotedByText: this.creative.advertiser ? this.creative.promotedByText : ''
      }
    };

    this.mediaUrl = clearMediaUrl(this.creative) ? '' : this.creative.mediaUrl;

    const _butlerResponse = ResponseStore.getResponse(props.adserverRequestId);

    const { width, height } = getSize(
      _butlerResponse.creative.behaviors.shouldRenderFixedSizeBanner,
      _butlerResponse.creative.size
    );
    this.height = height;
    this.width = width;

    this.transformOrigin =
      BannerConfig.SPECIAL_WIDTHS.includes(this.width) || (this.width == 300 && this.height == 600)
        ? TransformOrigin.Left
        : TransformOrigin.Center;
  }

  private _bannerType: BannerType | undefined = undefined;
  get bannerType(): BannerType {
    if (!this._bannerType) {
      this._bannerType = getBannerType(
        this.state.creative.adm,
        this.state.creative.sourceId,
        this.butlerResponse.placement.publisherKey
      );
    }

    return this._bannerType;
  }

  private _renderMethod: RenderMethod | undefined = undefined;
  get renderMethod(): RenderMethod {
    if (!this._renderMethod) {
      this._renderMethod = getRenderMethod(this.bannerType, this.state.creative);
    }

    return this._renderMethod;
  }

  private _clickableElement: Element | null = null;
  get clickableElement(): Element | null {
    if (!this._clickableElement) {
      this._clickableElement = getClickableElement({
        bannerType: this.bannerType,
        container: this.container
      });
    }

    return this._clickableElement;
  }

  private _clickoutUrl: string | undefined | null = undefined;
  get clickoutUrl() {
    if (!this._clickoutUrl) {
      this._clickoutUrl = findClickoutUrl({
        container: this.container,
        bannerType: this.bannerType,
        element: this.element,
        adm: this.state.creative.adm
      });
    }

    return this._clickoutUrl;
  }

  get bannerMetadata() {
    return {
      bannerType: this.bannerType,
      clickable: this.getBannerClickability(),
      renderMethod: this.renderMethod
    };
  }

  getBannerClickability = (): boolean => {
    const clickability = !!(this.state.creative.mediaUrl || this.clickableElement || this.clickoutUrl);
    this.isBannerClickable.value = clickability;
    return clickability;
  };

  // scale only used when creative has a dimension larger than placement
  get oversizedScale() {
    const { width, height } = this.state.creative.size;
    const { width: placementWidth, height: placementHeight } = this.butlerResponse.placement.size;
    if (!width || !height || !placementWidth || !placementHeight) return 1;

    if (width <= placementWidth && height <= placementHeight) {
      return 1;
    }

    return Math.min(placementWidth / width, placementHeight / height);
  }

  componentDidMount() {
    // If we have no media url we need to wait for the ad to get rendered so we can try to get the click_out url
    // or the clickable element from the rendered ad.
    if (this.mediaUrl) {
      this.getBannerClickability();
      this.showClickBlocker.value = true;
    } else {
      window.setTimeout(() => {
        this.getBannerClickability();
        this.showClickBlocker.value = true;
      }, 1000);
    }

    window.setTimeout(() => {
      postBannerMeta(this.bannerMetadata);
    }, 10000);

    if (!!this.butlerResponse.creative.description && this.creative.behaviors.shouldRenderEnhancedTemplateDescription) {
      const descriptionDiv = this.element?.querySelector('.str-description');
      descriptionDiv?.classList.add('str-enhanced');
    }

    setTimeout(() => {
      const metadata = this.bannerMetadata;
      Beacons.fire.bannerRendered(
        this.butlerResponse.adserverRequestId,
        metadata.bannerType,
        metadata.renderMethod,
        metadata.clickable
      );
    }, 10000);
  }

  handleClick = (event: MouseEvent) => {
    this.propagateClickEvent(event);
    if (this.clickableElement) {
      const onclick = this.clickableElement.getAttribute('onclick');
      if (onclick) {
        this.clickableElement.setAttribute('onclick', unescapeString(onclick));
      }

      this.clickableElement.dispatchEvent(generateClickEvent());
      return;
    }

    // We try to find the click out url and if found, we click it.
    const clickoutUrl = this.clickoutUrl;
    if (clickoutUrl) {
      window.open(clickoutUrl, '_blank');
      return;
    }
  };

  propagateClickEvent = (event: MouseEvent) => {
    Beacons.fire.userEvents.share(this.butlerResponse.adserverRequestId);
    if (this.getBannerClickability() || event.type === 'thumbnailclick') {
      this.props.onClick(event);
    }
  };

  updateClickable = () => {
    this._clickableElement = null;
    this.getBannerClickability();
  };

  componentDidCatch(error: any, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);

    sample(error, {
      errorPayload: {
        errorInfo,
        creative: this.state.creative,
        adserverRequestId: this.props.adserverRequestId,
        reactRefactor: true
      }
    });
  }

  clickBlockerClick = (_event: MouseEvent) => {
    if (this.mediaUrl) {
      this.propagateClickEvent(_event);
      window.open(this.mediaUrl, '_blank');
    } else {
      this.handleClick(_event);
    }
  };

  render() {
    return (
      <Fragment>
        <ClickBlocker
          onClick={this.clickBlockerClick}
          showClickBlocker={this.showClickBlocker}
          isBannerClickable={this.isBannerClickable}
        />
        <div
          data-testid="str-enhanced-display"
          className="str-react-template"
          onClick={this.handleClick}
          ref={(element) => {
            this.element = element;
          }}
        >
          <Template
            creative={this.creative}
            placement={this.placement}
            country={this.butlerResponse.country}
            adserverRequestId={this.props.adserverRequestId}
          >
            <div
              className="str-thumbnail-wrapper"
              style={{
                minHeight: this.height * 0.75,
                minWidth: this.width * 0.75,
                maxHeight: this.height * this.oversizedScale,
                maxWidth: this.width * this.oversizedScale
              }}
              ref={this.container}
            >
              <BannerIframe2
                dataThumbnailId={this.thumbnailId}
                className={'str-thumbnail'}
                outerHtml={this.state.creative.adm}
                transformOrigin={this.transformOrigin}
                width={this.width}
                height={this.height}
                onLoad={this.updateClickable}
                onIframeClick={this.handleClick}
                adserverRequestId={this.props.adserverRequestId}
                hasEnhancement={this.butlerResponse.creative.hasEnhancement}
              />
            </div>
          </Template>
        </div>
      </Fragment>
    );
  }
}
