import { GCEnhancementType } from '@sharethrough/groundcontrol-enhancement-flush';
import {
  ENHANCED,
  WEATHER_ENHANCEMENT,
  experimentManager,
  isExperimentForcingGenericEnhancement
} from 'modules/experiments';
import { FunctionComponent, h } from 'preact';
import { useRef } from 'preact/hooks';
import { useButler, useChildrenOverflow, useEnhancement } from 'tag/models/ad-experiences/hooks';
import { CreativeType } from 'tag/models/common/common.model';
import Countdown from 'tag/models/common/template/enhanced/content/countdown/countdown';
import CaptionsEnhancement from './captions/captions.component';
import styles from './content.module.scss';
import HeadlineEnhancement from './headline/headline.component';
import WeatherEnhancement from './weather/weather.component';

export const EnhancedTemplateContent: FunctionComponent = () => {
  const butlerResponse = useButler();
  const { loading, enhancement } = useEnhancement();
  const containerRef = useRef<HTMLDivElement>(null);

  const ELEMENT_PRIORITY = [
    '.str-advertiser-container',
    '.str-cta',
    '.str-title',
    '.str-description',
    '.str-countdown-cta'
  ];

  useChildrenOverflow(containerRef, () => {
    const container = containerRef.current;
    if (!container) return;

    let elClass;
    let elementToRemove;

    do {
      elClass = ELEMENT_PRIORITY.pop();
      if (!elClass) return;

      elementToRemove = container.querySelector(elClass);
    } while (!elementToRemove && ELEMENT_PRIORITY.length > 0);

    if (elementToRemove) {
      elementToRemove.remove();
    }
  });

  if (!butlerResponse) {
    return null;
  }

  const renderContent = () => {
    if (isExperimentForcingGenericEnhancement()) {
      return <HeadlineEnhancement />;
    }

    if (experimentManager.featureFlag(WEATHER_ENHANCEMENT) === ENHANCED) {
      return <WeatherEnhancement />;
    }

    if (butlerResponse.creative.action === CreativeType.NativeOutstream && butlerResponse.creative.srt) {
      return <CaptionsEnhancement />;
    }

    switch (enhancement?.type) {
      case GCEnhancementType.OutstreamCaptions:
        return <CaptionsEnhancement />;
      case GCEnhancementType.BannerHeadline:
      case GCEnhancementType.OutstreamHeadline:
        return <HeadlineEnhancement />;
      case GCEnhancementType.BannerCountdown:
      case GCEnhancementType.OutstreamCountdown:
        return <Countdown />;
      case 'banner-weather' as any:
        return <WeatherEnhancement />;
      default:
        return <HeadlineEnhancement />;
    }
  };

  return (
    <div
      className={`str-enhanced-info-wrapper ${styles['enhanced-info-wrapper']} ${loading ? 'loading' : ''}`}
      ref={containerRef}
    >
      {renderContent()}
    </div>
  );
};
