import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchIframeBusterFailed = (arid: AdserverRequestId, failureType: string) => {
  return new Beacon({
    data: {
      arid,
      type: BeaconType.iframeBusterFailed,
      failureType,
      ...getBaseParams(arid)
    }
  });
};
