import { ThreeHourForecast } from './DailyWeatherForecast';

export interface WeatherData {
  forecasts: ThreeHourForecast[];
}

export enum WeatherDegreesType {
  Fahrenheit = 'F',
  Celcius = 'C'
}
