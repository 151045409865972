import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import { AmazonPrimeLogo } from '../prime-logo/prime-logo.component';
import './rating.scss';
import { Star, StarType } from './star.component';

export const AmazonRating: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const ratingAsset = assets.rating;
  if (!ratingAsset) return null;

  const rating = parseFloat(ratingAsset.value);
  if (isNaN(rating) || rating < 0) return null;

  const stars = Array.from({ length: 5 }, (_, index) => {
    const type = index < Math.floor(rating) ? StarType.Full : index < rating ? StarType.Half : StarType.Empty;

    return <Star key={index} type={type} />;
  });

  const { likes, price } = assets;

  return (
    <div className="str-amazon-rating-container">
      <div className="str-amazon-rating">
        <div className="str-amazon-rating-stars">{stars}</div>
        {likes && <div className="str-amazon-rating-count">{likes.value}</div>}
      </div>
      {!price && <AmazonPrimeLogo />}
    </div>
  );
};
