export const getBrowserUrl = () => {
  if (window.location.ancestorOrigins && window.location.ancestorOrigins.length > 0) {
    // Use location.ancestorOrigins first, in case we're in a frame.
    // This will be NULL if we're not in a frame, so we can fallback to window.location.href
    // Not always supported, see https://developer.mozilla.org/en-US/docs/Web/API/Location/ancestorOrigins
    return window.location.ancestorOrigins[0].split('?')[0];
  }

  return window.location.href.split('?')[0];
};
