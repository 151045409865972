import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType, UserEvent } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchAutoplayVideoEngagement = (arid: AdserverRequestId, currentTime: number) => {
  return new Beacon({
    data: {
      type: BeaconType.userEvent,
      arid,
      engagement: true,
      userEvent: UserEvent.AutoplayVideoEngagement,
      videoDuration: currentTime * 1000,
      ...getBaseParams(arid)
    }
  });
};
