import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchBannerRendered = (arid: AdserverRequestId, clickable: boolean) => {
  return new Beacon({
    data: {
      type: BeaconType.bannerRendered,
      arid,
      clickable,
      ...getBaseParams(arid)
    }
  });
};
