import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import './product-image.scss';

export const AmazonProductImage: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { mainImage } = assets;
  if (!mainImage) return null;

  return (
    <div
      className="str-amazon-product-image"
      data-testid="amazon-product-image"
      style={{ backgroundImage: `url(${mainImage.url})` }}
    ></div>
  );
};
