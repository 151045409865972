import { dispatchNagCreativeType } from 'modules/beacons/beacons/internal/nagCreativeType';
import Launcher from 'modules/beacons/launcher';
import { h } from 'preact';
import Thumbnail from 'tag/models/common/thumbnail';
import { AdExperienceBase } from '../ad-experience';
import './clickout.scss';
import { ClickoutCreative, ClickoutProps, ClickoutState } from './types';
import { Template } from 'tag/models/common/template';
export class ClickoutV2 extends AdExperienceBase<ClickoutCreative, ClickoutProps, ClickoutState> {
  element: HTMLElement | null;

  constructor(props: ClickoutProps) {
    super(props);
    this.state = {
      clickEventFired: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.element = null;
  }

  componentDidMount() {
    Launcher.trackRenderedImpression(this.props.adserverRequestId, this.element?.parentElement as HTMLElement);
    dispatchNagCreativeType(this.props.adserverRequestId);
  }

  handleClick(event: MouseEvent) {
    if (
      (event?.target as HTMLElement).classList.contains('str-opt-out-icon') ||
      (event?.target as HTMLElement).classList.contains('str-green-pmp-icon') ||
      (event?.target as HTMLElement).classList.contains('str-opt-out-label') ||
      (event?.target as HTMLElement).classList.contains('str-subheader')
    ) {
      return;
    }

    this.props.onClick(event);
  }

  render() {
    const { height, width } = this.placement.size;

    return (
      <div
        className="str-react-template"
        data-testid="str-clickout"
        onClick={this.handleClick}
        ref={(element) => (this.element = element)}
      >
        <Template
          creative={this.creative}
          placement={this.placement}
          country={this.butlerResponse.country}
          adserverRequestId={this.props.adserverRequestId}
        >
          <Thumbnail
            adserverRequestId={this.butlerResponse.adserverRequestId}
            src={this.creative.thumbnailUrl}
            placementHeight={height || 'auto'}
            placementWidth={width || 'auto'}
          />
        </Template>
      </div>
    );
  }
}
