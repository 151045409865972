import Launcher from 'modules/beacons/launcher';
import Beacons from 'modules/beacons/utils';
import { AdClickEvent } from 'modules/imaSDK';
import { ButlerResponse } from 'modules/butler/response';
import { AdsManager } from 'modules/imaSDK';

export const clickListener = (
  adEvent: AdClickEvent,
  adsManager: AdsManager,
  butlerResponse: ButlerResponse,
  videoReplayed: boolean,
  htmlElement: HTMLElement
) => {
  Launcher.fireThirdPartyClickBeacons(butlerResponse);

  if (adEvent.h.h.clickThroughUrl) {
    Launcher.trackClick(butlerResponse.adserverRequestId, htmlElement);
  }

  Beacons.fire.imaClick(butlerResponse.adserverRequestId);

  if (!videoReplayed) {
    const currentTime = adEvent.h.h.duration - adsManager.getRemainingTime();
    Beacons.fire.userEvents.autoplayVideoEngagement(butlerResponse.adserverRequestId, currentTime);
  }
};
