import { AdClickEvent, AdCompleteEvent, AdProgressEvent, AdVolumeEvent, AdsManager } from 'modules/imaSDK';
import {
  adProgressListener,
  clickListener,
  completeListener,
  firstQuartileListener,
  impressionListener,
  midpointListener,
  pausedListener,
  resumedListener,
  startedListener,
  thirdQuartileListener,
  volumeChangedListener
} from './event-listeners';
import { VastVideoSignalState } from '../vast-video.component.state';
import { ButlerResponse } from 'modules/butler/response';

const {
  autoPlay,
  currentTime,
  muted,
  videoSrc,
  videoCompleted: completed,
  videoPlaying: playing,
  videoReplayed: replayed,
  videoStarted: started
} = VastVideoSignalState;

export const addEventListeners = (
  adsManager: AdsManager,
  butlerResponse: ButlerResponse,
  mainContainer: HTMLElement
) => {
  if (!adsManager) return;

  const types = window.google.ima.AdEvent.Type;
  const adserverRequestId = butlerResponse.adserverRequestId;

  adsManager.addEventListener(types.AD_PROGRESS, (e: AdProgressEvent) =>
    adProgressListener(adserverRequestId, e, currentTime, videoSrc)
  );
  adsManager.addEventListener(types.CLICK, (e: AdClickEvent) =>
    clickListener(e, adsManager, butlerResponse, replayed.value, mainContainer)
  );
  adsManager.addEventListener(types.COMPLETE, (e: any) =>
    completeListener(e, adsManager, adserverRequestId, muted, completed, playing)
  );
  adsManager.addEventListener(types.FIRST_QUARTILE, () => firstQuartileListener(adserverRequestId));
  adsManager.addEventListener(types.IMPRESSION, () => impressionListener(adserverRequestId, autoPlay.value));
  adsManager.addEventListener(types.MIDPOINT, () => midpointListener(adserverRequestId));
  adsManager.addEventListener(types.PAUSED, () => pausedListener(playing));
  adsManager.addEventListener(types.RESUMED, () => resumedListener(playing));
  adsManager.addEventListener(types.STARTED, () => startedListener(started, playing));
  adsManager.addEventListener(types.THIRD_QUARTILE, () => thirdQuartileListener(adserverRequestId));
  adsManager.addEventListener(types.VOLUME_CHANGED, (e: AdVolumeEvent) => volumeChangedListener(e, muted));
};

export const removeEventListeners = (
  adsManager: AdsManager,
  butlerResponse: ButlerResponse,
  mainContainer: HTMLElement
) => {
  if (!adsManager) return;

  const types = window.google.ima.AdEvent.Type;
  const adserverRequestId = butlerResponse.adserverRequestId;

  adsManager.removeEventListener(types.AD_PROGRESS, (e: AdProgressEvent) =>
    adProgressListener(adserverRequestId, e, currentTime, videoSrc)
  );
  adsManager.removeEventListener(types.CLICK, (e: AdClickEvent) =>
    clickListener(e, adsManager, butlerResponse, replayed.value, mainContainer)
  );
  adsManager.removeEventListener(types.COMPLETE, (e: AdCompleteEvent) =>
    completeListener(e, adsManager, adserverRequestId, muted, completed, playing)
  );
  adsManager.removeEventListener(types.FIRST_QUARTILE, () => firstQuartileListener(adserverRequestId));
  adsManager.removeEventListener(types.IMPRESSION, () => impressionListener(adserverRequestId, autoPlay.value));
  adsManager.removeEventListener(types.MIDPOINT, () => midpointListener(adserverRequestId));
  adsManager.removeEventListener(types.PAUSED, () => pausedListener(playing));
  adsManager.removeEventListener(types.RESUMED, () => resumedListener(playing));
  adsManager.removeEventListener(types.STARTED, () => startedListener(started, playing));
  adsManager.removeEventListener(types.THIRD_QUARTILE, () => thirdQuartileListener(adserverRequestId));
  adsManager.removeEventListener(types.VOLUME_CHANGED, (e: AdVolumeEvent) => volumeChangedListener(e, muted));
};
