import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchVideoVolumeOn = (arid: AdserverRequestId, currentTime: number) => {
  return new Beacon({
    fireOnce: true,
    cacheKey: `videoVolumeOn-${arid}`,

    data: {
      type: BeaconType.videoVolumeOn,
      arid,
      currentTime: currentTime,
      ...getBaseParams(arid)
    }
  });
};
