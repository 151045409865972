interface BannerMeta {
  bannerType: string;
  clickable: boolean;
  renderMethod: string;
}

const targetOrigins =
  ENV === 'dev'
    ? ['http://localhost:3003', 'http://localhost:3008']
    : [
        'https://enhanced-ads.nativeadvertising.com',
        'http://enhanced-ads.nativeadvertising.com',
        'http://generator.sharethrough.com',
        'https://generator.sharethrough.com',
        'https://sfa.sharethrough.com'
      ];

export const postBannerMeta = (bannerMetaData: BannerMeta) => {
  const message = {
    key: 'BannerMetadata',
    payload: bannerMetaData
  };

  // check if window.parent.origin is in targetOrigins
  if (targetOrigins.indexOf(window.parent.origin) >= 0) {
    window.parent.postMessage(message, window.parent.origin);
  }
};
