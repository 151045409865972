import AdChoiceIcon from 'assets/icons/adchoice.png';
import { FRENCH_DOMAINS } from 'modules/asset_swap/assets_by_language';
import { h } from 'preact';
import { OptOutIconConfig } from './opt-out-icon.config';
import styles from './opt-out-icon.module.scss';
import { OptOutIconProps } from './types';

export const OptOutIcon = (props: OptOutIconProps) => {
  if (props.creative.behaviors.shouldRemoveAdchoicesLogo) {
    return null;
  }

  const getUrl = () => {
    if (props.placement.domain)
      return FRENCH_DOMAINS.find((dom) => props.placement.domain.match(dom))
        ? OptOutIconConfig.Urls.French
        : OptOutIconConfig.Urls.Default;
    return OptOutIconConfig.Urls.Default;
  };

  return (
    <a href={getUrl()} target="_blank" data-testid="opt-out-link">
      <div className={`str-opt-out-container ${styles['opt-out-container']} ${styles['relocate-ad-choices']}`}>
        <span
          className={`str-opt-out-icon ${styles['opt-out-icon']}`}
          style={{ backgroundImage: `url(${AdChoiceIcon})` }}
        ></span>
        <span className={`str-opt-out-label ${styles['opt-out-label']}`}>AdChoices</span>
      </div>
    </a>
  );
};
