export interface PostScribeBannerProps {
  adm: string;
  adserverRequestId: string;
  getBannerMetadata: () => {
    clickable: boolean;
    bannerType: string;
    renderMethod: string;
  };
  updateClickable: () => void;
  thumbnailWidth: number;
  thumbnailHeight: number;
  enhanced?: boolean;
}

export interface BannerProps extends PostScribeBannerProps {
  rawType: string;
}

export interface BannerProps2 {
  adm: string;
  adserverRequestId: string;
  getBannerMetadata: () => {
    clickable: boolean;
    bannerType: string;
    renderMethod: string;
  };
  updateClickable: () => void;
  enhanced?: boolean;
}

export enum TransformOrigin {
  Left = 'left',
  Center = 'center'
}

export interface BannerAdmProps {
  outerHtml: string;
}

export interface BannerStyleProps {
  thumbnailId: string;
  width?: number;
  height?: number;
}

export interface BannerIframeProps2 extends BannerBaseProps {
  adserverRequestId: string;
  hasEnhancement: boolean;
  onLoad: () => void;
  onIframeClick?: (event: MouseEvent) => void;
}

export interface BannerDivProps extends BannerBaseProps {
  thumbnailId: string;
}

export interface BannerDivState {
  scale: number;
}

export interface BannerBaseProps {
  className: string;
  dataThumbnailId: string;
  height: number;
  outerHtml: string;
  transformOrigin: TransformOrigin;
  width: number;
}
