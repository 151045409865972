import {
  BannerCountdownPayload,
  CountdownConfig,
  OutstreamCountdownPayload
} from '@sharethrough/groundcontrol-enhancement-flush';
import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useButler, useEnhancement } from 'tag/models/ad-experiences/hooks';
import Advertiser from '../../advertiser';
import { CountdownClock } from './clock.component';
import './countdown.scss';
import { getActiveCountdown } from './getActiveCountdown';
import { getLayoutType } from './getLayoutType';
import HeadlineEnhancement from '../headline/headline.component';

const Countdown = () => {
  const countdownContainerRef = useRef<HTMLDivElement>(null);
  const butlerResponse = useButler();
  const { enhancement } = useEnhancement<BannerCountdownPayload | OutstreamCountdownPayload>();

  const [countdown, setCountdown] = useState<CountdownConfig | null>(null);

  // get active countdown config from enhancemnet payload
  const setActiveCountdownConfig = () => {
    if (!enhancement) return;

    const configs = enhancement.payload.configs || enhancement.payload;
    const activeCountdown = getActiveCountdown(configs as any);

    if (!activeCountdown) return;

    setCountdown(activeCountdown);
  };
  useEffect(setActiveCountdownConfig, [enhancement]);

  // set ad unit background color
  const setBackgroundColor = () => {
    const countdownContainerEl = countdownContainerRef.current;

    if (!countdown || !countdownContainerEl) return;

    const adUnitElement = countdownContainerEl.closest('[data-str-arid]') as HTMLElement;
    if (adUnitElement) adUnitElement.style.backgroundColor = countdown.backgroundColor || '#FFFFFF';
  };
  useEffect(setBackgroundColor, [countdown]);

  if (!enhancement || !butlerResponse) {
    return null;
  }

  // fallback to default enhancement if no countdown is active
  if (!countdown) {
    return <HeadlineEnhancement />;
  }

  const cta = countdown.cta;
  const textColor = countdown.textColor || '#000000';
  const timestampColor = countdown.timestampColor || '#000000';
  const backgroundColor = countdown.backgroundColor || '#FFFFFF';
  const language = enhancement.payload.language || butlerResponse.creative.language || 'en';

  const layout = getLayoutType(butlerResponse.creative, butlerResponse.placement);

  return (
    <div className="str-enhanced-info">
      <div
        className="str-countdown-container"
        data-testid="countdown-container"
        style={{ backgroundColor }}
        ref={countdownContainerRef}
      >
        <div
          className={`str-countdown-content ${layout === 'horizontal' ? 'nowrap' : ''}`}
          data-testid="countdown-content"
          style={{ color: textColor }}
        >
          <div className="str-countdown-cta" data-testid="countdown-cta">
            <span>{cta}</span>
          </div>
          <CountdownClock language={language} countdown={countdown} timestampColor={timestampColor} />
        </div>
      </div>
      <div className="str-bottom">
        <Advertiser />
      </div>
    </div>
  );
};

export default Countdown;
