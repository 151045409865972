import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import './headline.scss';

export const AmazonHeadline: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { title, description } = assets;

  // Should only render if both title and description are present
  if (!title || !description) return null;

  return (
    <div className="str-amazon-headline">
      <span>{title.text}</span>
    </div>
  );
};
