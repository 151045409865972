import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchClickthroughFound = (arid: AdserverRequestId, found: boolean) => {
  return new Beacon({
    fireOnce: true,
    cacheKey: `clickThroughFound-${arid}`,
    data: {
      type: found ? BeaconType.vastClickthroughFound : BeaconType.vastClickthroughNotFound,
      arid,
      ...getBaseParams(arid)
    }
  });
};
