import { ButlerCreative } from 'modules/butler/creative';

export const getEnhancedClasses = (
  creative: ButlerCreative,
  ...classNames: Array<string | null | undefined>
): string => {
  const creativeActionClass = creative.action ? `str-${creative.action}` : null;

  return ['str-adunit', 'str-template', 'str-compiled-template-enhanced', creativeActionClass, ...classNames]
    .filter(Boolean)
    .join(' ');
};
