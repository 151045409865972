import Beacons from 'modules/beacons/utils';
import { ButlerResponse } from 'modules/butler/response';
import { createScripts } from 'modules/utils';
import XandrTag from 'tag/helpers/visibility/xandr_tag';
import 'tag/polyfill/object_assign';
import { unescapeString } from './unescape-string.helper';

type TrackerMacroMapping = {
  '${CREATIVE_ID}': string;
  '${REFERER_URL_ENC}': string;
  '{{Publisher}}': string;
  '{{Site}}': string;
  '{{Placement}}': string;
  '{{Campaign}}': string;
  '{{Creative}}': string;
  '{{Advertiser}}': string;
  '{{adTag}}': string;
  '{{RenderTime}}': string;
  '{{AdSelector}}': string;

  // TODO: it'd be nice to statically define the list of macros
  // instead of allowing any string access here
  [key: string]: string;
};

const insertJsTracker = (trackers: string[], element: HTMLElement, butlerResponse: ButlerResponse) => {
  const trackersLoaded: { [key: string]: boolean } = {};
  const trackerSources: string[] = [];
  for (let jsTracker of trackers) {
    jsTracker = unescapeString(jsTracker);
    for (let script of createScripts(jsTracker)) {
      if (script.src !== undefined && script.src !== '') {
        trackerSources.push(script.src);
        script.addEventListener('load', () => {
          trackersLoaded[script.src] = true;
        });
      }

      element.appendChild(script);
    }
  }

  return setTimeout(() => {
    if (Object.keys(trackersLoaded).length !== trackerSources.length) {
      const sourcesFailedToLoad = trackerSources.filter((source) => !trackersLoaded[source]);
      Beacons.fire.jsTrackerFailedToLoad(butlerResponse.adserverRequestId, sourcesFailedToLoad.join(','));
    }
  }, 4000);
};

const replaceJsTrackerMacros = (tracker: string, trackerMacroMapping: TrackerMacroMapping) => {
  let replacedTracker = tracker;
  for (const macro of Object.keys(trackerMacroMapping)) {
    let value = encodeURIComponent(trackerMacroMapping[macro]);

    if (macro === '{{AdSelector}}') {
      value = value.replace(/'/g, '%27');
    }

    if (macro === '[DIV-ID]' && tracker.indexOf('q.adrta.com') < 0) {
      continue;
    }

    replacedTracker = replacedTracker.replace(macro, value);
  }
  return replacedTracker;
};

export function insertVisibilityTags(butlerResponse: ButlerResponse, element: HTMLElement) {
  const trackerMacroMapping = {
    '${CREATIVE_ID}': butlerResponse.creative.creativeKey,
    '${REFERER_URL_ENC}': window.location.href,
    '{{Publisher}}': butlerResponse.placement.publisherKey,
    '{{Site}}': butlerResponse.placement.siteKey,
    '{{Placement}}': butlerResponse.placement.key,
    '{{Campaign}}': butlerResponse.creative.campaignKey,
    '{{Creative}}': butlerResponse.creative.creativeKey,
    '{{Advertiser}}': butlerResponse.creative.advertiserKey || '',
    '{{adTag}}': element.tagName,
    '{{RenderTime}}': element.getAttribute('data-str-rendered') || '',
    '[DIV-ID]': element.getAttribute('data-str-rendered') || Date.now().toString(),
    '{{AdSelector}}': `[data-str-rendered=${element.getAttribute('data-str-rendered')}]`
  };

  let trackers = butlerResponse.creative.jsTracker;
  // if double verify is enabled, the tracker will be fired as part of the blocking logic
  if (butlerResponse.creative.behaviors.enableDoubleVerify) {
    trackers = trackers.filter((url) => url.indexOf('cdn.doubleverify.com') < 0);
  }

  trackers = trackers.map((jsTracker) => {
    const tempDoc = document.implementation.createHTMLDocument('');
    tempDoc.body.innerHTML = jsTracker;

    const trackerWithReplacements = replaceJsTrackerMacros(jsTracker, trackerMacroMapping);
    // if no children, then it is a plain url
    if (tempDoc.body.children.length === 0) {
      return `<script src='${trackerWithReplacements}' type='text/javascript'></script>`;
    } else {
      return trackerWithReplacements;
    }
  });

  const replacedTrackers = XandrTag.setup({
    element,
    jsTrackers: trackers
  });

  insertJsTracker(replacedTrackers, element, butlerResponse);
}
