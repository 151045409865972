import { Beacon } from '../Beacon';
import { dispatchVideoImpressionNotifications } from '../third-party/videoImpressionNotifications';
import { dispatchVideoWinNotifications } from '../third-party/videoWinNotifications';

import { AdserverRequestId, BeaconType, VideoTypes } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchVideoStart = (arid: AdserverRequestId, autoPlay: boolean) => {
  dispatchVideoWinNotifications(arid);
  dispatchVideoImpressionNotifications(arid);

  return new Beacon({
    fireOnce: true,
    cacheKey: `videoStart-${arid}`,
    data: {
      type: BeaconType.videoStart,
      arid,
      vidType: autoPlay ? VideoTypes.instantPlay : VideoTypes.clickToPlay,

      ...getBaseParams(arid)
    }
  });
};
