export default [
  {
    description: 'Zeta BidSwitch Display',
    seatId: '119',
    sourceId: '3cY4cGN788xJEhffKkqhgNhV'
  },
  {
    description: 'TTD Pfizer',
    seatId: '6056',
    sourceId: '5b286190338513af73f09c28'
  },
  {
    description: 'Yahoo Display Pfizer',
    seatId: '6071',
    sourceId: 'CoMLTJeWvGbEAymUxBat9rRZ'
  },
  {
    description: 'Yahoo Video Pfizer',
    seatId: '6071',
    sourceId: 'n96oYXGCuPykdLqwSM5NUEVp'
  },
  {
    description: 'Yahoo Native Pfizer',
    seatId: '6071',
    sourceId: 'BVbSRuzbUWjBEF6bQrmLHKkX'
  },
  {
    description: 'Yahoo CTV Pfizer',
    seatId: '6071',
    sourceId: 'gcTfVrFXSws6HptieUaGhjCx'
  },
  {
    description: 'DeepIntent Org',
    seatId: '422',
    sourceId: 'bf2b131f1f7eff9d8892972c'
  },
  {
    description: 'DeepIntent Org',
    seatId: '422',
    sourceId: '3cY4cGN788xJEhffKkqhgNhV'
  },
  {
    description: 'Xandr',
    seatId: '11550',
    sourceId: '0e8893f90b606c9c5d33f1be'
  },
  {
    description: 'TTD Lilly',
    seatId: '4948',
    sourceId: '5b286190338513af73f09c28'
  }
];
