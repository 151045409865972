import { Signal, signal } from '@preact/signals';
import { OUT_OF_VIEW_PAUSE_THRESHOLD } from 'modules/asset_swap/asset_swap';
import Cannon from 'modules/beacons/cannon';
import Launcher from 'modules/beacons/launcher';
import Beacons from 'modules/beacons/utils';
import Butler from 'modules/butler/api';
import { HostedVideoCreative } from 'modules/butler/creative';
import { registerObserver } from 'modules/observer';
import { createRef, h } from 'preact';
import { DurationKey, QuartileKey, getAllEnumValues } from 'tag/models/common/common';
import { Template } from 'tag/models/common/template';
import { AdExperienceBase, AdExperienceBaseProps } from '../ad-experience';
import { MuteButton } from '../vast-video2/mute-button';
import './hosted.video.scss';
import { HostedVideoState } from './types';

export class HostedVideo2 extends AdExperienceBase<HostedVideoCreative, AdExperienceBaseProps, HostedVideoState> {
  //TODO Rename this to HostedVideo
  allDurationBeacons = getAllEnumValues<number>(DurationKey);
  allQuartileBeacons = getAllEnumValues<number>(QuartileKey);
  autoPlay: boolean = false;
  elementRef = createRef<HTMLDivElement>();
  refThumbnailContainer = createRef<HTMLDivElement>();
  thumbnailVideoRef = createRef<HTMLVideoElement>();
  muted: Signal<boolean> = signal(true);

  constructor(props: AdExperienceBaseProps) {
    super(props);
    this.autoPlay = Butler.shouldCreativeInstantPlay(this.butlerResponse);
    this.state = {
      playing: false,
      videoCompleted: false,
      videoStarted: false
    };
  }

  componentDidMount(): void {
    Launcher.trackRenderedImpression(
      this.props.adserverRequestId,
      this.elementRef.current!.parentElement as HTMLElement
    );

    if (this.thumbnailVideoRef?.current) {
      // Note: this doesn't affect MUTE state, just the volume level when the
      // video is un-muted
      this.thumbnailVideoRef.current.volume = 1;
    }
  }

  handleClick = (event: MouseEvent) => {
    Cannon.fireCustomEngagement(this.butlerResponse);
    if (this.creative.customEngagementUrl) {
      this.props.onClick(event, {
        url: this.creative.customEngagementUrl,
        token: this.creative.customEngagementUrlToken,
        nonce: this.creative.customEngagementUrlNonce
      });
    } else {
      // it's possible that the user gets here, but hopefully they dont.
      // they could click the video after it's done playing, but we don't have
      // a clickout URL, so nothing would happen.
      return;
    }
  };

  pauseVideo = () => {
    const { current } = this.thumbnailVideoRef;
    if (current) current.pause();
  };

  playVideo = () => {
    const { current } = this.thumbnailVideoRef;
    if (!current) {
      return;
    } else {
      const playResult = current.play();
      // In older browsers, play() may return undefined instead of the promise
      // that we expect
      if (playResult && !!playResult.then) {
        return playResult.catch((err) => console.log('Error playing video:', err.message));
      } else {
        return Promise.resolve();
      }
    }
  };

  registerInView = () => {
    const { behaviors } = this.creative;
    const outOfViewPercentage = behaviors.shouldPauseAtFiftyPercentOutOfView ? OUT_OF_VIEW_PAUSE_THRESHOLD : 0.01;
    const inViewPercentage = 0.5;

    registerObserver({
      element: this.thumbnailVideoRef.current!,
      inViewPercentage,
      outOfViewPercentage,
      inViewCallback: () => {
        if ((this.state.videoStarted || this.autoPlay) && !this.state.videoCompleted) {
          this.playVideo();
        }
      },
      outOfViewCallback: () => {
        if (!this.creative.behaviors.shouldNotPauseOutOfView) {
          this.pauseVideo();
        }
      }
    });
  };

  numberToQuartile = (e: number): QuartileKey => {
    return e < 50 ? QuartileKey.Q25 : e < 75 ? QuartileKey.Q50 : e < 95 ? QuartileKey.Q75 : QuartileKey.Q95;
  };

  videoEvents = {
    loaded: () => {
      this.registerInView();
    },
    ended: () => {
      this.setState({ videoCompleted: true });
    },
    timeUpdate: () => {
      const { currentTime, duration } = this.thumbnailVideoRef.current!;
      const { adserverRequestId } = this.props;
      if (this.thumbnailVideoRef.current && !this.state.playing && this.state.videoStarted) {
        Beacons.fire.videoViewDuration(
          this.props.adserverRequestId,
          this.thumbnailVideoRef.current.currentTime,
          this.thumbnailVideoRef.current.muted
        );
      }
      const roundedCurrentTime = Math.round(currentTime);
      const exist = this.allDurationBeacons.indexOf(roundedCurrentTime);
      if (exist >= 0) {
        Beacons.fire.silentAutoPlayDuration(this.allDurationBeacons[exist], adserverRequestId);
        this.allDurationBeacons.splice(exist, 1);
      }

      const roundedDuration: number = Math.floor(duration);
      const quartile = this.allQuartileBeacons[0];
      const quartileExist = quartile <= (currentTime / roundedDuration) * 100;
      if (quartileExist) {
        Beacons.fire.videoQuartile(this.numberToQuartile(quartile), adserverRequestId, true);
        this.allQuartileBeacons.splice(0, 1);
      }
    },
    play: () => {
      this.setState({ playing: true, videoStarted: true });
      Beacons.fire.videoStart(this.props.adserverRequestId, this.autoPlay, true);
      Beacons.fire.videoPlay(this.props.adserverRequestId);
    },
    pause: () => {
      this.setState({ playing: false });
    }
  };

  handleMute = (event: MouseEvent) => {
    event.stopPropagation();
    const { current: videoPlayer } = this.thumbnailVideoRef;
    videoPlayer!.muted = !videoPlayer!.muted;
    this.muted.value = !this.muted.value;
    if (this.props.publishClick) this.props.publishClick(event);
  };

  render() {
    const { videoCompleted, videoStarted } = this.state;
    return (
      <div
        ref={this.elementRef}
        data-testid="hosted-video-thumbnail-container"
        className="str-react-template"
        onClick={this.handleClick}
      >
        <Template
          creative={this.creative}
          placement={this.placement}
          country={this.butlerResponse.country}
          adserverRequestId={this.props.adserverRequestId}
        >
          <div className="str-thumbnail-wrapper">
            <div className="str-thumbnail" ref={this.refThumbnailContainer}>
              <MuteButton
                muted={this.muted.value} //TODO VALIDATE THIS
                videoCompleted={videoCompleted}
                videoStarted={videoStarted}
                onClick={this.handleMute}
              />
              <video
                autoPlay
                className="thumbnail-video"
                onEnded={this.videoEvents.ended}
                data-testid="thumbnail-video"
                ref={this.thumbnailVideoRef}
                src={this.creative.mediaUrl}
                playsInline={true}
                poster={this.creative.thumbnailUrl}
                muted={true}
                onLoadedData={this.videoEvents.loaded}
                onPause={this.videoEvents.pause}
                onPlay={this.videoEvents.play}
                onTimeUpdate={this.videoEvents.timeUpdate}
              />
            </div>
          </div>
        </Template>
      </div>
    );
  }
}
