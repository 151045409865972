import Beacons from 'modules/beacons/utils';
import { ButlerResponse } from 'modules/butler/response';
import postscribe from 'postscribe';
import { h } from 'preact';
import { Ref, useEffect, useRef } from 'preact/hooks';
import DoubleVerify from 'tag/models/double_verify';

type Props = {
  tracker: string;
  setDoubleVerifyStatus: (arg: DoubleVerifyStatus) => void;
  butlerResponse: ButlerResponse;
};

export type DoubleVerifyStatus = 'init' | 'success' | 'fallback';

export const DoubleVerifyTag = ({ tracker, setDoubleVerifyStatus, butlerResponse }: Props) => {
  const element: Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(
    function insertDvTags() {
      const handleDoubleVerifySuccess = () => {
        setDoubleVerifyStatus('success');
      };

      const handleDoubleVerifyFallback = () => {
        setDoubleVerifyStatus('fallback');
      };

      if (!element.current) {
        return;
      }
      try {
        const dvScript = DoubleVerify.generateDoubleVerifyScript(tracker, butlerResponse.adserverRequestId);

        DoubleVerify.handleCallbacks(handleDoubleVerifySuccess, handleDoubleVerifyFallback, butlerResponse);

        postscribe(element.current, dvScript, {
          done: () => {
            // Maybe we want to track rendered impression here at some point?
          },
          error: () => {
            Beacons.fire.doubleVerifyError(butlerResponse.adserverRequestId);
            handleDoubleVerifyFallback();
          }
        });
        // signal we put DV on the page
        Beacons.fire.doubleVerifyRender(butlerResponse.adserverRequestId);
      } catch {
        Beacons.fire.doubleVerifyError(butlerResponse.adserverRequestId);
        handleDoubleVerifyFallback();
      }
    },
    [setDoubleVerifyStatus, butlerResponse, tracker]
  );

  return <div ref={element}></div>;
};
