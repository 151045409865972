import { ButlerResponse } from 'modules/butler/response';

abstract class CookieSyncer {
  private static _cookieSources: {
    urls: { [key: string]: boolean };
    htmlBeacons: { [key: string]: boolean };
  } = { urls: {}, htmlBeacons: {} };
  static async sync(butlerResponse: ButlerResponse) {
    CookieSyncer.syncUrls(butlerResponse.cookieSyncUrls);
    CookieSyncer.syncHtmlBeacons(butlerResponse.htmlBeacons);
  }

  private static syncUrls(urls: string[]) {
    if (!urls.length) return;

    const frame = document.createElement('iframe');

    frame.className = 'str-cookie-sync';
    frame.style.cssText = 'display:none;';
    document.body.appendChild(frame);

    const doc = frame.contentDocument;

    if (doc) {
      const div = document.createElement('div');
      let imgTags = '';

      urls.forEach((url) => {
        // Skip adding the cookie sync pixel if it has previously been added
        if (CookieSyncer._cookieSources.urls[url]) return;

        imgTags += `<img src='${url}'>\n`;
        CookieSyncer._cookieSources.urls[url] = true;
      });
      div.innerHTML = imgTags;

      // IE10 workaround, yuck
      // https://stackoverflow.com/questions/14967316/cant-get-same-origin-iframe-body-on-ie10
      if (doc.body === null) {
        doc.write('<body></body>');
      }

      doc.body.appendChild(div);

      setTimeout(() => {
        frame.parentElement && frame.parentElement.removeChild(frame);
      }, 2000);
    }
  }

  private static syncHtmlBeacons(beacons: string[]) {
    if (!beacons.length) return;

    beacons.forEach((beacon) => {
      // Skip adding the html beacon element if it has previously been added
      if (CookieSyncer._cookieSources.htmlBeacons[beacon]) return;

      // Wrapping html beacons in a div so as not to assume htmlBeacons array will
      // only contain iframes
      const div = document.createElement('div');

      div.className = 'str-html-beacon';
      div.innerHTML = beacon;
      document.body.appendChild(div);
      CookieSyncer._cookieSources.htmlBeacons[beacon] = true;
    });
  }
}

export default CookieSyncer;
