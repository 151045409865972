// this function is to allow mocking of window.location
// needed for the spec of getPlacementLocation
import WindowHelper from '../tag/helpers/window.helper';
import SafeFrameHandler from './safe_frame_handler';

// Possible values: safeframe, amp-safeframe, iframe, amp-iframe, top, amp-top
function renderEnv(): string {
  return LocationUtils.isAMP() ? `amp-${LocationUtils.renderFrame()}` : LocationUtils.renderFrame();
}

function renderFrame(): string {
  // Though not likely, it _is_ possible that the safeframe API object is located on the top window, in which case we should still return safeframe since we use the API in our rendering code.
  // Because of this, we need to check if we're in a safeframe first.
  if (SafeFrameHandler.isSafeFrame()) {
    return 'safeframe';
  } else if (WindowHelper.isIframe()) {
    return 'iframe';
  } else if (WindowHelper.isTopWindow()) {
    return 'top';
  } else {
    return 'unknown';
  }
}

// Is this an AMP page?
// After sampling high traffic AMP page URLs using Snowflake data,
// window["AMP_CONTEXT_DATA"] appears to be a reliable signal that the page is AMP
function isAMP(): boolean {
  return window.hasOwnProperty('AMP_CONTEXT_DATA');
}

const LocationUtils = {
  renderEnv,
  renderFrame,
  isAMP
};

export default LocationUtils;
