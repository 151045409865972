import { experimentManager } from './experiment-manager';
import { ENHANCED, GENERIC, UNENHANCED } from './types';

export const WEATHER_ENHANCEMENT = experimentManager.create({
  id: 'ef22dd50-339d-4945-8cdb-fa620b347d51',
  variants: [UNENHANCED, ENHANCED, GENERIC],
  volume: 0,
  criteria: ({ butlerResponse }) => {
    // action=banner
    if (butlerResponse.creative.action !== 'banner') return false;

    // creative_size=300x250
    if (butlerResponse.creative.size.width !== 300 || butlerResponse.creative.size.height !== 250) return false;

    // placement_size=300x250,300x600
    if (butlerResponse.placement.size.width !== 300) return false;
    if (butlerResponse.placement.size.height !== 250 && butlerResponse.placement.size.height !== 600) return false;

    // creative ids / source id:
    const creativeKeys = [
      'V5J3YnPygd61DpGaQKLXUr8H-567784875',
      'V5J3YnPygd61DpGaQKLXUr8H-571994035',
      'V5J3YnPygd61DpGaQKLXUr8H-571994034',
      'V5J3YnPygd61DpGaQKLXUr8H-551986227',
      'V5J3YnPygd61DpGaQKLXUr8H-564520191',
      'V5J3YnPygd61DpGaQKLXUr8H-505554311',
      'V5J3YnPygd61DpGaQKLXUr8H-540611871',
      'V5J3YnPygd61DpGaQKLXUr8H-534023890',
      'V5J3YnPygd61DpGaQKLXUr8H-569852478',
      'V5J3YnPygd61DpGaQKLXUr8H-559068950',
      'V5J3YnPygd61DpGaQKLXUr8H-534813718'
    ];

    return creativeKeys.includes(butlerResponse.creative.creativeKey);
  }
});
