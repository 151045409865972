import { VastVideoCreative } from 'modules/butler/creative';
import { ButlerResponse } from 'modules/butler/response';
import { shouldDisableEnhancement } from 'modules/policies';
import ResponseStore from 'modules/response/response_store';
import { CreativeType, EnhanceableCreativeTypes } from 'tag/models/common/common.model';
import { AdserverRequestId, BeaconOptions } from '../types';

export function getBaseParams(butlerResponse: AdserverRequestId | ButlerResponse): BeaconOptions {
  if (typeof butlerResponse === 'string') {
    butlerResponse = ResponseStore.getResponse(butlerResponse);
  }

  const params: BeaconOptions = {
    awid: butlerResponse.auctionWinId,
    ckey: butlerResponse.creative.creativeKey,
    pkey: butlerResponse.placement.key,
    tkey: butlerResponse.placement.templateKey,
    action: butlerResponse.creative.action,
    sourceId: butlerResponse.creative.sourceId
  };

  if (butlerResponse.creative.dealId) {
    params.deal_id = butlerResponse.creative.dealId;
  }

  if (butlerResponse.placement.bundleIdentifier) {
    params.bundleId = butlerResponse.placement.bundleIdentifier;
  }

  if (butlerResponse.supplyId) {
    params.supplyId = butlerResponse.supplyId;
  }

  if (EnhanceableCreativeTypes.includes(butlerResponse.creative.action)) {
    const shouldEnhance = !shouldDisableEnhancement(
      butlerResponse.adserverRequestId,
      butlerResponse.creative,
      butlerResponse.placement
    );
    params.shouldEnhance = shouldEnhance ? 1 : 0;
  }

  if (
    [CreativeType.NativeOutstream, CreativeType.Outstream, CreativeType.HostedVideo].includes(
      butlerResponse.creative.action
    )
  ) {
    params.isVast = (!!(butlerResponse.creative as VastVideoCreative).vastUrl).toString();
  }

  return params;
}
