import { ButlerResponse } from 'modules/butler/response';
import { getButlerResponseSizes } from './getButlerResponseSizes';
import { ResponsiveAds, SizeType } from './types';

export const responsiveTemplate = (butlerResponse: ButlerResponse): string | null => {
  const { action } = butlerResponse.creative;

  if (!ResponsiveAds.includes(action)) return null;
  const { placementSize, creativeSize } = getButlerResponseSizes(butlerResponse);

  if (placementSize.width && placementSize.height) {
    // TODO: Add some logic to handle sizeless placements
    const placementRatio = placementSize.width / placementSize.height;
    // TODO: Refactor to set creativeSizeType to Square for native types (when creative dimensions not provided)
    const creativeRatio = (creativeSize.width || 16) / (creativeSize.height || 9);
    const placementSizeType = getSizeType(placementRatio);
    const creativeSizeType = getSizeType(creativeRatio);

    return `str-responsive str-responsive-placement-${placementSizeType} str-responsive-creative-${creativeSizeType}`;
  }

  return null;
};

export const getSizeType = (ratio: number): SizeType => {
  return ratio <= 2 / 7
    ? SizeType.Tallest
    : ratio <= 4 / 5
      ? SizeType.Tall
      : ratio <= 6 / 4
        ? SizeType.Square
        : ratio <= 7 / 2
          ? SizeType.Wide
          : SizeType.Widest;
};
