import Beacons from 'modules/beacons/utils';
import { Signal } from '@preact/signals';
import { AdCompleteEvent, AdsManager } from 'modules/imaSDK';

export const completeListener = (
  adEvent: AdCompleteEvent,
  adsManager: AdsManager,
  adserverRequestId: string,
  muted: Signal<boolean>,
  videoCompleted: Signal<boolean>,
  videoPlaying: Signal<boolean>
) => {
  const remainingTime = adsManager.getRemainingTime();
  const currentTime = remainingTime >= 0 ? adEvent.h.h.duration - remainingTime : adEvent.h.h.duration;
  Beacons.fire.videoViewDuration(adserverRequestId, currentTime, muted.value, true);
  videoCompleted.value = true;
  videoPlaying.value = false;
};
