import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchVideoPlay = (arid: AdserverRequestId) => {
  // action, isvast and sourceid are not used in the beacon
  return new Beacon({
    fireOnce: true,
    cacheKey: `videoPlay-${arid}`,
    data: {
      type: BeaconType.videoPlay,
      arid,
      engagement: true,
      duration: 0,
      ...getBaseParams(arid)
    }
  });
};
