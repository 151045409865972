import { ButlerResponse } from 'modules/butler/response';
import Beacons from './beacons/utils';

export const CustomTracker = {
  js: (element: HTMLElement, butlerResponse: ButlerResponse): void => {
    const div = document.createElement('div');

    for (let rawUrl of extractUrls(element, 'data-str-js-tracker')) {
      const url = applyMacros(rawUrl, element, butlerResponse);
      div.appendChild(generateScript(url, butlerResponse));
      document.body.appendChild(div);
    }

    document.body.appendChild(div);
  },

  click: (element: HTMLElement, butlerResponse: ButlerResponse): void => {
    for (let url of extractUrls(element, 'data-str-click-tracker')) {
      butlerResponse.creative.beacons.click.push(url);
    }
  }
};

const extractUrls = (element: HTMLElement, dataAttr: string): string[] => {
  let rawTrackers: string[] = [];
  let data = element.getAttribute(dataAttr);

  if (!data) return rawTrackers;

  try {
    // check if data is a stringified array
    rawTrackers = JSON.parse(data);
  } catch (error) {
    // otherwise it is a plain string
    rawTrackers = [data];
  }
  return rawTrackers;
};

const applyMacros = (url: string, element: Element, butlerResponse: ButlerResponse): string => {
  const renderTimestamp = element.getAttribute('data-str-rendered') || '';

  return applyXandrMacros(
    url
      .replace(/\${CREATIVE_ID}/g, encodeURIComponent(butlerResponse.creative.creativeKey))
      .replace(/\${REFERER_URL_ENC}/g, encodeURIComponent(window.location.href))
      .replace(/{{Publisher}}/g, encodeURIComponent(butlerResponse.placement.publisherKey))
      .replace(/{{Site}}/g, encodeURIComponent(butlerResponse.placement.siteKey))
      .replace(/{{Placement}}/g, encodeURIComponent(butlerResponse.placement.key))
      .replace(/{{Campaign}}/g, encodeURIComponent(butlerResponse.creative.campaignKey))
      .replace(/{{Creative}}/g, encodeURIComponent(butlerResponse.creative.creativeKey))
      .replace(/{{Advertiser}}/g, encodeURIComponent(butlerResponse.creative.advertiserKey || ''))
      .replace(/{{adTag}}/g, encodeURIComponent(element.tagName))
      .replace(/{{RenderTime}}/g, encodeURIComponent(renderTimestamp))
      .replace(/{{AdSelector}}/g, encodeURIComponent(`[data-str-rendered="${renderTimestamp}"]`)),
    element
  );
};

const applyXandrMacros = (url: string, element: Element): string => {
  if (url.match(/\/\/cdn\.adnxs\.com.*\/trk\.js/) !== null) {
    url = url.replace('%native_dom_id%', '');
    element.classList.add('anx_cr_loc');
  }
  return url;
};

const generateScript = (tracker: string, butlerResponse: ButlerResponse): HTMLScriptElement => {
  let script = document.createElement('script');

  const div = document.createElement('div');
  div.innerHTML = tracker;
  const input = div.firstChild as HTMLScriptElement;

  if (!script) {
    throw 'Unable to parse js tracker';
  }

  if (input.tagName === 'SCRIPT') {
    if (input.src) script.src = input.src;
    if (input.innerHTML) script.innerHTML = input.innerHTML;
  } else {
    script.src = tracker;
  }

  script.onerror = () => {
    console.log('error', tracker);
    Beacons.fire.jsTrackerFailedToLoad(butlerResponse.adserverRequestId, tracker);
  };

  return script;
};

export default CustomTracker;
