import { DurationKey } from 'tag/models/common/common.model';
import { AdserverRequestId, CreativeBeaconType } from '../types';
import { dispatchCreativeNotifications } from './creativeNotifications';

export const dispatchSilentPlayNotifications = (arid: AdserverRequestId, durationKey?: DurationKey) => {
  return dispatchCreativeNotifications({
    arid,
    beaconType: getCreativeBeaconType(durationKey)
  });
};

const getCreativeBeaconType = (durationKey?: DurationKey): CreativeBeaconType => {
  switch (durationKey) {
    case DurationKey.D3:
      return CreativeBeaconType.SilentPlay;
    case DurationKey.D10:
      return CreativeBeaconType.TenSecondSilentPlay;
    case DurationKey.D15:
      return CreativeBeaconType.FifteenSecondSilentPlay;
    case DurationKey.D30:
      return CreativeBeaconType.ThirtySecondSilentPlay;
    default:
      return CreativeBeaconType.SilentPlay;
  }
};
