import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import { AmazonBrandLogo } from '../brand-logo/brand-logo.component';
import { AmazonHeadline } from '../headline/headline.component';
import { AmazonProductImage } from '../product-image/product-image.component';
import './thumbnail.scss';

export const AmazonThumbnail: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const showLogoAndHeadline = assets.description || assets.icon;

  return (
    <div className="str-amazon-thumbnail">
      {showLogoAndHeadline && (
        <div className="str-amazon-thumbnail-brand">
          <div className="str-amazon-thumbnail-logo">
            <AmazonBrandLogo />
          </div>
          <AmazonHeadline />
        </div>
      )}
      <AmazonProductImage />
    </div>
  );
};
