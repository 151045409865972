import { EnhancedDisplayCreative } from 'modules/butler/creative';

// DCM has two rendering modes that it supports: `iframe` and `script`
// Rendering in script allows us to get a clickout url, and doesn't
// seem to have an effect on the ad's ability to render.
function setDcmRenderingModeToScript(adm: string) {
  return adm.replace(/data-dcm-rendering-mode\s*=\s*('|")iframe('|")/, 'data-dcm-rendering-mode="script"');
}

export const getAdm = (creative: EnhancedDisplayCreative) => {
  let adm = creative.adm || '';
  adm = adm.replace(/<\\\/script>/gi, '</script>');
  adm = setDcmRenderingModeToScript(adm);

  return adm;
};
