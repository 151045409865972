import { IBackgroundStyleResponse } from '.';
import { CSSProperties } from 'preact/compat';

export class BackgroundStyle implements IBackgroundStyleResponse {
  color: string | undefined;
  hoverColor: string | undefined;

  constructor(response?: IBackgroundStyleResponse) {
    this.color = response?.color;
    this.hoverColor = response?.hoverColor;
  }

  toStyle(): CSSProperties {
    return { backgroundColor: this.color };
  }
}
