export default [
  '10fastfingers.com',
  'allacronyms.com',
  'boxrec.com',
  'heyquiz.com',
  'brutal.io',
  'chosic.com',
  'techlicious.com',
  'songfacts.com',
  'watchtv24.com',
  'razzball.com',
  'nitrotype.com',
  'typing.com',
  'abduzeedo.com',
  'countdowntopregnancy.com',
  'cssauthor.com',
  'diyphotography.net',
  'eventhubs.com',
  'filmschoolrejects.com',
  'firstshowing.net',
  'groundedreason.com',
  'headphonereview.com',
  'itsfoss.com',
  'neatorama.com',
  'photographylife.com',
  'songfacts.com',
  'speckyboy.com',
  'themerkle.com',
  'tvseriesfinale.com',
  'webdesignledger.com',
  'thetechgame.com',
  'healthyeater.com',
  'slantmagazine.com',
  'slowrobot.com',
  'Insidercarnews.com',
  'bikez.com',
  'starwarsnewsnet.com',
  'draftsite.com',
  'pajiba.com',
  'uscreditcardguide.com',
  'start.me',
  'quotefancy.com',
  'wordgamehelper.com'
];
