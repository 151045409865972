import { ButlerCreative, VastVideoCreative } from 'modules/butler/creative';

export class DcoError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DcoError';
  }
}

export const DCO_REQUEST_TIMEOUT = 1200;
export const DEFAULT_MAX_HEADLINE_LENGTH = 140;

export type DcoAsset = {
  title?: DcoTitleAsset;
  img?: DcoImageAsset;
  data?: DcoDataAsset;
  video?: DcoVideoAsset;
  link?: DcoLinkAsset;
};

export type DcoDataAsset = {
  type: number;
  value: string;
};

export type DcoVideoAsset = {
  vasttag: string;
};

export type DcoLinkAsset = {
  url: string;
  clicktrackers: string[];
};

export type DcoImageAsset = {
  type: number;
  url: string;
  h: number;
  w: number;
};

export type DcoTitleAsset = {
  text: string;
};

export type DcoEventTracker = {
  url: string;
  event: number;
  method: number;
};

export type DcoAssetResponse = {
  assets: DcoAsset[];
  eventtrackers: DcoEventTracker[];
};

export type DcoResponse = {
  advertiser: string | null;
  alternateThumbnailAssets: DcoImageAsset[];
  brandLogoUrl: string | null;
  creativeType: ButlerCreative['action'];
  description: string | null;
  title: string | null;
  mediaUrl: string | null;
  thumbnailUrl: string | null;
  vastUrl: VastVideoCreative['vastUrl'] | null;
  clickTrackers: string[];
  impressionTrackers: string[];
  visibleTrackers: string[];
};

export enum DcoStatus {
  Success = 'success',
  RenderFail = 'renderFail',
  Failure = 'failure',
  RequestTimeout = 'requestTimeout'
}
