import Beacons from 'modules/beacons/utils';
import { AdProgressEvent } from 'modules/ima';
import { QuartileKey } from 'tag/models/common/common';
import { Signal } from '@preact/signals';

export const adProgressListener = (
  adserverRequestId: string,
  event: AdProgressEvent,
  currentTime: Signal<number>,
  videoSrc: Signal<string | null | undefined>
) => {
  const { currentTime: adCurrentTime, duration } = event.o;

  if (videoSrc.value === undefined) {
    const video: HTMLVideoElement | null = document.querySelector('video:not(.blank-video)[src]');
    videoSrc.value = video ? video.src : null;
  }

  currentTime.value = adCurrentTime;

  if (adCurrentTime && duration && adCurrentTime / duration >= 0.95) {
    // Sometimes the first value of currentTime is higher than duration and would cause the 95% to fire too early.
    if (adCurrentTime > duration) {
      return;
    }

    Beacons.fire.videoQuartile(QuartileKey.Q95, adserverRequestId, true);
  }
};
