import ResponseStore from 'modules/response/response_store';
import { URLBeacon } from '../URLBeacon';
import { CreativeBeaconType } from '../types';

interface CreativeBeaconProps {
  arid: string;
  beaconType: CreativeBeaconType;
  onFailure?: (url: string) => void;
}

export const dispatchCreativeNotifications = ({ arid, beaconType, onFailure }: CreativeBeaconProps) => {
  const butlerResponse = ResponseStore.getResponse(arid);

  const urls = butlerResponse.creative.beacons[beaconType] || [];
  return urls.map((url) => {
    new URLBeacon({
      url,
      onFailure: () => {
        onFailure && onFailure(url);
      }
    });
  });
};
