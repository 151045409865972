import Beacons from 'modules/beacons/utils';
import { ButlerResponse } from 'modules/butler/response';
import { sample } from 'modules/monitoring';
import {
  Ad,
  AdDisplayContainer2,
  AdErrorEvent,
  AdClickEvent,
  AdsLoader,
  AdsManager,
  AdsRenderingSettings,
  InitParams
} from './types';
import { VastVideoCreative } from 'modules/butler/creative';

export class ImaSDK2 {
  ad?: Ad;
  adDisplayContainer: AdDisplayContainer2;
  adsInitialized: boolean = false;
  adsLoader: AdsLoader;
  adsManager?: AdsManager;
  browserSupportsAutoPlay: boolean = false;
  butlerResponse: ButlerResponse;
  containerElement: HTMLElement;
  dimensions: { width: number; height: number };
  onAdLoaded: (ad: Ad) => void = () => {};
  onAdsManagerLoaded: (adsManager: AdsManager) => void = () => {};
  vastUrl: string;
  videoElement: HTMLVideoElement;

  constructor(initParams: InitParams) {
    const {
      butlerResponse,
      containerElement,
      onAdLoaded,
      onAdsManagerLoaded,
      videoElement
    } = initParams;

    window.google.ima.settings.setVpaidMode(window.google.ima.ImaSdkSettings.VpaidMode.INSECURE);
    window.google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);

    this.adDisplayContainer = new window.google.ima.AdDisplayContainer(containerElement, videoElement);
    this.butlerResponse = butlerResponse;
    this.containerElement = containerElement;
    this.dimensions = { width: containerElement.clientWidth, height: containerElement.clientHeight };
    this.onAdLoaded = onAdLoaded;
    this.onAdsManagerLoaded = onAdsManagerLoaded;
    this.vastUrl = (butlerResponse.creative as VastVideoCreative).vastUrl;
    this.videoElement = videoElement;
    const adsLoader: AdsLoader = new window.google.ima.AdsLoader(this.adDisplayContainer);

    // Listen and respond to ads loaded and error events.
    adsLoader.addEventListener(
      window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.sdkEvents.adsManagerLoaded,
      false
    );
    adsLoader.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, this.sdkEvents.adError);

    this.adsLoader = adsLoader;

    this.videoElement.volume = 0;
    this.videoElement.muted = true;
    const playPromise = this.videoElement.play();
    if (playPromise !== undefined) {
      playPromise.then(() => this.afterAutoPlay(true)).catch(() => this.afterAutoPlay(false));
    }
  }

  afterAutoPlay = (supportAutoPlay: boolean) => {
    this.browserSupportsAutoPlay = supportAutoPlay;
    this.sdkEvents.requestAds();
  };

  sdkEvents = {
    getAdsManager: (
      adsManagerLoadedEvent: any,
      videoElement: HTMLVideoElement,
      adsRenderingSettings: AdsRenderingSettings
    ): AdsManager => {
      const adsManager = adsManagerLoadedEvent.getAdsManager(videoElement, adsRenderingSettings);
      adsManager.setVolume(0);
      return adsManager;
    },
    adsManagerLoaded: (adsManagerLoadedEvent: any) => {
      const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
      adsRenderingSettings.uiElements = [];
      adsRenderingSettings.enablePreloading = true;
      const adsManager = this.sdkEvents.getAdsManager(adsManagerLoadedEvent, this.videoElement, adsRenderingSettings);
      this.adsManager = adsManager;
      this.onAdsManagerLoaded(adsManager);
      adsManager.addEventListener(window.google.ima.AdEvent.Type.LOADED, (adEvent: AdClickEvent) => {
        const ad = adEvent.getAd();
        this.ad = ad;
        if (ad) {
          this.onAdLoaded(this.ad);
        }
      });
      adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, (err: AdErrorEvent) => {
        const error = err.getError();
        const errorCode = error.getErrorCode();
        // see error code meanings: https://support.google.com/authorizedbuyers/answer/9032019
        const ignoreImaAirbrakeErrors = [400, 401, 402, 403, 602, 901];

        // Don't log errors caused by the creative
        if (ignoreImaAirbrakeErrors.includes(errorCode)) {
          // send beacon code after we know the error message
          Beacons.fire.vastError(this.butlerResponse.adserverRequestId, errorCode);
          return;
        }

        const innerError = error.getInnerError();
        const errMessage = innerError ? innerError.message : error.getMessage();
        let errorPayload: { [key: string]: string | number } = {
          vastErrorCode: error.getVastErrorCode(),
          errorCode: errorCode,
          errorMessage: error.getMessage(),
          innerErrorMessage: innerError ? innerError.message : 'No inner error',
          errorType: error.getType()
        };

        sample('IMA Ad Error: ' + errMessage, {
          function: 'src/modules/ima.ts:ImaSDK.onAdsManagerLoaded',
          errorPayload: errorPayload
        });
      });
      adsManager.addEventListener(window.google.ima.AdEvent.Type.STARTED, (_event) => {
        /* We're re-muting the ad here. This has been done once
         * already, earlier in the ad's lifecycle, but it's possible
         * for VPAID ads to control their own volume, and we've run
         * into some of these unmuting themselves. This re-mutes as
         * soon as the video autoplays */
        if (adsManager.getVolume() > 0) {
          adsManager.setVolume(0);
        }
      });
      this.adsManager = adsManager;
      this.sdkEvents.initializeAds();
    },
    adError: (errorEvent: AdErrorEvent) => {
      const error = errorEvent.getError();
      console.error(error);
    },
    playAds: () => {
      if (!this.adsInitialized) {
        this.sdkEvents.initializeAds();
      }
      this.adsManager!.start();
    },
    initializeAds: () => {
      if (!this.adsInitialized) {
        this.adDisplayContainer.initialize();
        this.adsInitialized = true;
        this.adsManager!.init(this.dimensions.width, this.dimensions.height, window.google.ima.ViewMode.NORMAL);
      }
    },
    requestAds: () => {
      this.videoElement.pause();
      const adsRequest = new window.google.ima.AdsRequest();

      if (this.vastUrl.match('</VAST>')) {
        adsRequest.adsResponse = this.vastUrl;
      } else {
        adsRequest.adTagUrl = this.vastUrl;
      }

      adsRequest.linearAdSlotWidth = this.dimensions.width;
      adsRequest.linearAdSlotHeight = this.dimensions.height;
      adsRequest.nonLinearAdSlotWidth = this.dimensions.width;
      adsRequest.nonLinearAdSlotHeight = this.dimensions.height;

      adsRequest.setAdWillAutoPlay(this.browserSupportsAutoPlay);
      adsRequest.setAdWillPlayMuted(true);

      this.adsLoader.requestAds(adsRequest);
    }
  };
}
