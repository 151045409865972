import {
  ASSETS_BY_LANGUAGE,
  COUNTRY_LANGUAGE_MAPPING,
  FRENCH_BUNDLES,
  FRENCH_DOMAINS,
  SupportedLanguage
} from 'modules/asset_swap/assets_by_language';
import { BehaviorMap, enableBehaviors } from 'modules/asset_swap/behaviors';
import { Behaviors, ButlerCreative } from 'modules/butler/creative';
import { ButlerResponse } from 'modules/butler/response';
import { shouldDisableEnhancement } from 'modules/policies';
import { NO_ENHANCEMENT_BY_SIZES as NO_ENHANCEMENT_BY_PLACEMENT_SIZES } from 'modules/policies/data';
import { getParameterByName } from 'modules/utils';

type PartialButlerResponse = Pick<ButlerResponse, 'creative' | 'placement' | 'adserverRequestId'>;

export const OUT_OF_VIEW_PAUSE_THRESHOLD = 0.5;
const URL_BEHAVIOR_MAP: BehaviorMap = {
  shouldContainThumbnail: { shouldContainThumbnail: true },
  shouldHideDescription: { shouldHideDescription: true },
  shouldHidePromotedByText: { shouldHidePromotedByText: true },
  shouldRenderBannerTemplate: { shouldRenderBannerTemplate: true },
  shouldRenderVideoOnly: { shouldRenderVideoOnly: true },
  p: { shouldPauseAtFiftyPercentOutOfView: true },
  dnp: { shouldNotPauseOutOfView: true },
  dv: { enableDoubleVerify: true },
  shouldRenderCaptions: { shouldRenderCaptions: true }
};

export const getLanguage = ({ creative, placement, country }: ButlerResponse): SupportedLanguage => {
  if (
    (placement.domain && FRENCH_DOMAINS.find((dom) => placement.domain.match(dom))) ||
    FRENCH_BUNDLES.find((dom) => placement.bundleIdentifier?.match(dom))
  ) {
    return 'fr';
  } else if (creative.language && ASSETS_BY_LANGUAGE[creative.language]) {
    return creative.language;
  } else if (country) {
    let country_language = COUNTRY_LANGUAGE_MAPPING[country] ? COUNTRY_LANGUAGE_MAPPING[country] : 'en';
    return country_language;
  } else if (navigator.language) {
    let code = navigator.language.substring(0, 2) as SupportedLanguage;
    if (ASSETS_BY_LANGUAGE[code]) {
      return code;
    }
  }
  return 'en';
};

const getAssetsByLanguage = (butlerResponse: ButlerResponse) => {
  const { creative } = butlerResponse;
  let language = getLanguage(butlerResponse);
  const assets = ASSETS_BY_LANGUAGE[language];
  if (!!creative.title && creative.title !== ASSETS_BY_LANGUAGE['en']['title']) {
    assets.title = creative.title;
  }

  if (!!creative.description && creative.description !== ASSETS_BY_LANGUAGE['en']['description']) {
    assets.description = creative.description;
  }

  if (!!creative.advertiser && creative.advertiser !== ASSETS_BY_LANGUAGE['en']['advertiser']) {
    assets.advertiser = creative.advertiser;
  }

  if (!!creative.promotedByText && language === 'en') {
    assets.promotedByText = creative.promotedByText;
  }

  if (creative.ctaText) {
    assets.ctaText = creative.ctaText;
  }

  return assets;
};

const getDealId = () => {
  const strModify: boolean = getParameterByName('str_modify') === 'true';
  const dealId = getParameterByName('dealId');

  if (strModify && !!dealId) {
    return { dealId };
  }

  return {};
};

const strModifyBehaviors = (): Partial<Behaviors> => {
  let behaviors: Partial<Behaviors> = {};

  const strModify: string | null = getParameterByName('str_modify');
  if (strModify !== 'true') {
    return behaviors;
  }

  const behaviorParam: string | null = getParameterByName('behaviors');
  if (!behaviorParam) {
    return behaviors;
  }

  // 'shouldContainThumbnail,dnp - > [shouldContainThumbnail, dnp]'
  const urlBehaviors: string[] = behaviorParam.split(',');

  return enableBehaviors(behaviors, urlBehaviors, URL_BEHAVIOR_MAP);
};

const TEMPLATES_WITH_BANNER_AND_DESCRIPTION = [
  { template_size: { width: 300, height: 600 }, banner_size: { width: 300, height: 250 } },
  { template_size: { width: 320, height: 250 }, banner_size: { width: 300, height: 50 } },
  { template_size: { width: 320, height: 250 }, banner_size: { width: 320, height: 50 } },
  { template_size: { width: 336, height: 280 }, banner_size: { width: 300, height: 50 } },
  { template_size: { width: 336, height: 280 }, banner_size: { width: 320, height: 50 } },
  { template_size: { width: 970, height: 250 }, banner_size: { width: 250, height: 250 } },
  { template_size: { width: 970, height: 250 }, banner_size: { width: 300, height: 250 } },
  { template_size: { width: 970, height: 250 }, banner_size: { width: 320, height: 250 } },
  { template_size: { width: 970, height: 250 }, banner_size: { width: 336, height: 228 } },
  { template_size: { width: 970, height: 250 }, banner_size: { width: 728, height: 90 } }
];

const TEMPLATES_TO_MODIFY_THUMBNAIL_WRAPPER_HEIGHT = {
  bannerSizes: [
    { width: 300, height: 50 },
    { width: 300, height: 250 },
    { width: 320, height: 50 },
    { width: 320, height: 100 },
    { width: 120, height: 100 }
  ],
  templateSizes: [
    { width: 300, height: 50 },
    { width: 300, height: 250 },
    { width: 320, height: 50 },
    { width: 320, height: 100 },
    { width: 320, height: 250 },
    { width: 336, height: 280 },
    { width: 728, height: 90 },
    { width: 970, height: 90 }
  ]
};

const thumbnailWrapperHeightShouldBeModified = ({ creative, placement }: PartialButlerResponse): boolean => {
  const creative_check = !!TEMPLATES_TO_MODIFY_THUMBNAIL_WRAPPER_HEIGHT.bannerSizes.find((sizes) => {
    return sizes.height === creative.size.height && sizes.width === creative.size.width;
  });

  const placement_check = !!TEMPLATES_TO_MODIFY_THUMBNAIL_WRAPPER_HEIGHT.templateSizes.find((sizes) => {
    return sizes.height === placement.size.height && sizes.width === placement.size.width;
  });

  return creative_check && placement_check;
};

const bannerSizeMatchesPlacementSize = (butlerResponse: PartialButlerResponse) => {
  if (GENERATOR) {
    return false;
  }

  const { creative, placement, adserverRequestId } = butlerResponse;

  const { width: creativeWidth, height: creativeHeight } = creative.size;
  const { width: placementWidth, height: placementHeight } = placement.size;

  // render 300x50 banners in 320x50 placements
  if (placementWidth == 320 && placementHeight == 50 && creativeWidth == 300 && creativeHeight == 50) {
    return { width: 300, height: 50 };
  }

  // we only render raw banners with the creative & placement match exactly
  if (creativeWidth !== placementWidth || creativeHeight !== placementHeight) {
    return false;
  }

  const foundSize = NO_ENHANCEMENT_BY_PLACEMENT_SIZES.find((size) => {
    return size.width === placementWidth && size.height === placementHeight;
  });
  if (foundSize) return foundSize;

  if (shouldDisableEnhancement(adserverRequestId, creative, placement)) {
    return { width: Number(creative.size.width), height: Number(creative.size.height) };
  }

  return false;
};

export const NO_ENHANCEMENT_DOMAINS = ['coolmathgames.com'];

const shouldRenderRawVideo = ({ creative, placement, adserverRequestId }: PartialButlerResponse): boolean => {
  return shouldDisableEnhancement(adserverRequestId, creative, placement);
};

const shouldRenderRawBanner = ({ creative, placement, adserverRequestId }: PartialButlerResponse): boolean => {
  return shouldDisableEnhancement(adserverRequestId, creative, placement);
};

// TODO: How many of these can we move to the MPT/STR+?
const hardcodedBehaviors = (butlerResponse: PartialButlerResponse): Partial<Behaviors> => {
  const cachedShouldRenderRawBanner = shouldRenderRawBanner(butlerResponse);

  return {
    shouldNotExpandVideo: butlerResponse.creative.behaviors.shouldNotExpandVideo,
    shouldRenderBannerTemplate: cachedShouldRenderRawBanner,
    shouldRenderFixedSizeBanner: bannerSizeMatchesPlacementSize(butlerResponse),
    shouldRenderWithPostscribe: butlerResponse.creative.behaviors.shouldRenderWithPostscribe,
    shouldRenderEnhancedTemplateDescription: !!TEMPLATES_WITH_BANNER_AND_DESCRIPTION.find((sizes) => {
      return (
        sizes.banner_size.width === butlerResponse.creative?.size?.width &&
        sizes.banner_size.height === butlerResponse.creative?.size?.height &&
        butlerResponse.placement?.size?.width === sizes.template_size.width &&
        butlerResponse.placement?.size?.height === sizes.template_size.height
      );
    }),
    shouldModifyThumbnailWrapperHeight: thumbnailWrapperHeightShouldBeModified(butlerResponse),
    shouldRenderVideoOnly: shouldRenderRawVideo(butlerResponse)
  };
};

const behaviorsForCreative = (butlerResponse: ButlerResponse): Behaviors => {
  return {
    ...butlerResponse.creative.behaviors,
    ...hardcodedBehaviors(butlerResponse),
    ...strModifyBehaviors()
  };
};

const AssetSwap = {
  swap: (butlerResponse: ButlerResponse): ButlerCreative => {
    let partiallySwappedCreative = {
      ...butlerResponse.creative,
      ...getDealId(),
      ...getAssetsByLanguage(butlerResponse)
    };

    const behaviors = behaviorsForCreative({
      ...butlerResponse,
      creative: partiallySwappedCreative
    });

    if (behaviors.shouldHideDescription) {
      partiallySwappedCreative = {
        ...partiallySwappedCreative,
        description: ''
      };
    }

    // we do a two-step transformation here because we want the following
    // swaps/behaviors to use the (potentially-) overridden deal ID when
    // applicable
    return {
      ...partiallySwappedCreative,
      behaviors,
      beacons: partiallySwappedCreative.beacons
    };
  }
};

export default AssetSwap;
