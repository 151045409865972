import { JSONSubtitle } from 'tag/models/common/common';

const toSeconds = (time: string) => {
  const t = time.split(':');

  try {
    let s = t[2].split(',');

    // Just in case a . is decimal seperator
    if (s.length === 1) {
      s = t[2].split('.');
    }

    return parseFloat(t[0]) * 3600 + parseFloat(t[1]) * 60 + parseFloat(s[0]) + parseFloat(s[1]) / 1000;
  } catch (e) {
    return 0;
  }
};

const nextNonEmptyLine = (linesArray: Array<string>, position: number): number => {
  let idx = position;
  while (!linesArray[idx] || linesArray[idx].match(/^\s+$/)) {
    idx++;
  }

  return idx;
};

const lastNonEmptyLine = (linesArray: Array<string>): number => {
  let idx = linesArray.length - 1;

  while (idx >= 0 && !linesArray[idx]) {
    idx--;
  }

  return idx;
};

export const parseCaptions = (data: string = ''): JSONSubtitle[] => {
  const subs = [];
  const lines = data.split(/(?:\r\n|\r|\n)/gm);
  let endIdx = lastNonEmptyLine(lines) + 1;
  let idx = 0;
  let time;
  let text;

  for (let i = 0; i < endIdx; i++) {
    let sub = {
      id: 0,
      start: 0,
      end: 0,
      text: ''
    };
    text = [];

    i = nextNonEmptyLine(lines, i);
    // subs array will always start with index 1
    sub.id = parseInt(lines[i++], 10);

    // Split on '-->' delimiter, trimming spaces as well
    time = lines[i++].split(/[\t ]*-->[\t ]*/);

    sub.start = toSeconds(time[0]);

    // So as to trim positioning information from end
    idx = time[1].indexOf(' ');
    if (idx !== -1) {
      time[1] = time[1].substr(0, idx);
    }
    sub.end = toSeconds(time[1]);

    // Build single line of text from multi-line subtitle in file
    while (i < endIdx && lines[i] && !lines[i].match(/^\s+$/)) {
      text.push(lines[i++]);
    }

    // Join into 1 line, SSA-style linebreaks
    // Strip out other SSA-style tags
    sub.text = text.join('\\N').replace(/\{(\\[\w]+\(?([\w\d]+,?)+\)?)+\}/gi, '');

    // Escape HTML entities
    sub.text = sub.text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

    // Unescape great than and less than when it makes a valid html tag of a supported style (font, b, u, s, i)
    // Modified version of regex from Phil Haack's blog: http://haacked.com/archive/2004/10/25/usingregularexpressionstomatchhtml.aspx
    // Later modified by kev: http://kevin.deldycke.com/2007/03/ultimate-regular-expression-for-html-tag-parsing-with-php/
    sub.text = sub.text.replace(
      /&lt;(\/?(font|b|u|i|s))((\s+(\w|\w[\w\-]*\w)(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)(\/?)&gt;/gi,
      '<$1$3$7>'
    );
    sub.text = sub.text.replace(/\\N/gi, '<br />');

    subs.push(sub);
  }

  return subs;
};
