import { parseCaptions } from './parseCaptions';

type SRTPayload =
  | {
      [innerCreativeId: string]: string;
    }
  | string;

export const loadCaptions = async (srtPayload: SRTPayload, innerCreativeId: string) => {
  const srt = typeof srtPayload === 'string' ? srtPayload : srtPayload[innerCreativeId] || srtPayload.defaultSfaId;
  if (!srt) {
    throw new Error('No captions payload found');
  }

  // check if srtPayload is a url or a srt string
  const captions = srt.indexOf('http') === 0 ? await fetchCaptions(srt) : srt;

  if (!captions) {
    return null;
  }

  try {
    return parseCaptions(captions);
  } catch (e) {
    e.message = 'Error parsing captions: ' + e.message;

    throw e;
  }
};

const fetchCaptions = async (url: string) => {
  const response = await fetch(url);
  return response.text();
};
