import { DISPLAY_ALWAYS_ON, OUTSTREAM_ALWAYS_ON } from './disable-enhancements';
import { experimentManager } from './experiment-manager';

export const canExperimentDisableEnhancements = () => {
  const experiment = experimentManager.getActiveExperiment();
  return !!(experiment && experiment.variants?.includes('unenhanced'));
};

export const isExperimentForcingGenericEnhancement = () => {
  const experiment = experimentManager.getActiveExperiment();
  return !!(experiment && experiment.result === 'generic');
};

export const canExperimentForceGenericEnhancement = () => {
  const experiment = experimentManager.getActiveExperiment();
  return !!(experiment && experiment.variants.includes('generic'));
};

export const isAlwaysOnExperiment = () => {
  const experiment = experimentManager.getActiveExperiment();
  return !!(experiment && [DISPLAY_ALWAYS_ON, OUTSTREAM_ALWAYS_ON].includes(experiment.id));
};
