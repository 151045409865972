import { activeSlideIndex } from '@ad-rendering/groundcontrol-legacy/tag/models/ad-experiences/carousel 2/carousel';
import { useButler, useEnhancement } from '@ad-rendering/groundcontrol-legacy/tag/models/ad-experiences/hooks';
import { CreativeType } from '@ad-rendering/groundcontrol-legacy/tag/models/common/common';
import { BannerHeadlinePayload, OutstreamHeadlinePayload } from '@sharethrough/groundcontrol-enhancement-flush';
import { FunctionalComponent, h } from 'preact';
import './title.scss';
import { usePublisherStyling } from '../../../../publisher-styling/hook';
import { publisherStyles } from '../../../../publisher-styling/helper/publisherStyles';

const Title: FunctionalComponent = () => {
  const butlerResponse = useButler();
  if (!butlerResponse) return null;

  const { enhancement } = useEnhancement<BannerHeadlinePayload | OutstreamHeadlinePayload>();
  const butlerResponseTitle = enhancement?.payload.title || butlerResponse.creative.title;

  const slides = butlerResponse.creative.slides || [];
  const isIsi = !!butlerResponse.creative.isi?.text;

  const title =
    butlerResponse.creative.action === CreativeType.Carousel
      ? slides[activeSlideIndex.value].headline
      : butlerResponseTitle;

  const { styles } = usePublisherStyling();

  const { placement } = butlerResponse;
  const publisherStyle = `${placement.publisherKey}_${placement.domain}`;

  const fontStyle = styles?.title?.font?.toStyle();
  const backgroundStyle = styles?.title?.background?.toStyle();

  const arrayClassName = [
    'str-title',
    ...(isIsi ? ['str-title-isi'] : []),
    ...(!!publisherStyles[publisherStyle] ? ['str-enhanced-title'] : [])
  ];
  return (
    <div className={arrayClassName.join(' ')} style={{ ...fontStyle, ...backgroundStyle }}>
      {title}
    </div>
  );
};

export default Title;
