// #if !process.env.GC_NATIVE_OUTSTREAM
import RAU from './ad-unit.component';
// #endif

// #if process.env.GC_NATIVE_OUTSTREAM
import RAUNO from './ad-unit.native-outstream.component';
// #endif

const ReactAdUnit = GC_NATIVE_OUTSTREAM ? RAUNO : RAU;

export default ReactAdUnit;
