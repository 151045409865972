import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import './description.scss';

export const AmazonDescription: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { description, title, badge } = assets;

  if (!description && !title) return null;

  return (
    <div className={`str-amazon-description ${!badge ? 'str-amazon-description-multiline' : ''}`}>
      {description?.value || title?.text}
    </div>
  );
};
