import { ButlerCreative } from 'modules/butler/creative';
import { ButlerPlacement } from 'modules/butler/placement';
import { shouldDisableEnhancement } from 'modules/policies';
import { TemplateType } from '../types';

export const getTemplateType = (
  adserverRequestId: string,
  placement: ButlerPlacement,
  creative: ButlerCreative
): TemplateType => {
  if (shouldDisableEnhancement(adserverRequestId, creative, placement)) {
    return TemplateType.Unenhanced;
  }

  return TemplateType.Enhanced;
};
