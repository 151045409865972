type AssetBase = {
  id?: number;
  required?: boolean;
};

export type Asset = TitleAsset | ImageAsset | VideoAsset | DataAsset;

export type RawNativeAssets<T extends Asset = Asset> = Array<T>;

export type Title = {
  text: string;
  len?: number;
};

export type TitleAsset = AssetBase & {
  title: Title;
};

export type Link = {
  url: string;
  clicktrackers?: string[];
};

export type VideoAsset = AssetBase & {
  video: { vasttag: string };
};

export type ImageAsset<T = ImageAssetType> = AssetBase & {
  img: Image<T>;
};

export type Image<T = ImageAssetType> = {
  type: T;
  url: string;
  w?: number;
  h?: number;
  wmin?: number;
  hmin?: number;
};

export enum ImageAssetType {
  ICON = 1,
  LOGO = 2,
  MAIN = 3,

  // ! These are only used by Amazon DSP
  PRIME_LOGO = 512,
  AVAILABLE_ON_AMAZON = 513
}

export type DataAsset<T = DataAssetType, U = string> = AssetBase & {
  data: Data<T, U>;
  link?: Link;
};

export type Data<T = DataAssetType, U = string> = {
  type: T;
  value: U;
  len?: number;
};

export type DataLink<T = DataAssetType, U = string> = Data<T, U> & {
  link: Link;
};

export enum DataAssetType {
  SPONSORED = 1,
  DESC = 2,
  RATING = 3,
  LIKES = 4,
  DOWNLOADS = 5,
  PRICE = 6,
  SALEPRICE = 7,
  PHONE = 8,
  ADDRESS = 9,
  DESC2 = 10,
  DISPLAYURL = 11,
  CTATEXT = 12,
  DISCOUNT = 501,
  COLOR = 502,

  // ! These are only used by Amazon DSP
  BADGE = 507,
  REVIEW = 508,
  COUPON = 512
}

export interface NativeAssets {
  title?: Title;
  mainImage?: Image<ImageAssetType.MAIN>;
  logo?: Image<ImageAssetType.LOGO>;
  icon?: Image<ImageAssetType.ICON>;
  primeLogo?: Image<ImageAssetType.PRIME_LOGO>;
  availableLogo?: Image<ImageAssetType.AVAILABLE_ON_AMAZON>;
  sponsored?: Data<DataAssetType.SPONSORED, string>;
  description?: Data<DataAssetType.DESC, string>;
  rating?: Data<DataAssetType.RATING>;
  likes?: Data<DataAssetType.LIKES>;
  downloads?: Data<DataAssetType.DOWNLOADS>;
  price?: Data<DataAssetType.PRICE>;
  salePrice?: Data<DataAssetType.SALEPRICE>;
  phone?: Data<DataAssetType.PHONE>;
  address?: Data<DataAssetType.ADDRESS>;
  desc2?: Data<DataAssetType.DESC2>;
  displayUrl?: Data<DataAssetType.DISPLAYURL>;
  cta?: DataLink<DataAssetType.CTATEXT>;
  discount?: Data<DataAssetType.DISCOUNT>;
  color?: Data<DataAssetType.COLOR>;
  badge?: Data<DataAssetType.BADGE>;
  review?: DataLink<DataAssetType.REVIEW>;
  coupon?: Data<DataAssetType.COUPON>;
}
