export class StopEvaluationError extends Error {}

export interface Rule<T extends any[], U> {
  evaluate(...data: T): U | false;
  priority?: number;
}

export class RuleEngine<T extends any[], U = boolean> {
  private rules: Rule<T, U>[];

  constructor(rules: Rule<T, U>[] = []) {
    this.rules = rules;
  }

  addRule(rule: Rule<T, U>): void {
    this.rules.push(rule);
    this.sortRulesByPriority();
  }

  private sortRulesByPriority(): void {
    this.rules.sort((a, b) => {
      if (!a.priority && !b.priority) return 0;
      if (!a.priority) return 1;
      if (!b.priority) return -1;

      return a.priority - b.priority;
    });
  }

  evaluate(...data: T): U | false {
    try {
      for (const rule of this.rules) {
        const result = rule.evaluate(...data);
        if (!!result) {
          return result;
        }
      }

      return false;
    } catch (error) {
      if (error === StopEvaluationError) {
        return false;
      } else {
        throw error; // Re-throw the error if it's not a StopEvaluationError.
      }
    }
  }
}
