import { FunctionComponent, h } from 'preact';
import './replay.scss';

interface ReplayProps {
  onReplay: (ad: any) => void;
  videoCompleted: boolean;
  videoStarted: boolean;
  videoSrc: string | undefined | null;
}

export const Replay: FunctionComponent<ReplayProps> = ({ onReplay, videoCompleted, videoStarted, videoSrc }) => {
  if (!videoStarted || !videoSrc) {
    return null;
  }

  const handleOnLoadedData = (e: Event) => {
    const video = e.target as HTMLVideoElement;
    video.currentTime = video.duration;
  };

  const onReplayClick = (event: MouseEvent): void => {
    event.stopPropagation();
    onReplay(event);
  };

  return (
    <div className={`video-replay ${videoCompleted ? 'show' : ''}`} data-testid="replay-wrapper">
      <video className="replay-video" src={videoSrc} onLoadedData={handleOnLoadedData} />
      <div className="video-replay-container">
        <div className="replay-icon" onClick={onReplayClick} data-testid="replay-icon">
          <div className="img-icon "></div>
        </div>
        <div className="see-more-icon">
          <div className="img-icon"></div>
        </div>
      </div>
    </div>
  );
};
