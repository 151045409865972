import { ButlerResponse } from 'modules/butler/response';

export interface ButlerResponseSize {
  placementSize: { width: number | null; height: number | null };
  creativeSize: { width: number | null; height: number | null };
  placementStringSize: string;
  creativeStringSize: string;
}

export const getButlerResponseSizes = (butlerResponse: ButlerResponse): ButlerResponseSize => {
  return {
    placementSize: butlerResponse.placement.size,
    creativeSize: butlerResponse.creative.size,
    placementStringSize: `${butlerResponse.placement.size.width}x${butlerResponse.placement.size.height}`,
    creativeStringSize: `${butlerResponse.creative.size.width}x${butlerResponse.creative.size.height}`
  };
};
