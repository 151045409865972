import { dispatchExperiment } from 'modules/beacons/beacons';
import { dispatchViteExperiment } from 'modules/beacons/beacons/internal/viteBuild';
import Launcher from 'modules/beacons/launcher';
import Butler from 'modules/butler/api';
import { ButlerResponse } from 'modules/butler/response';
import CookieSyncer from 'modules/cookie_syncer';
import { CustomTracker } from 'modules/custom_tracker';
import { DcoHandler } from 'modules/dco';
import { BEACONS_REFACTOR, experimentManager } from 'modules/experiments';
import Generator from 'modules/generator';
import { sample } from 'modules/monitoring';
import { placePassbackTag } from 'modules/passback';
import CreativeResponse from 'modules/response/creative_response';
import { h, render } from 'preact';
import WindowHelper from 'tag/helpers/window.helper';
import 'tag/polyfill/custom_event';
import {
  setEnhancementRenderLatencyStartTime,
  setRenderLatencyStartTime
} from '../modules/beacons/beacons/utils/performanceTimeStore';
import ReactAdUnit from './models/ad-unit';
import './models/ad-unit.scss';

const sendEvent = (eventType: string, element: Element): void => {
  const event = new CustomEvent(eventType, { bubbles: true, cancelable: true });
  element.dispatchEvent(event);
};

const sampleError = (error: any, section: string, params?: { [index: string]: any }) =>
  sample(error, {
    function: 'tag/ad:bootAd',
    section: section,
    ...params
  });

const renderAdExperience = (butlerResponse: ButlerResponse, placeholder: Element): void => {
  // send render-start event, used for ad renders on AMP
  sendEvent('str-render-start', placeholder);
  const reactAdUnit = <ReactAdUnit butlerResponse={butlerResponse} />;
  // replace placeholder with ad
  render(reactAdUnit, placeholder.parentElement as Element, placeholder);
};

const bootAd = async (placeholder: HTMLElement): Promise<void> => {
  if (GENERATOR) {
    Generator.boot(renderAdExperience);
  } else {
    const impressionStartTime = WindowHelper.performanceTimestamp();

    let butlerResponse;
    try {
      butlerResponse = CreativeResponse.availableSynchronously(placeholder)
        ? Butler.fetchAdSync(placeholder)
        : await Butler.fetchAd(placeholder);
    } catch (e) {
      if (e.message === 'No Fill') {
        // send no-fill event, used for AMP
        sendEvent('str-no-fill', placeholder);
        await placePassbackTag(placeholder);
      } else {
        sampleError(e, 'fetch ad', {
          placeholderAttributes: placeholder.dataset || 'missing'
        });
      }
      return;
    }

    try {
      const impressionEndTime = WindowHelper.performanceTimestamp();
      setRenderLatencyStartTime();
      setEnhancementRenderLatencyStartTime();

      experimentManager.initialize({ butlerResponse });

      Launcher.trackImpressionReceived(butlerResponse.adserverRequestId, {
        startTime: impressionStartTime,
        endTime: impressionEndTime
      });

      Launcher.trackGroundControlPresent(butlerResponse, placeholder);
      dispatchViteExperiment(butlerResponse.adserverRequestId);
      // dispatch experiment beacon for refactored beacons
      if (experimentManager.getActiveExperiment()?.id === BEACONS_REFACTOR) {
        dispatchExperiment(butlerResponse.adserverRequestId);
      }
    } catch (e) {
      sampleError(e, 'beacon setup', {
        arid: butlerResponse.adserverRequestId
      });
    }

    try {
      CookieSyncer.sync(butlerResponse);
    } catch (e) {
      sampleError(e, 'cookie sync', {
        cookieSyncUrls: butlerResponse.cookieSyncUrls,
        htmlBeacons: butlerResponse.htmlBeacons,
        placementKey: butlerResponse.placement.key,
        arid: butlerResponse.adserverRequestId
      });
    }

    if (butlerResponse && butlerResponse.creative && butlerResponse.creative.dcoAssetUrl) {
      try {
        if (DcoHandler.required(butlerResponse)) {
          butlerResponse = await DcoHandler.dynamicallyOptimize(butlerResponse);
        }
      } catch (e) {
        sampleError(e, 'dco', {
          butlerResponse: butlerResponse
        });
      }
    }

    try {
      renderAdExperience(butlerResponse, placeholder);
      CustomTracker.js(placeholder as HTMLElement, butlerResponse);
    } catch (e) {
      sampleError(e, 'ad rendering', {
        butlerResponse: butlerResponse
      });
    }
  }
};

(window as any).bootAd = bootAd;

export default bootAd;
