import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchVideoViewDuration = (arid: AdserverRequestId, currentTime: number, muted: boolean) => {
  return new Beacon({
    data: {
      type: BeaconType.videoViewDuration,
      arid,
      duration: currentTime * 1000,
      silent: muted,
      ...getBaseParams(arid)
    }
  });
};
