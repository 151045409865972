import { sample } from './monitoring';

type XHROptions = { timeout: number };

export const Ajax = {
  Get: (url: string, options?: XHROptions): Promise<XMLHttpRequest> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.withCredentials = true;
      xhr.onload = () => resolve(xhr);
      xhr.onerror = () => reject(xhr.statusText);

      xhr.ontimeout = () => reject('Request timeout: ' + url);
      xhr.timeout = options && options.timeout ? options.timeout : 2000;

      xhr.send();
    });
  },

  GetJson: async (url: string, options?: XHROptions): Promise<Record<string, any>> => {
    try {
      const xhr = await Ajax.Get(url, options);
      return JSON.parse(xhr.response);
    } catch (e) {
      sample(e);
      return {};
    }
  },

  Jsonp: (url: string, callbackName: string): Promise<any> => {
    return new Promise((resolve) => {
      let shadowUrl = url;
      let script = document.createElement('script');
      const name = callbackName || '__jsonp__' + Math.round(100000 * Math.random());

      if (shadowUrl.match(/\?/)) {
        shadowUrl += `&callback=${name}`;
      } else {
        shadowUrl += `?callback=${name}`;
      }

      script.src = shadowUrl;

      (window as any)[name] = function (data: any) {
        resolve(data);
        document.body.removeChild(script);
        delete (window as any)[name];
      };

      document.body.appendChild(script);
    });
  }
};

export default Ajax;
