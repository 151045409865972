import Launcher from 'modules/beacons/launcher';
import { shouldDisableEnhancement } from 'modules/policies';
import { Fragment, h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { responsiveTemplate } from 'tag/models/ad-unit/utils/responsiveTemplate';
import { useButler, useEnhancement } from '../../hooks';
import { BannerIframeProps2 } from './types';
import { getIframeHtml2, getScale } from './utils';
import { addIframeWindowClick } from './utils/addIframeWindowClick';
import { getResponsiveIframe } from './utils/getResponsiveIframe';
import { iframeEvent } from './utils/iframeEvent';

const BannerIframe2 = (props: BannerIframeProps2) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { enhancement, error } = useEnhancement();
  const butler = useButler();
  const [isHtmlRender, setHtmlRender] = useState<boolean>(false);

  useEffect(() => {
    if (!butler) return;

    const scale = shouldDisableEnhancement(butler?.adserverRequestId, butler?.creative, butler?.placement)
      ? 1
      : getScale(iframeRef.current!.clientWidth, iframeRef.current!.clientHeight, props.width, props.height);

    const iframeHTML =
      butler && responsiveTemplate(butler)
        ? getResponsiveIframe(props.outerHtml, butler.creative.size.width, butler.creative.size.height)
        : getIframeHtml2(props.outerHtml, scale);
    const currentIframe = iframeRef.current!;
    const iframedoc =
      currentIframe.contentDocument || (currentIframe.contentWindow && currentIframe.contentWindow.document);

    if (iframedoc) {
      iframedoc.open();
      iframedoc.write(iframeHTML);
      iframedoc.close();
      if (currentIframe.contentWindow) {
        currentIframe.contentWindow.window.addEventListener('message', (event: any) => {
          iframeEvent(event.data, props.onIframeClick);
        });
      }
      setHtmlRender(true);
    }
  }, [props.outerHtml, props.width, props.height, props.onIframeClick, butler]);

  useEffect(() => {
    const { current: iframe } = iframeRef;
    if (!iframe) return;
    addIframeWindowClick(iframe, () => iframeEvent({ method: 'iframeclick' }, props.onIframeClick));
  }, []);

  useEffect(() => {
    if (isHtmlRender) {
      if ((props.hasEnhancement && (error || enhancement)) || !props.hasEnhancement) {
        Launcher.trackRenderedImpression(
          props.adserverRequestId,
          iframeRef.current!.parentElement as HTMLElement,
          enhancement?.enhancement_version_id
        );
      }
    }
  }, [isHtmlRender, props.adserverRequestId, props.hasEnhancement, enhancement, error]);

  const iframeProps = {
    'data-thumbnail-id': props.dataThumbnailId,
    className: props.className,
    scrolling: 'no',
    frameBorder: '0'
  };

  const onLoad = (_e: any) => {
    const { current: mainIframe } = iframeRef;
    if (!mainIframe || !mainIframe.contentDocument) return;
    const htmlIframe = mainIframe.contentDocument.querySelector(
      'iframe[data-creative-load-listener]'
    ) as HTMLIFrameElement;
    if (!htmlIframe || !htmlIframe.contentWindow) return;
    htmlIframe.contentWindow.window.postMessage('{"isInitClickTag":true,"relegateNavigation":"parent"}', '*');
  };

  return (
    <Fragment>
      <iframe {...iframeProps} onLoad={onLoad} ref={iframeRef} />
    </Fragment>
  );
};

export default BannerIframe2;
