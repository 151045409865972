import {
  BackgroundStyle,
  FontFamilyStyle,
  FontStyle,
  IFontFamilyResponse,
  IFontStyleResponse,
  IPublisherStyleResponse,
  LayoutStyle,
  TextStyle
} from '.';

export class PublisherStyles implements IPublisherStyleResponse {
  direction: 'row' | 'column' | undefined;
  font: IFontStyleResponse | undefined;
  background: BackgroundStyle | undefined;
  layout: LayoutStyle | undefined;
  cta: TextStyle | undefined;
  title: TextStyle | undefined;
  description: TextStyle | undefined;
  promotedBy: TextStyle | undefined;
  fontFamily: IFontFamilyResponse[] | undefined;

  constructor(publisherStyleResponse?: IPublisherStyleResponse) {
    this.direction = publisherStyleResponse?.direction;
    this.background = new BackgroundStyle(publisherStyleResponse?.background);
    this.layout = new LayoutStyle(publisherStyleResponse?.layout);
    this.cta = new TextStyle(publisherStyleResponse?.cta);
    this.title = new TextStyle(publisherStyleResponse?.title);
    this.description = new TextStyle(publisherStyleResponse?.description);
    this.promotedBy = new TextStyle(publisherStyleResponse?.promotedBy);
    this.fontFamily = new FontFamilyStyle(publisherStyleResponse?.fontFamily);
    this.font = new FontStyle(publisherStyleResponse?.font);
  }
}
