import { dispatchWinNotificationFail } from '../internal/winNotificationFail';
import { AdserverRequestId, CreativeBeaconType } from '../types';
import { dispatchCreativeNotifications } from './creativeNotifications';

export const dispatchWinNotifications = (arid: AdserverRequestId) => {
  return dispatchCreativeNotifications({
    arid,
    beaconType: CreativeBeaconType.WinNotification,
    onFailure: (url: string) => {
      dispatchWinNotificationFail(arid, url);
    }
  });
};
