import { FunctionComponent, h } from 'preact';
import { WeatherIconsMap } from './weather-icons-map';

interface WeatherIconProps {
  icon: keyof typeof WeatherIconsMap;
  color?: string;
  isCondensed?: boolean;
}

export const WeatherIcon: FunctionComponent<WeatherIconProps> = ({ icon, isCondensed, color = '#616e7c' }) => {
  return (
    <div className={`str-weather-icon ${isCondensed ? 'condensed' : ''}`}>
      <div style={{ color: color, display: 'flex' }}>{WeatherIconsMap[icon]}</div>
    </div>
  );
};
