import MapMarkerIcon from 'assets/icons/map-marker.svg';
import { ButlerResponse } from 'modules/butler/response';
import { FunctionComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { useButler, useEnhancement } from 'tag/models/ad-experiences/hooks';
import Advertiser from '../../advertiser';

import { dispatchExperiment } from 'modules/beacons/beacons';
import { ThreeHourForecast } from './DailyWeatherForecast';
import { useOpenWeatherAPI } from './useOpenWeatherAPI';
import { WeatherIcon } from './weather-icons.component';
import './weather.scss';
import {CallToAction} from "../../cta/cta.component";

const getClassName = (butlerResponse: ButlerResponse): Array<string> => {
  let classes = [];
  if (butlerResponse.placement.size.width === 300 && butlerResponse.placement.size.height === 250) {
    classes.push('condensed');
  }
  return classes;
};

const getEarliestFutureForecast = (forecasts: ThreeHourForecast[]) => {
  const now = new Date();
  return forecasts.findIndex((forecast) => new Date(forecast.timestamp) > now);
};

const WeatherEnhancement: FunctionComponent = () => {
  const butlerResponse = useButler();
  const { enhancement } = useEnhancement<any>();

  const { weatherData, loading } = useOpenWeatherAPI(enhancement?.payload.city);
  const [forecastIndex, setForecastIndex] = useState(0);

  // rotate every 3 seconds
  // if (weatherData) {
  //   setTimeout(() => {
  //     setForecastIndex((forecastIndex + 1) % weatherData.forecasts.length);
  //   }, 3000);
  // }

  if (!butlerResponse) {
    return null;
  }

  const title = enhancement?.payload.title || 'Weather Forecast';
  const color = enhancement?.payload.color || '#4bd1a6';

  const classes = getClassName(butlerResponse).join(' ');

  if (loading || !weatherData) {
    return <div>loading...</div>;
  }

  setForecastIndex(getEarliestFutureForecast(weatherData.forecasts));

  const { city, time, forecast, temperature, temperatureHigh, temperatureLow, icon } =
    weatherData?.forecasts[forecastIndex];

  dispatchExperiment(butlerResponse.adserverRequestId);

  return (
    <div className={`str-enhanced-info weather ${classes}`}>
      <div className={`str-weather`}>
        <div className="str-weather-container str-location-container">
          <div className="str-city">
            <img src={MapMarkerIcon}></img>
            <WeatherIcon icon={icon as any} isCondensed={true} />
            <div>{city}</div>
          </div>
          <WeatherIcon icon={icon as any} color={color} />
          <div className="str-forecast" key={forecastIndex}>
            <div className="str-day">{time},</div>
            <div className="str-forecast-text">{forecast}</div>
          </div>
        </div>
        <div className="str-weather-container str-temperature-container">
          <div className="str-temperature" style={{ color }} key={forecastIndex}>
            {temperature}
          </div>
          <div className="str-temperature-variation">
            <div className="str-temperature-high">
              <span className="icon icon-up"></span>
              <span>{temperatureHigh}</span>
            </div>
            <div className="str-temperature-low">
              <span className="icon icon-down"></span>
              <span>{temperatureLow}</span>
            </div>
          </div>
          <div className="str-title">{title}</div>
        </div>
      </div>
      <div className={`str-bottom`}>
        <Advertiser />
        <CallToAction />
      </div>
    </div>
  );
};

export default WeatherEnhancement;
