import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import './review.scss';

export const AmazonReview: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { review } = assets;
  if (!review) return null;

  return (
    <a className="str-amazon-review" href={review.link.url}>
      <span>{review.value}</span>
    </a>
  );
};
