import Cannon from 'modules/beacons/cannon';
import Beacons from 'modules/beacons/utils';
import { ButlerResponse } from 'modules/butler/response';
import { getBrowserName } from 'modules/environment-info/getBrowserName';
import { getBrowserUrl } from 'modules/environment-info/getBrowserUrl';
import { getPlatformType } from 'modules/environment-info/getPlatformType';
import { BEACONS_REFACTOR, experimentManager } from 'modules/experiments';
import { shouldDisableEnhancement } from 'modules/policies';
import ResponseStore from 'modules/response/response_store';
import { QueryArgs } from 'modules/utils';
import { buildViewTrackedUnit } from 'tag/helpers/in-view-tracker';
import { insertVisibilityTags } from 'tag/helpers/visibility.helper';
import { PerformanceTimestamp } from 'tag/helpers/window.helper';
import { EnhanceableCreativeTypes } from 'tag/models/common/common.model';
import { Audit, AuditTimer } from '../audit';
import { dispatchGroundControlPresent, dispatchImpression, dispatchImpressionReceived } from './beacons';

const trackRenderedImpression = (adserverRequestId: string, element: HTMLElement, enhancementVersionId?: string) => {
  if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
    dispatchImpression(adserverRequestId, element, enhancementVersionId);
    return;
  }

  const butlerResponse = ResponseStore.getResponse(adserverRequestId);
  const renderingTimer = AuditTimer.retrieve('rendering', adserverRequestId).stop();
  Audit.tick('total', adserverRequestId);

  buildViewTrackedUnit(element, adserverRequestId);
  handleRenderedImpression(adserverRequestId, element, renderingTimer.start, renderingTimer.end, enhancementVersionId);

  new AuditTimer('visible', adserverRequestId);

  insertVisibilityTags(butlerResponse, element);
};

const trackImpressionReceived = (
  adserverRequestId: string,
  { startTime, endTime }: { startTime: PerformanceTimestamp; endTime: PerformanceTimestamp }
) => {
  if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
    dispatchImpressionReceived(adserverRequestId);
    return;
  }

  Audit.tick(Beacons.types.ReceivedImpression, adserverRequestId);

  Cannon.fireBeacon(
    generateImpressionReceivedParams(adserverRequestId, startTime, endTime),
    ResponseStore.getResponse(adserverRequestId)
  ).catch((err) => {
    console.log(err);
  });
};

const trackGroundControlPresent = (butlerResponse: ButlerResponse, placeholder: HTMLElement) => {
  if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
    dispatchGroundControlPresent(butlerResponse.adserverRequestId, placeholder);
    return;
  }

  Cannon.fireBeacon({ type: Beacons.types.GroundControlPresent }, butlerResponse, placeholder);
};

const fireThirdPartyClickBeacons = (butlerResponse: ButlerResponse) => {
  const thirdPartyClickBeacons = butlerResponse.creative.beacons.click;
  thirdPartyClickBeacons.forEach((url: string) => {
    // Silence errors in the developer console with a catch block,
    // since we are not responsible for validating 3rd party beacon URLs
    Cannon.firePixel(url).catch(() => undefined);
  });
};

const trackClick = (adserverRequestId: string, element: HTMLElement): void => {
  const butlerResponse = ResponseStore.getResponse(adserverRequestId);

  Beacons.fire.userEvents.clickout(adserverRequestId, element);

  fireThirdPartyClickBeacons(butlerResponse);
};

const generateImpressionReceivedParams = (
  adserverRequestId: string,
  startTime: PerformanceTimestamp,
  endTime: PerformanceTimestamp
) => {
  const params: QueryArgs = {
    type: Beacons.types.ReceivedImpression
  };
  const bootTime = Audit.getTimeOrigin();
  if (bootTime > 0) {
    params[Beacons.benchmarkTypes.bootTime] = bootTime.toFixed(2);
  }
  const sdkBootTime = Audit.sdkBootTime();
  if (sdkBootTime !== undefined) {
    params[Beacons.benchmarkTypes.benchmarkBoot] = sdkBootTime.toString();
  }
  const isHeaderBidding = !!ResponseStore.getResponse(adserverRequestId).cpm;
  if (!isHeaderBidding && startTime && endTime) {
    const elapsedTime = endTime - startTime;
    params.butlerLatency = elapsedTime.toPrecision(4).toString();
  }

  const prt = Audit.getPRT(adserverRequestId);
  if (prt) {
    params.prtFetchStart = prt.fetchStart;
    params.prtRequestStart = prt.requestStart;
    params.prtResponseStart = prt.responseStart;
    params.prtResponseEnd = prt.responseEnd;
  }

  const response = ResponseStore.getResponse(adserverRequestId);
  if (EnhanceableCreativeTypes.includes(response.creative.action)) {
    const shouldEnhance = !shouldDisableEnhancement(adserverRequestId, response.creative, response.placement);
    params.shouldEnhance = shouldEnhance ? 1 : 0;
  }

  return params;
};

const handleRenderedImpression = (
  adserverRequestId: string,
  element: HTMLElement,
  startTime: PerformanceTimestamp,
  endTime: PerformanceTimestamp,
  enhancementVersionId?: string
): void => {
  if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
    dispatchImpression(adserverRequestId, element, enhancementVersionId);
    return;
  }

  const butlerResponse = ResponseStore.getResponse(adserverRequestId);

  let params: { [key: string]: string } = {
    type: Beacons.types.RenderedImpression,
    browser: getBrowserName(),
    platform: getPlatformType(),
    url: getBrowserUrl(),
    referrer: document.referrer.split('?')[0],
    evid: enhancementVersionId || butlerResponse.creative.enhancementVersionId
  };

  if (startTime !== null && endTime !== null) {
    const renderLatency = endTime - startTime;
    if (renderLatency > 0) {
      params[Beacons.benchmarkTypes.renderLatency] = renderLatency.toPrecision(4).toString();
    }
  }
  Audit.tick(Beacons.types.RenderedImpression, adserverRequestId);

  // Fire Sharethrough impression beacon
  Cannon.fireBeacon(params, butlerResponse, element).catch((err) => {
    console.log(err);
  });

  // Fire win notification
  Cannon.fireWinNotification(butlerResponse).catch((err) => console.log(err));

  // Fire third party impression beacons
  const thirdPartyBeacons = butlerResponse.creative.beacons.impression;
  thirdPartyBeacons.forEach((url: string) => {
    Cannon.firePixel(url);
  });
};

let Launcher = {
  fireThirdPartyClickBeacons,
  generateImpressionReceivedParams,
  trackRenderedImpression,
  trackImpressionReceived,
  trackClick,
  trackGroundControlPresent,
  handleRenderedImpression
};

if (GENERATOR) {
  Launcher = {
    fireThirdPartyClickBeacons: () => ({}),
    generateImpressionReceivedParams: () => ({}),
    trackRenderedImpression: () => {},
    trackImpressionReceived: () => {},
    trackClick: () => {},
    trackGroundControlPresent: () => {},
    handleRenderedImpression: () => {}
  };
}

export default Launcher;
