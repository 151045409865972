import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import './cta.scss';

const getCtaClass = (ctaValue: string) => {
  switch (ctaValue) {
    case 'Add to cart':
      return 'str-amazon-cta-add-to-cart';
    case 'Shop now':
      return 'str-amazon-cta-shop-now';
    default:
      return '';
  }
};

export const AmazonCta: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { cta, coupon } = assets;
  if (!cta?.value || !cta.link || !!coupon) return null;

  const ctaStyle = getCtaClass(cta.value);

  return (
    <a className={`str-amazon-cta ${ctaStyle}`} href={cta.link.url}>
      <div>{cta.value}</div>
    </a>
  );
};
