import { WeatherDegreesType } from './types';

export class DailyWeatherForecast {
  city: string;
  day: string;
  forecast: string;
  temperatureValue: number;
  temperatureHighValue: number;
  temperatureLowValue: number;
  icon: string;
  type: WeatherDegreesType;

  constructor(country: string, cityName: string, day: any) {
    this.city = cityName;
    this.day = new Date(day.dt * 1000).toLocaleDateString('en-US', { weekday: 'long' });
    this.forecast = day.weather[0].description;
    this.temperatureValue = Math.round(day.temp.day - 273.15);
    this.temperatureHighValue = Math.round(day.temp.max - 273.15);
    this.temperatureLowValue = Math.round(day.temp.min - 273.15);
    this.icon = day.weather[0].icon;
    this.type = country === 'US' ? WeatherDegreesType.Fahrenheit : WeatherDegreesType.Celcius;
  }

  private toFahrenheit(celsius: number): number {
    return Math.round((celsius * 9) / 5 + 32);
  }

  get temperature() {
    return `${
      this.type === WeatherDegreesType.Celcius ? this.temperatureValue : this.toFahrenheit(this.temperatureValue)
    }°${this.type}`;
  }

  get temperatureHigh() {
    return `${
      this.type === WeatherDegreesType.Celcius
        ? this.temperatureHighValue
        : this.toFahrenheit(this.temperatureHighValue)
    }°${this.type}`;
  }

  get temperatureLow() {
    return `${
      this.type === WeatherDegreesType.Celcius ? this.temperatureLowValue : this.toFahrenheit(this.temperatureLowValue)
    }°${this.type}`;
  }
}

export class ThreeHourForecast {
  city: string;
  time: string;
  forecast: string;
  temperatureValue: number;
  temperatureHighValue: number;
  temperatureLowValue: number;
  icon: string;
  type: WeatherDegreesType;
  timestamp: string;

  constructor(country: string, cityName: string, forecast: any) {
    this.city = cityName;
    this.time = new Date(forecast.dt * 1000).toLocaleDateString('en-US', { weekday: 'long' });
    this.forecast = forecast.weather[0].description;
    this.temperatureValue = Math.round(forecast.main.temp - 273.15);
    this.temperatureHighValue = Math.round(forecast.main.temp_max - 273.15);
    this.temperatureLowValue = Math.round(forecast.main.temp_min - 273.15);
    this.icon = forecast.weather[0].icon;
    this.type = country === 'US' ? WeatherDegreesType.Fahrenheit : WeatherDegreesType.Celcius;
    this.timestamp = forecast.dt_txt;
  }

  private toFahrenheit(celsius: number): number {
    return Math.round((celsius * 9) / 5 + 32);
  }

  get temperature() {
    return `${
      this.type === WeatherDegreesType.Celcius ? this.temperatureValue : this.toFahrenheit(this.temperatureValue)
    }°${this.type}`;
  }

  get temperatureHigh() {
    return `${
      this.type === WeatherDegreesType.Celcius
        ? this.temperatureHighValue
        : this.toFahrenheit(this.temperatureHighValue)
    }°${this.type}`;
  }

  get temperatureLow() {
    return `${
      this.type === WeatherDegreesType.Celcius ? this.temperatureLowValue : this.toFahrenheit(this.temperatureLowValue)
    }°${this.type}`;
  }
}
