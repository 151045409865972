import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';

export const dispatchViteExperiment = (arid: AdserverRequestId) => {
  const viteExperimentId = 'bda49453-ea45-4de7-aef5-3a5272d04b8a';

  const isViteBuild = CONFIG.version.includes('-vite');

  return new Beacon({
    data: {
      type: BeaconType.experiment,
      arid,
      experiment_id: viteExperimentId,
      experiment_variant_id: isViteBuild ? 'vite' : 'webpack'
    }
  });
};
