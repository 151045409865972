export default [
  {
    sourceId: 'V5J3YnPygd61DpGaQKLXUr8H',
    creativeKeys: [
      'V5J3YnPygd61DpGaQKLXUr8H-506824475',
      'V5J3YnPygd61DpGaQKLXUr8H-506826657',
      'V5J3YnPygd61DpGaQKLXUr8H-506818457',
      'V5J3YnPygd61DpGaQKLXUr8H-506814556',
      'V5J3YnPygd61DpGaQKLXUr8H-506826656',
      'V5J3YnPygd61DpGaQKLXUr8H-506824585',
      'V5J3YnPygd61DpGaQKLXUr8H-506826154',
      'V5J3YnPygd61DpGaQKLXUr8H-506814768',
      'V5J3YnPygd61DpGaQKLXUr8H-506849112',
      'V5J3YnPygd61DpGaQKLXUr8H-506816963',
      'V5J3YnPygd61DpGaQKLXUr8H-506818166',
      'V5J3YnPygd61DpGaQKLXUr8H-506818069',
      'V5J3YnPygd61DpGaQKLXUr8H-506824764',
      'V5J3YnPygd61DpGaQKLXUr8H-506824978',
      'V5J3YnPygd61DpGaQKLXUr8H-506827157',
      'V5J3YnPygd61DpGaQKLXUr8H-506845030',
      'V5J3YnPygd61DpGaQKLXUr8H-506819168',
      'V5J3YnPygd61DpGaQKLXUr8H-506824860',
      'V5J3YnPygd61DpGaQKLXUr8H-506814380',
      'V5J3YnPygd61DpGaQKLXUr8H-506825472',
      'V5J3YnPygd61DpGaQKLXUr8H-507466625',
      'V5J3YnPygd61DpGaQKLXUr8H-507476592',
      'V5J3YnPygd61DpGaQKLXUr8H-507458865',
      'V5J3YnPygd61DpGaQKLXUr8H-507469407',
      'V5J3YnPygd61DpGaQKLXUr8H-506827078',
      'V5J3YnPygd61DpGaQKLXUr8H-506814173',
      'V5J3YnPygd61DpGaQKLXUr8H-506827080',
      'V5J3YnPygd61DpGaQKLXUr8H-506827158',
      'V5J3YnPygd61DpGaQKLXUr8H-506826153',
      'V5J3YnPygd61DpGaQKLXUr8H-506826155',
      'V5J3YnPygd61DpGaQKLXUr8H-506825677',
      'V5J3YnPygd61DpGaQKLXUr8H-506852089',
      'V5J3YnPygd61DpGaQKLXUr8H-506853686',
      'V5J3YnPygd61DpGaQKLXUr8H-506818165',
      'V5J3YnPygd61DpGaQKLXUr8H-506814463',
      'V5J3YnPygd61DpGaQKLXUr8H-506825676',
      'V5J3YnPygd61DpGaQKLXUr8H-506825563',
      'V5J3YnPygd61DpGaQKLXUr8H-506849113',
      'V5J3YnPygd61DpGaQKLXUr8H-506845029',
      'V5J3YnPygd61DpGaQKLXUr8H-506825368'
    ]
  }
];
