import { RawPlacement } from '../response';

// Define a mapping of sizes to template keys
const templateKeys: Record<string, string> = {
  '300x50': 'empty',
  '320x50': 'empty',
  '320x480': 'empty',
  '468x60': 'empty',
  '120x600': 'empty',
  '300x250': '300x250-mpu',
  '320x250': '320x250',
  '300x100': '300x100',
  '320x100': '320x100',
  '336x280': '336x280',
  '300x600': '300x600',
  '970x90': '970x90',
  '970x250': '970x250',
  '728x90': '728x90',
  '160x600': '160x600',
  '640x430': '640x430'
};

export const getTemplateKey = (rawPlacement: RawPlacement) => {
  if (!rawPlacement.placementAttributes.size?.h || !rawPlacement.placementAttributes.size?.w) {
    return 'empty';
  }

  const { h: height, w: width } = rawPlacement.placementAttributes.size;
  const size = `${width}x${height}`;

  return templateKeys[size] || 'empty';
};
