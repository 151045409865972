export const extractEClickUrl = (adm: string) => {
  const eClickURLRegex = /<!--\s*eClickURL\s+=\s+(.*?)\s*-->/;
  const match = adm.match(eClickURLRegex);

  if (!match) {
    return null;
  }

  return decodeURIComponent(match[1]);
};
