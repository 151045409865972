export const getResponsiveIframe = (adm: string, width: number | null, height: number | null): string => {
  return `
  <html>
    <head>
      <style>
        .str-thumbnail-body {
          margin: auto;
          display: flex;
          overflow: -moz-scrollbars-none;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
        .str-thumbnail-body::-webkit-scrollbar {
          width: 0 !important;
        }
      </style>
      <script>
        function resize() {
          const { innerWidth, innerHeight } = window;
          const width = ${width};
          const height = ${height};
          const thumbnailContent = document.querySelector(".str-thumbnail-content");
          const thumbnailContentScale = document.querySelector(".str-thumbnail-content-scale");
          const heightScale = innerHeight / (height ?? thumbnailContent.clientHeight);
          const widthScale = innerWidth / (width ?? thumbnailContent.clientWidth);
          const scale = Math.min(heightScale, widthScale);
          thumbnailContentScale.style.width = width ?? thumbnailContent.clientWidth;
          thumbnailContentScale.style.height = height ?? thumbnailContent.clientHeight;
          if (scale < 1) {
            thumbnailContent.style.transform = "scale(" + scale + ")";
            thumbnailContent.style.transformOrigin = "center";
          } else {
            thumbnailContent.style.transformOrigin = "";
            thumbnailContent.style.transform = "scale(1)";
          }
        }
        window.addEventListener("resize", () => resize());
        const observer = new MutationObserver(() => {
          resize();
        });
        observer.observe(document, {
          childList: true,
          subtree: true,
        });
      </script>
    </head>
    <body class="str-thumbnail-body">
      <div class="str-thumbnail-content">
        <div class="str-thumbnail-content-scale">${adm}</div>
      </div>
    </body>
  </html>
`;
};
