import { Signal } from '@preact/signals';
import Beacons from 'modules/beacons/utils';
import { ButlerResponse } from 'modules/butler/response';
import { Ad } from 'modules/imaSDK';
import { shouldDisableEnhancement } from 'modules/policies';
import { VastVideo } from '../vast-video.component';

export const adLoadedHandler = (
  component: VastVideo,
  butlerResponse: ButlerResponse,
  videoReplayed: Signal<boolean>,
  videoLoaded: Signal<boolean>,
  videoSource: Signal<string | null | undefined>
) => {
  const shouldFireIsEnhancedBeacon =
    !butlerResponse.creative.behaviors.shouldRenderVideoOnly &&
    !videoReplayed.value &&
    butlerResponse.creative.action !== 'hosted-video' &&
    !shouldDisableEnhancement(butlerResponse.adserverRequestId, butlerResponse.creative, butlerResponse.placement);

  return (ad: Ad) => {
    if (!ad || !ad.h) return;

    const { mediaUrl: videoSrc, creativeId } = ad.h;
    if (shouldFireIsEnhancedBeacon) {
      Beacons.fire.isEnhanced(butlerResponse.adserverRequestId, '', creativeId);
    }
    if (component.props.captionsContext?.setInnerCreativeId) {
      component.props.captionsContext?.setInnerCreativeId(creativeId);
    }
    videoLoaded.value = true;
    if (videoSrc) videoSource.value = videoSrc;
  };
};
