import {
  BannerHeadlinePayload,
  HeadlineConfig,
  OutstreamHeadlinePayload
} from '@sharethrough/groundcontrol-enhancement-flush';
import { ButlerContext } from 'modules/butler/butlerProvider';
import { FunctionComponent, h } from 'preact';
import { useContext, useRef } from 'preact/hooks';
import { useEnhancement, useTextOverflow } from 'tag/models/ad-experiences/hooks';
import { usePublisherStyling } from '../../publisher-styling/hook';
import { getCtaText } from '../../utils';
import './cta.module.scss';

const getStyle = (cta: HeadlineConfig['cta'] | undefined) => {
  if (!cta) return {};

  return {
    '--cta-text-color': cta.textColor || '#fff',
    '--cta-background-color': cta.backgroundColor || '#25394a',
    '--cta-text-hover-color': cta.textHoverColor || '#fff',
    '--cta-background-hover-color': cta.backgroundHoverColor || '#4bd1a6'
  };
};

export const CallToAction: FunctionComponent = () => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const textOverflow = useTextOverflow(spanRef, 10);
  const butlerResponse = useContext(ButlerContext);
  const { enhancement } = useEnhancement<BannerHeadlinePayload | OutstreamHeadlinePayload>();
  const { styles } = usePublisherStyling();

  if (!butlerResponse || butlerResponse.creative.isi || styles?.cta?.hide) return null;

  let text = getCtaText(butlerResponse);
  if (enhancement) {
    const cta = enhancement?.payload.cta;
    text = cta?.text || text;
  }

  const cta = enhancement?.payload.cta;
  const ctaFontStyle = styles?.cta?.font?.toStyle();
  const ctaBackroundStyle = styles?.cta?.background?.toStyle();

  return (
    <div
      className="str-cta str-cta-button"
      style={{ ...getStyle(cta), ...ctaFontStyle, ...ctaBackroundStyle }}
      data-testid="cta-button"
    >
      <span ref={spanRef}>{textOverflow ? 'See More' : text}</span>
    </div>
  );
};
