import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchJsTrackerFailedToLoad = (arid: AdserverRequestId, sources: string) => {
  return new Beacon({
    data: {
      type: BeaconType.jsTrackerFailedToLoad,
      sources,
      arid,
      ...getBaseParams(arid)
    }
  });
};
