import { downloadScript } from 'modules/load_script';

const IMA_SDK_URL = '//imasdk.googleapis.com/prerelease/js/3.554.2/ima3.js';

const loadImaSDK = async () => {
  await downloadScript(
    IMA_SDK_URL,
    () => typeof window.google !== 'undefined' && typeof window.google.ima !== 'undefined'
  );
};

export default loadImaSDK;
