import { ButlerPlacement } from 'modules/butler/placement';
import { ButlerResponse } from 'modules/butler/response';
import ResponseStore from 'modules/response/response_store';
import { Component } from 'preact';
import { AdExperienceBaseProps } from './types';

export abstract class AdExperienceBase<TCreative, TProps extends AdExperienceBaseProps, TState> extends Component<
  TProps,
  TState
> {
  constructor(props: TProps) {
    super(props);
  }

  _butlerResponse: ButlerResponse | undefined = undefined;
  get butlerResponse(): ButlerResponse {
    if (!this._butlerResponse) {
      this._butlerResponse = ResponseStore.getResponse(this.props.adserverRequestId);
    }
    return this._butlerResponse;
  }

  get creative(): TCreative {
    return this.butlerResponse.creative as TCreative;
  }

  get placement(): ButlerPlacement {
    return this.butlerResponse.placement;
  }
}
