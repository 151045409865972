import Ajax from 'modules/ajax';
import { objToQueryString, recreateChildScriptTags } from 'modules/utils';
import { sample } from './monitoring';

export const placePassbackTag = async (placeholder: HTMLElement): Promise<void> => {
  try {
    const placementKey = placeholder.getAttribute('data-str-native-key');
    if (!placementKey) return;

    const placement: any = await Ajax.GetJson(requestURI(placementKey));
    if (!placement || !placement.placementAttributes || !placement.placementAttributes.passback_tag) return;

    let passbackContainer = document.createElement('div');
    passbackContainer.innerHTML = placement.placementAttributes.passback_tag;

    recreateChildScriptTags(passbackContainer);

    if (placeholder.parentNode) {
      (placeholder.parentNode as HTMLElement).replaceChild(passbackContainer, placeholder);
    } else {
      placeholder.innerHTML = '';
      placeholder.insertAdjacentElement('afterend', passbackContainer);
    }
  } catch (e) {
    sample(e, {
      function: 'modules/passback:placePassbackTag',
      placeholderAttributes: placeholder.dataset || 'missing'
    });
  }
};

const requestURI = (placementKey: string): string => {
  return `${CONFIG.sfpAdserverDomain}/placement?${objToQueryString({
    key: placementKey
  })}`;
};
