import { Signal } from '@preact/signals';
import { h } from 'preact';
import styles from './clickblocker.module.scss';

type Props = {
  onClick: (event: MouseEvent) => void;
  showClickBlocker: Signal<boolean>;
  isBannerClickable: Signal<boolean>;
};

const ClickBlocker = (props: Props) => {
  if (!props.isBannerClickable.value || !props.showClickBlocker.value) return null;

  const { onClick } = props;
  return (
    <div
      data-testid="str-banner-clickblocker"
      onClick={(event) => {
        onClick(event);
      }}
      className={`${styles['str-banner-clickblocker']}`}
    />
  );
};

export default ClickBlocker;
