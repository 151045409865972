import { OutstreamCaptionsPayload } from '@sharethrough/groundcontrol-enhancement-flush';
import { sample } from 'modules/monitoring';
import { FunctionComponent, createContext, h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useButler, useEnhancement } from 'tag/models/ad-experiences/hooks';
import { CreativeType, JSONSubtitle } from 'tag/models/common/common';
import { loadCaptions } from './loadCaptions';

interface CaptionsProviderState {
  currentTime: number;
  currentSubtitle: JSONSubtitle | null;
  srt: JSONSubtitle[];
  innerCreativeId: string;
}

interface CaptionsContextProps extends CaptionsProviderState {
  setInnerCreativeId: (value: string) => void;
  setCurrentTime: (value: number) => void;
}

export const CaptionsContext = createContext<CaptionsContextProps | null>(null);

export const CaptionsProvider: FunctionComponent = ({ children }) => {
  const { enhancement, error } = useEnhancement<OutstreamCaptionsPayload>();
  const butlerResponse = useButler();

  const [captionsState, setCaptionsState] = useState<CaptionsProviderState>({
    currentTime: 0,
    currentSubtitle: null,
    srt: [],
    innerCreativeId: ''
  });

  useEffect(() => {
    if (butlerResponse?.creative.action !== CreativeType.NativeOutstream) return;
    if (!butlerResponse?.creative.srt && !enhancement?.payload.srt) return;

    if (captionsState.innerCreativeId) {
      // priority to srt payload in butler response
      let srt = butlerResponse?.creative.srt;

      if (!srt && enhancement?.payload.srt) {
        srt = enhancement?.payload.srt;
      }

      loadCaptions(srt, captionsState.innerCreativeId)
        .then((srt) => {
          if (!srt) return;
          setCaptionsState((prevState) => ({ ...prevState, srt }));
        })
        .catch((e) => {
          sample(e, {
            adserverRequestId: butlerResponse?.adserverRequestId
          });
        });
    }
  }, [
    enhancement,
    enhancement?.type,
    captionsState.innerCreativeId,
    error,
    butlerResponse?.adserverRequestId,
    butlerResponse
  ]);

  const newSubtitle = useMemo(() => {
    if (butlerResponse?.creative.action !== CreativeType.NativeOutstream) return null;
    if (!captionsState.srt) return null;
    if (captionsState.srt.length === 0) return null;

    return captionsState.srt.find((subtitle) => {
      return captionsState.currentTime >= subtitle.start && captionsState.currentTime <= subtitle.end;
    });
  }, [captionsState.currentTime, captionsState.srt]);

  useEffect(() => {
    if (newSubtitle === captionsState.currentSubtitle) return;
    if (newSubtitle?.text !== captionsState.currentSubtitle?.text) {
      setCaptionsState((prevState) => ({ ...prevState, currentSubtitle: newSubtitle ?? null }));
    }
  }, [newSubtitle]);

  const setInnerCreativeId = (value: string) => {
    setCaptionsState((prevState) => ({ ...prevState, innerCreativeId: value }));
  };

  const setCurrentTime = (value: number) => {
    // only update state if full second has passed
    if (captionsState.srt.length === 0) return;
    setCaptionsState((prevState) => ({ ...prevState, currentTime: value }));
  };

  const contextValue = {
    ...captionsState,
    setInnerCreativeId,
    setCurrentTime
  };

  return <CaptionsContext.Provider value={contextValue}>{children}</CaptionsContext.Provider>;
};
