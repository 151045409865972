export enum PublisherStyleErrorType {
  FETCH_DATA_FAILED = 'FETCH_DATA_FAILED',
  UNDEFINED = 'UNDEFINED'
}

export class PublisherStyleError extends Error {
  type: PublisherStyleErrorType;
  constructor(type: PublisherStyleErrorType, message: string) {
    super(message);
    this.type = type;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
