export default [
  { width: 120, height: 160 },
  { width: 120, height: 600 },
  { width: 250, height: 250 },
  { width: 300, height: 50 },
  { width: 300, height: 100 },
  { width: 300, height: 300 },
  { width: 300, height: 400 },
  { width: 320, height: 50 },
  { width: 320, height: 100 },
  { width: 320, height: 250 },
  { width: 320, height: 480 },
  { width: 336, height: 280 },
  { width: 468, height: 60 },
  { width: 480, height: 320 },
  { width: 728, height: 90 },
  { width: 768, height: 1024 },
  { width: 970, height: 90 },
  { width: 970, height: 250 },
  { width: 1024, height: 768 }
];
