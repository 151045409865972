import { GCEnhancementPayload } from '@sharethrough/groundcontrol-enhancement-flush';
import { ButlerContext } from 'modules/butler/butlerProvider';
import { EnhancementContext } from 'modules/enhancements/enhancementProvider';
import { EnhancementError } from 'modules/enhancements/loadEnhancement.type';
import { RefObject } from 'preact';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks';

/* Pass delay = null to pause the interval */
export const useInterval = (callback: Function, delay: number | null) => {
  const savedCallback = useRef<Function>(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay === null) {
      return;
    }

    function tick() {
      savedCallback.current && savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export const useButler = () => {
  return useContext(ButlerContext);
};

export const useEnhancement = <T extends GCEnhancementPayload>(): {
  loading: boolean;
  enhancement: T | null;
  error: EnhancementError | null;
} => {
  const { enhancement, error, loading } = useContext(EnhancementContext);

  return {
    loading,
    enhancement: enhancement as T,
    error
  };
};

export const useDimensions = (ref: RefObject<HTMLElement>) => {
  const butlerResponse = useButler();

  const [dimensions, setDimensions] = useState({
    creativeDimensions: { width: 0, height: 0 },
    placementDimensions: { width: 0, height: 0 },
    creativeEl: null as HTMLElement | null,
    placementEl: null as HTMLElement | null
  });

  useEffect(() => {
    if (!ref.current || (dimensions.creativeEl && dimensions.placementEl)) return;

    setDimensions((prevDimensions) => ({
      ...prevDimensions,
      creativeEl: ref.current!.closest('.str-thumbnail-wrapper') as HTMLElement,
      placementEl: ref.current!.closest('.str-adunit') as HTMLElement
    }));
  }, [ref, dimensions.creativeEl, dimensions.placementEl]);

  useEffect(() => {
    if (!butlerResponse || (!dimensions.creativeEl && !dimensions.placementEl)) return;

    const { creative, placement } = butlerResponse;

    setDimensions((prevDimensions) => ({
      ...prevDimensions,
      creativeDimensions: {
        width: creative.size.width || dimensions.creativeEl?.clientWidth || 0,
        height: creative.size.height || dimensions.creativeEl?.clientHeight || 0
      },
      placementDimensions: {
        width: placement.size.width || dimensions.placementEl?.clientWidth || 0,
        height: placement.size.height || dimensions.placementEl?.clientHeight || 0
      }
    }));
  }, [butlerResponse, dimensions.creativeEl, dimensions.placementEl]);

  return dimensions;
};

export const useTextOverflow = (spanRef: RefObject<HTMLSpanElement>, width: number) => {
  const [textOverflow, setTextOverflow] = useState(false);

  useEffect(() => {
    if (!spanRef.current) return;

    const textWidth = spanRef.current.clientWidth;
    const divWidth = spanRef.current.parentElement?.clientWidth || 0;

    if (divWidth - textWidth < width) {
      setTextOverflow(true);
    }
  }, [spanRef, width]);

  return textOverflow;
};

export const useChildrenOverflow = (containerRef: RefObject<HTMLElement>, callback: () => void) => {
  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const container = containerRef.current;

    const hasOverflow =
      container.scrollHeight > container.clientHeight || container.scrollWidth > container.clientWidth;

    if (callback && hasOverflow) {
      callback();
    }
  }, [containerRef, callback]);
};
