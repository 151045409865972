interface fetchWithTimeoutOptions extends RequestInit {
  timeout?: number;
}
export const fetchWithTimeout = async (url: string, options: fetchWithTimeoutOptions): Promise<Response> => {
  const { timeout, ...fetchOptions } = options;

  const timeoutPromise = new Promise<Response>((_, reject) =>
    setTimeout(() => reject(new Error('Request timeout')), timeout || 2000)
  );

  const fetchPromise = fetch(url, fetchOptions);

  return Promise.race([fetchPromise, timeoutPromise]);
};
