import Launcher from 'modules/beacons/launcher';
import { ScrollerCreative } from 'modules/butler/creative';
import { ComponentChild, h } from 'preact';
import { AdExperienceBase } from '../ad-experience.base';
import { AdExperienceBaseProps } from '../types';
import './scroller.scss';
import { ScrollerState } from './types';
import { Template } from 'tag/models/common/template';

export class Scroller2 extends AdExperienceBase<ScrollerCreative, AdExperienceBaseProps, ScrollerState> {
  //TODO rename to scroller
  element: HTMLElement | null = null;
  refThumbnailContainer: HTMLDivElement | null = null;
  refThumbnail: HTMLDivElement | null = null;
  refImage: HTMLImageElement | null = null;
  wiggleRoom: number = 0;
  gcIframe: HTMLIFrameElement | undefined | null = undefined;
  imageWidth: number = 0;
  imageHeight: number = 0;
  parentElement: HTMLElement | undefined = undefined;

  get imageScalar(): number {
    return this.refThumbnailContainer!.clientWidth / this.imageWidth;
  }

  get scrollableDistance(): number {
    if (!this.parentElement) return 0;
    return this.parentElement.scrollHeight - this.parentElement.clientHeight;
  }

  constructor(props: AdExperienceBaseProps) {
    super(props);
  }

  preLoadImage = () => {
    const newImg = new Image();
    newImg.onload = () => this.onLoadImage(newImg);
    newImg.src = this.creative.thumbnailUrl;
  };

  updatePosition = () => {
    if (!window.parent || !this.parentElement) return;
    const iframePosition = this.getIframeBoundingClientRect();

    const aux = iframePosition.top - this.parentElement.clientHeight + this.refThumbnailContainer!.clientHeight;
    this.refThumbnail!.style.backgroundPositionY = `${
      this.imageHeight *
      this.imageScalar *
      (aux / (this.parentElement.clientHeight + this.refThumbnailContainer!.clientHeight))
    }px`;
  };

  componentWillUnmount(): void {
    document.removeEventListener('scroll', () => this.updatePosition());
  }

  componentDidMount() {
    this.preLoadImage();
    Launcher.trackRenderedImpression(this.props.adserverRequestId, this.element?.parentElement as HTMLElement);
    if (window.parent) window.parent.addEventListener('scroll', () => this.updatePosition());
  }

  getIframeBoundingClientRect = (): { top: number; bottom: number; height: number } => {
    const gcIframe = this.getGcIframe();
    if (!gcIframe) return { top: 0, bottom: 0, height: 0 };
    const { top, bottom, height } = gcIframe.getBoundingClientRect();
    return { top, bottom, height };
  };

  getGcIframe = (): HTMLIFrameElement | null => {
    if (this.gcIframe === undefined) {
      if (!window.parent) return null;
      const gcWindow = window;
      const topIframes = window.parent.document.querySelectorAll('iframe');
      for (var i = 0, len = topIframes.length; i < len; i++) {
        const iframe = topIframes[i];
        if (iframe.contentWindow === gcWindow) {
          this.gcIframe = iframe;
          break;
        }
      }
      if (!this.gcIframe) this.gcIframe = null;
    }
    return this.gcIframe;
  };

  onLoadImage = (image: HTMLImageElement) => {
    if (
      !window.parent ||
      !window.parent.document ||
      !window.parent.document.body ||
      !window.parent.document.body.parentElement
    )
      return;

    const { parentElement } = window.parent.document.body;
    this.imageWidth = image.width;
    this.imageHeight = image.height;
    this.parentElement = parentElement;

    this.getGcIframe();
    this.updatePosition();
    this.setState({ imageLoaded: true });
  };

  render(): ComponentChild {
    return (
      <div
        className="str-react-template"
        data-testid="str-scroller"
        onClick={(e) => this.props.onClick(e)}
        ref={(element) => {
          this.element = element;
        }}
      >
        <Template
          creative={this.creative}
          placement={this.placement}
          country={this.butlerResponse.country}
          adserverRequestId={this.props.adserverRequestId}
        >
          <div className="str-thumbnail-wrapper">
            <div className="str-thumbnail" ref={(element) => (this.refThumbnailContainer = element)}>
              <div
                ref={(element) => (this.refThumbnail = element)}
                style={{
                  backgroundImage: `${this.state.imageLoaded ? 'url(' + this.creative.thumbnailUrl + ')' : ''}`,
                  display: `${this.state.imageLoaded ? 'block' : 'none'}`,
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}
              />
            </div>
          </div>
        </Template>
      </div>
    );
  }
}
