import { Notifier } from '@airbrake/browser';
import { INotice } from '@airbrake/browser/dist/notice';
import * as Log from 'modules/log';
import { useErrorBoundary as usePreactErrorBoundary } from 'preact/hooks';
import { useButler, useEnhancement } from 'tag/models/ad-experiences/hooks';
import { ButlerResponse, RawResponse } from './butler/response';
import { DcoError } from './dco/types';
import ResponseStore from './response/response_store';

const AIRBRAKE_PROJECT_ID = 217882;
const AIRBRAKE_PROJECT_KEY = '66698213e89602cf542eb0bdb3699a4b';
const AIRBRAKE_REPORTING_RATE = 0.00001; // report 0.001% of errors to airbrake
const LAMBDA_URL = 'https://ia6mv2ydrd6k5vbwfvrk6o6are0uptqn.lambda-url.us-east-1.on.aws';

type Options = {
  adserverRequestId?: string;
  bidResponse?: {};
  function?: string;
  errorPayload?: {};
  placeholderAttributes?: DOMStringMap | string;
  message?: string;
  response?: string;
  section?: string;
  template?: string;
  templateKey?: string;
  errorEvent?: Event;
  url?: string;
  butlerResponse?: ButlerResponse;
  butlerResponseLink?: string;
  dcoPayload?: any;
  dcoPayloadLink?: string;
  flags?: any;
  amazonBadge?: any;
  enhancement?: any;
};

export const sample = (error: string | Error, options: Options = {}): Promise<any> => {
  // We skip sampling in dev mode, send all errors to console instead
  if (ENV === 'dev') {
    return log(error, options);
  }

  if (Math.random() < AIRBRAKE_REPORTING_RATE) {
    return log(error, options);
  }

  return new Promise<null>((resolve) => resolve(null));
};

export const log = async (error: string | Error, options: Options = {}) => {
  const client = await getAirbrakeClient();
  const message = {
    error: error,
    context: { version: CONFIG.version, options: options }
  };

  const adserverRequestId = options.adserverRequestId || options.butlerResponse?.adserverRequestId;

  if (adserverRequestId) {
    const rawButlerResponse = ResponseStore.getRawResponse(adserverRequestId);

    if (rawButlerResponse) {
      delete options.butlerResponse;
      uploadButlerResponse(rawButlerResponse);
      options.butlerResponseLink = `https://sfar-data-production.s3.amazonaws.com/butler-response-log/${adserverRequestId}`;

      if (error instanceof DcoError && options.dcoPayload) {
        uploadDcoLog(adserverRequestId, options.dcoPayload);
        options.dcoPayloadLink = `https://sfar-data-production.s3.amazonaws.com/butler-response-log/${adserverRequestId}-dco`;
      }
    }
  }

  Log.error(message);
  client.notify(message).then();
};

// Filter will add reference to sourcemap
export const addSourceMaps = (notice: INotice): INotice | null => {
  const sourceMaps = notice.context.sourceMaps ?? {};

  Object.keys(sourceMaps).forEach((jsFile) => {
    if (jsFile.includes('sfp.js') || jsFile.includes('gc.js')) {
      sourceMaps[jsFile] = 'https://sdk.sharethrough.com/gc.js.map';
    }
  });

  notice.context.sourceMaps = {
    ...sourceMaps,
    'https://native.sharethrough.com/assets/sfp.js': 'https://sdk.sharethrough.com/gc.js.map'
  };

  return notice;
};

// Filter errors when in the dev environment
export const filterDev = (notice: INotice): INotice | null => {
  return ENV === 'dev' ? null : notice;
};

// Filter errors where placement key indicates DFP macro replacement has failed
export const filterIllegalPlacementKey = (notice: INotice): INotice | null => {
  const illegalPlacementKey = '%%PATTERN:strnativekey%%';
  if (
    notice &&
    notice.context &&
    notice.context.options &&
    notice.context.options.pKey &&
    notice.context.options.pKey === illegalPlacementKey
  ) {
    return null;
  } else {
    return notice;
  }
};

export const getAirbrakeClient = async (): Promise<Notifier> => {
  const { Notifier } = await import(/* webpackChunkName: 'airbrake' */ '@airbrake/browser');

  let client = new Notifier({
    projectId: AIRBRAKE_PROJECT_ID,
    projectKey: AIRBRAKE_PROJECT_KEY,
    environment: ENV
  });

  client.addFilter(addSourceMaps);
  client.addFilter(filterIllegalPlacementKey);
  client.addFilter(filterDev);

  return client;
};

export const uploadButlerResponse = (butlerResponse: RawResponse) => {
  const data = {
    adserverRequestId: butlerResponse.adserverRequestId,
    butlerResponse
  };

  if (navigator.sendBeacon) {
    navigator.sendBeacon(LAMBDA_URL, JSON.stringify(data));
  } else {
    fetch(LAMBDA_URL, {
      method: 'POST',
      body: JSON.stringify(data),
      keepalive: true
    });
  }
};

export const uploadDcoLog = (adserverRequestId: string, dcoPayload: {}) => {
  const data = {
    adserverRequestId: `${adserverRequestId}-dco`,
    butlerResponse: dcoPayload
  };

  if (navigator.sendBeacon) {
    navigator.sendBeacon(LAMBDA_URL, JSON.stringify(data));
  } else {
    fetch(LAMBDA_URL, {
      method: 'POST',
      body: JSON.stringify(data),
      keepalive: true
    });
  }
};

export const useErrorBoundary = (message?: string) => {
  const butlerResponse = useButler() || undefined;
  const enhancement = useEnhancement();

  const options: Options = {
    butlerResponse,
    enhancement
  };

  return usePreactErrorBoundary((error) => {
    if (message && error instanceof Error) {
      error.message = `${message}: ${error.message}`;
    }

    sample(error, options);
  });
};
