import AssetSwap from 'modules/asset_swap/asset_swap';
import { BehaviorMap, enableBehaviors } from 'modules/asset_swap/behaviors';
import { AdserverRequestId } from 'modules/beacons/beacons/types';
import { Behaviors, ButlerCreative, ISI, MutableButlerCreative } from 'modules/butler/creative';
import * as ButlerPlacement from 'modules/butler/placement';
import { sample } from 'modules/monitoring';
import { RawNativeAssets, setNativeAssets } from 'modules/ortb';
import ResponseStore from 'modules/response/response_store';
import { normalizeJsTracker } from 'modules/utils';

type size = {
  w?: number | null;
  h?: number | null;
  width?: number | null;
  height?: number | null;
};

export type RawCreativeWrapper = {
  auctionWinId: string;
  creative: any;
  cpm: number | null;
  // srt can be a url string or stringified object of type { [key: string]: string }
  srt?: string;
};

export type RawPlacement = {
  status: 'pre-live' | 'live' | 'off' | 'archived';
  placementAttributes: {
    domain: string;
    bundleIdentifier?: string;
    cssOverrides?: string;
    template_key: string;
    promoted_by_text: string;
    size: size;
    site_key: string;
    publisher_key: string;
    max_headline_length: number;
    hasCustomTemplate: boolean;
  };
  hasCustomTemplate: boolean;
};

export type RawResponse = {
  adserverRequestId: string;
  cookieSyncUrls: string[];
  htmlBeacons: string[];
  cpm: number | null;
  creatives: RawCreativeWrapper[];
  placement: RawPlacement;
  supplyId: string;
  country?: string;
  nativeAssets: RawNativeAssets;
};

type _ButlerResponse = {
  adserverRequestId: AdserverRequestId;
  auctionWinId: string;
  cookieSyncUrls: string[];
  htmlBeacons: string[];
  cpm: number | null;
  supplyId: string;
  country?: string;
};

export type MutableButlerResponse = _ButlerResponse & {
  creative: MutableButlerCreative;
  placement: ButlerPlacement.T;
};

export type ButlerResponse = _ButlerResponse & {
  creative: ButlerCreative;
  placement: Readonly<ButlerPlacement.T>;
};

export type T = ButlerResponse;

const DEFAULT_BEHAVIORS: Behaviors = {
  enableDoubleVerify: false,
  shouldChangeOptOutUrl: false,
  shouldContainThumbnail: false,
  shouldForceEnhanced: false,
  shouldForceUnenhanced: false,
  shouldGreenPMP: false,
  shouldHideDescription: false,
  shouldHidePromotedByText: false,
  shouldModifyThumbnailWrapperHeight: false,
  shouldNotExpandVideo: false,
  shouldNotPauseOutOfView: false,
  shouldPauseAtFiftyPercentOutOfView: false,
  shouldRelocateAdchoicesLogo: false,
  shouldRemoveAdchoicesLogo: false,
  shouldRenderBannerTemplate: false,
  shouldRenderCaptions: false,
  shouldRenderControlSoundButtonOnLeft: false,
  shouldRenderEnhancedTemplateDescription: false,
  shouldRenderFixedSizeBanner: false,
  shouldRenderTemplateInFrench: false,
  shouldRenderVideoOnly: false,
  shouldRenderWithPostscribe: false
};

const BUTLER_BEHAVIOR_MAP: BehaviorMap = {
  shouldContainThumbnail: { shouldContainThumbnail: true },
  shouldForceEnhanced: { shouldForceEnhanced: true },
  shouldForceUnenhanced: { shouldForceUnenhanced: true },
  shouldGreenPMP: { shouldGreenPMP: true },
  shouldHideDescription: { shouldHideDescription: true },
  shouldHidePromotedByText: { shouldHidePromotedByText: true },
  shouldNotPauseOutOfView: { shouldNotPauseOutOfView: true },
  shouldPauseAtFiftyPercentOutOfView: { shouldPauseAtFiftyPercentOutOfView: true },
  shouldRemoveAdchoicesLogo: { shouldRemoveAdchoicesLogo: true },
  shouldRenderBannerTemplate: { shouldRenderBannerTemplate: true },
  shouldRenderCaptions: { shouldRenderCaptions: true },
  shouldRenderVideoOnly: { shouldRenderVideoOnly: true },
  shouldEnableDoubleVerify: { enableDoubleVerify: true }
};

export const getDefaultBehaviors = (creative: any): Behaviors => {
  let behaviors: Behaviors = { ...DEFAULT_BEHAVIORS };

  const enabledBehaviors = creative.behaviors;
  if (!enabledBehaviors || !Array.isArray(enabledBehaviors)) {
    return behaviors;
  }

  return enableBehaviors(behaviors, enabledBehaviors, BUTLER_BEHAVIOR_MAP);
};

const getCreative = (response: RawResponse) => {
  const creative = response.creatives.length > 0 ? response.creatives[0] : null;
  if (!creative) {
    throw new Error('No creatives in butler response');
  }
  return creative;
};

export const build = (response: RawResponse, placementKey = '', jsTrackers = []): ButlerResponse => {
  ResponseStore.setRawResponse(response);

  if (ENV === 'dev') {
    console.log('response:', response);
  }

  const creativeWrapper = getCreative(response);
  let isi: ISI = null;

  if (creativeWrapper.creative && creativeWrapper.creative.isi) {
    isi = {
      text: creativeWrapper.creative.isi.text || '',
      autoscroll: !!creativeWrapper.creative.isi.autoscroll
    };
  }

  // These are a temporary fix to replace the old imgix domain with the sharethrough CDN.
  // // once the NAG workaround has been fully deprecated, we can remove this.
  if (creativeWrapper.creative.thumbnail_url?.match('str-thumbnails-production.imgix.net')) {
    creativeWrapper.creative.thumbnail_url = creativeWrapper.creative.thumbnail_url.replace(
      'str-thumbnails-production.imgix.net',
      'static.sharethrough.com'
    );
  }
  if (
    ['carousel', 'slideshow'].includes(creativeWrapper.creative.action) &&
    creativeWrapper.creative.slides[0]?.thumbnail_url?.match('str-thumbnails-production.imgix.net')
  ) {
    creativeWrapper.creative.slides = creativeWrapper.creative.slides.map((slide: { [key: string]: any }) => {
      slide.thumbnail_url = slide.thumbnail_url.replace(
        'str-thumbnails-production.imgix.net',
        'static.sharethrough.com'
      );
      return slide;
    });
  }

  let srt = creativeWrapper.srt || creativeWrapper.creative.srt;
  if (
    creativeWrapper.creative.action === 'hosted-video' ||
    creativeWrapper.creative.action === 'native-outstream' ||
    creativeWrapper.creative.action === 'outstream'
  ) {
    // regex to test if srt is a stringified js object
    if (srt && /{.*:/.test(srt)) {
      try {
        srt = JSON.parse(srt);
      } catch (error) {
        srt = '';
        sample(error, {
          function: 'src/modules/butler/response:build',
          message: `Invalid srt data in butler response: ${srt}`,
          adserverRequestId: response.adserverRequestId
        });
      }
    }
  }

  let jsTracker = [...normalizeJsTracker(creativeWrapper.creative.jsTracker), ...jsTrackers];
  let doubleVerifyTracker = null;
  const dvTrackers = jsTracker.filter((url) => url.indexOf('cdn.doubleverify.com') >= 0);
  if (dvTrackers) {
    doubleVerifyTracker = dvTrackers[0];
  }

  const brandDisclosure = response.placement.placementAttributes.promoted_by_text;

  const placement = ButlerPlacement.build(placementKey, response);

  // TODO (2022-10-17): if we're able to prepend https for all (not just in-app) we should
  // just do this in the `firePixel` function
  if (placement && !!placement.bundleIdentifier) {
    for (let key in creativeWrapper.creative.beacons) {
      creativeWrapper.creative.beacons[key] = creativeWrapper.creative.beacons[key].map((beacon: string) => {
        if (beacon.indexOf('//') == 0) {
          return `https:${beacon}`;
        } else {
          return beacon;
        }
      });
    }
  }

  let creative = {
    action: creativeWrapper.creative.action,
    adm: creativeWrapper.creative.adm,
    advertiser: creativeWrapper.creative.advertiser,
    advertiserKey: creativeWrapper.creative.advertiser_key,
    customEngagementUrl: creativeWrapper.creative.custom_engagement_url,
    customEngagementLabel: creativeWrapper.creative.custom_engagement_label,
    customEngagementUrlToken: creativeWrapper.creative.custom_engagement_url_token || '',
    customEngagementUrlNonce: creativeWrapper.creative.custom_engagement_url_nonce || '',
    ctaText: creativeWrapper.creative.ctatext,
    dcoAssetUrl: creativeWrapper.creative.dco_asset_url,
    description: creativeWrapper.creative.description,
    title: creativeWrapper.creative.title,
    promotedByText: brandDisclosure,
    creativeKey: creativeWrapper.creative.creative_key,
    beacons: {
      impression: creativeWrapper.creative.beacons.impression || [],
      visible: creativeWrapper.creative.beacons.visible || [],
      play: creativeWrapper.creative.beacons.play || [],
      click: creativeWrapper.creative.beacons.click || [],
      silent_play: creativeWrapper.creative.beacons.silent_play || [],
      ten_second_silent_play: creativeWrapper.creative.beacons.ten_second_silent_play || [],
      fifteen_second_silent_play: creativeWrapper.creative.beacons.fifteen_second_silent_play || [],
      thirty_second_silent_play: creativeWrapper.creative.beacons.thirty_second_silent_play || [],
      first_quartile: creativeWrapper.creative.beacons.first_quartile || [],
      midpoint: creativeWrapper.creative.beacons.midpoint || [],
      third_quartile: creativeWrapper.creative.beacons.third_quartile || [],
      completed_silent_play: creativeWrapper.creative.beacons.completed_silent_play || [],
      'win-notification': creativeWrapper.creative.beacons['win-notification'] || [],
      'video-impression': creativeWrapper.creative.beacons['video-impression'] || [],
      'video-win-notification': creativeWrapper.creative.beacons['video-win-notification'] || []
    },
    behaviors: getDefaultBehaviors(creativeWrapper.creative),
    brandLogoUrl: creativeWrapper.creative.brand_logo_url || '',
    campaignKey: creativeWrapper.creative.campaign_key,
    contentUrl: creativeWrapper.creative.content_url,
    forceClickToPlay: !!creativeWrapper.creative.force_click_to_play,
    experiments: undefined,
    mediaUrl: creativeWrapper.creative.media_url,
    mediaUrlToken: creativeWrapper.creative.media_url_token || '',
    mediaUrlNonce: creativeWrapper.creative.media_url_nonce || '',
    thumbnailUrl: creativeWrapper.creative.thumbnail_url,
    optOutUrl: creativeWrapper.creative.opt_out_url,
    optOutText: creativeWrapper.creative.opt_out_text,
    sourceId: creativeWrapper.creative.source_id,
    vastUrl: creativeWrapper.creative.vast_url || null,
    dealId: creativeWrapper.creative.deal_id,
    nudgeEnabled: creativeWrapper.creative.nudge_enabled,
    clickoutSamePage: creativeWrapper.creative.clickout_same_page,
    doubleVerifyTracker: doubleVerifyTracker,
    jsTracker: jsTracker,
    scrollDirection: creativeWrapper.creative.scrollDirection,
    size: {
      width: creativeWrapper.creative.size?.w || null,
      height: creativeWrapper.creative.size?.h || null
    },
    slides: (creativeWrapper.creative.slides || []).map((slideFromButler: { [key: string]: any }) => ({
      thumbnailUrl: slideFromButler.thumbnail_url,
      sortOrder: slideFromButler.sort_order,
      headline: slideFromButler.headline,
      description: slideFromButler.description,
      mediaUrl: slideFromButler.media_url,
      mediaUrlToken: slideFromButler.media_url_token || '',
      mediaUrlNonce: slideFromButler.media_url_nonce || ''
    })),
    slideshowAutoscroll: !!creativeWrapper.creative.slideshow_autoscroll,
    isi: isi,
    // we want to apply the no-crop logic only when thumbnail_fillimages equals 0, hence this weird boolean:
    thumbnailFillImages: creativeWrapper.creative.thumbnail_fillimages === 0,
    bannerAsset: creativeWrapper.creative.banner_asset,
    // STX put srt right under creativeWrapper, so we should support both places for now.
    srt,
    instl: creativeWrapper.creative.instl,
    isClickable: creativeWrapper.creative.is_clickable ?? null,
    language: creativeWrapper.creative.language,
    seatId: creativeWrapper.creative.seat_id,
    forceEnhanced: false,
    enhancementVersionId: creativeWrapper.creative.enhancement_version_id,
    hasEnhancement: creativeWrapper.creative.hasEnhancement,
    nativeAssets: response.nativeAssets
  };

  setNativeAssets(creative.nativeAssets);

  const butlerResponse = {
    fetchDataFailed: false,
    adserverRequestId: response.adserverRequestId,
    auctionWinId: creativeWrapper.auctionWinId,
    cookieSyncUrls: response.cookieSyncUrls || [],
    htmlBeacons: response.htmlBeacons || [],
    cpm: response.cpm,
    supplyId: response.supplyId,
    creative,
    placement,
    country: response.country
  };

  return {
    ...butlerResponse,
    creative: AssetSwap.swap(butlerResponse)
  };
};
