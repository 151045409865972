import { OutstreamCaptionsPayload } from '@sharethrough/groundcontrol-enhancement-flush';
import Beacons from 'modules/beacons/utils';
import { HostedVideoCreative, VastVideoCreative } from 'modules/butler/creative';
import { CaptionsContext } from 'modules/enhancements/captions/captionsProvider';
import { FunctionComponent, h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { useButler, useEnhancement } from 'tag/models/ad-experiences/hooks';
import Advertiser from '../../advertiser';
import HeadlineEnhancement from '../headline/headline.component';
import './captions.scss';

const hasInnerCreativeId = (
  enhancement: OutstreamCaptionsPayload | null,
  creative: VastVideoCreative | HostedVideoCreative,
  innerCreativeId: string
) => {
  if (!enhancement) {
    return Object.keys(creative.srt).includes(innerCreativeId);
  }
  return Object.keys(enhancement.payload.srt).includes(innerCreativeId);
};

const CaptionsEnhancement: FunctionComponent = () => {
  const butlerResponse = useButler();
  const captions = useContext(CaptionsContext);
  if (!butlerResponse || !captions) {
    return null;
  }

  const { enhancement } = useEnhancement<OutstreamCaptionsPayload>();
  const [captionArray, setCaption] = useState<string[]>(['']);

  useEffect(() => {
    if (!captions) return;
    const { currentSubtitle } = captions;
    if (currentSubtitle) {
      if (currentSubtitle.text !== captionArray[captionArray.length - 1])
        setCaption((prevState) => {
          return [...prevState, currentSubtitle.text];
        });
    } else {
      if (captionArray[captionArray.length - 1] !== '')
        setCaption((prevState) => {
          return [...prevState, ''];
        });
    }
  }, [captions?.currentSubtitle]);

  // not the preferred approach but this fallbacks to the default enhancement when there are no captions for the current innerCreativeId
  if (!hasInnerCreativeId(enhancement, butlerResponse.creative as any, captions.innerCreativeId)) {
    return <HeadlineEnhancement />;
  }

  Beacons.fire.videoSubtitlesEnabled(butlerResponse.adserverRequestId, true);

  return (
    <div className="str-enhanced-info">
      <div className="str-text str-captions">
        <div style={{ display: 'flex', textAlign: 'center' }}>
          {captionArray.map((caption, index) => (
            <div
              style={{ flex: 1 }}
              className={`str-title str-caption-container str-${index === captionArray.length - 1 ? 'current' : 'prev'}-caption`}
              key={index}
            >
              {caption}
            </div>
          ))}
        </div>
      </div>
      <div className="str-bottom">
        <Advertiser />
      </div>
    </div>
  );
};

export default CaptionsEnhancement;
