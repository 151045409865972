import { FontFamilyStyle } from '../model/font-family';

export const loadFontFamily = (font: FontFamilyStyle) => {
  const fontFace = font.map((font) => {
    return new FontFace(font.family, `url(${font.url})`, {
      style: font.style,
      weight: font.weight
    });
  });
  fontFace.forEach((fontFace) => {
    loadFontFace(fontFace);
  });
};

async function loadFontFace(fontFace: FontFace) {
  const loadedFont = await fontFace.load();
  document.fonts.add(loadedFont);
}
