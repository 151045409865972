import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

type DoubleVerifyTypes =
  | BeaconType.doubleVerifyError
  | BeaconType.doubleVerifyFallback
  | BeaconType.doubleVerifyRender
  | BeaconType.doubleVerifySuccess;

const dispatch = (arid: AdserverRequestId, type: DoubleVerifyTypes) => {
  return new Beacon({
    data: {
      type,
      arid,
      ...getBaseParams(arid)
    }
  });
};

export const dispatchDoubleVerify = {
  error: (arid: AdserverRequestId) => dispatch(arid, BeaconType.doubleVerifyError),
  fallback: (arid: AdserverRequestId) => dispatch(arid, BeaconType.doubleVerifyFallback),
  render: (arid: AdserverRequestId) => dispatch(arid, BeaconType.doubleVerifyRender),
  success: (arid: AdserverRequestId) => dispatch(arid, BeaconType.doubleVerifySuccess)
};
