export const publisherStyles: { [key: string]: string } = {
  dummy_test: 'test', //TODO just for test purpose.
  bustle_test: 'bustle',
  elitedaily_test: 'elitedaily',
  foodnetwork_test: 'foodnetwork',
  rollingstone_test: 'rollingstone',
  forbes_test: 'forbes',
  hgtv_test: 'hgtv',
  evenintstandard_test: 'evenintstandard'
};
