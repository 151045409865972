const logWithLabel = (label: string, message: any, ...rest: any[]) => {
  console.log(`STR:${label}`, message, ...rest);
};

export const debug = (message: any, ...rest: any[]) => {
  logWithLabel('debug', message, ...rest);
};

export const warn = (message: any, ...rest: any[]) => {
  logWithLabel('warn', message, ...rest);
};

export const error = (message: any, ...rest: any[]) => {
  logWithLabel('error', message, ...rest);
};
