import { Signal, signal } from '@preact/signals';

export interface IVastVideoSignalState {
  autoPlay: Signal<boolean>;
  currentTime: Signal<number>;
  inView: Signal<boolean>;
  muted: Signal<boolean>;
  resetValues: () => void;
  resetVideoValues: () => void;
  videoCompleted: Signal<boolean>;
  videoLoaded: Signal<boolean>;
  videoPlaying: Signal<boolean>;
  videoReplayed: Signal<boolean>;
  videoSrc: Signal<string | undefined | null>;
  videoStarted: Signal<boolean>;
}

export const VastVideoSignalState: IVastVideoSignalState = {
  autoPlay: signal(false),
  currentTime: signal(0),
  inView: signal(true),
  muted: signal(true),
  videoCompleted: signal(false),
  videoLoaded: signal(false),
  videoPlaying: signal(false),
  videoReplayed: signal(false),
  videoStarted: signal(false),
  videoSrc: signal(undefined),
  resetVideoValues: () => {
    VastVideoSignalState.videoCompleted.value = false;
    VastVideoSignalState.videoStarted.value = false;
    VastVideoSignalState.videoPlaying.value = false;
  },
  resetValues: () => {
    VastVideoSignalState.autoPlay.value = false;
    VastVideoSignalState.inView.value = true;
    VastVideoSignalState.muted.value = true;
    VastVideoSignalState.videoCompleted.value = false;
    VastVideoSignalState.videoLoaded.value = false;
    VastVideoSignalState.videoPlaying.value = false;
    VastVideoSignalState.videoReplayed.value = false;
    VastVideoSignalState.videoStarted.value = false;
    VastVideoSignalState.videoSrc.value = undefined;
  }
};
