import { Store } from 'modules/store/Store';
import { v4 } from 'uuid';
import { BeaconOptions } from '../types';

const store = new Store<{ gcid: string }>({ gcid: '' });

export const setGroundControlInstanceId = () => {
  const gcid = v4();
  store.setState({ gcid });
};

export const getGroundControlInstanceId = (): BeaconOptions => {
  return store.getState();
};
