import { experimentManager } from './experiment-manager';
import { ENHANCED, UNENHANCED } from './types';

export const DISPLAY_ALWAYS_ON = experimentManager.create({
  id: 'cd4fe9ef-e23e-45d7-9693-c5a427721119',
  variants: [ENHANCED, UNENHANCED],
  volume: 0,
  criteria: ({ butlerResponse }) => {
    return butlerResponse.creative.action === 'banner';
  }
});

export const OUTSTREAM_ALWAYS_ON = experimentManager.create({
  id: '7c1925bf-9ffe-4910-bd5a-f13fa95260c7',
  variants: [ENHANCED, UNENHANCED],
  volume: 0,
  criteria: ({ butlerResponse }) => {
    return butlerResponse.creative.action === 'native-outstream';
  }
});

export const DISPLAY_ENHANCEMENT_DISABLING = experimentManager.create({
  id: 'b55f8d3c-600c-4240-b053-57f364a28293',
  variants: [ENHANCED, UNENHANCED],
  volume: 0.1,
  criteria: ({ butlerResponse }) => {
    const combinations: {
      [key: string]: string[];
    } = {
      '160x600': ['160x600'],
      '300x250': ['320x50'],
      '300x600': ['160x600']
    };

    const { size: creative } = butlerResponse.creative;
    const { size: placement } = butlerResponse.placement;
    const placementSize = `${placement.width}x${placement.height}`;
    const creativeSize = `${creative.width}x${creative.height}`;

    const placementExist = combinations[placementSize];

    if (placementExist) {
      return placementExist.includes(creativeSize);
    }

    return false;
  }
});
