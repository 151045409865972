import { FunctionComponent, h } from 'preact';
import { useButler } from 'tag/models/ad-experiences/hooks';
import Advertiser from '../../advertiser';
import { CallToAction } from '../../cta/cta.component';
import EnhancedText from '../enhanced-text/enhanced-text.component';
import ISI from '../isi/isi.component';

const HeadlineEnhancement: FunctionComponent = () => {
  const butlerResponse = useButler();
  if (!butlerResponse) {
    return null;
  }
  return (
    <div className="str-enhanced-info">
      <EnhancedText />
      <ISI />
      <div className="str-bottom">
        <Advertiser />
        <CallToAction />
      </div>
    </div>
  );
};

export default HeadlineEnhancement;
