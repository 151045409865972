import { CreativeType } from 'tag/models/common/common.model';

export enum SizeType {
  Wide = 'wide',
  Widest = 'widest',
  Tall = 'tall',
  Tallest = 'tallest',
  Square = 'square'
}

export const ResponsiveAds = [CreativeType.Banner, CreativeType.Clickout, CreativeType.NativeOutstream];
