export default [
  {
    seatId: '1456',
    sourceId: '5b286190338513af73f09c28'
  },
  {
    seatId: '5038',
    sourceId: '5b286190338513af73f09c28'
  },
  {
    seatId: '3889',
    sourceId: '5b286190338513af73f09c28'
  }
];
