import { GCEnhancementPayload } from '@sharethrough/groundcontrol-enhancement-flush';
import {
  setEnhancementFetchLatencyEndTime,
  setEnhancementFetchLatencyStartTime
} from 'modules/beacons/beacons/utils/performanceTimeStore';
import { ButlerResponse } from 'modules/butler/response';
import { sample } from 'modules/monitoring';
import { fetchEnhancement } from './fetchEnhancement';
import {
  EnhancementError,
  EnhancementErrorType,
  SmartEnhancementPayload,
  isSmartEnhancementPayload
} from './loadEnhancement.type';

export const loadEnhancement = async (butlerResponse: ButlerResponse): Promise<GCEnhancementPayload | null> => {
  if (!butlerResponse.creative.hasEnhancement || butlerResponse.creative.isClickable === false) {
    return null;
  }

  try {
    setEnhancementFetchLatencyStartTime();
    const enhancementsPayload = await fetchEnhancement(butlerResponse);
    if (!enhancementsPayload) {
      const error = new EnhancementError(EnhancementErrorType.NO_ENHANCEMENTS_PAYLOAD, 'No enhancements payload');
      sample(error, {
        adserverRequestId: butlerResponse.adserverRequestId
      });

      throw error;
    }

    // Select the enhancement payload based on the dealId
    const payload = enhancementsPayload[butlerResponse.creative.dealId] || enhancementsPayload['open_exchange'];

    // If the payload is a weighted enhancement payload, select a variant based on the weight, otherwise return the payload
    return isSmartEnhancementPayload(payload) ? selectVariant(payload) : payload;
  } catch (e) {
    if (e instanceof EnhancementError) throw e;

    const error = new EnhancementError(EnhancementErrorType.FETCH_DATA_FAILED, `Failed to fetch enhancements: ${e}`);
    sample(error, {
      adserverRequestId: butlerResponse.adserverRequestId
    });

    throw e;
  } finally {
    setEnhancementFetchLatencyEndTime();
  }
};

const selectVariant = (payload: SmartEnhancementPayload) => {
  const totalWeight = payload.variants.reduce((acc, variant) => acc + variant.weight, 0);
  const random = Math.random() * totalWeight;

  let sum = 0;
  for (const variant of payload.variants) {
    sum += variant.weight;
    if (random < sum) {
      console.log('variant', variant);
      return variant;
    }
  }

  return null;
};
