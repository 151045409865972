import 'intersection-observer';
import { AuditTimer } from 'modules/audit';
import Cannon from 'modules/beacons/cannon';
import Beacons from 'modules/beacons/utils';
import ResponseStore from 'modules/response/response_store';
import { CreativeType } from 'tag/models/common/common.model';

const intersectionRatio = 0.5;

const allAds = new Map();
let visibleAds = new Set();
let previouslyVisibleAds = new Set();

class InViewTracker {
  static startup() {
    document.addEventListener('visibilitychange', InViewTracker.handleVisibilityChange, false);
  }

  static handleVisibilityChange() {
    if (document.hidden) {
      if (previouslyVisibleAds.size === 0) {
        previouslyVisibleAds = new Set(visibleAds);
        visibleAds.clear();
        previouslyVisibleAds.forEach(function (adBox: HTMLElement) {
          const trackedUnit = ViewTrackedAdUnit.retrieve(adBox);
          trackedUnit.comeOutOfView();
        });
      }
    } else {
      previouslyVisibleAds.forEach(function (adBox: HTMLElement) {
        const trackedUnit = ViewTrackedAdUnit.retrieve(adBox);
        trackedUnit.comeInView();
      });
      visibleAds = new Set(previouslyVisibleAds);
      previouslyVisibleAds.clear();
    }
  }

  static intersectionCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(function (entry) {
      let adBox = entry.target as HTMLElement;
      const trackedUnit = ViewTrackedAdUnit.retrieve(adBox);

      if (entry.isIntersecting) {
        if (entry.intersectionRatio >= intersectionRatio) {
          trackedUnit.comeInView();
          visibleAds.add(adBox);
        } else {
          trackedUnit.comeOutOfView();
          visibleAds.delete(adBox);
        }
      } else {
        trackedUnit.comeOutOfView();
        visibleAds.delete(adBox);
      }
    });
  }

  // Used in spec only
  static getVisibleAds() {
    return visibleAds;
  }

  // Used in spec only
  static getPreviouslyVisibleAds() {
    return previouslyVisibleAds;
  }

  // Used in spec only
  static resetTrackedAds() {
    allAds.clear();
    visibleAds.clear();
    previouslyVisibleAds.clear();
  }
}

const generateKey = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

const buildViewTrackedUnit = (element: HTMLElement, arid: string): ViewTrackedAdUnit => {
  adObserver = new IntersectionObserver(InViewTracker.intersectionCallback, {
    root: null,
    rootMargin: '0px',
    threshold: [intersectionRatio]
  });

  return new ViewTrackedAdUnit(element, arid, adObserver);
};

class ViewTrackedAdUnit {
  element: HTMLElement;
  visibleFired: boolean;
  arid: string;
  timer: number | undefined;
  inViewTime: number = 1000;

  constructor(element: HTMLElement, arid: string, observer: IntersectionObserver) {
    this.element = element;
    this.visibleFired = false;
    this.arid = arid;
    this.timer = undefined;

    const { creative } = ResponseStore.getResponse(arid);
    if (
      [CreativeType.HostedVideo, CreativeType.Outstream, CreativeType.Video, CreativeType.NativeOutstream].includes(
        creative.action
      )
    ) {
      this.inViewTime = 2000;
    }

    if (!element)
      // some browsers seem to lose the reference to the element, in which case there's no point tracking visibility
      // https://www.pivotaltracker.com/story/show/175752318
      return;

    const key = generateKey();

    element.dataset.trackingKey = key;
    allAds.set(key, this);
    observer.observe(element);
  }

  static retrieve(element: HTMLElement): ViewTrackedAdUnit {
    return allAds.get(element.dataset.trackingKey);
  }

  comeInView(): void {
    if (!this.visibleFired) {
      const that = this;
      this.timer = window.setTimeout(() => {
        AuditTimer.retrieve('visible', that.arid).stop();
        that.fireVisible();
      }, this.inViewTime);
    }
  }

  comeOutOfView(): void {
    window.clearTimeout(this.timer);
    this.timer = undefined;
  }

  fireVisible(): void {
    const butlerResponse = ResponseStore.getResponse(this.arid);

    Beacons.fire.viewableImpression(this.arid);

    this.visibleFired = true;

    // Third Party Visible Beacons
    butlerResponse.creative.beacons.visible.forEach((url: string) => {
      Cannon.firePixel(url);
    });
  }
}

let adObserver: IntersectionObserver | null = null;

export { InViewTracker, ViewTrackedAdUnit, buildViewTrackedUnit };
