import { FunctionComponent, h } from 'preact';

export enum StarType {
  Full = 'full',
  Half = 'half',
  Empty = 'empty'
}

interface StarProps {
  type: StarType;
}

export const Star: FunctionComponent<StarProps> = ({ type }) => {
  if (!Object.values(StarType).includes(type)) return null;

  return (
    <svg
      data-testid={`star-${type}`}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 19 19"
      width="24px"
      height="24px"
      shape-rendering="geometricPrecision"
    >
      {type === 'full' && (
        <path
          d="M9.5,1.325L12.104,6.581L17.922,7.423L13.711,11.511L14.706,17.286L9.5,14.56L4.293,17.286L5.288,11.511L1.077,7.423L6.895,6.581L9.5,1.325Z"
          style={{ fill: 'url("#_starGradient_5pm7gjqod")' }}
        ></path>
      )}

      {type === 'half' && (
        <path
          d="M9.49,1.345L9.49,14.565L4.293,17.286L5.288,11.511L1.077,7.423L6.895,6.581L9.49,1.345Z"
          style={{ fill: 'url("#_starGradient_5pm7gjqod")' }}
        ></path>
      )}

      {type === 'empty' && (
        <path
          d="M9.5,1.325L12.104,6.581L17.922,7.423L13.711,11.511L14.706,17.286L9.5,14.56L4.293,17.286L5.288,11.511L1.077,7.423L6.895,6.581L9.5,1.325Z"
          style={{ fill: 'white' }}
        ></path>
      )}

      <path
        d="M9.5,1.325L12.104,6.581L17.922,7.423L13.711,11.511L14.706,17.286L9.5,14.56L4.293,17.286L5.288,11.511L1.077,7.423L6.895,6.581L9.5,1.325Z"
        style={{ fill: 'none', stroke: 'rgb(162, 106, 0)', strokeWidth: 1 }}
      ></path>
      <defs>
        <linearGradient
          id="_starGradient_5pm7gjqod"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(7.07124e-16,11.5482,-11.5482,7.07124e-16,9.4995,5.53952)"
        >
          <stop offset="0" style={{ stopColor: 'rgb(255, 206, 0)', stopOpacity: 1 }}></stop>
          <stop offset="1" style={{ stopColor: 'rgb(255, 167, 0)', stopOpacity: 1 }}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
