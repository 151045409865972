import { BeaconBase } from './BeaconBase';

class BeaconQueue {
  static instance: BeaconQueue;
  processingInterval: number;
  batchSize: number;
  isProcessing: boolean;
  queue: BeaconBase[] = [];
  cache = new Set<string>();

  constructor(batchSize = 5, processingInterval = 300) {
    this.queue = [];
    this.isProcessing = false;
    this.batchSize = batchSize;
    this.processingInterval = processingInterval;
    window.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  static getInstance(): BeaconQueue {
    if (!BeaconQueue.instance) {
      BeaconQueue.instance = new BeaconQueue();
    }

    return BeaconQueue.instance;
  }

  enqueue(beacon: BeaconBase): void {
    const fireOnce = beacon.fireOnce;
    const cacheKey = beacon.cacheKey;

    if (fireOnce && cacheKey && this.cache.has(cacheKey)) {
      return;
    }

    this.queue.push(beacon);

    if (!this.isProcessing) {
      this.processQueue();
    }
  }

  handleVisibilityChange = (): void => {
    if (document.defaultView?.document.visibilityState === 'hidden') {
      this.processQueue();
    }
  };

  async processQueue(): Promise<void> {
    this.isProcessing = true;

    if (this.queue.length === 0) {
      this.isProcessing = false;
      return;
    }

    const batch = this.queue.splice(0, this.batchSize);

    const promises = batch.map(async (beacon) => {
      const fireOnce = beacon.fireOnce;
      const cacheKey = beacon.cacheKey;

      if (fireOnce && cacheKey && this.cache.has(cacheKey)) {
        return;
      }

      const success = await beacon.sendBeacon();

      if (success && beacon.cacheKey) {
        this.cache.add(beacon.cacheKey);
      }
    });

    await Promise.all(promises);

    await new Promise((resolve) => setTimeout(resolve, this.processingInterval));

    await this.processQueue();
  }

  reset() {
    this.queue = [];
    this.cache = new Set<string>();
    this.isProcessing = false;

    window.removeEventListener('visibilitychange', this.handleVisibilityChange);

    BeaconQueue.instance = new BeaconQueue();
  }
}

export default BeaconQueue.getInstance();
