import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType, UserEvent } from '../types';
import { getBaseParams, getElementParams, getGroundControlInstanceId } from '../utils';

export const dispatchUserClick = (arid: AdserverRequestId, element: HTMLElement) => {
  return new Beacon({
    data: {
      arid,
      type: BeaconType.userEvent,
      userEvent: UserEvent.Clickout,
      gcid: getGroundControlInstanceId().gcid,
      ...getBaseParams(arid),
      ...getElementParams(element)
    }
  });
};
