import { useButler } from 'tag/models/ad-experiences/hooks';
import { CreativeType } from 'tag/models/common/common';
import { nativeAssets } from './signal';

export const useNativeAssets = () => {
  const butlerResponse = useButler();

  if (!butlerResponse) return null;
  if (butlerResponse.creative.action !== CreativeType.Clickout) return null;

  return nativeAssets.value;
};
