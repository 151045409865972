import { DurationKey } from 'tag/models/common/common';
import { Beacon } from '../Beacon';
import { dispatchSilentPlayNotifications } from '../third-party/silentPlayNotifications';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchSilentAutoPlayDuration = (arid: AdserverRequestId, durationThreshold?: DurationKey) => {
  dispatchSilentPlayNotifications(arid, durationThreshold);

  return new Beacon({
    data: {
      type: BeaconType.silentAutoPlayDuration,
      duration: Number(durationThreshold) * 1000,
      arid,
      ...getBaseParams(arid)
    }
  });
};
