import Beacons from 'modules/beacons/utils';
import { ButlerResponse } from 'modules/butler/response';
import { isTestEnvironment } from 'modules/environment-info/isTestEnvironment';
import { debug } from 'modules/log';
import { objToQueryString, QueryArgs, replaceCacheBusterParam } from 'modules/utils';
import 'tag/polyfill/object_assign';
import { stringParamsToObject } from './beacons/utils/stringParamsToObject';

const beaconPrioritizedParams: { [index: string]: number } = {
  type: 1,
  arid: 2,
  pkey: 3,
  supplyId: 4,
  tkey: 5,
  ckey: 6,
  userEvent: 7,
  umtime: 8
};

function firePixel(url: string, listenForError = false): Promise<void> {
  // Don't fire beacons in test environments
  if (isTestEnvironment()) {
    const params = stringParamsToObject(url);
    if (params) {
      if (params.type) {
        debug(`Internal Beacon - ${params.type}`, params);
      } else {
        debug('3rd Party Beacon', url);
      }
    }
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(), { once: true });
    if (listenForError) {
      img.addEventListener('error', (event) => reject(event), { once: true });
    }
    img.src = replaceCacheBusterParam(url);
  });
}

function fireBeacon(queryArgs: QueryArgs, butlerResponse: ButlerResponse, element?: HTMLElement): Promise<void> {
  const beaconUrl = generateRequest({
    ...queryArgs,
    ...Beacons.defaultParams(butlerResponse, element)
  });

  return Cannon.firePixel(beaconUrl);
}

// Used to fire beacons when no butlerResponse is present
function fireSimpleBeacon(queryArgs: QueryArgs): Promise<void> {
  const beaconUrl = generateRequest({
    ...queryArgs
  });
  return Cannon.firePixel(beaconUrl);
}

function generateRequest(queryArgs: QueryArgs): string {
  const resource = 'butler';
  const args = Object.assign({}, queryArgs, Beacons.preResponseParams());
  return `${CONFIG.trackingDomain}/${resource}?${objToQueryString(args, beaconPrioritizedParams)}`;
}

function fireWinNotificationError(src: string, butlerResponse: ButlerResponse): Promise<void> {
  return Cannon.fireBeacon(
    {
      type: Beacons.types.NurlFail,
      nurl: src
    },
    butlerResponse
  );
}

function fireVideoWinNotification(butlerResponse: ButlerResponse): Promise<void> {
  const urls = butlerResponse.creative.beacons['video-win-notification'] || [];
  return fireWinUrls(urls, butlerResponse);
}

function fireWinNotification(butlerResponse: ButlerResponse): Promise<void> {
  const urls = butlerResponse.creative.beacons['win-notification'] || [];
  return fireWinUrls(urls, butlerResponse);
}

function fireVideoImpression(butlerResponse: ButlerResponse): Promise<void> {
  const urls = butlerResponse.creative.beacons['video-impression'] || [];
  return fireWinUrls(urls, butlerResponse, false);
}

function fireWinUrls(urls: string[], butlerResponse: ButlerResponse, handleErrors: boolean = true): Promise<void> {
  return Promise.all(
    urls.map((nurl) => {
      Cannon.firePixel(nurl, handleErrors).catch((error: any) => {
        console.log('Error firing win notification', error);
        Cannon.fireWinNotificationError(nurl, butlerResponse).catch(() => {
          console.warn('Unable to fire win notification error');
        });
      });
    })
  ).then(() => {
    // allows us to enforce Promise<void> return type instead of Promise<void[]>
    return;
  });
}

function fireCustomEngagement(butlerResponse: ButlerResponse): Promise<void> {
  return Cannon.fireBeacon(
    {
      type: Beacons.types.UserEvent,
      share: Beacons.shareTypes.custom,
      engagement: true,
      userEvent: Beacons.types.Share
    },
    butlerResponse
  );
}

let Cannon = {
  fireBeacon,
  fireSimpleBeacon,
  fireCustomEngagement,
  firePixel,
  fireWinNotification,
  fireWinNotificationError,
  fireVideoImpression,
  fireVideoWinNotification,
  generateRequest
};

if (GENERATOR) {
  Cannon = {
    fireBeacon: () => Promise.resolve(),
    fireSimpleBeacon: () => Promise.resolve(),
    fireCustomEngagement,
    firePixel: () => Promise.resolve(),
    fireWinNotification,
    fireWinNotificationError,
    fireVideoImpression,
    fireVideoWinNotification,
    generateRequest
  };
}

export default Cannon;
