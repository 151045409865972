// See https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
const validb64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
export function encodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, toSolidBytes));
}

// helper method to get an object from an encoded json string
export function getJsonObject(obj) {
  if (typeof obj == 'string') {
    return JSON.parse(decodeUnicode(obj));
  } else {
    return obj;
  }
}

export function decodeUnicode(str) {
  try {
    return decodeURIComponent(
      atob(swapUrlSafeB64Symbols(str))
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
  } catch (e) {
    // If str is not utf-8 encoded, we skip the uri encoding
    return atob(swapUrlSafeB64Symbols(str));
  }
  // Going backwards: from bytestream, to percent-encoding, to original string.
}

function swapUrlSafeB64Symbols(str) {
  let mapped = (str + '===').slice(0, str.length + (str.length % 4));
  return mapped.replace(/-/g, '+').replace(/_/g, '/');
}

function toSolidBytes(match, p1) {
  return String.fromCharCode('0x' + p1);
}

export function validb64(str) {
  return validb64Regex.test(str);
}

const Base64 = {
  getJsonObject,
  decodeUnicode,
  encodeUnicode,
  validb64
};

export default Base64;
