import { ButlerResponse } from 'modules/butler/response';
import { FunctionComponent, createContext, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { loadPublisherStyles } from './helper/loadPublisherStyles';
import { PublisherStyles } from './model/publisher-style';
import { PublisherStyleError } from './model/publisher-style-error';

export interface PublisherStylingContext {
  styles: PublisherStyles | undefined;
  error: PublisherStyleError | undefined;
}

export const PublisherStylingContext = createContext<PublisherStylingContext>({
  styles: undefined,
  error: undefined
});

interface IPublisherStylingProviderProps {
  butlerResponse: ButlerResponse;
}

export const PublisherStylingProvider: FunctionComponent<IPublisherStylingProviderProps> = ({
  butlerResponse,
  children
}) => {
  const [styles, setPublisherStyles] = useState<PublisherStyles | undefined>(undefined);
  const [error, setError] = useState<PublisherStyleError | undefined>(undefined);

  useEffect(() => {
    if (!butlerResponse) return;
    loadPublisherStyles(butlerResponse)
      .then(setPublisherStyles)
      .catch((error) => {
        setPublisherStyles(new PublisherStyles());
        setError(error);
      });
  }, [butlerResponse]);

  return <PublisherStylingContext.Provider value={{ styles, error }}>{children}</PublisherStylingContext.Provider>;
};
