import { RefObject } from 'preact';
import { BannerType } from '.';

interface GetClickableElementProps {
  container: RefObject<HTMLDivElement>;
  bannerType: BannerType;
}

const DOUBLECLICK_CLICKOUT_URL = '//adclick.g.doubleclick.net/pcs/click?';
const GOOGLEADS_CLICKOUT_URL = '//googleads.g.doubleclick.net/dbm/clk?';

function getClickableElementQuery(bannerType: BannerType): string {
  if (bannerType === BannerType.Basis) {
    return '[onclick]';
  }

  return 'a:not(.qc-adchoices-link):not([href*="privacy_summary.html"]):not([href*="adssettings.google.com"]):not([href*="privacy.centro.net"]):not([href="#"]):not([href*="info.yahoo.com"])';
}

function isElementVisible(element: HTMLElement): boolean {
  return element.offsetHeight > 0 && element.offsetWidth > 0;
}

function isDoubleclickLink(element: HTMLLinkElement): boolean {
  return !!(element.href?.match(DOUBLECLICK_CLICKOUT_URL) || element.href?.match(GOOGLEADS_CLICKOUT_URL));
}

function checkNestedIframes(
  nestedIframes: NodeListOf<HTMLIFrameElement> | undefined,
  clickableElementQuery: string
): Element | null {
  if (nestedIframes) {
    for (const iterator of nestedIframes) {
      if (iterator.contentDocument) {
        const element = iterator.contentWindow?.document?.querySelector(clickableElementQuery);
        if (element) return element;
      }
    }
  }
  return null;
}

export const getClickableElement = (props: GetClickableElementProps): Element | null => {
  const { container, bannerType } = props;

  if (!container.current) return null;

  const imgElement = container.current.querySelector('img[onclick]');
  const onclick = imgElement?.getAttribute('onclick');

  if (onclick) {
    if (bannerType === BannerType.Xandr && onclick.match(/EBG\.ads/)) return imgElement;
    if (bannerType === BannerType.Rubicon && onclick.match(/window\.open\('http(s)?:\/\/pixel\.mathtag\.com\/click/))
      return imgElement;
  }

  const clickableElementQuery = getClickableElementQuery(bannerType);
  let element: Element | null = container.current.querySelector(clickableElementQuery);

  try {
    if (!element) {
      const iframe = container.current.querySelector('iframe');
      if (iframe?.contentDocument) {
        element = iframe.contentWindow?.document.querySelector(clickableElementQuery) || null;
        if (!element) {
          const nestedIframes: NodeListOf<HTMLIFrameElement> | undefined =
            iframe.contentWindow?.document.querySelectorAll('iframe');
          element = checkNestedIframes(nestedIframes, clickableElementQuery);
        }
      }
    }
  } catch (e) {}

  if (element) {
    if (isDoubleclickLink(element as HTMLLinkElement)) return element;
    if (isElementVisible(element as HTMLElement)) return element;
  }

  return null;
};
