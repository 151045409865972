import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchViewableImpression = (arid: AdserverRequestId) => {
  return new Beacon({
    data: {
      type: BeaconType.viewableImpression,
      arid,
      ...getBaseParams(arid)
    }
  });
};
