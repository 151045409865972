import { HostedVideoCreative } from 'modules/butler/creative';
import * as Log from 'modules/log';
import { sample } from 'modules/monitoring';
import { h } from 'preact';
import {
  AmazonNative,
  Carousel2,
  ClickoutV2,
  EnhancedDisplay2,
  HostedVideo2,
  Scroller2,
  Slideshow,
  VastVideo2
} from '../ad-experiences/ad-experience';
import { CreativeType } from '../common/common';
import ReactAdUnitBase from './ad-unit.base.component';
import { ReactAdUnitProps } from './types';

class ReactAdUnit extends ReactAdUnitBase {
  constructor(props: ReactAdUnitProps) {
    super(props);
  }

  experienceToRender = (): h.JSX.Element | null => {
    const adserverRequestId = {
      adserverRequestId: this.props.butlerResponse.adserverRequestId
    };
    // if DV worked (status === 'success'), render ad experience normally
    if (
      this.props.butlerResponse.creative.behaviors.enableDoubleVerify &&
      this.dvTracker &&
      this.state.doubleVerifyStatus !== 'success'
    ) {
      if (this.state.doubleVerifyStatus === 'init') {
        // In this case render nothing, and let the logic in componentDidMount
        // handle the scripts. This is necessary to render the scripts with
        // postscribe.
        return null;
      } else if (this.state.doubleVerifyStatus === 'fallback') {
        return <ClickoutV2 {...adserverRequestId} onClick={this.publishClickAndRedirect} />;
      }
      // If neither of the above conditions is met, DV says render as normal
    }

    switch (this.props.butlerResponse.creative.action) {
      case CreativeType.Clickout:
        if (this.props.butlerResponse.creative.sourceId === 'MR2ocDuaNCWP75ZJSgTEHjdB')
          return <AmazonNative {...adserverRequestId} onClick={this.publishClickEvent} />;

        return <ClickoutV2 {...adserverRequestId} onClick={this.publishClickAndRedirect} />;
      case CreativeType.Slideshow:
        return <Slideshow {...adserverRequestId} onClick={this.publishClickEvent} />;
      case CreativeType.Banner:
        return <EnhancedDisplay2 {...adserverRequestId} onClick={this.publishClickEvent} />;
      case CreativeType.Scroller:
        return <Scroller2 {...adserverRequestId} onClick={this.publishClickAndRedirect} />;
      case CreativeType.Carousel:
        return <Carousel2 {...adserverRequestId} onClick={this.publishClickEvent} />;
      case CreativeType.NativeOutstream:
      case CreativeType.Outstream:
        return (
          <VastVideo2
            {...adserverRequestId}
            publishClick={this.publishClickEvent}
            onClick={this.publishClickAndRedirect}
          />
        );
      case CreativeType.HostedVideo:
        if ((this.props.butlerResponse.creative as HostedVideoCreative).vastUrl === null) {
          return (
            <HostedVideo2
              {...adserverRequestId}
              publishClick={this.publishClickEvent}
              onClick={this.publishClickAndRedirect}
            />
          );
        } else {
          return (
            <VastVideo2
              {...adserverRequestId}
              publishClick={this.publishClickEvent}
              onClick={this.publishClickAndRedirect}
            />
          );
        }
      default:
        // @ts-ignore
        const e = `creative action ${this.props.butlerResponse.creative!.action} does not match any known types`;
        sample('Creative action error', {
          function: e,
          adserverRequestId: this.props.butlerResponse.adserverRequestId
        });
        Log.error(e);
        throw e;
    }
  };
}

export default ReactAdUnit;
