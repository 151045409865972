import { VastVideoCreative } from 'modules/butler/creative';
import { ButlerResponse } from 'modules/butler/response';
import { BEACONS_REFACTOR, experimentManager, isExperimentForcingGenericEnhancement } from 'modules/experiments';
import LocationUtils from 'modules/location_utils';
import { sample } from 'modules/monitoring';
import { elementDimensions, elementPosition, thumbnailDimensions } from 'modules/page_geometry';
import { shouldDisableEnhancement } from 'modules/policies';
import ResponseStore from 'modules/response/response_store';
import { CreativeType, DurationKey, QuartileKey } from 'tag/models/common/common';
import {
  dispatchBannerRendered,
  dispatchFoursquareImpression,
  dispatchIsEnhanced,
  dispatchVideoPlay,
  dispatchVideoQuartile,
  dispatchVideoStart
} from './beacons';
import { dispatchClickthroughFound } from './beacons/internal/clickThroughFound';
import { dispatchDoubleVerify } from './beacons/internal/doubleVerify';
import { dispatchIframeBusterFailed } from './beacons/internal/iframeBusterFailed';
import { dispatchImaClick } from './beacons/internal/imaClick';
import { dispatchImaNoCreativeIdFound } from './beacons/internal/imaNoCreativeIdFound';
import { dispatchJsTrackerFailedToLoad } from './beacons/internal/jsTrackerFailedToLoad';
import { dispatchPixelVisible } from './beacons/internal/pixelVisible';
import { dispatchSilentAutoPlayDuration } from './beacons/internal/silentAutoPlayDuration';
import { dispatchAutoplayVideoEngagement } from './beacons/internal/userAutoplayVideoEngagement';
import { dispatchUserClick } from './beacons/internal/userClickout';
import { dispatchUserShare } from './beacons/internal/userShare';
import { dispatchVpaidEnabled } from './beacons/internal/vPaidEnabled';
import { dispatchVastError } from './beacons/internal/vastError';
import { dispatchVideoSubtitlesEnabled } from './beacons/internal/videoSubtitlesEnabled';
import { dispatchVideoViewDuration } from './beacons/internal/videoViewDuration';
import { dispatchVideoVolumeOn } from './beacons/internal/videoVolumeOn';
import { dispatchViewableImpression } from './beacons/internal/viewableImpression';
import { getGroundControlInstanceId } from './beacons/utils';
import Cannon from './cannon';

let _tagVersion = CONFIG.version;
let firedBeacons: { [key: string]: string[] } = {};
const hasBeenFired = (adserverRequestId: string, type: string, justOnce: boolean) => {
  if (justOnce) {
    if (!firedBeacons[adserverRequestId]) {
      firedBeacons[adserverRequestId] = [];
    }
    if (firedBeacons[adserverRequestId].indexOf(type) === -1) {
      firedBeacons[adserverRequestId].push(type);
      return false;
    } else {
      return true;
    }
  }
  return false;
};

const Beacons = {
  types: {
    // errors
    Error: 'error',
    IframeBusterFailed: 'iframeBusterFailed',
    JsTrackerFailedToLoad: 'jsTrackerFailedToLoad',

    // stx_network
    ReceivedImpression: 'impressionReceived',
    RenderedImpression: 'impression',
    ViewableImpression: 'visible',

    // signal when gc is loaded / used to render
    GroundControlPresent: 'groundControlPresent',

    // view_tracker beacons
    VideoStart: 'videoStart',
    VideoPlay: 'videoPlay',
    AutoplayVideoEngagement: 'autoplayVideoEngagement',
    SilentAutoPlayDuration: 'silentAutoPlayDuration',
    VideoViewDuration: 'videoViewDuration',
    Share: 'share',
    UserEvent: 'userEvent',
    CompletionPercent: 'completionPercent',

    // // visibility_helper
    Visible: 'visible',
    TimeInView: 'timeInView',
    PixelVisibleInApp: 'pixelVisibleInApp',

    // dco
    DcoRequest: 'DCORequest',

    // client side notifications
    NurlFail: 'winNotificationFail',

    // IMA
    ImaClick: 'imaClick',
    ImaError: 'imaError',
    ImaAutoplayFailure: 'imaAutoplayFailure',
    ImaNoCreativeIdFound: 'imaNoCreativeIdFound',
    VastErrorCode: 'vastErrorCode',

    // Banner
    BannerRendered: 'banner.rendered',
    IsEnhanced: 'isEnhanced',
    BannerRenderExperiment: {
      iframe: 'bannerIframe',
      postscribe: 'bannerPostscribe'
    },

    VideoVolumeOn: 'videoVolumeOn',
    VastClickthrough: {
      found: 'vastClickthroughFound',
      notFound: 'vastClickthroughNotFound'
    },

    VastVpaid: {
      enabled: 'VastVpaidEnabled',
      notEnabled: 'VastVpaidNotEnabled'
    },

    // Double Verify lifecycle
    DoubleVerifyRender: 'doubleVerifyRender',
    DoubleVerifySuccess: 'doubleVerifySuccess',
    DoubleVerifyFallback: 'doubleVerifyFallback',
    DoubleVerifyError: 'doubleVerifyError',

    // Subtitle experiment
    SubtitlesEnabled: 'subtitlesEnabled',

    Experiment: 'is_experiment',
    BundleIdWinTest: 'bundle_id_win_test',
    Base64WinUrl: 'base_64_win_url'
  },

  userEvents: {
    autoplayVideoEngagement: 'autoplayVideoEngagement',
    clickout: 'clickout',
    share: 'share',
    videoPlay: 'videoPlay'
  },

  shareTypes: {
    custom: 'custom'
  },

  videoTypes: {
    instantPlay: 'instant',
    clickToPlay: 'clickToPlay',
    youtube: 'youtube'
  },

  benchmarkTypes: {
    bootTime: 'bootTime',
    renderLatency: 'renderLatency',
    benchmarkBoot: 'benchmarkBoot',
    benchmarkVisible: 'benchmarkVisible'
  },

  iframeBusterFailureTypes: {
    placementTagInsertion: 'tagInsertion',
    iframeNotFound: 'notFound'
  },

  preResponseParams: (): { [key: string]: string } => {
    return {
      renderEnv: LocationUtils.renderEnv(),
      umtime: window.Date.now().toString(),
      version: _tagVersion
    };
  },

  defaultParams: (response: ButlerResponse, element?: HTMLElement): { [key: string]: string } => {
    let elementParams = {};

    if (element) {
      const eDimensions = elementDimensions(element);
      const ePosition = elementPosition(element);
      const thumbDimensions = thumbnailDimensions(element);

      elementParams = {
        pwidth: eDimensions.width.toString(),
        pxoff: ePosition.x?.toString(),
        pyoff: ePosition.y?.toString(),
        twidth: thumbDimensions.width.toString()
      };
    }

    let defaults = {
      action: response.creative.action,
      arid: response.adserverRequestId,
      awid: response.auctionWinId,
      ckey: response.creative.creativeKey,
      pkey: response.placement.key,
      sourceId: response.creative.sourceId,
      supplyId: response.supplyId,
      tkey: response.placement.templateKey,
      gcid: getGroundControlInstanceId().gcid,
      ...elementParams,
      ...Beacons.preResponseParams()
    } as any;

    if (!!response.creative.dealId) {
      defaults.deal_id = response.creative.dealId;
    }

    if (!!response.placement.bundleIdentifier) {
      defaults.bundleId = response.placement.bundleIdentifier;
    }

    if (
      [CreativeType.NativeOutstream, CreativeType.Outstream, CreativeType.HostedVideo].includes(
        response.creative.action
      )
    ) {
      defaults.isVast = (!!(response.creative as VastVideoCreative).vastUrl).toString();
    }

    return defaults;
  },

  setTagVersion: (version: string) => (_tagVersion = version),

  fire: {
    videoPlay: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVideoPlay(adserverRequestId);
        return;
      }

      const type = Beacons.types.VideoPlay;
      if (!hasBeenFired(adserverRequestId, type, justOnce)) {
        Cannon.fireBeacon(
          {
            type,
            engagement: true,
            duration: 0
          },
          ResponseStore.getResponse(adserverRequestId)
        );
      }
    },
    videoStart: (adserverRequestId: string, autoPlay: boolean, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVideoStart(adserverRequestId, autoPlay);
        return;
      }

      const type = Beacons.types.VideoStart;
      if (!hasBeenFired(adserverRequestId, type, justOnce)) {
        const videoType: string = autoPlay ? Beacons.videoTypes.instantPlay : Beacons.videoTypes.clickToPlay;
        const butlerResponse = ResponseStore.getResponse(adserverRequestId);

        Cannon.fireBeacon(
          {
            type,
            vidType: videoType
          },
          butlerResponse
        );
        Cannon.fireVideoWinNotification(butlerResponse).catch((err) => console.log(err));
        Cannon.fireVideoImpression(butlerResponse).catch((err) => console.log(err));
      }
    },
    videoQuartile: (percentThreshold: QuartileKey, adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVideoQuartile(adserverRequestId, percentThreshold);
        return;
      }

      const type = Beacons.types.CompletionPercent;
      if (!hasBeenFired(adserverRequestId, `${type}_${percentThreshold}`, justOnce)) {
        const butlerResponse = ResponseStore.getResponse(adserverRequestId);
        Cannon.fireBeacon(
          {
            type,
            value: percentThreshold
          },
          butlerResponse
        );
        const beacons = butlerResponse.creative.beacons;
        switch (percentThreshold) {
          case '25':
            return beacons.first_quartile.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
          case '50':
            return beacons.midpoint.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
          case '75':
            return beacons.third_quartile.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
          case '95':
            return beacons.completed_silent_play.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
          default:
            return false;
        }
      }
    },
    videoViewDuration: (adserverRequestId: string, currentTime: number, muted: boolean, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVideoViewDuration(adserverRequestId, currentTime, muted);
        return;
      }

      const type = Beacons.types.VideoViewDuration;
      if (!hasBeenFired(adserverRequestId, type, justOnce)) {
        Cannon.fireBeacon(
          {
            type,
            duration: currentTime * 1000,
            silent: muted
          },
          ResponseStore.getResponse(adserverRequestId)
        );
      }
    },
    videoVolumeOn: (adserverRequestId: string, currentTime: number, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVideoVolumeOn(adserverRequestId, currentTime);
        return;
      }

      const type = Beacons.types.VideoVolumeOn;
      const butlerResponse = ResponseStore.getResponse(adserverRequestId);
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon(
        {
          type,
          currentTime: currentTime
        },
        butlerResponse
      );
    },
    isEnhanced: (
      adserverRequestId: string,
      enhancementVersionId: string,
      inner_creative_id: string = '',
      justOnce: boolean = true
    ) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchIsEnhanced(adserverRequestId, enhancementVersionId, inner_creative_id);
        return;
      }

      const type = Beacons.types.IsEnhanced;
      if (!hasBeenFired(adserverRequestId, type, justOnce)) {
        const args = {
          ...(inner_creative_id != '' && { inner_creative_id }),
          evid: enhancementVersionId,
          type
        };

        const butlerResponse = ResponseStore.getResponse(adserverRequestId);

        // check if beacon is misfired. If so, log to airbrake and early return
        if (shouldDisableEnhancement(adserverRequestId, butlerResponse.creative, butlerResponse.placement)) {
          const experiment = experimentManager.getActiveExperiment();
          if (experiment && experiment.result === 'unenhanced') {
            sample(new Error('ExperimentIsEnhancedError'), { butlerResponse });
            return;
          }
        }

        if (isExperimentForcingGenericEnhancement()) {
          args.evid = '';
        }

        Cannon.fireBeacon(args, butlerResponse);
      }
    },
    vPaidEnabled: (adserverRequestId: string, vpaidEnalbed: boolean, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVpaidEnabled(adserverRequestId, vpaidEnalbed);
        return;
      }

      const type = vpaidEnalbed ? Beacons.types.VastVpaid.enabled : Beacons.types.VastVpaid.notEnabled;
      if (!hasBeenFired(adserverRequestId, type, justOnce)) {
        Cannon.fireBeacon({ type }, ResponseStore.getResponse(adserverRequestId));
      }
    },
    clickThroughFound: (adserverRequestId: string, found: boolean, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchClickthroughFound(adserverRequestId, found);
        return;
      }

      const type = found ? Beacons.types.VastClickthrough.found : Beacons.types.VastClickthrough.notFound;
      if (!hasBeenFired(adserverRequestId, type, justOnce)) {
        Cannon.fireBeacon({ type }, ResponseStore.getResponse(adserverRequestId));
      }
    },
    imaNoCreativeIdFound: (adserverRequestId: string, creativeId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchImaNoCreativeIdFound(adserverRequestId, creativeId);
        return;
      }

      const type = Beacons.types.ImaNoCreativeIdFound;
      if (!hasBeenFired(adserverRequestId, type, justOnce)) {
        Cannon.fireBeacon({ type, imaCreativeId: creativeId }, ResponseStore.getResponse(adserverRequestId));
      }
    },
    imaClick: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchImaClick(adserverRequestId);
        return;
      }

      const type = Beacons.types.ImaClick;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type }, ResponseStore.getResponse(adserverRequestId));
    },
    videoSubtitlesEnabled: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVideoSubtitlesEnabled(adserverRequestId);
        return;
      }

      const type = Beacons.types.SubtitlesEnabled;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type }, ResponseStore.getResponse(adserverRequestId));
    },
    jsTrackerFailedToLoad: (adserverRequestId: string, sources: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchJsTrackerFailedToLoad(adserverRequestId, sources);
        return;
      }

      const type = Beacons.types.JsTrackerFailedToLoad;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type, sources }, ResponseStore.getResponse(adserverRequestId));
    },
    vastError: (adserverRequestId: string, error: number, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchVastError(adserverRequestId, error);
        return;
      }

      const type = Beacons.types.VastErrorCode;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type, error }, ResponseStore.getResponse(adserverRequestId));
    },
    bannerRendered: (
      adserverRequestId: string,
      bannerType: string,
      renderMethod: string,
      clickable: boolean,
      justOnce: boolean = false
    ) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchBannerRendered(adserverRequestId, clickable);
        return;
      }

      const type = Beacons.types.BannerRendered;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon(
        {
          type,
          bannerType,
          renderMethod,
          clickable
        },
        ResponseStore.getResponse(adserverRequestId)
      );
    },
    doubleVerifyError: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchDoubleVerify.error(adserverRequestId);
        return;
      }

      const type = Beacons.types.DoubleVerifyError;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type }, ResponseStore.getResponse(adserverRequestId));
    },
    doubleVerifyRender: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchDoubleVerify.render(adserverRequestId);
        return;
      }

      const type = Beacons.types.DoubleVerifyRender;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type: Beacons.types.DoubleVerifyRender }, ResponseStore.getResponse(adserverRequestId));
    },
    doubleVerifySuccess: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchDoubleVerify.success(adserverRequestId);
        return;
      }

      const type = Beacons.types.DoubleVerifySuccess;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type }, ResponseStore.getResponse(adserverRequestId));
    },
    viewableImpression: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchViewableImpression(adserverRequestId);
        return;
      }

      const type = Beacons.types.ViewableImpression;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon(
        {
          type
        },
        ResponseStore.getResponse(adserverRequestId)
      );
    },
    iframeBusterFailed: (failureType: string, payload: any) => {
      try {
        if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
          dispatchIframeBusterFailed(payload, failureType);
          return;
        }
      } catch (e) {
        console.error('Error dispatching iframeBusterFailed', e);
      }

      const type = Beacons.types.IframeBusterFailed;
      Cannon.fireBeacon({ type, failureType }, payload);
    },
    doubleVerifyFallback: (response: ButlerResponse, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchDoubleVerify.fallback(response.adserverRequestId);
        return;
      }

      const type = Beacons.types.DoubleVerifyFallback;
      if (hasBeenFired(response.adserverRequestId, type, justOnce)) return;
      const fallbackResponse = JSON.parse(JSON.stringify(response));
      Cannon.fireBeacon({ type }, fallbackResponse);
    },
    silentAutoPlayDuration: (durationThreshold: DurationKey, adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchSilentAutoPlayDuration(adserverRequestId, durationThreshold);
        return;
      }

      const type = Beacons.types.SilentAutoPlayDuration;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      const butlerResponse = ResponseStore.getResponse(adserverRequestId);
      Cannon.fireBeacon(
        {
          type,
          duration: Number(durationThreshold) * 1000
        },
        butlerResponse
      );
      const { beacons } = butlerResponse.creative;
      switch (durationThreshold) {
        case DurationKey.D3:
          return beacons.silent_play.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
        case DurationKey.D10:
          return beacons.ten_second_silent_play.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
        case DurationKey.D15:
          return beacons.fifteen_second_silent_play.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
        case DurationKey.D30:
          return beacons.thirty_second_silent_play.forEach((beaconUrl) => Cannon.firePixel(beaconUrl));
        default:
          return false;
      }
    },
    pixelVisible: (adserverRequestId: string, justOnce: boolean = false) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchPixelVisible(adserverRequestId);
        return;
      }

      const type = Beacons.types.PixelVisibleInApp;
      if (hasBeenFired(adserverRequestId, type, justOnce)) return;
      Cannon.fireBeacon({ type }, ResponseStore.getResponse(adserverRequestId));
    },
    experimentBeacon: (
      adserverRequestId: string,
      experiment_id?: string,
      experiment_variant_id?: string,
      params?: { [key: string]: string }
    ) => {
      const type = Beacons.types.Experiment;

      // ensure we only fire one experiment beacon by experiment_id
      if (hasBeenFired(adserverRequestId, `${type}-${experiment_id}`, true)) return;

      const experiment = experimentManager.getActiveExperiment();

      if (!experiment) return;

      const payload = {
        type,
        experiment_id: experiment_id || experiment.id,
        experiment_variant_id: experiment_variant_id || experiment.result,
        ...params
      };

      Cannon.fireBeacon(payload, ResponseStore.getResponse(adserverRequestId));
    },
    bundleIdWinTest: (response: ButlerResponse, bundleId: string, winUrl: string) => {
      Cannon.fireBeacon({ type: Beacons.types.BundleIdWinTest, bundleId, winUrl }, response);
    },
    base64WinUrl: (response: ButlerResponse, bundleId: string, winUrl: string) => {
      const encoded = btoa(winUrl);
      Cannon.fireBeacon({ type: Beacons.types.Base64WinUrl, bundleId, winUrl: encoded }, response);
    },
    userEvents: {
      share: (adserverRequestId: string) => {
        if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
          dispatchUserShare(adserverRequestId);
          return;
        }

        Cannon.fireBeacon(
          {
            share: Beacons.shareTypes.custom,
            type: Beacons.types.UserEvent,
            userEvent: Beacons.userEvents.share
          },
          ResponseStore.getResponse(adserverRequestId)
        );
      },
      autoplayVideoEngagement: (adserverRequestId: string, currentTime: number) => {
        if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
          dispatchAutoplayVideoEngagement(adserverRequestId, currentTime);
          return;
        }

        Cannon.fireBeacon(
          {
            type: Beacons.types.UserEvent,
            userEvent: Beacons.userEvents.autoplayVideoEngagement,
            engagement: true,
            videoDuration: currentTime * 1000
          },
          ResponseStore.getResponse(adserverRequestId)
        );
      },
      clickout: (adserverRequestId: string, element: HTMLElement) => {
        if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
          dispatchUserClick(adserverRequestId, element);
          return;
        }

        Cannon.fireBeacon(
          {
            type: Beacons.types.UserEvent,
            userEvent: Beacons.userEvents.clickout
          },
          ResponseStore.getResponse(adserverRequestId),
          element
        );
      }
    },

    foursquareImpression: ({ creative }: ButlerResponse) => {
      if (experimentManager.featureFlag(BEACONS_REFACTOR) === 'refactored') {
        dispatchFoursquareImpression(creative.creativeKey, creative.sourceId);
        return;
      }

      const creativeKey = creative.creativeKey.replace(`${creative.sourceId}-`, '');
      const timestamp = Date.now();
      const url = `https://p.placed.com/api/v2/sync/impression?partner=matterkind&version=1.0&plaid=0063o000019k9xfAAA&payload_campaign_identifier=${creativeKey}&payload_timestamp=${timestamp}&payload_type=impression&xr=tapad`;
      Cannon.firePixel(url);
    }
  }
};

export default Beacons;
