import IframeMessageListener from 'iframe_buster/message_listener';
import { setGroundControlInstanceId } from 'modules/beacons/beacons/utils/getInstanceId';
import * as Log from 'modules/log';
import { sample } from 'modules/monitoring';
import { queryParamsIncludes } from 'modules/utils';
import bootAd from 'tag/ad';
import { InViewTracker } from 'tag/helpers/in-view-tracker';

if (ENV === 'dev') {
  // @ts-ignore
  import('preact/debug');
}

const setup = (): void => {
  if (window.STR) return;
  window.STR = {
    debug: queryParamsIncludes('str_debug'),
    IframeMessageListenerRegistered: false,
    Tag: {
      boot: boot,
      singleAdBoot: singleAdBoot
    },
    Uid: {
      xandr: '',
      criteo: '',
      ttd: ''
    }
  };
};

const boot = async (): Promise<void> => {
  InViewTracker.startup();
  if (!GENERATOR) {
    IframeMessageListener.listen();
  }

  setGroundControlInstanceId();

  const placeholders = document.querySelectorAll('[data-str-native-key]:not([data-str-visited-flag])');

  const bootingAds: Promise<void>[] = [];
  placeholders.forEach((placeholder) => bootingAds.push(bootAd(placeholder as HTMLElement)));

  await Promise.all(bootingAds);
};

// method used by DTP to boot a single ad per time
const singleAdBoot = async (adId: string): Promise<void> => {
  InViewTracker.startup();
  if (!GENERATOR) {
    IframeMessageListener.listen();
  }
  await bootAd(document.querySelectorAll(`[data-dtp-id="${adId}"]`)[0] as HTMLElement);
};

const main = async () => {
  setup();
  try {
    await boot();
  } catch (error) {
    sample(error, {
      function: 'tag/index:main'
    });
    Log.error(error);
  }
};

const gc = () => {
  try {
    if ((document as any).attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
      main();
    } else {
      document.addEventListener('DOMContentLoaded', main);
    }
  } catch (error) {
    sample(error, {
      function: 'tag/index:gc'
    });
  }
};

export default gc;
