import { getRenderStrategy } from './getRenderStrategy';

export enum BannerType {
  Postscribe = 'postscribe',
  Iframe = 'iframe',
  Div = 'div',
  DcmScript = 'dcm_script',
  CentroJs = 'centro_js',
  Flashtalking = 'flashtalking',
  Adform = 'adform',
  Xandr = 'xandr',
  Quantcast = 'quantcast',
  Rubicon = 'rubicon',
  TradeDeskBanner = 'trade_desk_banner',
  Pubmatic = 'pubmatic',
  Yahoo = 'yahoo',
  Openx = 'openx',
  Dv360 = 'dv360',
  Dv360Iframe = 'dv360-iframe',
  Basis = 'basis',
  Other = 'other'
}

export const DSP = {
  regex: {
    ADFORM_REGEX: /a2\.adform\.net/,
    BASIS_REGEX: /https?:\/\/clickserv\.sitescout\.com\/clk.[^']*/,
    CENTRO_JS_REGEX:
      /<a onclick\s*=\s*('|")new Image\(\)\.src\s*=\s*('|")\S+('|")\s*href=('|")https?:\/\/clickserv\.sitescout\.com\/\S*('|")/,
    DCM_SCRIPT_REGEX: /data-dcm-rendering-mode\s*=\s*('|")script('|")/,
    FLASHTALKING_REGEX: /.*servedby\.flashtalking\.com/,
    LINKEDIN_REGEX: /rtb-us-(east|west)\.linkedin\.com/,
    PUBMATIC_REGEX: /ads\.pubmatic\.com/,
    QUANTCAST_REGEX: /content\.quantcount\.com/,
    RUBICON_REGEX: /(rubicon_cb\s?=\s?)|(window\.rubicon_ad\s?=\s?)/,
    // TTD tag with just a link and an image
    TTD_PLAIN_OLD_BANNER: /<a.*?href=["|'].*?["|'].*?>/,
    XANDR_REGEX: /<script src="https?:\/\/\w{4}-ib\.adnxs\.com/,
    YAHOO_SCRIPT_REGEX: /<script.*?ybp\.yahoo\.com.*?<\/script>/
  },

  source: {
    ADOBE: '5USETMxNT2j2QSCbVgvBAbfR',
    DV360: 'V5J3YnPygd61DpGaQKLXUr8H',
    OPENX: '6E6dnPq1ztfkuxxNupdpgjDB',
    TRADE_DESK: '5b286190338513af73f09c28'
  }
};

export const getBannerType = (adm: string, sourceId: string, publisherKey: string): BannerType => {
  //This is for testing and not production
  const strategy = getRenderStrategy(adm);

  // Every DSP has a unique key
  if (strategy === 'ps') {
    return BannerType.Postscribe;
  } else if (strategy === 'iframe' || sourceId === DSP.source.ADOBE || adm.match(DSP.regex.LINKEDIN_REGEX)) {
    return BannerType.Iframe;
  } else if (strategy === 'div') {
    return BannerType.Div;
  } else if (adm.match(DSP.regex.DCM_SCRIPT_REGEX)) {
    return BannerType.DcmScript;
  } else if (sourceId === DSP.source.TRADE_DESK) {
    // The Trade Desk use cases
    // Sometimes can have dcm_script within TTD, so this case
    // should remain lower
    if (adm.match(DSP.regex.TTD_PLAIN_OLD_BANNER)) {
      return BannerType.Postscribe;
    }
    return BannerType.TradeDeskBanner;
  } else if (sourceId === DSP.source.OPENX) {
    return BannerType.Openx;
  } else if (adm.match(DSP.regex.CENTRO_JS_REGEX)) {
    return BannerType.CentroJs;
  } else if (adm.match(DSP.regex.FLASHTALKING_REGEX)) {
    return BannerType.Flashtalking;
  } else if (adm.match(DSP.regex.ADFORM_REGEX)) {
    return BannerType.Adform;
  } else if (adm.match(DSP.regex.XANDR_REGEX)) {
    return BannerType.Xandr;
  } else if (adm.match(DSP.regex.QUANTCAST_REGEX)) {
    return BannerType.Quantcast;
  } else if (adm.match(DSP.regex.RUBICON_REGEX)) {
    return BannerType.Rubicon;
  } else if (adm.match(DSP.regex.PUBMATIC_REGEX)) {
    return BannerType.Pubmatic;
  } else if (adm.match(DSP.regex.BASIS_REGEX)) {
    return BannerType.Basis;
  } else if (adm.match(DSP.regex.YAHOO_SCRIPT_REGEX)) {
    return BannerType.Yahoo;
  } else if (sourceId === DSP.source.DV360) {
    if (['c3cac6b7', '31c129df', '1341fef4', '30658ce4', 'f4074b07', '0aeeeff7'].includes(publisherKey)) {
      return BannerType.Dv360Iframe;
    } else {
      return BannerType.Dv360;
    }
  } else {
    return BannerType.Other;
  }
};
