import { Experiment, ExperimentProps, ExperimentWithResult } from './Experiment';

/**
 * ExperimentManager class manages experiments and their evaluation.
 * @template Context - The type of the context object used for experiment evaluation.
 */
export class ExperimentManager<Context> {
  private experiments: Experiment<Context>[] = [];
  private activeExperiment: ExperimentWithResult<Context> | null = null;

  /**
   * Initializes the ExperimentManager by evaluating all experiments with the given context.
   * @param context - The context object used for experiment evaluation.
   */
  public initialize(context: Context) {
    if (this.isMocked) {
      console.log('Experiment mocked');
      return;
    }

    this.experiments.forEach((experiment) => {
      experiment.evaluate(context);
    });
  }

  /**
   * Creates a new experiment with the provided properties and adds it to the list of experiments.
   * @param props - The properties of the experiment.
   * @returns The ID of the created experiment.
   */
  public create(props: ExperimentProps<Context>): string {
    const experiment = new Experiment({
      id: props.id,
      volume: props.volume,
      variants: props.variants,
      criteria: props.criteria
    });

    this.experiments.push(experiment);
    return experiment.id;
  }

  /**
   * Retrieves the active experiment from the list of experiments.
   * @returns The active experiment or null if no active experiments exist.
   */
  public getActiveExperiment(): ExperimentWithResult<Context> | null {
    if (this.isMocked) {
      console.log('Returning mocked experiment');
      return {
        id: this.mockedExperimentId,
        result: this.mockedVariant
      } as ExperimentWithResult<Context>;
    }

    if (this.activeExperiment) return this.activeExperiment;

    const activeExperiments = this.experiments.filter((experiment) => !!experiment.result);
    if (activeExperiments.length === 0) return null;

    const randomIndex = Math.floor(Math.random() * activeExperiments.length);
    this.activeExperiment = activeExperiments[randomIndex];

    return this.activeExperiment;
  }

  /**
   * Retrieves the result of a feature flag experiment with the specified ID.
   * @param id - The ID of the experiment.
   * @returns The result of the experiment if it exists and is active, otherwise undefined.
   */
  public featureFlag(id: string): string | undefined {
    if (this.isMocked) {
      if (this.mockedExperimentId === id) {
        console.log('Returning mocked variant');
        return this.mockedVariant;
      }

      return;
    }

    const experimentIds = this.experiments.map((experiment) => experiment.id);
    if (!experimentIds.includes(id)) {
      console.error(new Error(`Experiment ${id} does not exist`));
    }

    if (id === this.getActiveExperiment()?.id) {
      return this.getActiveExperiment().result;
    }
  }

  /**
   * Clears the list of experiments.
   */
  public clear() {
    this.experiments = [];
  }

  private get isMocked(): boolean {
    return !!window && !!window['__EXPERIMENT_MOCK__'];
  }

  private get mockedExperimentId(): string {
    return this.isMocked ? window['__EXPERIMENT_MOCK__'][0] : '';
  }

  private get mockedVariant(): string {
    return this.isMocked ? window['__EXPERIMENT_MOCK__'][1] : '';
  }
}
