import { GCEnhancementPayload } from '@sharethrough/groundcontrol-enhancement-flush';
export enum EnhancementErrorType {
  FETCH_DATA_FAILED = 'fetch_data_failed',
  NO_ENHANCEMENTS_PAYLOAD = 'no_enhancements_payload',
  NOT_ENHANCED = 'not_enhanced',
  NOT_CLICKABLE = 'not_clickable',
  TIMEOUT = 'timeout'
}

export class EnhancementError extends Error {
  type: EnhancementErrorType;
  constructor(type: EnhancementErrorType, message: string) {
    super(message);
    this.type = type;

    // This line is needed to restore the correct prototype chain.
    // (see https://stackoverflow.com/questions/41102060/typescript-extending-error-class)
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

type PlacementSize = string;
type CreativeSize = string;

type WeightedEnhancementPayload = GCEnhancementPayload & {
  weight: number;
  payload: GCEnhancementPayload['payload'] & {
    backgroundColor?: string;
    cta?: {
      backgroundColor?: string;
      textHoverColor?: string;
      textColor?: string;
    };
  };
};

export interface SmartEnhancementPayload {
  eligibility?: {
    placementDimensions: Record<PlacementSize, CreativeSize[]>;
  };
  variants: WeightedEnhancementPayload[];
}

export type SmartEnhancementFlush = {
  [dealId: string]: SmartEnhancementPayload;
};

export const isSmartEnhancementPayload = (payload: Record<string, any>): payload is SmartEnhancementPayload => {
  return (
    Array.isArray(payload.variants) &&
    payload.variants.every((variant): variant is WeightedEnhancementPayload => isWeigthedEnhancementPayload(variant))
  );
};

const isWeigthedEnhancementPayload = (variant: Record<string, any>): variant is WeightedEnhancementPayload => {
  return typeof variant.weight === 'number' && typeof variant.type === 'string' && typeof variant.payload === 'object';
};
