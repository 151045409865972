import { Behaviors } from 'modules/butler/creative';

export type BehaviorMap = {
  [key: string]: Partial<Behaviors>;
};

export const enableBehaviors = <T extends Behaviors | Partial<Behaviors>>(
  behaviors: T,
  enableList: string[],
  map: BehaviorMap
): T => {
  enableList.forEach((enableKey) => {
    const behavior = map[enableKey];

    if (behavior) {
      behaviors = { ...behaviors, ...behavior };
    }
  });

  return behaviors;
};
