import PmpIcon from 'assets/icons/greenpmp.png';
import { Creative } from 'modules/butler/creative';
import { h } from 'preact';
import GreenPmpIconConfig from './green-pmp-icon.config';
import styles from './green-pmp-icon.module.scss';

type GreenPmpIconProps = {
  creative: Creative;
};

export const GreenPmpIcon = (props: GreenPmpIconProps) => {
  if (!props.creative.behaviors.shouldGreenPMP || GreenPmpIconConfig.DEAL_ID_DISABLED.includes(props.creative.dealId)) {
    return null;
  } else {
    return (
      <a href={'https://www.sharethrough.com/green-icon'} target="_blank" data-testid="green-pmp-link">
        <div
          className={`str-green-pmp-container ${styles['green-pmp-container']}`}
          style={{ backgroundImage: `url(${PmpIcon})` }}
        >
          <span className={`str-green-pmp-icon ${styles['green-pmp-icon']}`}></span>
        </div>
      </a>
    );
  }
};
