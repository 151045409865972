// Trying to encapsulate the complexity of the Safe Frame implementation
// Usage: SafeFrameHandler.expandFullscreen().then(function)
// https://www.iab.com/wp-content/uploads/2014/08/SafeFrames_v1.1_final.pdf

declare global {
  interface Window {
    $sf: {
      ext: {
        geom: () => { exp: any; win: any; self: { l: number; t: number } };
        expand: (dimensions: any) => void;
        collapse: () => void;
        status: () => string;
        register: (width: number, height: number, callback: (status: string, data: any) => void) => void;
      };
    };
  }
}

export type Dimensions = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export const SafeFrameHandler = {
  isSafeFrame: () => {
    try {
      SafeFrameHandler.safeFrameWindow();
      return true;
    } catch {
      return false;
    }
  },

  // Return a reference to the window object that does have $sf defined
  safeFrameWindow: (win?: Window): Window => {
    // start with win param undefined
    if (!win) {
      win = window;
    }

    try {
      if (win === window.top) {
        // base case -- we've reached the top window and can stop searching for $sf
        if (win.$sf && win.$sf.ext) {
          return win;
        } else {
          throw new Error('Safeframe not found');
        }
      } else {
        if (win.$sf && win.$sf.ext) {
          // found $sf defined on current window object, win, and can stop traversing the DOM
          return win;
        } else {
          // didn't find $sf on current window win, need to check win's parent window
          return SafeFrameHandler.safeFrameWindow(win.parent); // note: this is where calling a function with a cross origin window object as a parameter will generally throw a CORS exception
        }
      }
    } catch {
      // trying to access a cross-origin window
      throw new Error('Safeframe not found');
    }
  },

  // Register the universal callback for all Safe Frame operations
  register: (): void => {
    const sfWin = SafeFrameHandler.safeFrameWindow();
    const { w, h } = SafeFrameHandler.containerGeometry();
    sfWin.$sf.ext.register(w, h, (_status: string, _data: any) => null);
  },

  expandFullscreen: (): Promise<void> => {
    return Promise.resolve(SafeFrameHandler.expand(SafeFrameHandler.expansionGeometry()));
  },

  expand: (dimensions: any): Promise<void> => {
    const sfWin = SafeFrameHandler.safeFrameWindow();
    if (sfWin.$sf.ext.status() == 'expanded') {
      return Promise.resolve();
    }

    return Promise.resolve(sfWin.$sf.ext.expand(dimensions));
  },

  collapse: (): Promise<void> => {
    const sfWin = SafeFrameHandler.safeFrameWindow();
    if (sfWin.$sf.ext.status() == 'collapsed') {
      return Promise.resolve();
    }

    sfWin.$sf.ext.collapse();

    // the actual collapsing of safeframe is delayed
    return new Promise((resolve, _) => {
      const interval = setInterval(function () {
        const status = sfWin.$sf.ext.status();
        if (status === 'collapsed') {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  },

  expansionGeometry: (): any => SafeFrameHandler.safeFrameWindow().$sf.ext.geom().exp,

  containerGeometry: (): any => SafeFrameHandler.safeFrameWindow().$sf.ext.geom().self,

  windowGeometry: (): any => SafeFrameHandler.safeFrameWindow().$sf.ext.geom().win
};

export default SafeFrameHandler;
