import Beacons from 'modules/beacons/utils';
import { DcoHandler } from 'modules/dco';
import { registerObserver } from 'modules/observer';
import ResponseStore from 'modules/response/response_store';
import { FunctionComponent, h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import styles from './thumbnail.module.scss';
import { ThumbnailProps } from './types';

export const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  src,
  style,
  adserverRequestId,
  placementHeight,
  placementWidth
}) => {
  const inViewPixels = 1;
  const outOfViewPixels = 0;

  const thumbnailRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (thumbnailRef.current) {
      registerObserver({
        element: thumbnailRef.current,
        inViewPixels,
        outOfViewPixels,
        inViewCallback: () => {
          Beacons.fire.pixelVisible(adserverRequestId, true);
        }
      });
    }
  });

  const response = ResponseStore.getResponse(adserverRequestId);
  if (!response) return null;

  const isDco = !!response.creative.dcoAssetUrl;

  if (isDco) {
    const alternateThumbnailAssets = response.creative.alternateThumbnailAssets;
    if (alternateThumbnailAssets && alternateThumbnailAssets.length > 0) {
      src = DcoHandler.chooseBestThumbnail(alternateThumbnailAssets, 250, 250);
    }
  }

  const isIsi = !!response.creative.isi;

  return (
    <div
      ref={thumbnailRef}
      className={`${styles['thumbnail-wrapper']} str-thumbnail-wrapper ${isIsi ?? 'str-thumbnail-wrapper-isi'}`}
    >
      <img
        className={`str-thumbnail ${styles['thumbnail']}`}
        src={src}
        style={{ ...style, maxHeight: placementHeight, maxWidth: placementWidth }}
      />
    </div>
  );
};
