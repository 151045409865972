import { sample } from 'modules/monitoring';

export const fetchBase = <T>(url: string): Promise<T> => {
  return fetch(url)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }
    })
    .catch((error) => {
      //TODO implement generic error handling
      sample(error);
      return null;
    });
};
