import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import { useButler } from '../../hooks';
import './advertiser.scss';

export const AmazonAdvertiser: FunctionComponent = () => {
  const butlerResponse = useButler();

  const assets = useNativeAssets();
  if (!butlerResponse || !assets || !assets.sponsored) return null;

  const { sponsored } = assets;
  const { promotedByText } = butlerResponse.creative;

  return (
    <div className="str-amazon-advertiser-container str-advertiser-container advertiser-container">
      <span className="str-amazon-promoted-by str-promoted-by promoted-by">{promotedByText}</span>
      <span className="str-amazon-advertiser str-advertiser advertiser">{sponsored.value}</span>
    </div>
  );
};
