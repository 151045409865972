import { ButlerResponse, RawResponse } from 'modules/butler/response';

const _responses: { [arid: string]: ButlerResponse } = {};
const _rawResponses: { [arid: string]: RawResponse } = {};

const ResponseStore = {
  setRawResponse(response: RawResponse) {
    const key = response.adserverRequestId;
    _rawResponses[key] = response;
  },

  getRawResponse(adserverRequestId: string) {
    return _rawResponses[adserverRequestId];
  },

  setResponse(response: ButlerResponse) {
    const key = response.adserverRequestId;
    _responses[key] = response;
  },

  getResponse(adserverRequestId: string) {
    return _responses[adserverRequestId];
  }
};

export default ResponseStore;
