import { GCEnhancementPayload } from '@sharethrough/groundcontrol-enhancement-flush';
import { ButlerResponse } from 'modules/butler/response';
import { sample } from 'modules/monitoring';
import { FunctionComponent, createContext, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { loadEnhancement } from './loadEnhancement';
import { EnhancementError, EnhancementErrorType } from './loadEnhancement.type';

interface EnhancementContextInterface {
  enhancement: GCEnhancementPayload | null;
  error: EnhancementError | null;
  loading: boolean;
}

export const EnhancementContext = createContext<EnhancementContextInterface>({
  enhancement: null,
  error: null,
  loading: false
});

interface EnhancementProviderProps {
  butlerResponse: ButlerResponse;
}

export const EnhancementProvider: FunctionComponent<EnhancementProviderProps> = ({ butlerResponse, children }) => {
  const [enhancement, setEnhancement] = useState<GCEnhancementPayload | null>(null);
  const [error, setError] = useState<EnhancementError | null>(null);
  const shouldLoadEnhancement = butlerResponse.creative.isClickable !== false && butlerResponse.creative.hasEnhancement;
  const [loading, setLoading] = useState<boolean>(shouldLoadEnhancement);

  useEffect(() => {
    if (butlerResponse.creative.isClickable === false) {
      const error = new EnhancementError(
        EnhancementErrorType.NOT_CLICKABLE,
        'Creative is not enhanceable due to clickability'
      );
      setError(error);

      return;
    }

    if (!butlerResponse.creative.hasEnhancement) {
      // TODO: Look into not setting error when creative doesn't have enhancement
      const error = new EnhancementError(
        EnhancementErrorType.NOT_ENHANCED,
        "Creative doesn't have a custom enhancement"
      );
      setError(error);

      return;
    }

    // used for previewing enhancements
    const mockedEnhancementData = (window as any)[`str_enhancement_${butlerResponse.adserverRequestId}`];
    if (mockedEnhancementData) {
      setEnhancement(mockedEnhancementData);
      setLoading(false);
      return;
    }

    setLoading(true);

    let timedOut = false;
    const timeoutId = setTimeout(() => {
      timedOut = true;
      setLoading(false);
    }, 600); // Set timeout as needed

    loadEnhancement(butlerResponse)
      .then((enhancement) => {
        if (timedOut) {
          throw new EnhancementError(
            EnhancementErrorType.TIMEOUT,
            'Enhancement loading exceeded maximum allowed time.'
          );
        } else {
          setEnhancement(enhancement);
          setLoading(false);
        }
      })
      .catch((error: EnhancementError) => {
        setError(error);
        setLoading(false);
        sample(error, {
          adserverRequestId: butlerResponse.adserverRequestId
        });
      });

    return () => {
      clearTimeout(timeoutId);
    };
  }, [butlerResponse]);

  if (ENV === 'dev') {
    console.log('enhancement:', enhancement);
  }

  return (
    <EnhancementContext.Provider
      value={{
        enhancement,
        error,
        loading
      }}
    >
      {children}
    </EnhancementContext.Provider>
  );
};
