import { CSSProperties } from 'preact/compat';
import { IFontStyleResponse } from '.';

export class FontStyle implements IFontStyleResponse {
  family: string | undefined;
  color: string | undefined;
  size: number | undefined;
  weight: number | undefined;
  hoverColor: string | undefined;
  capitalized: boolean | undefined;

  constructor(response?: IFontStyleResponse) {
    this.family = response?.family;
    this.color = response?.color;
    this.size = response?.size;
    this.weight = response?.weight;
    this.hoverColor = response?.hoverColor;
    this.capitalized = response?.capitalized;
  }

  toStyle(): CSSProperties {
    return {
      fontSize: `${this.size}em`,
      fontFamily: this.family,
      color: this.color,
      textTransform: this.capitalized ? 'uppercase' : undefined,
      fontWeight: this.weight
    };
  }
}
