// TODO: move this into the butler module (maybe butler/api.ts?) once we're
// ready to fix a million imports
// [pivotal](https://www.pivotaltracker.com/story/show/170270171)
import Ajax from 'modules/ajax';
import * as ButlerResponse from 'modules/butler/response';
import { CustomTracker } from 'modules/custom_tracker';
import CreativeResponse from 'modules/response/creative_response';
import ResponseStore from 'modules/response/response_store';
import { normalizeJsTracker, objToQueryString } from 'modules/utils';
import window_helper from 'tag/helpers/window.helper';
import { Audit, AuditTimer } from '../audit';

function requestURI(placementKey: string, params: { [index: string]: string | boolean } = {}) {
  params.placement_key = placementKey;
  params.secure = document.location.protocol.indexOf('http') < 0 || document.location.protocol.indexOf('https') > -1;
  params.cb = (Math.random() + 1).toString(36).substring(7);

  return `${CONFIG.butlerDomain}/v4?${objToQueryString(params)}`;
}

const processRawResponse = (
  placementKey: string,
  placeholder: Element,
  response: any,
  timer: AuditTimer
): ButlerResponse.T => {
  timer.assign(response.adserverRequestId).stop();

  // temp timer for rendering countdown with remote data
  new AuditTimer('fetchingEnhancementData', response.adserverRequestId);

  new AuditTimer('rendering', response.adserverRequestId);
  const parsingTimer = new AuditTimer('butlerResponseParsing', response.adserverRequestId);
  const butlerResponse = ButlerResponse.build(
    response as ButlerResponse.RawResponse,
    placementKey,
    normalizeJsTracker(placeholder.getAttribute('data-str-js-tracker'))
  );

  parsingTimer.stop();

  Audit.setType(butlerResponse.creative.action);
  CustomTracker.click(placeholder as HTMLElement, butlerResponse);

  ResponseStore.setResponse(butlerResponse);

  return butlerResponse;
};

const getPlacementKey = (placeholder: Element): string => {
  const placementKey = placeholder.getAttribute('data-str-native-key');
  if (!placementKey) {
    throw new Error('No data-str-native-key value found on placeholder');
  }

  return placementKey;
};

const initAdFetching = (): AuditTimer => {
  Audit.sdkBootTime();
  return new AuditTimer('butlerResponseTime');
};

const Butler = {
  request: async (placementKey: string, params?: { [key: string]: string }) => {
    const url = requestURI(placementKey, params);
    const responseJson = await Ajax.GetJson(url, {
      timeout: 5000
    });
    if (Object.keys(responseJson).length === 0) throw new Error('No Fill');

    if (responseJson && responseJson.adserverRequestId) {
      const prt = window_helper.getPRT(url);
      if (prt) Audit.setPRT(responseJson.adserverRequestId, prt);
    }

    return responseJson;
  },

  fetchAdSync: (placeholder: Element): ButlerResponse.T => {
    const placementKey = getPlacementKey(placeholder);
    const responseTimer = initAdFetching();
    const rawResponse = CreativeResponse.getResponseSync(placeholder);
    return processRawResponse(placementKey, placeholder, rawResponse, responseTimer);
  },

  fetchAd: async (placeholder: Element): Promise<ButlerResponse.T> => {
    const placementKey = getPlacementKey(placeholder);
    const responseTimer = initAdFetching();
    const rawResponse = await CreativeResponse.getResponse(placeholder, placementKey);
    return processRawResponse(placementKey, placeholder, rawResponse, responseTimer);
  },

  shouldCreativeInstantPlay: (butlerResponse: ButlerResponse.T) => {
    const { creative } = butlerResponse;

    if (
      creative.action !== 'hosted-video' &&
      creative.action !== 'native-outstream' &&
      creative.action !== 'outstream'
    ) {
      throw new Error('Invalid creative type for instant play detection');
    }
    // Note: not including logic for the `str-no-instant` override class
    return !creative.forceClickToPlay;
  }
};

export default Butler;
