import { ButlerCreative } from 'modules/butler/creative';
import { ButlerPlacement } from 'modules/butler/placement';

export const getFixedSizeBannerClass = (creative: ButlerCreative, placement: ButlerPlacement): string => {
  if (creative.behaviors.shouldRenderFixedSizeBanner) {
    if (creative.size.width === placement.size.width && creative.size.height === placement.size.height) {
      return `str-adunit-banner-fixed-size w${creative.size.width}h${creative.size.height}`;
    } else {
      return `str-adunit-banner-fixed-size w${creative.behaviors.shouldRenderFixedSizeBanner.width}h${creative.behaviors.shouldRenderFixedSizeBanner.height}`;
    }
  }

  if (placement.templateKey === 'empty') {
    return `str-adunit-banner-fixed-size w${creative.size.width}h${creative.size.height}`;
  }

  return '';
};
