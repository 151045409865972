import { ButlerCreative } from 'modules/butler/creative';
import { ButlerPlacement } from 'modules/butler/placement';

export enum TemplateType {
  Enhanced,
  Unenhanced
}

export interface TemplateProps {
  className?: string;
  country: string | undefined;
  creative: ButlerCreative;
  placement: ButlerPlacement;
  children?: preact.ComponentChildren;
  adserverRequestId: string;
}

export interface TextTemplateProps {
  description: string;
  shouldHideDescription: boolean | undefined;
  title: string;
}

export interface SubtitlesTemplateProps {
  currentSubtitle: string | undefined;
}

export interface SubtitlesTemplateState {
  currentSubtitle: Array<string> | undefined;
  previousSubtitle: Array<string>;
  showPrevCaption: boolean;
  showNextCaption: boolean;
}
