import { sample } from 'modules/monitoring';
import BeaconQueue from './BeaconQueue';

export abstract class BeaconBase {
  retries: number = 3;
  fireOnce: boolean = false;
  cacheKey?: string;

  abstract url: string;
  abstract fire(): Promise<void>;

  onFailure?(): void;
  onSuccess?(): void;

  enqueue(): void {
    BeaconQueue.enqueue(this);
  }

  async sendBeacon(): Promise<boolean> {
    try {
      await this.fire();

      this.onSuccess && this.onSuccess();

      return true;
    } catch (error) {
      this.retries--;
      if (this.retries > 0) {
        this.enqueue();
      } else {
        sample(error, {
          url: this.url,
          message: 'Beacon failed to send: ' + error.message,
          errorPayload: error
        });

        this.onFailure && this.onFailure();
      }

      return false;
    }
  }
}
