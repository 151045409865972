import { Data, DataAssetType, DataLink, Image, ImageAssetType, NativeAssets, RawNativeAssets } from './types';

export const nativeAssetsParser = (rawNativeAssets: RawNativeAssets) => {
  let parsedAssets: NativeAssets = {};

  if (!rawNativeAssets) return parsedAssets;

  for (const asset of rawNativeAssets) {
    if ('title' in asset) {
      parsedAssets.title = asset.title;
    } else if ('img' in asset) {
      switch (asset.img.type) {
        case ImageAssetType.MAIN:
          parsedAssets.mainImage = asset.img as Image<ImageAssetType.MAIN>;
          break;
        case ImageAssetType.LOGO:
          parsedAssets.logo = asset.img as Image<ImageAssetType.LOGO>;
          break;
        case ImageAssetType.ICON:
          parsedAssets.icon = asset.img as Image<ImageAssetType.ICON>;
          break;
        case ImageAssetType.PRIME_LOGO:
          parsedAssets.primeLogo = asset.img as Image<ImageAssetType.PRIME_LOGO>;
          break;
        case ImageAssetType.AVAILABLE_ON_AMAZON:
          parsedAssets.availableLogo = asset.img as Image<ImageAssetType.AVAILABLE_ON_AMAZON>;
          break;
      }
    } else if ('data' in asset) {
      switch (asset.data.type) {
        case DataAssetType.SPONSORED:
          parsedAssets.sponsored = asset.data as Data<DataAssetType.SPONSORED>;
          break;
        case DataAssetType.DESC:
          parsedAssets.description = asset.data as Data<DataAssetType.DESC>;
          break;
        case DataAssetType.RATING:
          parsedAssets.rating = asset.data as Data<DataAssetType.RATING>;
          break;
        case DataAssetType.LIKES:
          parsedAssets.likes = asset.data as Data<DataAssetType.LIKES>;
          break;
        case DataAssetType.DOWNLOADS:
          parsedAssets.downloads = asset.data as Data<DataAssetType.DOWNLOADS>;
          break;
        case DataAssetType.PRICE:
          parsedAssets.price = asset.data as Data<DataAssetType.PRICE>;
          break;
        case DataAssetType.SALEPRICE:
          parsedAssets.salePrice = asset.data as Data<DataAssetType.SALEPRICE>;
          break;
        case DataAssetType.PHONE:
          parsedAssets.phone = asset.data as Data<DataAssetType.PHONE>;
          break;
        case DataAssetType.ADDRESS:
          parsedAssets.address = asset.data as Data<DataAssetType.ADDRESS>;
          break;
        case DataAssetType.DESC2:
          parsedAssets.desc2 = asset.data as Data<DataAssetType.DESC2>;
          break;
        case DataAssetType.DISPLAYURL:
          parsedAssets.displayUrl = asset.data as Data<DataAssetType.DISPLAYURL>;
          break;
        case DataAssetType.CTATEXT:
          parsedAssets.cta = { ...asset.data, link: asset.link } as DataLink<DataAssetType.CTATEXT>;
          break;
        case DataAssetType.DISCOUNT:
          parsedAssets.discount = asset.data as Data<DataAssetType.DISCOUNT>;
          break;
        case DataAssetType.COLOR:
          parsedAssets.color = asset.data as Data<DataAssetType.COLOR>;
          break;
        case DataAssetType.BADGE:
          parsedAssets.badge = asset.data as Data<DataAssetType.BADGE>;
          break;
        case DataAssetType.REVIEW:
          parsedAssets.review = { ...asset.data, link: asset.link } as DataLink<DataAssetType.REVIEW>;
          break;
        case DataAssetType.COUPON:
          parsedAssets.coupon = asset.data as Data<DataAssetType.COUPON>;
          break;
      }
    }
  }

  return parsedAssets;
};
