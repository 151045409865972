import { ButlerCreative } from 'modules/butler/creative';

// https://sharethrough.com (old) and https://sfa.sharethrough.com/trackable_redirect (new)
// are placeholders for enhanced-ads workarounds with no media_url override (url is a required field)
// do NOT actually click out to it
export const clearMediaUrl = (creative: ButlerCreative) => {
  const { mediaUrl } = creative;
  const invalidUrls = ['https://sharethrough.com', 'https://sfa.sharethrough.com/trackable_redirect'];

  return !!(mediaUrl && invalidUrls.some((url) => mediaUrl.includes(url)));
};
