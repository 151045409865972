import WindowHelper, { PerformanceTimestamp } from 'tag/helpers/window.helper';

let t0: number;
let auditTimers: { [key: string]: { [key: string]: AuditTimer } } = {};

function initArid(arid: string) {
  if (!window.Audit) {
    Audit.init();
  }
  if (window.Audit.timing[arid] === undefined) {
    window.Audit.timing[arid] = {};
  }
}

/*
 * Static class that gathers measures and organizes per arid
 *
 * This can only measure from t0
 * Use `AuditTimer` to measure between 2 checkpoints
 */
class Audit {
  static init() {
    t0 = WindowHelper.performanceTimestamp() || -1;
    if (window.Audit === undefined) {
      window.Audit = {
        t0: t0,
        sdkBootTime: -1,
        sdk: 'groundcontrol',
        type: '',
        timing: {},
        performanceEntries: {}
      };
    }
  }

  static getTimeOrigin() {
    return t0;
  }

  static setType(type: string) {
    window.Audit.type = type;
  }

  static time(): number {
    return (WindowHelper.performanceTimestamp() || 0) - t0;
  }

  /* stores/returns the boot time for the sdk
   * this is done separately and stored in the global Audit object because we only want to calculate this once
   * if value already exists, we just return it
   */
  static sdkBootTime() {
    if (window.Audit.sdkBootTime === -1) {
      window.Audit.sdkBootTime = (WindowHelper.performanceTimestamp() || 0) - t0;
    }
    return window.Audit.sdkBootTime;
  }

  /*
   * Assigns a time to an arid for a given measure
   */
  static assign(type: string, time: number, arid: string): void {
    initArid(arid);
    window.Audit.timing[arid][type] = time;
  }

  /*
   * Completes a measure between t0 and now
   */
  static tick(type: string, arid: string): void {
    initArid(arid);
    window.Audit.timing[arid][type] = (WindowHelper.performanceTimestamp() || 0) - t0;
  }

  /*
   * Returns the time in milliseconds of a completed measure
   *
   * Shortcut for: AuditTimer.retrieve(type, arid).time()
   */
  static getTime(type: string, arid: string): number | undefined {
    return window.Audit.timing[arid][type] || undefined;
  }

  static setPRT(arid: string, entry: PerformanceResourceTiming): void {
    window.Audit.performanceEntries[arid] = entry;
  }

  static getPRT(arid: string): PerformanceResourceTiming {
    return window.Audit.performanceEntries[arid] || undefined;
  }
}

/*
 * Object representing a measure between 2 checkpoints
 *
 * The call to the constructor starts the measure
 * A call to `stop()` ends the measure and stores the result in the global window.Audit
 */
class AuditTimer {
  start: PerformanceTimestamp;
  end: PerformanceTimestamp;
  type: string;
  arid: string;

  constructor(type: string, arid?: string) {
    this.start = WindowHelper.performanceTimestamp();
    this.end = null;
    this.type = type;
    this.arid = '_default';

    if (arid) {
      this.assign(arid);
    }
  }

  /*
   * Assigns a timer to its arid, to use if the timer was created before the arid was known
   */
  assign(arid: string): AuditTimer {
    this.arid = arid;
    initArid(this.arid);

    if (auditTimers[arid] === undefined) {
      auditTimers[arid] = {};
    }
    auditTimers[arid][this.type] = this;

    return this;
  }

  /*
   * Ends the measure and stores the result in the global window.Audit
   */
  stop(): AuditTimer {
    initArid(this.arid);
    this.end = WindowHelper.performanceTimestamp();
    window.Audit.timing[this.arid][this.type] = (this.end || 0) - (this.start || 0);

    return this;
  }

  /*
   * Returns the raw measure
   */
  time(): number | undefined {
    return this.start !== null && this.end !== null ? this.end - this.start : undefined;
  }

  /*
   * Returns a previously created timer
   */
  static retrieve(type: string, arid: string): AuditTimer {
    return auditTimers[arid] && auditTimers[arid][type] ? auditTimers[arid][type] : new StubTimer();
  }
}

/**
 * Fake timer returned when a browser incompatibility prevents an actual timer to be retrieved
 * Pivotal: https://www.pivotaltracker.com/story/show/174810848
 */
class StubTimer extends AuditTimer {
  constructor() {
    super('_stub');
    this.start = null;
    this.end = null;
  }

  assign(): AuditTimer {
    return this;
  }

  stop(): AuditTimer {
    return this;
  }
}

export { Audit, AuditTimer };
