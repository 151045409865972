// https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/history
export const IMA_SDK_URL = '//imasdk.googleapis.com/prerelease/js/3.554.2/ima3.js';

export const coinToss = () => Math.random() > 0.5;

export type QueryArgs = { [index: string]: string | string[] | number | boolean };

const entities: { [index: string]: string } = {
  amp: '&',
  apos: "'",
  '#x27': "'",
  '#x2F': '/',
  '#39': "'",
  '#47': '/',
  lt: '<',
  gt: '>',
  nbsp: ' ',
  quot: '"'
};

export function decodeHTMLEntities(text: string): string {
  return text.replace(/&([^;]+);/gm, function replaceHTMLEntities(match: string, entity: string) {
    return entities[entity] || match;
  });
}
export function htmlToElement(html: string): HTMLElement {
  // ie doesn't support the 'template' element which might be a better fit here
  const template = document.createElement('div');
  html = html.trim();
  template.innerHTML = html;
  return <HTMLElement>template.firstChild;
}

export function referrerParam(url: string) {
  let result;
  if (url.match(/\?/)) {
    result = '&';
  } else {
    result = '?';
  }
  const param = `strref=${encodeURIComponent(window.location.origin)}`;
  result += param;
  return result;
}

export function replaceCacheBusterParam(url: string) {
  const swappedUrl = url.replace(/\[timestamp\]/g, window.Date.now().toString());
  // there's a bug in ie 10 and 11 that crashes when url has % character
  return swappedUrl.replace(/%%/g, '%25%25');
}

export function getParameterByName(parameterName: string) {
  const regex = new RegExp(`[\\?&]${parameterName}=([^&#]*)`);
  const results = regex.exec(location.search);
  if (results === null) {
    return null;
  } else {
    return decodeURIComponent(results[1]).replace(/\+/g, ' ');
  }
}

export function objToQueryString(obj: QueryArgs | null, priorities: { [index: string]: number } = {}): string {
  if (!obj) return '';

  return Object.entries(obj)
    .sort(([a], [b]) => {
      const priorityA = priorities[a] || Number.MAX_SAFE_INTEGER;
      const priorityB = priorities[b] || Number.MAX_SAFE_INTEGER;
      return priorityA - priorityB;
    })
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&')
        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
}

// URLSearchParams does not have good compatibility
// export function queryParamsIncludes(target) {
//   let params = new URLSearchParams(location.search);
//   return params.has(target);
// }

export function queryParamsIncludes(target: String): boolean {
  const queryString = location.search;
  if (queryString.length === 0) return false;

  // removes the ? from the query string
  return !!queryString
    .slice(1)
    .split('&')
    .find((qs) => qs.split('=')[0] == target);
}

export const normalizeJsTracker = (jsTracker: any) => {
  if (typeof jsTracker === 'string') {
    try {
      return JSON.parse(jsTracker);
    } catch (e) {
      if (e instanceof SyntaxError) return [jsTracker];
      throw e;
    }
  } else if (Array.isArray(jsTracker)) {
    return jsTracker;
  } else {
    return [];
  }
};

export function copyScriptTag(script: HTMLElement): HTMLScriptElement {
  // we need to recreate all script tags programmatically because otherwise browsers block them
  const newScript = document.createElement('script');
  // iterate through all attributes (e.g. "src") and copy their values
  Array.from(script.attributes).forEach((attr) => {
    try {
      newScript.setAttribute(attr.nodeName, attr.nodeValue || '');
    } catch {
      // Skip the attribute if not valid
    }
  });

  // then copy any inner text content
  // try/catch for IE compatibility -- need to use newScript.text in IE
  try {
    newScript.appendChild(document.createTextNode(script.innerHTML));
  } catch {
    newScript.text = script.innerHTML;
  }

  return newScript;
}

export function createScripts(scriptHtmlString: string): HTMLScriptElement[] {
  // sandbox which provides isolation for untrusted scripts
  const tempDoc = document.implementation.createHTMLDocument('');
  tempDoc.body.innerHTML = scriptHtmlString;

  const children = Array.from(tempDoc.body.children);
  const scriptLikeChildren = children.filter(
    (child) => (child as HTMLScriptElement).tagName.toLowerCase() === 'script'
  );

  return scriptLikeChildren.map((scriptLike) => {
    return copyScriptTag(scriptLike as HTMLElement);
  });
}

export function recreateChildScriptTags(htmlEl: HTMLElement): void {
  // pull out the script tags from htmlEl
  const scripts = Array.from(htmlEl.getElementsByTagName('script'));

  scripts.forEach((script) =>
    // parentNode is potentially null, but we can use the non-null assertion operator (!)
    // since scripts are being extracted from the parent element passed to the function
    // we're using the version that's exported via Utils for testing purpose
    script.parentNode!.replaceChild(Utils.copyScriptTag(script), script)
  );
}

export function firstParent(element: Element, attribute: string) {
  let parentNode = element.parentNode as Element;
  while (parentNode !== null) {
    if (parentNode.matches && parentNode.matches(attribute)) {
      return parentNode;
    }
    parentNode = parentNode.parentNode as Element;
  }
  return null;
}

export function topmostIframeIdOrName(
  windowObj: Window,
  shouldReplaceIframeContainer: boolean = false
): undefined | string {
  const topLevelWindow = windowObj.top;
  let topmostWindow = windowObj;

  // If we're in a nested iframe, traverse the window hierarchy
  // and find the highest level iframe window (direct child of the top window)
  while (topmostWindow.parent !== topLevelWindow) {
    if (topmostWindow.parent && topmostWindow.parent.frameElement) {
      topmostWindow = topmostWindow.parent;
    } else {
      // we are already at the topmost window that can provide a frame element
      // avoid endless loop for the while condition
      break;
    }
  }

  // once we have the highest level iframe window, look for an ID or name attribute we can use
  if (!!topmostWindow.frameElement) {
    if (shouldReplaceIframeContainer) {
      if (topmostWindow.frameElement.parentElement) {
        return elementIdOrName(topmostWindow.frameElement.parentElement);
      }
    } else {
      return elementIdOrName(topmostWindow.frameElement);
    }
  }

  // returns undefined if no frame element available
  return undefined;
}

export function elementIdOrName(targetElement: Element) {
  const fallbackIdentifier: string = 'str-element-id-xxxx'.replace(/[x]/g, () => {
    return ((Math.random() * 16) % 16 | 0).toString(16);
  });
  let identifier = targetElement.id || (targetElement as HTMLIFrameElement).name;
  if (!identifier) {
    targetElement.id = fallbackIdentifier;
    identifier = fallbackIdentifier;
  }
  return identifier;
}

// Polyfill for limited dispatchEvent support in IE11
export function generateClickEvent() {
  let clickEvent: Event;

  if (typeof Event === 'function') {
    clickEvent = new MouseEvent('click');
  } else {
    clickEvent = document.createEvent('MouseEvents');
    clickEvent.initEvent('click', true, true);
  }

  return clickEvent;
}

export function debounce(func: Function, timeout: number) {
  let operator = func;
  let noop = () => {};
  return () => {
    operator();
    if (operator !== noop) {
      operator = noop;
      setTimeout(() => (operator = func), timeout);
    }
  };
}

export function evaluateCropping(testEl: HTMLElement) {
  // Find the outermost placement wrapper and the template element
  const wrapperEl = firstParent(testEl, '[data-str-native-key]');
  const templateEl = testEl.querySelector('.str-adunit');
  if (!wrapperEl || !templateEl) {
    return {};
  }

  // If we cannot get parent element bounding rectangle (or no parent element) then we cannot test for cropping
  if (!wrapperEl.parentElement || !wrapperEl.parentElement.getBoundingClientRect()) {
    return {};
  }

  // The thresholds for deltas below / above which we don't want to fire the beacon.
  // Thresholds are used to preempt firing the beacon for very small or very large deltas.
  // Very large deltas have resulted difficult to replicate and may be indicative
  // of custom styling applied to the placement by publishers.
  const croppingThresholdMin = 30;
  const croppingThresholdMax = 300;

  const templateElRect = templateEl.getBoundingClientRect();
  const wrapperElParentRect = wrapperEl.parentElement.getBoundingClientRect();

  const deltaTop = Math.floor(templateElRect.top - wrapperElParentRect.top) || 0;
  const deltaLeft = Math.floor(templateElRect.left - wrapperElParentRect.left) || 0;
  const deltaBottom = Math.floor(templateElRect.bottom - wrapperElParentRect.bottom) || 0;
  const deltaRight = Math.floor(templateElRect.right - wrapperElParentRect.right) || 0;

  // positive logic for testing when cropped:
  //   top and left of templateEl will be less than wrapperEl parent, respectively
  //   bottom and right of templateEl will be greater than wrapperEl parent, respectively
  //   only need one of the tests to be true in order to indicate cropping
  const isCropped =
    (deltaTop <= croppingThresholdMin * -1 && deltaTop >= croppingThresholdMax * -1) ||
    (deltaLeft <= croppingThresholdMin * -1 && deltaLeft >= croppingThresholdMax * -1) ||
    (deltaBottom >= croppingThresholdMin && deltaBottom <= croppingThresholdMax) ||
    (deltaRight >= croppingThresholdMin && deltaRight <= croppingThresholdMax);

  return {
    isCropped: isCropped,
    deltas: {
      // only send _actual_ value of delta if it is contributing to cropping, otherwise send 0 for that delta
      croppedDeltaTop: deltaTop < 0 ? deltaTop : 0,
      croppedDeltaLeft: deltaLeft < 0 ? deltaLeft : 0,
      croppedDeltaBottom: deltaBottom > 0 ? deltaBottom : 0,
      croppedDeltaRight: deltaRight > 0 ? deltaRight : 0
    }
  };
}

// Exporting subset of functions within an object to
// facilitate Jest spying and mocking, this is not meant
// to be used outside of a this file except for specs
export const Utils = {
  copyScriptTag
};
