import { Beacon } from '../Beacon';
import { AdserverRequestId, BeaconType } from '../types';
import { getBaseParams } from '../utils/getBaseParams';

export const dispatchImaNoCreativeIdFound = (arid: AdserverRequestId, imaCreativeId: string) => {
  return new Beacon({
    fireOnce: true,
    cacheKey: `ima-no-creative-found-${arid}`,

    data: {
      type: BeaconType.imaNoCreativeIdFound,
      imaCreativeId,
      arid,
      ...getBaseParams(arid)
    }
  });
};
