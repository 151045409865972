import { BeaconBase } from './BeaconBase';
import { BeaconEvents } from './types';
import { firePixel } from './utils';

interface URLBeaconProps extends BeaconEvents {
  url: string;
}

export class URLBeacon extends BeaconBase {
  url: string;

  constructor(props: URLBeaconProps) {
    super();

    this.url = props.url;

    this.onSuccess = props.onSuccess;
    this.onFailure = props.onFailure;

    this.enqueue();
  }

  fire(): Promise<void> {
    return firePixel(this.url);
  }
}
