import { useNativeAssets } from 'modules/ortb';
import { FunctionComponent, h } from 'preact';
import { AmazonAvailableLogo } from '../available-logo/amazon-logo.component';
import './brand-logo.scss';

export const AmazonBrandLogo: FunctionComponent = () => {
  const assets = useNativeAssets();
  if (!assets) return null;

  const { icon, availableLogo } = assets;

  if (!icon && availableLogo) return <AmazonAvailableLogo />;
  if (!icon) return null;

  return (
    <div
      className="str-amazon-brand-logo"
      data-testid="amazon-brand-logo"
      style={{ backgroundImage: `url(${icon.url})` }}
    ></div>
  );
};
