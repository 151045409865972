import Beacons from 'modules/beacons/utils';
import { shouldDisableEnhancement } from 'modules/policies';
import { FunctionComponent, h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useButler, useEnhancement } from 'tag/models/ad-experiences/hooks';
import { TemplateProps } from '..';
import { setEnhancementRenderLatencyEndTime } from '../../../../../modules/beacons/beacons/utils/performanceTimeStore';
import '../template.scss';
import { getEnhancedClasses } from '../utils/getEnhancedClasses';
import EnhancedTemplateContent from './content';
import Drawer from './drawer';

export const EnhancedTemplate: FunctionComponent<TemplateProps> = (props) => {
  const butlerResponse = useButler();
  const { enhancement, error } = useEnhancement();

  useEffect(() => {
    if (!butlerResponse) {
      return;
    }

    if ((butlerResponse.creative.hasEnhancement && (error || enhancement)) || !butlerResponse.creative.hasEnhancement) {
      const enhancement_version_id = enhancement?.enhancement_version_id;
      const enhancementVersionId = enhancement_version_id ?? butlerResponse.creative.enhancementVersionId;
      if (!['clickout', 'hosted-video'].includes(butlerResponse.creative.action)) {
        if (
          !shouldDisableEnhancement(butlerResponse.adserverRequestId, butlerResponse.creative, butlerResponse.placement)
        ) {
          Beacons.fire.isEnhanced(props.adserverRequestId, enhancementVersionId);
        }
      }
    }
  }, [enhancement, error, butlerResponse, props.adserverRequestId]);

  const { creative, placement, className } = props;
  const enhancedClasses = getEnhancedClasses(creative, className);

  setEnhancementRenderLatencyEndTime();

  return (
    <div className={enhancedClasses}>
      {props.children}
      <EnhancedTemplateContent></EnhancedTemplateContent>
      <Drawer creative={creative} placement={placement} />
    </div>
  );
};
