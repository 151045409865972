const DEFAULT_BANNER_WIDTH = 300;
const DEFAULT_BANNER_HEIGHT = 250;

export const getSize = (
  shouldRenderFixedSizeBanner: { width: number; height: number } | false,
  size: { width: number | null; height: number | null }
): { width: number; height: number } => {
  if (shouldRenderFixedSizeBanner) {
    return { height: shouldRenderFixedSizeBanner.height, width: shouldRenderFixedSizeBanner.width };
  } else if (size.width && size.height) {
    return { width: size.width, height: size.height };
  } else {
    return { height: DEFAULT_BANNER_HEIGHT, width: DEFAULT_BANNER_WIDTH };
  }
};
