import { GCEnhancementsFlush } from '@sharethrough/groundcontrol-enhancement-flush';
import { ButlerResponse } from 'modules/butler/response';
import { SmartEnhancementFlush } from './loadEnhancement.type';
import { fetchBase } from 'tag/models/common/fetchData/fetch-base';

const ENHANCEMENT_CDN_URL = 'https://enhancements-cdn.sharethrough.com/enhancements/gc';

// TODO: Replace by experiment
const isDeo = () => {
  return false;
};

const DEO_URL =
  'https://enhancements-data-production.s3.us-east-1.amazonaws.com/tmp/enhancements/83271_750105346-F2Stothm3wg5g6opTuaPadz9/payload-stage.json';

/**
 * Fetches the enhancements data from the API
 * @param creativeKey The creative key of the enhancement
 * @returns The enhancements data
 */
export const fetchEnhancement = (
  butlerResponse: ButlerResponse
): Promise<GCEnhancementsFlush | SmartEnhancementFlush> => {
  const adType = ['native-outstream', 'hosted-video'].includes(butlerResponse.creative.action) ? 'outstream' : 'banner';

  const url = isDeo()
    ? DEO_URL
    : `${ENHANCEMENT_CDN_URL}/${adType}-${butlerResponse.creative.creativeKey}/payload.json`;

  return fetchBase(url);
};
