import { ImaSDK2 } from 'modules/imaSDK';
import loadImaSDK from 'modules/imaSDK/ima.config';
import { VastVideo } from '../vast-video.component';
import { adLoadedHandler } from './adLoadedHandler';
import { adsManagerLoadedHandler } from './adsManagedLoadedHandler';
import { ButlerResponse } from 'modules/butler/response';
import { Signal } from '@preact/signals';

export const loadIma = async (
  component: VastVideo, //TODO Remove component
  butlerResponse: ButlerResponse,
  mainContainer: HTMLElement,
  videoReplayed: Signal<boolean>,
  videoLoaded: Signal<boolean>,
  videoSource: Signal<string | null | undefined>
) => {
  await loadImaSDK();

  const imaSDK = new ImaSDK2({
    butlerResponse: butlerResponse,
    containerElement: component.refThumbnail.current!,
    onAdLoaded: adLoadedHandler(component, butlerResponse, videoReplayed, videoLoaded, videoSource),
    onAdsManagerLoaded: adsManagerLoadedHandler(component, mainContainer),
    videoElement: component.refBlankVideo.current!
  });
  return imaSDK;
};
