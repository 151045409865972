type PromiseCache = {
  [key: string]: Promise<string>;
};

const cache: PromiseCache = {};

// TODO: handle timeout? maybe an optional parameter?
// TODO: write tests for this code mang
export const downloadScript = (url: string, waitUntil: () => boolean): Promise<string> => {
  if (cache[url] != undefined) {
    return cache[url];
  }

  /*  it's possible that our wait condition is already met, no need
   *  to download the script at all */
  if (waitUntil()) {
    cache[url] = Promise.resolve('');
    return cache[url];
  }

  cache[url] = new Promise((resolve, _) => {
    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);

    const waitInterval = setInterval(() => {
      if (!waitUntil()) {
        return;
      }

      clearInterval(waitInterval);
      resolve('');
    }, 100);
  });

  return cache[url];
};
