// TODO: typescriptify and refactor this module
const XandrTag = {
  setup({ element, jsTrackers }) {
    jsTrackers = jsTrackers || [];

    if (jsTrackers.length === 0) {
      return [];
    }

    let hasANTracker = false;
    jsTrackers.forEach((jsTracker, index, jsTrackersArray) => {
      if (XandrTag.isXandr(jsTracker)) {
        hasANTracker = true;
        jsTrackersArray[index] = jsTracker.replace('%native_dom_id%', '');
      }
    });

    if (hasANTracker) element.classList.add('anx_cr_loc');

    return jsTrackers;
  },

  isXandr: function isXandr(jsTracker) {
    return jsTracker.match(/\/\/cdn\.adnxs\.com.*\/trk\.js/);
  }
};

export default XandrTag;
