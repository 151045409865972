import { FunctionComponent, h } from 'preact';
import { SlideshowSlideProps } from '../types';
import { CarouselSlide } from '../../carousel 2/carousel.slide/carousel.slide';
import { CreativeSlide } from 'modules/butler/creative';
import './slideshow.slides.scss';

export const SlideshowSlide: FunctionComponent<SlideshowSlideProps> = ({ slides }) => {
  return (
    <div className={'slideshow-slides'}>
      {slides.map((slide: CreativeSlide, index: number) => {
        return (
          <div className={'slideshow-slide'} style={{ transform: `translateX(${index * 100}%)` }}>
            <CarouselSlide slide={slide} active={2} />
          </div>
        );
      })}
    </div>
  );
};
